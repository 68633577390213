import { observer } from 'mobx-react-lite'
import moment from 'moment'
import type React from 'react'
import { Platform, TouchableOpacity, View } from 'react-native'

import { SystemIcon } from '#components/base/SystemIcon'
import { Text } from '#components/base/Text'
import { redirectLearn } from '#components/utils/redirectLearn'
import { tw } from '#components/utils/tw'
import { ActionSheet } from '#components/widgets/ActionSheet'
import { CircularProgress } from '#components/widgets/CircularProgress'
import { navigate } from '#navigator/helpers'
import { S } from '#store'

type Props = {
  closeActionsheet: () => void
  topicId: string
  isCompleted: boolean
  lastDate: string
}

export const ActionSheetTopic: React.FC<Props> = observer(
  ({ closeActionsheet, topicId, lastDate }) => {
    const data = S.lessonPercentage.getLessonPercentageById(topicId)
    const handleButton = (type: 'learn' | 'speak') => () => {
      closeActionsheet()
      const currentDate = moment().format('YYYY-MM-DD')
      console.log('currentDate: ', currentDate)
      console.log('lastDate: ', lastDate)
      console.log('count: ', data.countVocabPhraseReview)
      console.log(
        'check: ',
        !moment(currentDate).isSame(moment(lastDate, 'YYYY-MM-DD'), 'day'),
      )
      if (
        (!lastDate ||
          !moment(currentDate).isSame(moment(lastDate, 'YYYY-MM-DD'), 'day')) &&
        data.countVocabPhraseReview > 0
      ) {
        navigate('App', { screen: 'VocabPhraseReview', params: { topicId } })
      } else {
        switch (type) {
          case 'learn':
            return redirectLearn({ ...data, topicId })
          case 'speak':
            return navigate('App', {
              screen: 'ScenarioSpeak',
              params: { topicId },
            })
          default:
            break
        }
      }
    }

    return (
      <ActionSheet
        isLoading={data.fetching}
        header='What do you want to do?'
        specialType='Headline3'
        containerStyle={tw.style('', Platform.OS !== 'web' && tw`h-110`)}
      >
        <View style={tw.style('flex-1 flex-col gap-y-4 w-full p-4 pt-2')}>
          <TouchableOpacity
            style={tw`w-full justify-center rounded-5 align-center py-4 px-5 bg-neutral-75 shadow`}
            onPress={handleButton('learn')}
          >
            <View
              style={tw`flex-1 rounded-full justify-center items-center self-center mb-1`}
            >
              <CircularProgress
                progressPercent={data.percentGame + data.percentListening}
                size={80}
                bgColor={tw.color('background-light-2')}
                pgColor={tw.color('primary-400')}
                backgroundColor={tw.color('background-light-2')}
              >
                <SystemIcon
                  type='SAX'
                  name='Teacher'
                  variant='Bold'
                  size={40}
                  color={tw.color('primary-400')}
                />
              </CircularProgress>
            </View>
            <View style={tw.style('self-center mb-2')}>
              <Text specialType='Title' color={tw.color('primary-400')}>
                Learn lessons
              </Text>
            </View>
          </TouchableOpacity>
          <TouchableOpacity
            style={tw`w-full justify-center rounded-5 align-center py-4 px-5 bg-neutral-75 shadow`}
            onPress={handleButton('speak')}
          >
            <View
              style={tw`flex-1 rounded-full justify-center items-center self-center mb-1`}
            >
              <CircularProgress
                progressPercent={data?.percentSpeaking}
                size={80}
                bgColor={tw.color('secondary1-50')}
                pgColor={tw.color('secondary1-500')}
                backgroundColor={tw.color('secondary1-50')}
              >
                <SystemIcon
                  type='SAX'
                  name='Microphone2'
                  variant='Bold'
                  size={40}
                  color={tw.color('secondary1-400')}
                />
              </CircularProgress>
            </View>
            <View style={tw.style('self-center mb-2')}>
              <Text specialType='Title' color={tw.color('secondary1-500')}>
                Role play
              </Text>
            </View>
          </TouchableOpacity>
        </View>
      </ActionSheet>
    )
  },
)
