import { useIsFocused } from '@react-navigation/native'
import { Button, Flex, Spin, Typography } from 'antd'
import { useEffect, useState } from 'react'

import { handleFileType } from '#admin/components/utils/checkFileType'
import { NavigateBackButton } from '#admin/components/utils/NavigateBackButton'
import { toastError, toastSuccess } from '#admin/components/utils/Toast'
import { tw } from '#components/utils/tw'
import type {
  ResourceInWord,
  SearchResourceInWordQuery,
} from '#graphql/codegen'
import { gql } from '#graphql/urql'
import type { AdminScreenProps } from '#navigator/types'
import type { Answer, GameType, InputQuestionGame } from '#types/games'
import { LabelGame, mediaGameT } from '#types/games'
import type { SearchWordItem } from '#types/word'

import { Answers } from './Answers'
import type { QuesPhraseType } from './HandleQuesFillTheBlank'
import {
  getAnsFromQuesPhrase,
  getQuesPhraseFromQuesUi,
  getQuesUiFromAns,
} from './HandleQuesFillTheBlank'
import { Question } from './Question'

export const QuesDetail = ({
  navigation,
  route,
}: AdminScreenProps<'AdminTopicQuesDetail'>) => {
  const isFocused = useIsFocused()
  const { wordId, topicId, quesId } = route.params

  const [wordData, setWordData] = useState<SearchWordItem | null>(null)

  const [resourceInWord, setResourceInWord] = useState<
    SearchResourceInWordQuery['searchResourceInWord']
  >([])

  const [reloadPage, setReloadPage] = useState<boolean>(true)
  const [loading, setLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [question, setQuestion] = useState<InputQuestionGame>({
    id: quesId ?? undefined,
    wordId: wordId ?? '',
    typeGame: 'sameOrDifferent',
    text: '',
    index: 0,
  })

  const [answers, setAnswers] = useState<Answer[]>([])
  const [questionPhrase, setQuestionPhrase] = useState<string>(
    wordData?.vocabAndPhrase ?? '',
  )

  useEffect(() => {
    if (isFocused) {
      fetchData()
    }
  }, [isFocused])

  const fetchData = async () => {
    setReloadPage(true)
    setAnswers([
      {
        audio: {
          url: '',
          name: '',
        },
        image: {
          url: '',
          name: '',
        },
        text: '',
        isTrue: true,
        mediaId: '',
        thumbnailId: '',
      },
    ])

    if (wordId && !topicId) {
      setQuestion({
        id: quesId ?? undefined,
        wordId,
        typeGame: 'sameOrDifferent',
        text: '',
        index: 0,
      })
    } else {
      setQuestion({
        id: quesId ?? undefined,
        wordId: wordId ?? '',
        typeGame: 'sameOrDifferent',
        text: '',
        index: 0,
      })
    }

    await fetchDataWord()
    if (quesId) {
      fetchAnswerInQuestion()
    } else {
      setReloadPage(false)
    }
  }
  const fetchDataWord = async () => {
    const responseResourceInWord = await gql.searchResourceInWord(
      {
        filter: { wordId },
        order: ['createdAt_desc'],
      },
      { requestPolicy: 'network-only' },
    )
    const resource = responseResourceInWord.data?.searchResourceInWord ?? []
    setResourceInWord(resource)
    const responseWord = await gql.searchWord(
      { filter: { id: wordId } },
      { requestPolicy: 'network-only' },
    )
    const word = responseWord.data?.searchWord[0] || null
    setWordData(word)
    setQuestion(currentState => {
      const videoInfo = resource?.find(e => e?.type === 'video_game')
      const audioInfo = resource?.find(e => e?.type === 'audio_game')
      const media = { image: word, audio: audioInfo, video: videoInfo }

      return {
        ...currentState,
        text: word?.vocabAndPhrase ?? '',
        media: {
          mediaId: media?.[mediaGameT[currentState.typeGame]]?.mediaId || '',
          url: media?.[mediaGameT[currentState.typeGame]]?.media?.url || '',
          name: media?.[mediaGameT[currentState.typeGame]]?.media?.name || '',
        },
        thumbnail:
          currentState.typeGame === 'sameOrDifferent'
            ? {
                mediaId: media?.image?.mediaId || '',
                url: media?.image?.media?.url || '',
                name: media?.image?.media?.name || '',
              }
            : undefined,
      }
    })
    setDataAnswers(word, resource as ResourceInWord[])
  }

  const fetchAnswerInQuestion = async () => {
    try {
      const response = await gql.searchAnswerInQuestion(
        {
          filter: { questionId: quesId },
          order: ['createdAt_asc'],
        },
        { requestPolicy: 'network-only' },
      )
      const res = response.data?.searchAnswerInQuestion ?? []
      const searchQuestion = await gql.searchQuestion(
        { filter: { id: quesId } },
        { requestPolicy: 'network-only' },
      )
      const q = searchQuestion.data?.searchQuestion || []

      const videoInfo = q?.find(
        e => handleFileType(e.media?.url ?? '').split('/')[0] === 'video',
      )
      const audioInfo = q?.find(
        e => handleFileType(e.media?.url ?? '').split('/')[0] === 'audio',
      )
      const media = { image: q[0], audio: audioInfo, video: videoInfo }

      setQuestion({
        ...q[0],
        id: quesId,
        wordId: q[0]?.wordId ?? '',
        typeGame: (q[0]?.type as GameType) ?? 'sameOrDifferent',
        text: q[0]?.text ?? '',
        media:
          q[0].type === 'correctTranslation' && media.video !== undefined
            ? {
                mediaId: media?.video?.mediaId || '',
                name: media?.video?.media?.name || '',
                url: media?.video?.media?.url || '',
              }
            : {
                mediaId: media?.[mediaGameT[q[0].type]]?.mediaId || '',
                name: media?.[mediaGameT[q[0].type]]?.media?.name || '',
                url: media?.[mediaGameT[q[0].type]]?.media?.url || '',
              },
        thumbnail: ['sameOrDifferent', 'correctPhrase'].includes(q[0].type)
          ? {
              mediaId: media?.image?.thumbnailId || '',
              url: media?.image?.thumbnail?.url || '',
              name: media?.image?.thumbnail?.name || '',
            }
          : undefined,
      })
      if (res.length > 0) {
        const fetchedAnswers = res.map(item => ({
          id: item.id,
          isTrue: item.isTrue,
          text: item.answer?.text,
          audio: {
            url: item?.media?.url ?? '',
            name: item?.media?.name ?? '',
          },
          image: {
            url: item?.thumbnail?.url ?? '',
            name: item?.thumbnail?.name ?? '',
          },
          answerId: item.answerId,
          mediaId: item.mediaId,
          thumbnailId: item.thumbnailId,
        }))
        setAnswers(fetchedAnswers)
      } else if (!q || q[0]?.type === 'sameOrDifferent') {
        setDataAnswers(wordData, resourceInWord || [])
      }
    } catch (error) {
      toastError({ message: 'Failed to load question data' })
    } finally {
      setReloadPage(false)
    }
  }

  const setDataAnswers = (
    w: SearchWordItem | null | undefined,
    r: SearchResourceInWordQuery['searchResourceInWord'],
  ) => {
    setAnswers([
      {
        audio: {
          url: r?.find(e => e.type === 'audio_game')?.media?.url || '',
          name: r?.find(e => e.type === 'audio_game')?.media?.name || '',
        },
        image: {
          url: w?.media?.url || '',
          name: w?.media?.name || '',
        },
        text: LabelGame.LabelSame,
        isTrue: true,
        thumbnailId: w?.mediaId || '',
        mediaId: r?.find(e => e.type === 'image_game')?.mediaId || '',
      },
      {
        audio: {
          url: r?.find(e => e.type === 'audio_game')?.media?.url || '',
          name: r?.find(e => e.type === 'audio_game')?.media?.name || '',
        },
        image: {
          url: w?.media?.url || '',
          name: w?.media?.name || '',
        },
        text: LabelGame.LabelDifferent,
        isTrue: false,
        thumbnailId: w?.mediaId || '',
        mediaId: r?.find(e => e.type === 'image_game')?.mediaId || '',
      },
    ])
  }

  const checkQuestionFillBlank = () => {
    if (question.typeGame === 'fillTheBlank') {
      if (!question.text?.includes('...')) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }

  const updateQuestion = async () => {
    try {
      setLoading(true)
      let answer
      if (question.typeGame === 'fillTheBlank' && !wordData?.isVocabulary) {
        answers.map(e => {
          if (e.isTrue) {
            answer = { id: e.answerId, text: e.text }
          }
        })
        await gql.updateAnswer({
          id: answer.id ?? '',
          data: { text: answer.text },
        })
      }
      const updateQues = await gql.updateQuestion({
        data: {
          text: question.text,
          topicId,
          mediaId: question?.media?.mediaId ?? '',
          thumbnailId: question?.thumbnail?.mediaId ?? '',
          index: question?.index,
        },
        id: quesId || '',
      })

      if (updateQues.data?.updateQuestion) {
        toastSuccess({ message: 'Update question successfully' })
      } else {
        toastError({ message: 'Failed to save question' })
      }
    } catch (error) {
      toastError({ message: `Failed to save answers: ${error}` })
    } finally {
      setLoading(false)
    }
  }
  const addNewQuestion = async () => {
    try {
      setLoading(true)
      setIsLoading(true)
      const addQues = await gql.createQuestion({
        data: {
          topicId,
          text: question.text || wordData?.vocabAndPhrase,
          type: question.typeGame,
          wordId: question.wordId,
          mediaId: question?.media?.mediaId ?? '',
          thumbnailId: question.thumbnail?.mediaId ?? '',
          ...(question.typeGame === 'completeTheDialogue' && {
            index: question.index,
          }),
        },
      })

      if (addQues.data?.createQuestion) {
        const filteredAnswers = answers.map(answer => ({
          id: answer.id ?? undefined,
          text: answer.text || '',
          image: answer.image || '',
          audio: answer.audio || '',
          isTrue: answer.isTrue,
          mediaId: answer.mediaId,
          thumbnailId: answer.thumbnailId,
        }))
        filteredAnswers.map(async (answer, index) => {
          const createAnswerData = await gql.createAnswer({
            data: {
              text: answer.text,
            },
          })
          await gql.createAnswerInQuestion({
            data: {
              questionId: addQues.data?.createQuestion.id || '',
              answerId: createAnswerData.data?.createAnswer.id,
              isTrue: answer.isTrue,
              mediaId: answer.mediaId,
              thumbnailId: answer.thumbnailId,
            },
          })
        })
        handleNavigateBack()
        toastSuccess({ message: 'Create question & answers successfully' })
      } else {
        toastError({ message: 'Failed to create question' })
      }
    } catch (error) {
      toastError({ message: `Failed to save answers: ${error}` })
    } finally {
      setLoading(false)
    }
  }

  const handleNavigateBack = () => {
    if (navigation.canGoBack()) {
      navigation.goBack()
    } else {
      navigation.navigate('AdminTopicQues', {
        wordId: wordId ?? '',
        topicId: topicId ?? '',
      })
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (
      question.typeGame === 'fillTheBlank' &&
      quesId &&
      !wordData?.isVocabulary
    ) {
      const quesUi = getQuesUiFromAns(
        question.text,
        answers.find(e => e.isTrue)?.text ?? '',
        wordData?.vocabAndPhrase ?? '',
      )
      const quesPhrase = getQuesPhraseFromQuesUi(
        quesUi,
        wordData?.vocabAndPhrase ?? '',
        question.text,
      )

      const ansText = getAnsFromQuesPhrase(
        wordData?.vocabAndPhrase ?? '',
        quesPhrase,
      )

      setAnswers(prev =>
        prev.map(answer =>
          answer.isTrue ? { ...answer, text: ansText } : { ...answer },
        ),
      )
    }
  }, [quesId])

  useEffect(() => {
    if (question.typeGame === 'fillTheBlank' && !wordData?.isVocabulary) {
      if (quesId) {
        const ansArr = wordData?.vocabAndPhrase?.split(' ')
        const quesPhrase: QuesPhraseType = {
          quesPhrase: questionPhrase ?? '',
          word1: -1,
          word2: -1,
        }
        questionPhrase.split(' ').map((e, index) => {
          if (e === '...' && ansArr) {
            if (quesPhrase.word1 === -1) {
              quesPhrase.word1 = index
            } else {
              quesPhrase.word2 = index
            }
          }
        })
        const ansText = getAnsFromQuesPhrase(
          wordData?.vocabAndPhrase ?? '',
          quesPhrase,
        )

        setAnswers(prev =>
          prev.map(answer =>
            answer.isTrue ? { ...answer, text: ansText } : { ...answer },
          ),
        )
      } else {
        const quesArr = questionPhrase?.split(' ') || []
        const ansArr = wordData?.vocabAndPhrase?.split(' ')
        let ansText = ''
        quesArr.map((e, index) => {
          if (e === '...' && ansArr) {
            if (ansText !== '') {
              ansText += ' ... '
            }
            ansText = ansText + ansArr[index]
          }
        })
        if (ansText !== '') {
          setAnswers(e =>
            e.map(answer =>
              answer.isTrue ? { ...answer, text: ansText } : { ...answer },
            ),
          )
        } else {
          setAnswers(e =>
            e.map(answer =>
              answer.isTrue
                ? { ...answer, text: wordData?.vocabAndPhrase }
                : { ...answer },
            ),
          )
        }
      }
    }
  }, [questionPhrase])
  const checkAudioFile = () => {
    const temp = ['completeTheDialogue', 'fillTheBlank', 'rearrangement']
    let check = false
    if (temp?.includes(question.typeGame)) {
      check = false
    } else {
      if (question.media?.mediaId && question.media?.url) {
        check = false
      } else {
        check = true
      }
    }
    return check
  }
  return (
    <Flex vertical style={tw`relative flex-1 p-4 `}>
      <Flex style={tw`flex items-center`}>
        <NavigateBackButton direction={handleNavigateBack} disabled={loading} />
        <Flex style={tw`flex-1 flex-col`}>
          <Typography.Text style={tw`text-6 px-2`}>Question</Typography.Text>
        </Flex>
        <Button
          style={tw` m-4`}
          type='primary'
          onClick={!question.id ? addNewQuestion : updateQuestion}
          loading={loading || isLoading}
          disabled={
            reloadPage ||
            (answers.length < 2 && question.typeGame !== 'rearrangement') ||
            (!question.text &&
              ['rearrangement', 'completeTheDialogue'].includes(
                question.typeGame,
              )) ||
            checkQuestionFillBlank() ||
            checkAudioFile()
          }
        >
          {!question.id ? 'Create question' : 'Update question'}
        </Button>
      </Flex>
      {reloadPage ? (
        <Flex style={tw`flex-1 flex items-center justify-center`}>
          <Spin />
        </Flex>
      ) : (
        <Flex
          vertical
          style={{
            overflowY: 'scroll',
            height: '85vh',
            width: '75vw',
            alignSelf: 'center',
          }}
        >
          {isLoading && (
            <Flex
              style={tw.style({
                position: 'absolute',
                top: '50%',
                left: '50%',
                zIndex: 1,
              })}
            >
              <Spin />
            </Flex>
          )}
          <Question
            question={question}
            resourceInWord={resourceInWord as ResourceInWord[]}
            answers={answers ?? []}
            questionPhrase={questionPhrase ?? wordData?.vocabAndPhrase}
            setQuestionPhrase={setQuestionPhrase}
            setQuestion={(q: Partial<InputQuestionGame>) => {
              if ('typeGame' in q && q.typeGame !== question.typeGame) {
                const videoInfo = resourceInWord?.find(
                  e => e?.type === 'video_game',
                )
                const audioInfo = resourceInWord?.find(
                  e => e?.type === 'audio_game',
                )
                const media = {
                  image: wordData,
                  audio: audioInfo,
                  video: videoInfo,
                }
                setQuestion(currentState => ({
                  ...currentState,
                  ...q,
                  media: {
                    mediaId: media?.[mediaGameT[q?.typeGame!]]?.mediaId || '',
                    name: media?.[mediaGameT[q?.typeGame!]]?.media?.name || '',
                    url: media?.[mediaGameT[q?.typeGame!]]?.media?.url || '',
                  },
                  thumbnail:
                    q.typeGame === 'sameOrDifferent'
                      ? {
                          mediaId: media?.image?.mediaId || '',
                          url: media?.image?.media?.url || '',
                          name: media?.image?.media?.name || '',
                        }
                      : undefined,
                }))
                if (q.typeGame === 'sameOrDifferent') {
                  setDataAnswers(wordData, resourceInWord || [])
                } else {
                  setAnswers([
                    {
                      audio: {
                        url:
                          resourceInWord?.find(e => e.type === 'audio_game')
                            ?.media?.url || '',
                        name:
                          resourceInWord?.find(e => e.type === 'audio_game')
                            ?.media?.name || '',
                      },

                      image: {
                        url:
                          wordData?.media?.type === 'image_game'
                            ? wordData?.media?.url
                            : '',
                        name:
                          wordData?.media?.type === 'image_game'
                            ? wordData?.media?.name
                            : '',
                      },

                      text: wordData?.vocabAndPhrase || '',
                      isTrue: true,
                      mediaId:
                        resourceInWord?.find(e => e.type === 'audio_game')
                          ?.mediaId || '',
                      thumbnailId: wordData?.mediaId || '',
                    },
                  ])
                }
              } else {
                setQuestion(currentState => ({
                  ...currentState,
                  ...q,
                }))
              }
            }}
            isEditing={!!quesId}
            word={wordData}
          />
          <Answers
            question={question}
            answers={answers}
            disable={loading}
            resourceInWord={resourceInWord as ResourceInWord[]}
            setAnswer={({ text, index, type }) => {
              switch (type) {
                case 'Add': {
                  setAnswers(s => [...s, text])
                  break
                }
                case 'Remove': {
                  setAnswers(s => s.filter((_, i) => i !== index))
                  break
                }
                case 'UpdateCorrect': {
                  setAnswers(s =>
                    s.map((item, idx) => ({ ...item, isTrue: idx === index })),
                  )
                  break
                }
                default: {
                  setAnswers(s => {
                    const newAnswers = [...s]
                    newAnswers[index] = text
                    return newAnswers
                  })
                  break
                }
              }
            }}
          />
        </Flex>
      )}
    </Flex>
  )
}
