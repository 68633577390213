import { zodResolver } from '@hookform/resolvers/zod'
import { GoogleSignin } from '@react-native-google-signin/google-signin'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Platform, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import type { z } from 'zod'

import { images } from '#assets'
import { Button } from '#components/base/Button/Button'
import { Image } from '#components/base/Image'
import { SystemIcon } from '#components/base/SystemIcon'
import { Text } from '#components/base/Text'
import { FormField } from '#components/form/FormField'
import { Input } from '#components/form/Input'
import { InputPassword } from '#components/form/InputPassword'
import { useOverlay } from '#components/overlay/hooks'
import { tw } from '#components/utils/tw'
import { BackgroundAuth } from '#components/widgets/BackgroundAuth'
import { Divider } from '#components/widgets/Divider'
import { Header } from '#components/widgets/Header'
import { WarningPassword } from '#components/widgets/WarningPassword'
import type { AuthStackScreenProps } from '#navigator/types'

import { ButtonSocial } from './ButtonSocial'
import { CombinedSchema, EmailSchema, onCheckEmail } from './onCheckEmail'
import { onLoginFacebook } from './onLoginFacebook'
import { onLoginGoogle } from './onLoginGoogle'
import { onLoginSubmit } from './onLoginSubmit'
import type { RegisterFormValues } from './onRegisterSubmit'
import { onRegisterSubmit } from './onRegisterSubmit'

type ButtonType = 'initial' | 'validEmail' | 'registering'
type LoadingIcon = 'google' | 'apple' | 'facebook' | null

export type EmailInputFormValues = z.infer<typeof EmailSchema>
export type LoginFormValues = z.infer<typeof CombinedSchema>

export const Login = ({ navigation }: AuthStackScreenProps<'Login'>) => {
  const {
    control: emailControl,
    watch: emailWatch,
    clearErrors: clearEmailErrors,
    handleSubmit: handleSubmitEmail,
  } = useForm<EmailInputFormValues>({
    resolver: zodResolver(EmailSchema),
    shouldFocusError: false,
  })

  const { control, watch, setValue } = useForm<LoginFormValues>({
    resolver: zodResolver(CombinedSchema),
    shouldFocusError: false,
  })
  const [buttonState, setButtonState] = useState<ButtonType>('initial')
  const [isLoading, setIsLoading] = useState(false)
  const [passwordCondition, setPasswordCondition] = useState(false)
  const [loading, setLoading] = useState<LoadingIcon>(null)

  const emailValue = emailWatch('email')
  const passwordValue = watch('password')
  const { openModal } = useOverlay()
  const insets = useSafeAreaInsets()

  const values: RegisterFormValues = {
    email: emailValue,
    password: passwordValue,
  }

  useEffect(() => {
    GoogleSignin.configure({
      webClientId:
        '283605109549-qrrg1h41lojaqu9l4i7getunn7sf7hpo.apps.googleusercontent.com',
    })
  }, [])

  const handleGoogle = async () => {
    setLoading('google')
    await onLoginGoogle()
    setLoading(null)
  }
  const handleFacebook = async () => {
    setLoading('facebook')
    await onLoginFacebook()
    setLoading(null)
  }

  const handleApple = async () => {
    setLoading('apple')
    // await onLoginGoogle()
    setTimeout(() => {
      setLoading(null)
    }, 5000)
  }

  const handleButtonPress = async () => {
    setIsLoading(true)
    if (buttonState === 'initial') {
      const isPassed = await onCheckEmail(emailValue)
      setButtonState(isPassed ? 'validEmail' : 'registering')
    } else if (buttonState === 'validEmail') {
      await onLoginSubmit({ email: emailValue, password: passwordValue })
    } else if (buttonState === 'registering' && passwordCondition) {
      setButtonState('registering')
      await onRegisterSubmit(values, openModal)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      setValue('password', '')
    })
    return unsubscribe
  }, [navigation, setValue])

  const onPressBack = () => {
    setButtonState('initial')
    setValue('password', '')
  }

  const onPressPrivacyPolicy = () => {
    navigation.navigate('PrivacyPolicy')
  }

  const onPressTermOfUse = () => {
    navigation.navigate('TermsOfUse')
  }

  const renderTitle = () => {
    const title: { [key in ButtonType]: { title: string; subTitle: string } } =
      {
        initial: { title: 'Sign in or create account', subTitle: '' },
        validEmail: { title: 'Sign in', subTitle: 'Hello, welcome back' },
        registering: {
          title: 'Create account',
          subTitle: 'Just a few thing to get started',
        },
      }
    return (
      <View
        style={tw.style(
          'items-center',
          buttonState === 'initial' ? 'pt-14' : 'pt-8',
        )}
      >
        {buttonState === 'initial' && (
          <View style={tw`items-center pb-6`}>
            <Image source={images.logo} style={tw.style('w-35 h-30')} />
          </View>
        )}
        <View style={tw`items-center gap-2 pb-8`}>
          <Text textAlign='center' specialType='Headline2'>
            {title[buttonState].title}
          </Text>
          {buttonState !== 'initial' && (
            <Text
              textAlign='center'
              specialType='Title'
              color={tw.color('text-2')}
            >
              {title[buttonState].subTitle}
            </Text>
          )}
        </View>
      </View>
    )
  }

  return (
    <View
      style={tw.style(
        'flex-1 flex-col bg-background-light-1',
        Platform.OS === 'ios' &&
          buttonState === 'initial' && {
            paddingTop: Math.max(insets.top, 16),
          },
      )}
    >
      {buttonState !== 'initial' && <Header onPressBack={onPressBack} />}
      <BackgroundAuth>
        {renderTitle()}
        <View style={tw`gap-4`}>
          <FormField name='email' control={emailControl}>
            <Input
              style={tw.style(
                buttonState === 'validEmail' ? 'text-text-2' : '',
              )}
              autoCapitalize='none'
              placeholder='Type your email here'
              value={emailValue}
              keyboardType='email-address'
              onChangeText={() => {
                clearEmailErrors('email')
              }}
              onSubmitEditing={handleSubmitEmail(handleButtonPress)}
              // suffix={
              //   buttonState !== 'initial' && (
              //     <ButtonIcon
              //       icon={{
              //         name: 'Edit2',
              //         type: 'SAX',
              //       }}
              //       shadow={false}
              //       onPress={onPressBack}
              //       style={tw`p-0 bg-transparent`}
              //       tone='secondary'
              //       size={24}
              //     />
              //   )
              // }
              editable={buttonState === 'initial'}
              autoFocus
              prefix={
                <View style={tw.style('pr-2')}>
                  <SystemIcon
                    type='SAX'
                    name='Sms'
                    color={tw.color(
                      buttonState === 'validEmail' ? 'neutral-300' : 'icon',
                    )}
                  />
                </View>
              }
            />
          </FormField>

          {(buttonState === 'validEmail' || buttonState === 'registering') && (
            <View>
              <FormField name='password' control={control}>
                <InputPassword
                  spellCheck={false}
                  onSubmitEditing={handleSubmitEmail(handleButtonPress)}
                  autoFocus
                  placeholder='Enter your password'
                  prefix={
                    <View style={tw.style('pr-2')}>
                      <SystemIcon type='SAX' name='Lock1' />
                    </View>
                  }
                />
              </FormField>
              {buttonState === 'validEmail' && (
                <Button
                  onPress={() =>
                    navigation.navigate('ForgotPassword', {
                      emailValue,
                    })
                  }
                  size='plain'
                  tone='plain'
                  style={tw`self-end`}
                  titleColor={tw.color('primary-400')}
                >
                  <Text
                    color={tw.color('primary-400')}
                    specialType='paragraph2'
                  >
                    Forgot your password?
                  </Text>
                </Button>
              )}
            </View>
          )}
          <Button
            tone='primary'
            onPress={handleSubmitEmail(handleButtonPress)}
            disabled={
              !emailValue ||
              (buttonState === 'validEmail' && !passwordValue) ||
              (buttonState === 'registering' && !passwordCondition)
            }
            loading={isLoading}
          >
            Continue
          </Button>
          {buttonState === 'registering' && (
            <WarningPassword
              password={passwordValue || ''}
              onAllConditionsMet={isValid => {
                setPasswordCondition(isValid)
              }}
            />
          )}
        </View>
        {buttonState === 'initial' && (
          <View style={tw`flex-col`}>
            <Divider.WithText text='Or' style={tw`my-8`} />
            <View style={tw`flex-col gap-y-3`}>
              <ButtonSocial
                text='Sign in with Google   '
                icon='google'
                onPress={handleGoogle}
                loading={loading === 'google'}
                disable={!!loading}
              />
              <ButtonSocial
                text='Sign in with Apple     '
                icon='apple'
                onPress={handleApple}
                loading={loading === 'apple'}
                disable={!!loading}
              />
              <ButtonSocial
                text='Sign in with Facebook'
                icon='facebook'
                onPress={handleFacebook}
                loading={loading === 'facebook'}
                disable={!!loading}
              />
            </View>
          </View>
        )}
      </BackgroundAuth>

      {buttonState === 'initial' && (
        <View
          style={tw.style('flex-col items-center', {
            paddingBottom: Math.max(insets.bottom, 16),
          })}
        >
          <Text
            textAlign='center'
            color={tw.color('text-2')}
            specialType='Note'
          >
            By sign in you agree to
          </Text>
          <Text
            textAlign='center'
            color={tw.color('text-2')}
            specialType='Note'
          >
            <Text
              color={tw.color('primary-400')}
              specialType='Note2'
              onPress={onPressPrivacyPolicy}
            >
              privacy policy
            </Text>
            {' and '}
            <Text
              color={tw.color('primary-400')}
              specialType='Note2'
              onPress={onPressTermOfUse}
            >
              terms of use
            </Text>
          </Text>
        </View>
      )}
    </View>
  )
}
