import type {
  Stripe,
  StripeElements,
  StripeExpressCheckoutElement,
  StripeExpressCheckoutElementOptions,
} from '@stripe/stripe-js'

import { isCurrencySupport } from './utils/CheckCurrency'

type PaymentRequestOptions = {
  currency: string
  amount: number | undefined
}

type TypePayment = 'googlePay' | 'applePay'

var ex: StripeExpressCheckoutElement | undefined

export const checkOrtherPaymentSupported = async (
  stripe: Stripe,
  tp: TypePayment,
) => {
  if (!stripe) {
    return
  }
  // Create this request to check if the payment method
  // is supported in the browser in uses
  const pr = stripe.paymentRequest({
    country: 'US',
    currency: 'usd',
    total: {
      label: 'Check support',
      amount: 1000,
    },
    requestPayerName: true,
    requestPayerEmail: true,
  })

  const r = await pr.canMakePayment()
  if (tp === 'googlePay') {
    return r?.googlePay ?? false
  }
  return r?.applePay ?? false
}

export const createOrtherPaymentRequest = async (
  stripe: Stripe,
  { currency, amount }: PaymentRequestOptions,
  tp: TypePayment,
) => {
  if (!amount) {
    return null
  }
  unmountOrtherPay()
  const elements = stripe.elements({
    mode: 'payment',
    amount: amount * 100,
    currency: isCurrencySupport(currency),
    setup_future_usage: 'off_session',
    appearance: {
      theme: 'stripe',
      variables: {
        borderRadius: '26px',
      },
      labels: 'floating',
    },
  })
  const o: StripeExpressCheckoutElementOptions = {
    buttonType: {
      googlePay: 'buy',
      applePay: 'buy',
    },
  }

  if (tp === 'googlePay') {
    o.paymentMethods = {
      applePay: 'never',
      googlePay: 'always',
    }
  } else {
    o.paymentMethods = {
      googlePay: 'never',
      applePay: 'always',
    }
  }
  const containerId = tp === 'googlePay' ? '#ggpay' : '#applepay'

  const expressCheckoutElement = elements.create('expressCheckout', o)
  ex = expressCheckoutElement

  setTimeout(() => {
    expressCheckoutElement.mount(containerId)
  }, 100)
  expressCheckoutElement.on('click', event => {
    const options = {
      emailRequired: true,
    }
    event.resolve(options)
  })
  return elements
}

export const confirmGgPayement = async (
  elements: StripeElements,
  stripe: Stripe,
  cli: string,
) => {
  if (!ex) {
    return false
  }

  if (!stripe || !elements) {
    return false
  }
  return new Promise((r, rj) => {
    ex?.on('confirm', async () => {
      const { error: submitError } = await elements.submit()
      if (submitError) {
        r(false)
      }

      const { error } = await stripe.confirmPayment({
        elements,
        clientSecret: cli,
        confirmParams: {
          return_url: 'https://cody.nongdan.dev/payment',
        },
        redirect: 'if_required',
      })

      r(!error)
    })
  })
}

export const unmountOrtherPay = () => {
  if (ex) {
    ex.unmount()
    ex.off('click')
    ex.off('confirm')
  }
}
