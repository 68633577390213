import { observer } from 'mobx-react-lite'
import moment from 'moment'
import { useEffect, useState } from 'react'
import {
  Animated,
  Platform,
  Pressable,
  TouchableOpacity,
  useWindowDimensions,
  View,
} from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import type { NavigationState, SceneRendererProps } from 'react-native-tab-view'
import { TabView } from 'react-native-tab-view'

import { images } from '#assets'
import { Image } from '#components/base/Image'
import { ScrollView } from '#components/base/ScrollView'
import { SystemIcon } from '#components/base/SystemIcon'
import { Text } from '#components/base/Text'
import { useOverlay } from '#components/overlay/hooks'
import { tw } from '#components/utils/tw'
import { useSearchStreakInUser, useSearchUser } from '#graphql/codegen'
import { gql } from '#graphql/urql'
import { AvatarProfile } from '#screens/Profile/AvatarProfile'
import { Streak } from '#screens/Streak/Streak'
import { S } from '#store'

// import { navigate } from '#navigator/helpers'
import { CompletedTab } from './CompletedTab'
import { LessonsTab } from './LessonsTab'

type Route = {
  key: string
  title: string
}

type State = NavigationState<Route>

export const Topics = observer(() => {
  const layout = useWindowDimensions()
  const insets = useSafeAreaInsets()

  const { openModal } = useOverlay()

  const [tabIndex, setTabIndex] = useState<number>(0)
  const [routes] = useState<Route[]>([
    { key: 'lessons', title: 'Lessons' },
    { key: 'completed', title: 'Completed' },
  ])

  const [d] = useSearchUser({
    variables: {
      filter: {
        OR: [
          { email_iLike: `%${S.shared.currentUser?.email}%` },
          { name_iLike: `%${S.shared.currentUser?.name}%` },
        ],
      },
      order: ['name_asc'],
    },
  })
  const thumbnailUser = d?.data?.searchUser[0]?.thumbnail?.url
  const nameUser = d?.data?.searchUser[0]?.name

  const [streakData, refetch] = useSearchStreakInUser({
    variables: { filter: { userId: S.shared.currentUser?.id } },
  })
  const streakInUser = streakData?.data?.searchStreakInUser || []
  const lastDate = streakInUser.length > 0 ? streakInUser[0].lastDate : ''
  const newDate = moment().format('YYYY-MM-DD')
  const yesterday = moment(newDate).subtract(1, 'days').format()
  const count = streakInUser.length > 0 ? streakInUser[0].countStreak : 0
  const countStreak = moment(lastDate).isSame(moment(newDate).format(), 'day')
    ? count
    : !lastDate || !moment(lastDate).isSame(yesterday, 'day')
      ? 0
      : count

  useEffect(() => {
    const initializeStreakData = async () => {
      if (!streakInUser.length && !streakData.fetching) {
        await gql.createStreakInUser({
          data: {
            countStreak: 0,
            dateStreak: { attendance: [] },
            userId: S.shared.currentUser?.id || '',
            streakId: '',
            highestStreak: 0,
            lastDate: '',
          },
        })
        refetch({ requestPolicy: 'network-only' })
      }
    }

    initializeStreakData()
  }, [streakData.fetching])

  const onStreakIconPress = () => {
    // navigate('App', { screen: 'StreakDaily', params: {} })
    if (!streakData.fetching) {
      openModal(Streak, {
        userId: S.shared.currentUser?.id || '',
      })
    }
  }

  const renderItem =
    ({
      navigationState,
      position,
    }: {
      navigationState: State
      position: Animated.AnimatedInterpolation<number>
    }) =>
    ({ route, index }: { route: Route; index: number }) => {
      const inputRange = navigationState.routes.map((_, i) => i)
      const activeOpacity = position.interpolate({
        inputRange,
        outputRange: inputRange.map((i: number) => (i === index ? 1 : 0)),
      })
      const inactiveOpacity = position.interpolate({
        inputRange,
        outputRange: inputRange.map((i: number) => (i === index ? 0 : 1)),
      })

      return (
        <View style={tw.style('py-3 items-center')}>
          <Animated.View
            style={[
              tw.style('items-center justify-center'),
              { opacity: inactiveOpacity },
            ]}
          >
            <View style={tw.style('items-center justify-center px-1.5')}>
              <Text
                specialType='Headline3'
                textAlign='center'
                color={tw.color('text-3')}
              >
                {route.title}
              </Text>
              <Animated.View
                style={tw.style('w-full h-1', { opacity: inactiveOpacity })}
              />
            </View>
          </Animated.View>
          <Animated.View
            style={[
              tw.style('items-center justify-center absolute inset-0'),
              { opacity: activeOpacity },
            ]}
          >
            <View style={tw.style('items-center justify-center px-1.5')}>
              <Text
                specialType='Headline3'
                textAlign='center'
                color={tw.color('primary-400')}
              >
                {route.title}
              </Text>
            </View>
            <Animated.View
              style={tw.style('w-full h-1 rounded-full bg-primary-400', {
                opacity: activeOpacity,
              })}
            />
          </Animated.View>
        </View>
      )
    }

  const renderTabBar = (
    props: SceneRendererProps & { navigationState: NavigationState<Route> },
  ) => (
    <View
      style={tw.style(
        'flex-row items-center mt-2 px-4 pb-4 border-b border-neutral-200',
        Platform.OS === 'ios' && {
          paddingTop: Math.max(insets.top),
        },
      )}
    >
      <AvatarProfile
        key={thumbnailUser}
        size='large'
        name={nameUser}
        style={tw`w-12 h-12 rounded-full`}
        source={{
          uri: thumbnailUser,
        }}
        isImage={!thumbnailUser}
      />
      <View style={tw`flex-1 flex-row items-center px-2 justify-center`}>
        <ScrollView
          horizontal
          contentContainerStyle={tw`w-full justify-center items-center`}
        >
          {props.navigationState.routes.map((route: Route, index: number) => (
            <Pressable key={route.key} onPress={() => props.jumpTo(route.key)}>
              {renderItem(props)({ route, index })}
            </Pressable>
          ))}
        </ScrollView>
      </View>
      <TouchableOpacity
        style={tw.style(
          'rounded-2xl bg-background-light-white w-11 h-11 items-center justify-center',
        )}
        onPress={onStreakIconPress}
        disabled={streakData.fetching}
      >
        {countStreak < 1 ? (
          <SystemIcon type='SVG' name={'streak-icon-none'} size={27} />
        ) : (
          <Image
            resizeMode='contain'
            source={images.image_streak}
            style={tw`w-6.5 h-6.5`}
          />
        )}
      </TouchableOpacity>
    </View>
  )

  const renderScene = ({ route }: { route: Route }) => {
    switch (route.key) {
      case 'lessons':
        return <LessonsTab />
      case 'completed':
        return <CompletedTab />
      default:
        return null
    }
  }

  return (
    <View style={tw.style('flex-1')}>
      <TabView
        navigationState={{ index: tabIndex, routes }}
        renderScene={renderScene}
        onIndexChange={setTabIndex}
        initialLayout={{ width: layout.width }}
        renderTabBar={renderTabBar}
        lazy
      />
    </View>
  )
})
