import ColorHash from 'color-hash'
import type { IAvatarProps } from 'native-base'
import { Avatar as NbAvatar, View } from 'native-base'
import { Text } from 'react-native'
import type { Style } from 'twrnc'

import { tw } from '#components/utils/tw'

export type AvatarProps = Omit<IAvatarProps, 'style'> & {
  id?: string
  name?: string
  style?: Style
  isImage?: boolean
}

const colorHash = new ColorHash()
export const idToColorHex = (id: string) => colorHash.hex(id)

const AvatarProfile = ({
  isImage,
  id = '',
  name = '',
  style,
  ...props
}: AvatarProps) => (
  <View style={tw`relative w-10 h-10 items-center justify-center`}>
    <NbAvatar
      {...props}
      style={tw.style(style, {
        backgroundColor: idToColorHex(id),
      })}
    />

    {isImage === true && (
      <Text style={tw`absolute text-white text-lg font-bold`}>
        {name?.[0]?.toUpperCase?.()}
      </Text>
    )}
  </View>
)

AvatarProfile.Group = NbAvatar.Group
export { AvatarProfile }
