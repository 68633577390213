import {
  CardNumberElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import type { StripeCardNumberElement } from '@stripe/stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { observer } from 'mobx-react-lite'
import { ScrollView } from 'moti/build/components'
import { useEffect, useRef, useState } from 'react'
import { FlatList, Platform, View } from 'react-native'

import { Button } from '#components/base/Button/Button'
import { Text } from '#components/base/Text'
import { toastError } from '#components/utils/Toast'
import { tw } from '#components/utils/tw'
import { Dropdown } from '#components/widgets/DropDown'
import { PUBLISHABLE_KEY_STRIPE } from '#config'
import type { Code } from '#graphql/codegen'
import { gql } from '#graphql/urql'
import { S } from '#store'
import type { ProductItem } from '#types/product'

import { CannotContinue } from './component/CannotContinue'
import { CardInforInput } from './component/CardInforInput'
import { CardPaymentInput } from './component/CardPaymentInput'
import { ItemPayment } from './component/ItemPayment'
import { ZipCodeInput } from './component/ZipCodeInput'
import { countries } from './country'
import { ExistingCardPayment } from './ExistingCardPayment'
import {
  checkOrtherPaymentSupported,
  confirmGgPayement,
  createOrtherPaymentRequest,
} from './OtherPayment.web'
import { PaymentNotSupport } from './PaymentNotSupport'
import { PaymentStatus } from './PaymentSatus'
import type { CardSavedType } from './type'
import { createPaymentIntentApi } from './utils/api'
import { getCurrencySymbol } from './utils/getCurrencySymbols'
import { navigateTopic } from './utils/navigate'
import { useStatusPayment } from './utils/useStatusPayment'
import { paymentMethods } from './utils/utils'

const stripePromise = loadStripe(PUBLISHABLE_KEY_STRIPE)

const CheckoutForm: React.FC<ActionSheetStripeProps> = ({
  selectedPlan,
  closeActionsheet,
}) => {
  const stripe = useStripe()
  const elements = useElements()
  const [selectedMethod, setSelectedMethod] = useState('card')
  const [selectedCard, setSelectedCard] = useState('')
  const [selectedCountry, setSelectedCountry] = useState<string>('')
  const [cardComplete, setCardComplete] = useState<boolean>(false)
  const [paymentData, setPaymentData] = useState({
    paymentMethodId: '',
    customerId: '',
    paymentIntent: '',
  })
  const [CPMState, setCPMState] = useState<boolean>(false)
  const [cardSave, setCardSaved] = useState<CardSavedType[]>([])
  const [addNewCard, setAddNewCard] = useState(false)
  const [canContinue, setCanContinue] = useState(true)
  const {
    paymentSuccess,
    sttPayment,
    isPaymentSuccess,
    isPaymentFail,
    tryPayAgain,
  } = useStatusPayment()
  const [isGGPaymentSupport, setGGPaymentSupport] = useState(true)
  const [isApplePaymentSupport, setApplePaymentSupport] = useState(true)
  const currency =
    (selectedPlan?.price?.currency.toUpperCase() as Code) || 'AUD'
  const amount = selectedPlan?.price?.unitAmount
  const idPrice = selectedPlan?.idPrice
  const refCard = useRef<{
    getName: () => string
    setName: (n: string) => void
  } | null>(null)
  const refZip = useRef<{
    getZip: () => string
    setZip: (n: string) => void
  } | null>(null)

  const createCardPaymentRequest = async () => {
    if (!elements || !stripe) {
      return ''
    }
    const cardElement: StripeCardNumberElement | null =
      elements.getElement(CardNumberElement)
    if (!cardElement) {
      return ''
    }

    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        name: refCard.current?.getName(),
        address: {
          postal_code: refZip.current?.getZip(),
          country: selectedCountry,
        },
      },
    })

    if (error) {
      console.error(error.message)
      return ''
    }
    return paymentMethod.id
  }

  const createPIApi = async (s?: string) => {
    if (!amount) {
      return
    }
    if (s) {
      return await createPaymentIntentApi(
        amount,
        currency,
        selectedMethod,
        idPrice,
        s,
      )
    }
    return await createPaymentIntentApi(
      amount,
      currency,
      selectedMethod,
      idPrice,
    )
  }

  const createOrtherPayment = async () => {
    if (!stripe || !elements) {
      console.error('Stripe.js has not yet loaded.')
      return
    }
    const method = selectedMethod === 'googlePay' ? 'googlePay' : 'applePay'
    const isPMSupported = await checkOrtherPaymentSupported(stripe, method)

    if (!isPMSupported) {
      method === 'googlePay'
        ? setGGPaymentSupport(false)
        : setApplePaymentSupport(false)
      return
    }

    const e = await createOrtherPaymentRequest(
      stripe,
      {
        currency,
        amount,
      },
      method,
    )
    const c = await createPIApi()
    if (!c?.cliSecret || !e) {
      isPaymentFail()
      return
    }
    const res = await confirmGgPayement(e, stripe, c?.cliSecret)
    !res ? isPaymentFail() : isPaymentSuccess()
  }
  // create card payment
  const createPayment = async () => {
    if (!stripe || !elements) {
      console.error('Stripe.js has not yet loaded.')
      return
    }
    let paymentMethodId = selectedCard

    if ((addNewCard || !cardSave) && selectedMethod === 'card') {
      paymentMethodId = await createCardPaymentRequest()
      if (!paymentMethodId) {
        return false
      }
    }
    const c = await createPIApi(paymentMethodId)

    if (c) {
      return {
        paymentMethodId,
        customerId: c.customerId || '',
        paymentIntent: c.paymentIntent || '',
      }
    } else {
      isPaymentFail()
      return false
    }
  }
  // submit card
  const handleSubmit = async event => {
    if (
      !!refCard.current?.getName() &&
      cardComplete &&
      !!selectedCountry &&
      !!selectedMethod &&
      !!refZip.current?.getZip()
    ) {
      event.preventDefault()
      const r = await createPayment()
      if (r) {
        setCPMState(true)
        setPaymentData(r)
      } else {
        toastError({ message: 'Card not supported' })
      }
      return
    }
    setCanContinue(false)
  }

  // comfirm card payment
  const confirmPayment = async () => {
    let { paymentMethodId, customerId, paymentIntent } = paymentData
    if (!addNewCard) {
      const r = await createPayment()
      if (!r) {
        return
      }
      paymentMethodId = r.paymentMethodId
      customerId = r.customerId
      paymentIntent = r.paymentIntent
    }
    try {
      const confirmResponse = await gql.confirmPaymentIntent({
        data: {
          customer_id: customerId || '',
          payment_intent_id: paymentIntent || '',
          payment_method_id: paymentMethodId || '',
        },
      })
      const confirmPaymentIntentData =
        confirmResponse.data?.confirmPaymentIntent

      if (confirmPaymentIntentData) {
        isPaymentSuccess()
        return
      }
      isPaymentFail()
    } catch (error) {
      isPaymentFail()
    }
  }

  const setCardInfor = fIdx => {
    refCard.current?.setName(fIdx ? fIdx.billing_details.name : '')
    refZip.current?.setZip(fIdx ? fIdx.billing_details.address.postal_code : '')
    setSelectedCountry(fIdx ? fIdx.billing_details.address.country : '')
    setSelectedCard(fIdx ? fIdx.id : '')
  }

  const getCard = async () => {
    try {
      const r = await gql.listCustomerPaymentMethod(undefined, {
        requestPolicy: 'network-only',
      })
      if (
        !r ||
        !r.data?.listCustomerPaymentMethod ||
        r.data?.listCustomerPaymentMethod.length === 0
      ) {
        setAddNewCard(true)
        return
      }
      setAddNewCard(false)
      const d = r?.data?.listCustomerPaymentMethod
      setCardSaved(d)
      const fIdx = d[0]
      setCardInfor(fIdx)
    } catch (error) {
      setAddNewCard(true)
    }
  }

  const onPressPaymentStatus = () => {
    if (!paymentSuccess) {
      tryPayAgain()
      return
    }
    closeActionsheet()
    navigateTopic()
  }

  useEffect(() => {
    setGGPaymentSupport(true)
    setApplePaymentSupport(true)
    const s = selectedMethod
    if (s === 'card') {
      getCard()
    } else {
      createOrtherPayment()
    }
  }, [selectedMethod])

  const countryOptions = countries.map(country => ({
    label: country.name,
    value: country.code,
  }))

  const ExpressCheckout = () => {
    if (Platform.OS !== 'web') {
      return
    }

    return (
      <div id='checkout-page'>
        <div id={selectedMethod === 'googlePay' ? 'ggpay' : 'applepay'}></div>
      </div>
    )
  }

  return (
    <View style={[tw`flex-1 w-full `]}>
      {/* Header */}

      <View style={[tw`w-full px-9 py-4`]}>
        <Text
          textAlign='center'
          specialType='Headline2'
          style={tw.style('text-center', { lineHeight: '125%' })}
        >
          Checkout
        </Text>
      </View>

      <View style={[tw`flex-1 w-full justify-center items-center`]}>
        {!canContinue && (
          <CannotContinue closeActionsheet={() => setCanContinue(true)} />
        )}
        {sttPayment && (
          <PaymentStatus
            status={paymentSuccess}
            onClick={onPressPaymentStatus}
          />
        )}

        {canContinue && !sttPayment && (
          <>
            <ScrollView
              style={tw.style('w-full')}
              showsVerticalScrollIndicator={false}
            >
              <View
                style={tw.style(
                  'flex-1 p-4 bg-white rounded-lg shadow-md border border-gray-300',
                )}
              >
                <FlatList
                  data={paymentMethods}
                  horizontal
                  keyExtractor={item => item.id}
                  renderItem={({ item }) => (
                    <ItemPayment
                      selectedMethod={selectedMethod}
                      onPress={() => {
                        setSelectedMethod(item.id)
                      }}
                      item={item}
                    />
                  )}
                  style={tw.style('mb-4')}
                  showsHorizontalScrollIndicator={false}
                />
                {selectedMethod === 'card' ? (
                  !addNewCard ? (
                    <ExistingCardPayment
                      cardSaveData={cardSave}
                      selectedCard={selectedCard}
                      setSelectedCard={(item: CardSavedType) => {
                        setSelectedCard(item.id)
                        setCardInfor(item)
                      }}
                      disabled={CPMState}
                      newCard={() => {
                        setCardInfor(null)
                        setAddNewCard(true)
                      }}
                    />
                  ) : (
                    !CPMState && (
                      <View>
                        {/* Card Input Fields */}

                        <CardInforInput ref={refCard} />

                        {/* Card Number, Expiry, and CVC */}

                        <CardPaymentInput
                          onFillCardFinish={(e: boolean) => {
                            setCardComplete(e)
                          }}
                        />
                        {/* Country and ZIP */}
                        <View style={tw.style('pb-3')}>
                          <Text specialType='Title' color={tw.color('text-1')}>
                            Country
                          </Text>
                        </View>

                        <Dropdown
                          style={tw.style({
                            fontFamily: 'Nunito-Regular',
                            fontSize: 18,
                          })}
                          options={countryOptions}
                          onSelect={setSelectedCountry}
                          defaultValue={
                            selectedCountry
                              ? selectedCountry
                              : S.shared.currentUser?.nativeLanguage ===
                                  'English'
                                ? 'GB'
                                : 'ES'
                          }
                        />

                        <ZipCodeInput ref={refZip} />
                      </View>
                    )
                  )
                ) : (
                  <View />
                )}

                {(selectedMethod !== 'card' ||
                  (!addNewCard && cardSave) ||
                  CPMState) && (
                  <View style={tw`bg-neutral-100 px-4 py-3 rounded-xl`}>
                    {/* Order Summary */}
                    <Text specialType='Headline3' color={tw.color('text-1')}>
                      Order summary
                    </Text>
                    <View style={tw`flex-row justify-between mt-3 mb-2`}>
                      <Text specialType='paragraph1' color={tw.color('text-1')}>
                        Package name
                      </Text>
                      <Text specialType='paragraph1' color={tw.color('text-1')}>
                        {selectedPlan.name}
                      </Text>
                    </View>
                    <View style={tw`flex-row justify-between`}>
                      <View style={tw`flex-row justify-between items-center`}>
                        <Text
                          specialType='Headline4'
                          color={tw.color('text-1')}
                        >
                          Total{' '}
                        </Text>
                        <Text specialType='Note' color={tw.color('text-1')}>
                          VAT included
                        </Text>
                      </View>

                      <Text specialType='Headline4' color={tw.color('text-1')}>
                        {`${getCurrencySymbol(selectedPlan.price?.currency)}${selectedPlan.price?.unitAmount}`}
                      </Text>
                    </View>
                  </View>
                )}
              </View>
            </ScrollView>

            {/* Button outside of ScrollView */}
            <View style={tw.style('w-full p-4 bg-background-light-2')}>
              {(!isApplePaymentSupport || !isGGPaymentSupport) && (
                <PaymentNotSupport
                  type={!isApplePaymentSupport ? 'Apple Pay' : 'Google Pay'}
                />
              )}

              {selectedMethod === 'card' ? (
                !CPMState && (addNewCard || !cardSave) ? (
                  <Button onPress={handleSubmit}>Continue</Button>
                ) : (
                  <Button onPress={confirmPayment}>Subscribe now</Button>
                )
              ) : (
                <ExpressCheckout />
              )}
            </View>
          </>
        )}
      </View>
    </View>
  )
}
type ActionSheetStripeProps = {
  selectedPlan: ProductItem
  closeActionsheet: () => void
}

export const ActionSheetStripe: React.FC<ActionSheetStripeProps> = observer(
  ({ selectedPlan, closeActionsheet }) => (
    <Elements stripe={stripePromise}>
      <CheckoutForm
        selectedPlan={selectedPlan}
        closeActionsheet={closeActionsheet}
      />
    </Elements>
  ),
)
