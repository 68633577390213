import type React from 'react'
import type { FlatListProps } from 'react-native'
import { View } from 'react-native'

import { FlatList } from '#components/base/FlatList'
import { tw } from '#components/utils/tw'
import type { UserTaskItem, UserTasks } from '#types/chat'

import { TaskItem as TaskItemComp } from './TaskItem'

type ITasksProps = {
  data: UserTasks
  ListHeaderComponent?: FlatListProps<UserTaskItem>['ListHeaderComponent']
  contentContainerStyle?: FlatListProps<UserTaskItem>['contentContainerStyle']
}

export const ListTask: React.FC<ITasksProps> = ({
  data,
  ListHeaderComponent,
  contentContainerStyle,
}) => {
  const validData = Array.isArray(data) ? data : []

  const renderItem = ({
    item,
    index,
  }: {
    item: UserTaskItem
    index: number
  }) => <TaskItemComp item={item} index={index} />

  const renderItemSeparator = () => (
    <View style={tw`h-[0.5px] bg-neutral-200 my-3`} />
  )

  return (
    <FlatList
      data={validData}
      renderItem={renderItem}
      ItemSeparatorComponent={renderItemSeparator}
      ListHeaderComponent={ListHeaderComponent}
      contentContainerStyle={contentContainerStyle}
    />
  )
}
