import { DeleteOutlined } from '@ant-design/icons'
import { Button, Flex } from 'antd'

import { NavigateBackButton } from '#admin/components/utils/NavigateBackButton'
import { toastError, toastSuccess } from '#admin/components/utils/Toast'
import { ModalLayout } from '#admin/components/widgets/ModalLayout'
import { Text } from '#components/base/Text'
import { useOverlay } from '#components/overlay/hooks'
import { tw } from '#components/utils/tw'
import { useSearchProduct } from '#graphql/codegen'
import { gql } from '#graphql/urql'
import type { AdminScreenProps } from '#navigator/types'

import { EditFeatureModal } from './EditFeatureModal'
import { EditGifModal } from './EditGifModal'

export const PaymentDetail = ({
  navigation,
  route,
}: AdminScreenProps<'AdminPaymentDetail'>) => {
  const { id } = route.params
  const [product, refetchProduct] = useSearchProduct({
    variables: {
      filter: { id },
      order: ['idProduct_asc'],
      page: {},
    },
  })
  const { openModal } = useOverlay()
  const searchProduct = product.data?.searchProduct ?? []

  const handleEditGif = async () => {
    openModal(EditGifModal, {
      titleGif: searchProduct[0].titleGif,
      typeGif: searchProduct[0].titlePlansGif,
      subtitleGif: searchProduct[0].subTitleGif,
      imageGif: {
        id: searchProduct[0].gifId,
        url: searchProduct[0].gif?.url,
      },
      productId: id,
      onOk: reload,
    })
  }

  const handleEditFeature = (index: number) => {
    openModal(EditFeatureModal, {
      selectedFeature: searchProduct[0]?.marketingFeatures?.features ?? [],
      indexFeature: index,
      productId: id,
      onOk: reload,
    })
  }

  const reload = () => {
    refetchProduct({ requestPolicy: 'network-only' })
  }

  const handleDeleteFeature = (indexDelete: number) => {
    openModal(ModalLayout, {
      onConfirm: async () => {
        const res = await gql.updateProduct({
          id: searchProduct[0].id,
          data: {
            marketingFeatures: {
              features: searchProduct[0]?.marketingFeatures?.features?.filter(
                (_, index) => index !== indexDelete,
              ),
            },
          },
        })
        if (res.data) {
          reload()
          toastSuccess({ message: 'Delete feature success' })
        } else {
          toastError({ message: 'Delete feature failed' })
        }
      },
      title: 'Confirm delete',
      content: 'Are you sure you want to delete this feature?',
      containerProps: {
        closeOnOverlayClick: false,
      },
    })
  }

  const handleNavigateBack = () => {
    navigation.navigate('AdminPayment')
  }

  return (
    <Flex style={tw`flex-1 flex-col p-4 h-full`}>
      <Flex style={tw`flex pt-5 items-center`}>
        <NavigateBackButton direction={handleNavigateBack} />
        <Text style={tw`text-6 px-2`}>Topic detail</Text>
      </Flex>
      <Flex>
        <Text
          style={tw`py-4`}
          specialType='Subtitle'
        >{`Product: ${searchProduct[0]?.name ?? ''}`}</Text>
      </Flex>
      <div style={tw`flex flex-col items-center w-full h-full gap-4`}>
        <div
          style={tw`flex items-center w-[50%] bg-white p-4 rounded-lg gap-4`}
        >
          <img
            src={
              searchProduct[0]?.gif?.url ??
              'https://static.vecteezy.com/system/resources/thumbnails/022/493/595/small_2x/3d-question-mark-icon-or-ask-faq-answer-solution-isolated-on-transparent-background-file-png.png'
            }
            alt='gif_photo'
            style={tw`w-[20%]`}
          />
          <div>
            <h1>
              {searchProduct[0]?.titleGif === ''
                ? 'Input Title Gif'
                : searchProduct[0]?.titleGif + ' '}
              <span style={tw`text-blue-500`}>
                {searchProduct[0]?.titlePlansGif}
              </span>
            </h1>
            <div>
              {searchProduct[0]?.subTitleGif === ''
                ? 'Input Subtitle Gif'
                : searchProduct[0]?.subTitleGif}
            </div>
          </div>
          <Button style={tw`ml-auto`} onClick={() => handleEditGif()}>
            Edit
          </Button>
        </div>

        <div
          style={tw.style('p-4 bg-white rounded-lg w-[50%] h-[65%]', {
            overflowY: 'scroll',
          })}
        >
          <Flex gap={8} style={tw`flex w-full items-center`}>
            <img
              src='https://static.vecteezy.com/system/resources/thumbnails/022/493/595/small_2x/3d-question-mark-icon-or-ask-faq-answer-solution-isolated-on-transparent-background-file-png.png'
              alt='feature_icon'
              style={tw`bg-gray-200 p-2 w-[64px] h-[64px] rounded-lg`}
            />
            <div>
              <div style={tw`font-semibold text-[16px]`}>...</div>
              <div>...</div>
            </div>
            <Button style={tw`ml-auto`} onClick={() => handleEditFeature(-1)}>
              Add
            </Button>
          </Flex>
          {searchProduct[0]?.marketingFeatures?.features?.map((e, index) => (
            <div>
              <hr />
              <Flex gap={8} style={tw`flex w-full items-center`}>
                <img
                  src={e.image}
                  alt='feature_icon'
                  style={tw`bg-gray-200 p-2 w-[64px] h-[64px] rounded-lg`}
                />
                <div>
                  <div style={tw`font-semibold text-[16px]`}>{e.title}</div>
                  <div>{e.description}</div>
                </div>
                <Flex style={tw`ml-auto`} gap={4}>
                  <Button
                    type='primary'
                    onClick={() => handleEditFeature(index)}
                  >
                    Edit
                  </Button>
                  <Button
                    onClick={() => handleDeleteFeature(index)}
                    icon={<DeleteOutlined style={tw`text-red-400`} />}
                  />
                </Flex>
              </Flex>
            </div>
          ))}
        </div>
      </div>
    </Flex>
  )
}
