import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { View } from 'react-native'
import { z } from 'zod'

import { Button } from '#components/base/Button/Button'
import { SystemIcon } from '#components/base/SystemIcon'
import { Text } from '#components/base/Text'
import { FormField } from '#components/form/FormField'
import { Input } from '#components/form/Input'
import { useOverlay } from '#components/overlay/hooks'
import { tw } from '#components/utils/tw'
import { BackgroundAuth } from '#components/widgets/BackgroundAuth'
import { OTPDialog } from '#components/widgets/ModalOTP'
import type { AuthStackScreenProps } from '#navigator/types'

import { confirmOTP } from './onConfirmOTP'
import { onForgotPassword } from './onForgotPassword'

const FormSchema = z.object({
  email: z
    .string({ required_error: 'Email is required' })
    .email('Invalid email address'),
})

export type ForgotPasswordFormValues = z.infer<typeof FormSchema>

export const ForgotPassword = ({
  navigation,
  route,
}: AuthStackScreenProps<'ForgotPassword'>) => {
  const { control, watch, handleSubmit, setValue } =
    useForm<ForgotPasswordFormValues>({
      resolver: zodResolver(FormSchema),
      shouldFocusError: false,
    })
  const e = route?.params?.emailValue
  const emailValue = watch('email')
  const { openModal } = useOverlay()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    if (e) {
      setValue('email', e)
    }
  }, [e, setValue])

  const onPressForgotPassword = async () => {
    setIsLoading(true)
    const response = await onForgotPassword(emailValue)
    if (response) {
      setIsLoading(false)
      openModal(OTPDialog, {
        handleConfirm,
        idForgotPassword: response,
        containerProps: {
          closeOnOverlayClick: false,
        },
      })
    }
  }

  const handleConfirm = async (code: string, idForgotPassword: string) => {
    const response = await confirmOTP(code, idForgotPassword)
    if (response?.data?.confirmOTP) {
      navigation.navigate('NewPassword', {
        id: idForgotPassword,
        secret: response?.data?.confirmOTP,
      })
      return true
    } else {
      return null
    }
  }

  return (
    <BackgroundAuth isHeader>
      <View style={tw`items-center pt-8`}>
        <View style={tw`items-center mb-6`}>
          <View
            style={tw`h-24 w-24 bg-primary-200 rounded-full items-center justify-center opacity-40`}
          />
          <View
            style={tw.style(
              'absolute top-0 left-0 right-0 bottom-0 items-center justify-center',
            )}
          >
            <SystemIcon
              type='SAX'
              name='Lock1'
              size={48}
              color={tw.color('primary-400')}
            />
          </View>
        </View>
        <View style={tw`items-center gap-2 pb-8`}>
          <Text textAlign='center' specialType='Headline2'>
            Forgot Password
          </Text>
          <Text
            textAlign='center'
            specialType='Title'
            color={tw.color('text-2')}
          >
            We will send a verification code to email
          </Text>
        </View>
      </View>
      <View style={tw`gap-4`}>
        <FormField name='email' control={control}>
          <Input
            autoCapitalize='none'
            spellCheck={false}
            onSubmitEditing={handleSubmit(onPressForgotPassword)}
            autoFocus
          />
        </FormField>
        <Button
          loading={isLoading}
          onPress={handleSubmit(onPressForgotPassword)}
        >
          Continue
        </Button>
      </View>
    </BackgroundAuth>
  )
}
