import type { FC } from 'react'
import { useEffect } from 'react'
import { Platform, TouchableOpacity, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

import { Avatar } from '#components/base/Avatar'
import { SystemIcon } from '#components/base/SystemIcon'
import { Text } from '#components/base/Text'
import { useOverlay } from '#components/overlay/hooks'
import { tw } from '#components/utils/tw'
import { logCustomEvent } from '#config/firebaseConfig'
import { useSearchAiTutor } from '#graphql/codegen'
import { navigate, replace } from '#navigator/helpers'
import { S } from '#store'
import type { UserTasks } from '#types/chat'

import { ChatSetting } from './ChatSetting/ChatSetting'
import { Tasks } from './Tasks/Tasks'

type Props = {
  tasks: UserTasks
  topicId: string
  topicName: string
}

export const Header: FC<Props> = ({ tasks, topicId, topicName }) => {
  const { openActionsheet } = useOverlay()
  const onPressBack = () => {
    replace('Home', { screen: 'Topics' })
  }
  const insets = useSafeAreaInsets()

  const [aiTutor] = useSearchAiTutor({
    variables: { filter: { id: S.shared.currentUser?.aiTutorId } },
  })
  const [firstItem] = aiTutor.data?.searchAiTutor ?? []

  useEffect(() => {
    logCustomEvent('chat_session', {
      topic_id: topicId,
      topic: topicName,
      tutor_name: firstItem?.name ?? '',
      tutor_id: S.shared.currentUser?.aiTutorId,
    })
  }, [])

  const onPressTaskSquare = () => {
    openActionsheet(Tasks, { data: tasks, aiTutor: firstItem })
  }

  const onPressSetting = () => {
    openActionsheet(ChatSetting, {})
  }

  const onPressWordsOrPhrases = () => {
    navigate('App', { screen: 'SuggestedSpeak', params: { topicId } })
  }

  return (
    <View
      style={tw.style(
        'flex-row items-center h-15 bg-neutral-75 mb-1 shadow-sm',
        Platform.OS === 'ios' && {
          paddingTop: Math.max(insets.top),
          height: 76 + insets.top,
        },
      )}
    >
      <TouchableOpacity
        style={tw`h-12 w-12 justify-center items-center`}
        onPress={onPressBack}
      >
        <SystemIcon type='SAX' name='ArrowLeft2' />
      </TouchableOpacity>
      <View style={tw`flex-1 flex-row items-center`}>
        <Avatar
          name={firstItem?.name ?? 'Cody'}
          source={{ uri: firstItem?.thumbnail?.url }}
        />
        <View style={tw`ml-2`}>
          <Text specialType='Headline4'>{firstItem?.name ?? 'Cody'}</Text>
        </View>
      </View>
      <TouchableOpacity
        style={tw`h-12 w-12 justify-center items-center`}
        onPress={onPressWordsOrPhrases}
      >
        <SystemIcon type='SAX' name='MagicStar' />
      </TouchableOpacity>
      <TouchableOpacity
        style={tw`h-12 w-12 justify-center items-center`}
        onPress={onPressTaskSquare}
      >
        <SystemIcon type='SAX' name='TaskSquare' />
      </TouchableOpacity>
      <TouchableOpacity
        style={tw`h-12 w-12 justify-center items-center`}
        onPress={onPressSetting}
      >
        <SystemIcon type='SAX' name='Setting2' />
      </TouchableOpacity>
    </View>
  )
}
