import { z } from 'zod'

import { gql } from '#graphql/urql'

export const EmailSchema = z.object({
  email: z
    .string({ required_error: 'Email is required' })
    .email('Invalid email address'),
})

export const PasswordSchema = z.object({
  password: z
    .string({ required_error: 'Password is required' })
    .min(6, { message: 'Password must be at least 6 characters' }),
})
export const CombinedSchema = EmailSchema.merge(PasswordSchema)

export const onCheckEmail = async (email: string) => {
  const res = await gql.checkEmail({ email })
  return res.data?.checkEmail ?? false
}
