import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { View } from 'react-native'

import { SystemIcon } from '#components/base/SystemIcon'
import { Text } from '#components/base/Text'
import { tw } from '#components/utils/tw'
import type { TasksItem } from '#graphql/codegen'
import { useSearchTopicInUser, useUpdateTopicInUser } from '#graphql/codegen'
import { S } from '#store'
import type { SearchTopic } from '#types/topic'

import { Skeleton } from './Skeleton'

type Props = {
  loading: boolean
  topicId: string
  tasksFromTopic: SearchTopic[number]['task']['data']
}

export const TaskList = observer(
  ({ loading, topicId, tasksFromTopic }: Props) => {
    const [updateResult, updateTopicInUser] = useUpdateTopicInUser()

    const [topicInUserData] = useSearchTopicInUser({
      variables: { filter: { topicId, userLearnId: S.shared.currentUser?.id } },
      requestPolicy: 'network-only',
    })

    const topicInUser = topicInUserData.data?.searchTopicInUser[0]
    const tasks: TasksItem[] = topicInUser?.task.data || []

    useEffect(() => {
      if (!loading && !!topicInUser?.id) {
        const newTasks = handleMergeTasks(tasksFromTopic, tasks)
        updateTopicInUser({
          id: topicInUser.id,
          data: { task: { data: newTasks } },
        })
      }
    }, [topicInUser?.id, loading])

    const handleMergeTasks = (
      topicTasks: any,
      userTasks: any,
      isSubTask?: boolean,
    ) =>
      topicTasks.reduce((acc, cur) => {
        const task = userTasks.find(t => t.id === cur.id)

        const subTasks = !isSubTask
          ? handleMergeTasks(cur.subTask, task?.subTask || [], true)
          : undefined

        const newTask = {
          ...cur,
          isPassed: !!subTasks?.length
            ? subTasks.every(t => t.isPassed)
            : !!task?.isPassed,
          ...(!subTasks ? {} : { subTask: subTasks }),
        }

        acc.push(newTask)
        return acc
      }, [] as TasksItem[])

    const renderTaskItem = (task: TasksItem, idx: number) => (
      <View
        key={task.id}
        style={tw`flex flex-col bg-white p-4 rounded-2xl border border-gray-100 shadow gap-y-1.5`}
      >
        <TaskItem index={idx} task={task} />
        {(task.subTask || []).map(t => (
          <TaskItem key={t?.id} task={t!} isSubTask />
        ))}
      </View>
    )

    return (
      <View style={tw`flex flex-col gap-y-3`}>
        <Text specialType='Headline3'>Task list</Text>
        <View style={tw`flex flex-col gap-y-1.5`}>
          {loading || topicInUserData.fetching || updateResult.fetching ? (
            <Skeleton.TaskList />
          ) : (
            tasks.map(renderTaskItem)
          )}
        </View>
      </View>
    )
  },
)

const TaskItem = ({
  index,
  task,
  isSubTask,
}: {
  index?: number
  task: TasksItem
  isSubTask?: boolean
}) => (
  <View style={tw`flex flex-row justify-between gap-x-4`}>
    {!isSubTask && (
      <View
        style={tw.style(
          'flex-1 flex-row gap-x-1.5 text-[17px]',
          task.isPassed && 'line-through opacity-60',
        )}
      >
        <Text specialType='paragraph1' style={tw`text-[17px]`}>
          {(index || 0) + 1}.
        </Text>
        <Text specialType='paragraph1' style={tw`text-[17px]`}>
          {task.name}
        </Text>
      </View>
    )}
    {isSubTask && (
      <View style={tw`flex-row items-center gap-x-1.5 ml-5`}>
        <View style={tw`w-1.5 h-1.5 rounded-full bg-gray-500`} />
        <Text
          specialType='paragraph1'
          style={tw.style(
            'text-base text-gray-600',
            task.isPassed && 'line-through opacity-60',
          )}
        >
          {task.name}
        </Text>
      </View>
    )}
    <View style={tw`mt-px`}>
      {task.isPassed && (
        <SystemIcon
          type='SAX'
          name='TickCircle'
          variant='Bold'
          color={tw.color('green-500')}
        />
      )}
      {!task.isPassed && (
        <View style={tw`w-6 h-6`}>
          <View style={tw`flex flex-1 m-0.5 rounded-full bg-gray-300`} />
        </View>
      )}
    </View>
  </View>
)
