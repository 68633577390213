import { Form, Input } from 'antd'
import type React from 'react'
import { useState } from 'react'

import { toastError, toastSuccess } from '#admin/components/utils/Toast'
import { ModalLayout } from '#admin/components/widgets/ModalLayout'
import { Upload } from '#admin/components/widgets/Upload'
import { tw } from '#components/utils/tw'
import { gql } from '#graphql/urql'

type Prop = {
  titleGif: string
  typeGif: string
  subtitleGif: string
  imageGif: { id: string; url: string }
  productId: string
  closeModal: () => void
  onOk: () => void
}

export const EditGifModal = ({
  titleGif,
  typeGif,
  subtitleGif,
  imageGif,
  productId,
  closeModal,
  onOk,
}: Prop) => {
  const { TextArea } = Input

  const [loading, setLoading] = useState<boolean>(false)
  const [title, setTitle] = useState<string>(titleGif)
  const [type, setType] = useState<string>(typeGif)
  const [subtitle, setSubtitle] = useState<string>(subtitleGif)
  const [image, setImage] = useState<{ id: string; url: string }>(imageGif)

  const handleModalOk = async () => {
    setLoading(true)
    const res = await gql.updateProduct({
      id: productId,
      data: {
        titleGif: title,
        titlePlansGif: type,
        subTitleGif: subtitle,
        gifId: image.id,
      },
    })

    if (res?.error || !res?.data) {
      toastError({ title: 'Error', message: res?.error?.message })
      setLoading(false)
      return
    }
    toastSuccess({
      message: 'Update gif successfully',
    })
    onOk()
    closeModal()
    setLoading(false)
  }

  const handleModalCancel = () => {
    closeModal()
  }

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && title && subtitle && image.url && !loading) {
      handleModalOk()
    }
  }

  return (
    <ModalLayout
      title={'Edit gif'}
      closeModal={handleModalCancel}
      onConfirm={handleModalOk}
      disableOk={!title || !subtitle || !image.url || loading}
      autoClose={false}
      isLoading={loading}
    >
      <Form layout='vertical' onKeyUp={handleKeyPress} style={tw`w- pt-2`}>
        <Form.Item label='Image'>
          <Upload
            display='pictureCard'
            resourceType='image_feature'
            type='image'
            url={image.url}
            onChange={value => {
              setImage({
                id: value.id,
                url: value.url,
              })
            }}
          />
        </Form.Item>
        <Form.Item label='Title'>
          <Input
            value={title}
            onChange={e => {
              setTitle(e.target.value)
            }}
          />
        </Form.Item>
        <Form.Item label='Type'>
          <Input
            value={type}
            onChange={e => {
              setType(e.target.value)
            }}
          />
        </Form.Item>
        <Form.Item label='Subtitle'>
          <TextArea
            value={subtitle}
            onChange={e => {
              setSubtitle(e.target.value)
            }}
          />
        </Form.Item>
      </Form>
    </ModalLayout>
  )
}
