import { gql } from '#graphql/urql'

import { onLogoutSuccess } from './onLogoutSuccess'

export const onLogoutPress = async () => {
  const r = await gql.logout()
  if (r.error || !r.data) {
    return
  }
  await onLogoutSuccess()
}
