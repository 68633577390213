import { ArrowRightOutlined } from '@ant-design/icons'
import { Checkbox, Flex, Form, Input } from 'antd'
import type React from 'react'
import { useEffect, useState } from 'react'

import { handleFileType } from '#admin/components/utils/checkFileType'
import { toastError, toastSuccess } from '#admin/components/utils/Toast'
import { useDebounce } from '#admin/components/utils/useDebounce'
import { ModalLayout } from '#admin/components/widgets/ModalLayout'
import { AudioUploader } from '#admin/components/widgets/UploadAudios'
import {
  isValidAudioUrl,
  isValidImageUrl,
  isValidVideoUrl,
} from '#components/utils/checkMedia'
import { tw } from '#components/utils/tw'
import { gql } from '#graphql/urql'
import { S } from '#store'
import { LanguageLabel } from '#types/language'
import type { UploadFileProps } from '#types/media'
import type { SearchWordItem } from '#types/word'

type Prop = {
  selectedWord?: SearchWordItem
  closeModal: () => void
  onOk: () => void
  topicId: string
  language: string
}

type FileProps = UploadFileProps & { id: string }

const { TextArea } = Input

export const TopicDetailModal = ({
  selectedWord,
  closeModal,
  onOk,
  topicId,
  language,
}: Prop) => {
  const suportLanguage =
    language === LanguageLabel.English
      ? LanguageLabel.Spanish
      : LanguageLabel.English
  const [word, setWord] = useState<string>('')
  const [isVocabulary, setIsVocabulary] = useState<boolean>(true)
  const [showTranslate, setShowTranslate] = useState<boolean>(true)
  const [showImage, setShowImage] = useState<boolean>(false)
  const [fileList, setFileList] = useState<FileProps[]>([])
  const [fileDeleted, setFileDeleted] = useState<FileProps[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [translate, setTranslate] = useState<string>('')
  const debouncedWord = useDebounce<string>(word, 500)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    if (selectedWord) {
      initializeFields(selectedWord)
      setFileList([])
      setFileDeleted([])
      fetchResource()
    }
  }, [selectedWord?.id, selectedWord])

  useEffect(() => {
    if (debouncedWord && !selectedWord) {
      fetchSuggestedTranslation(debouncedWord)
    }
  }, [debouncedWord])

  const initializeFields = (w: SearchWordItem) => {
    setWord(w.vocabAndPhrase || '')
    setTranslate(w.translate?.text || '')
    setIsVocabulary(w.isVocabulary ?? true)
    setShowImage(w.isThumbnail ?? false)
    setShowTranslate(w.isTranslate ?? true)
  }

  const fetchSuggestedTranslation = async (t: string) => {
    const resp = await gql.suggestedTranslation({
      text: t,
      language: suportLanguage,
    })
    setTranslate(resp.data?.suggestedTranslation || '')
  }

  const fetchResource = async () => {
    setLoading(true)
    const res = await gql.searchResourceInWord(
      {
        filter: {
          wordId: selectedWord?.id,
        },
        order: ['createdAt_asc'],
      },
      { requestPolicy: 'network-only' },
    )

    const resources = res.data?.searchResourceInWord || []
    const updatedFileList = resources.map(item => ({
      ...item,
      name: item.media?.name || '',
      url: item.media?.url || '',
      type: handleFileType(item.media?.url || ''),
      uid: item.mediaId,
      id: item.id,
    }))
    setFileList(updatedFileList)
    setLoading(false)
  }

  const handleModalOk = async () => {
    try {
      if (!word || !translate || loading || checkFileAudio() || isLoading) {
        return
      }
      setIsLoading(true)
      let res
      const images = fileList.filter(i => isValidImageUrl(i.url || ''))
      if (selectedWord) {
        res = await gql.updateWord({
          id: selectedWord.id,
          data: {
            topicId,
            isVocabulary,
            isThumbnail: showImage,
            isTranslate: showTranslate,
            vocabAndPhrase: word,
            mediaId: images.length > 0 ? images[0].uid : '',
          },
        })
        await onTranslate({
          translateId: selectedWord.translate?.id || '',
          wordId: selectedWord.id || '',
        })
        await Promise.all(
          fileDeleted.map(async file => {
            if (file.id) {
              await gql.deleteResourceInWord({ id: file.id || '' })
            }
          }),
        )
        await Promise.all(
          fileList.map(async file => {
            if (!file.id) {
              await gql.createResourceInWord({
                data: {
                  wordId: selectedWord.id,
                  type: isValidImageUrl(file.url || '')
                    ? 'image_game'
                    : file.type?.split('/')[0] === 'audio'
                      ? 'audio_game'
                      : 'video_game',
                  mediaId: file.uid,
                },
              })
            }
          }),
        )
        setFileDeleted([])
        setFileList([])
      } else {
        res = await gql.createWord({
          data: {
            topicId,
            isVocabulary,
            vocabAndPhrase: word,
            isThumbnail: showImage,
            isTranslate: showTranslate,
            mediaId: images.length > 0 ? images[0].uid : '',
            userId: S.shared.currentUser?.id ?? '',
          },
        })
        if (res?.data?.createWord?.id) {
          await onTranslate({
            translateId: '',
            wordId: res?.data?.createWord?.id || '',
          })
          await Promise.all(
            fileList.map(async file => {
              if (file.uid) {
                await gql.createResourceInWord({
                  data: {
                    wordId: res?.data?.createWord?.id,
                    type: isValidImageUrl(file.url || '')
                      ? 'image_game'
                      : file.type?.split('/')[0] === 'audio'
                        ? 'audio_game'
                        : 'video_game',
                    mediaId: file.uid,
                  },
                })
              }
            }),
          )
        }
      }

      if (res.error || !res.data) {
        toastError({ title: 'Error', message: res.error?.message })
        setIsLoading(false)
        return
      }
      toastSuccess({
        message: `${isVocabulary ? 'Word' : 'Phrase'} handled successfully`,
      })
      onOk()
      resetForm()
      setIsLoading(false)
    } catch (error) {
      toastError({ message: 'Failed to handle word' })
      setIsLoading(false)
    }
  }

  const onTranslate = async ({
    translateId,
    wordId,
  }: {
    translateId: string
    wordId: string
  }) => {
    const params = {
      text: translate,
      wQAId: wordId,
      suportLanguage,
    }
    if (translateId) {
      await gql.updateTranslate({
        id: translateId,
        data: params,
        update: true,
      })
    } else {
      await gql.createTranslate({
        data: params,
      })
    }
  }

  const handleModalCancel = () => {
    resetForm()
  }

  const resetForm = () => {
    setWord('')
    setIsVocabulary(true)
    setShowImage(false)
    setShowTranslate(true)
    closeModal()
  }

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && word) {
      handleModalOk()
    }
  }

  const checkFileAudio = () => {
    const mediaChecks = [
      !fileList.filter(i => isValidImageUrl(i.url || '')).length,
      !fileList.filter(i => isValidAudioUrl(i.url || '')).length,
      !fileList.filter(i => isValidVideoUrl(i.url || '')).length,
    ]

    return mediaChecks.some(condition => condition)
  }

  const handleWarning = () => {
    const warnings: string[] = []

    if (!word) {
      warnings.push('vocabulary or phrase')
    }

    const images = fileList.filter(i => isValidImageUrl(i.url || ''))
    if (!images.length) {
      warnings.push('image')
    }

    const audios = fileList.filter(i => isValidAudioUrl(i.url || ''))
    const videos = fileList.filter(i => isValidVideoUrl(i.url || ''))
    if (!audios.length || !videos.length) {
      warnings.push('audio and video')
    }

    return warnings.length ? warnings.join(', ') : ''
  }

  const onChangeFileList = (files: FileProps[]) => {
    setFileList(prev => [...prev, ...files])
  }

  const onChangeFileDeleted = (file: FileProps) => {
    setFileList(prev => prev.filter(f => f?.uid !== file?.uid))
    setFileDeleted(prev => [...prev, file])
  }

  return (
    <ModalLayout
      title={selectedWord ? 'Edit vocabulary' : 'Add new vocabulary'}
      closeModal={handleModalCancel}
      onConfirm={handleModalOk}
      disableOk={loading || !word || !translate || checkFileAudio()}
      autoClose={false}
    >
      <Form layout='vertical' onKeyUp={handleKeyPress} style={tw`w- pt-2`}>
        <Flex gap={8}>
          <Form.Item label='Vocabulary' style={tw`w-full`}>
            <TextArea
              value={word}
              onChange={e => {
                setWord(e.target.value)
              }}
              autoSize={{ minRows: 2, maxRows: 2 }}
            />
          </Form.Item>
          <ArrowRightOutlined style={tw`mt-2`} />
          <Form.Item label={suportLanguage} style={tw`w-full`}>
            <TextArea
              value={translate}
              onChange={e => {
                setTranslate(e.target.value)
              }}
              autoSize={{ minRows: 2, maxRows: 2 }}
            />
          </Form.Item>
        </Flex>
        <Form.Item>
          <Flex gap={16}>
            <Checkbox
              onChange={e => {
                setIsVocabulary(e.target.checked)
                if (e.target.checked) {
                  setShowImage(false)
                }
              }}
              checked={isVocabulary}
            >
              Is word
            </Checkbox>
            <Checkbox
              onChange={e => setShowTranslate(e.target.checked)}
              checked={showTranslate}
            >
              Show translate
            </Checkbox>
            <Checkbox
              onChange={e => setShowImage(e.target.checked)}
              checked={showImage}
              disabled={isVocabulary}
            >
              Show image for phrase
            </Checkbox>
          </Flex>
        </Form.Item>
        <Flex style={tw`flex justify-center gap-12`} className='z-50'>
          <Form.Item style={tw`flex justify-center w-full`}>
            <AudioUploader
              fileList={fileList}
              onChangeFileList={onChangeFileList}
              onChangeFileDeleted={onChangeFileDeleted}
              loading={loading}
              warning={handleWarning()}
              onChangeLoading={setLoading}
            />
          </Form.Item>
        </Flex>
      </Form>
    </ModalLayout>
  )
}
