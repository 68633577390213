import { GoogleSignin } from '@react-native-google-signin/google-signin'
import {
  getAuth,
  GoogleAuthProvider,
  signInWithCredential,
  signInWithPopup,
} from 'firebase/auth'
import { Platform } from 'react-native'

import { toastError } from '#components/utils/Toast'
import { gql } from '#graphql/urql'
import { onAppInit } from '#screens/App/onAppInit'

export const onLoginGoogle = async () => {
  const provider = new GoogleAuthProvider()
  const auth = getAuth()
  try {
    let token: any
    let resGoogle: any

    if (Platform.OS === 'web') {
      // Kiểm tra platform
      resGoogle = await signInWithPopup(auth, provider)
      token = await auth.currentUser?.getIdToken()
    } else {
      await GoogleSignin.hasPlayServices({ showPlayServicesUpdateDialog: true })
      const response = await GoogleSignin.signIn()
      const credential = GoogleAuthProvider.credential(response.idToken)
      resGoogle = await signInWithCredential(auth, credential)
      token = await auth.currentUser?.getIdToken() // Lấy ID token từ Firebase
    }
    if (resGoogle && token) {
      const r = await gql.loginGoogle({
        data: {
          email: resGoogle.user.email ?? '',
          name: resGoogle.user.displayName,
          accessToken: token,
          photoURL: resGoogle.user.photoURL,
          emailVerified: resGoogle.user.emailVerified,
        },
      })
      if (r.error || !r.data || r.data.loginGoogle.user?.deactivate) {
        toastError({
          message: r.data?.loginGoogle.user?.deactivate
            ? 'Your account has been locked'
            : r.error?.message,
        })
        return
      }
      await onAppInit({
        currentAuthSession: r.data.loginGoogle,
      })
    } else {
      toastError({ message: 'Login Google failed' })
    }
  } catch (error) {
    toastError({ message: `Login failed: ${error}` })
  }
}
