import { Flex, Form, Input } from 'antd'
import type { FC } from 'react'
import { useState } from 'react'

import { toastError, toastSuccess } from '#admin/components/utils/Toast'
import { Label } from '#admin/components/widgets/Label'
import { ModalLayout } from '#admin/components/widgets/ModalLayout'
import { Upload } from '#admin/components/widgets/Upload'
import { tw } from '#components/utils/tw'
import { gql } from '#graphql/urql'
import type { ResponseFile } from '#types/media'
import type { SearchSplashItem } from '#types/splash'

type Pr = {
  closeModal: () => void
  item?: SearchSplashItem
  reload: () => void
}

const { TextArea } = Input

export const SplashModal: FC<Pr> = ({ closeModal, item, reload }) => {
  const [loading, setLoading] = useState(false)
  const [title, setTitle] = useState<string>(item?.title || '')
  const [subTitle, setSubTitle] = useState<string>(item?.subTitle || '')
  const [thumbnail, setThumbnail] = useState<{
    url: string
    thumbnailId: string
  }>({
    url: item?.thumbnail?.url || '',
    thumbnailId: item?.thumbnailId ?? '',
  })

  const handleSubmit = async () => {
    try {
      if (loading || !title || !subTitle || !thumbnail.thumbnailId) {
        return
      }
      setLoading(true)
      const params = {
        title,
        subTitle,
        thumbnailId: thumbnail.thumbnailId,
      }
      let message: string = ''
      if (item?.id) {
        const resp = await gql.updateSplash({
          id: item.id,
          data: params,
        })
        message =
          resp.error || !resp.data
            ? resp.error?.message || 'Update failed!'
            : ''
      } else {
        const resp = await gql.createSplash({
          data: params,
        })
        message =
          resp.error || !resp.data
            ? resp.error?.message || 'Create failed!'
            : ''
      }

      if (message) {
        toastError({ title: 'Error', message })
        return
      }

      const successMessage = item?.id
        ? 'Updated successfully.'
        : 'Created successfully.'
      toastSuccess({ message: successMessage })
      reload()
      closeModal()
    } catch {
      toastError({
        message: item ? 'Update failed!.' : 'Create failed!',
      })
    } finally {
      setLoading(false)
    }
  }
  const handleModalCancel = () => {
    closeModal()
  }

  const onChangeThumbnail = (data: ResponseFile) => {
    setThumbnail({
      url: data.url,
      thumbnailId: data.id,
    })
  }

  return (
    <ModalLayout
      title={item ? 'Edit' : 'Add'}
      closeModal={handleModalCancel}
      onConfirm={handleSubmit}
      disableOk={loading || !title || !subTitle || !thumbnail.thumbnailId}
      autoClose={false}
    >
      <Flex style={tw`flex-1/2`}>
        <Form layout='vertical' style={tw`w-120 bg-white rounded-5`}>
          <Form.Item label={<Label label='Title' required />}>
            <Input
              placeholder='Enter your title'
              value={title}
              onChange={e => {
                setTitle(e.target.value)
              }}
            />
          </Form.Item>
          <Form.Item label={<Label label='Sub title' required />}>
            <Flex gap={8}>
              <TextArea
                value={subTitle}
                onChange={e => setSubTitle(e.target.value)}
              />
            </Flex>
          </Form.Item>
          <Form.Item style={tw`text-center w-full`}>
            <Flex gap={32} style={tw`flex items-center justify-center`}>
              <Upload
                resourceType='image_game'
                type='image'
                display='pictureCard'
                url={thumbnail.url}
                onChange={onChangeThumbnail}
                onDeleted={() => setThumbnail({ url: '', thumbnailId: '' })}
                setLoadingUpload={setLoading}
                required
              />
            </Flex>
          </Form.Item>
        </Form>
      </Flex>
    </ModalLayout>
  )
}
