import { toastError } from '#components/utils/Toast'
import { gql } from '#graphql/urql'
import { onAppInit } from '#screens/App/onAppInit'

import type { LoginFormValues } from '.'

export const onLoginSubmit = async (values: LoginFormValues) => {
  const r = await gql.login(values)
  if (r.error || !r.data || r.data?.login?.user?.deactivate) {
    toastError({
      message: r.data?.login?.user?.deactivate
        ? 'Your account has been locked'
        : r.error?.message,
    })
    return
  }
  await onAppInit({
    currentAuthSession: r.data.login,
  })
}
