import { Flex, Form, Input } from 'antd'
import type { FC } from 'react'
import { useEffect, useState } from 'react'

import { toastError, toastSuccess } from '#admin/components/utils/Toast'
import { ModalLayout } from '#admin/components/widgets/ModalLayout'
import { tw } from '#components/utils/tw'
import { gql } from '#graphql/urql'
import type { SearchTopicItem } from '#types/topic'

type Prop = {
  topic: SearchTopicItem
  onChangeData: (data: Partial<SearchTopicItem>) => void
  closeModal: () => void
}

const { TextArea } = Input

export const InfoTopic: FC<Prop> = ({ topic, closeModal, onChangeData }) => {
  const [scenario, setScenario] = useState<string>(topic.scenario)
  const [aiTutorRole, setAiTutorRole] = useState<string>(topic.aiTutorRole)
  const [userRole, setUserRole] = useState<string>(topic.userRole)
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    setScenario(topic.scenario)
    setAiTutorRole(topic.aiTutorRole)
    setUserRole(topic.userRole)
  }, [topic.scenario, topic.aiTutorRole, topic.userRole])

  const handleModalOk = async () => {
    try {
      if (
        loading ||
        (topic.scenario === scenario &&
          topic.aiTutorRole === aiTutorRole &&
          topic.userRole === userRole)
      ) {
        return
      }
      setLoading(true)
      const params = {
        scenario,
        aiTutorRole,
        userRole,
      }
      const resp = await gql.updateTopic({
        id: topic.id,
        data: params,
      })
      if (resp.error || !resp.data) {
        toastError({ title: 'Error', message: resp.error?.message })
        setLoading(false)
        return
      }
      onChangeData(params)
      toastSuccess({ message: 'Successfully updated info' })
      setLoading(false)
      closeModal()
    } catch (error) {
      toastError({ message: 'Failed updated info' })
      setLoading(false)
    }
  }

  const handleModalCancel = () => {
    closeModal()
  }

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleModalOk()
    }
  }

  return (
    <ModalLayout
      title='Edit info'
      closeModal={handleModalCancel}
      onConfirm={handleModalOk}
      disableOk={
        loading ||
        (topic.scenario === scenario &&
          topic.aiTutorRole === aiTutorRole &&
          topic.userRole === userRole)
      }
      autoClose={false}
    >
      <Form layout='vertical' onKeyUp={handleKeyPress} style={tw`w-full pt-2`}>
        <Form.Item label='Scenario'>
          <TextArea
            value={scenario}
            onChange={e => setScenario(e.target.value)}
            className='w-full'
          />
        </Form.Item>
        <Form.Item label='AI tutor role'>
          <Flex gap={8}>
            <Input
              value={aiTutorRole}
              onChange={e => setAiTutorRole(e.target.value)}
            />
          </Flex>
        </Form.Item>
        <Form.Item label='User role'>
          <Flex gap={8}>
            <Input
              value={userRole}
              onChange={e => setUserRole(e.target.value)}
            />
          </Flex>
        </Form.Item>
      </Form>
    </ModalLayout>
  )
}
