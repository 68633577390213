import { DeleteOutlined, EditOutlined, FilterFilled } from '@ant-design/icons'
import { Button, Flex, Image, Select, Spin, Tag } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'

import { useDebounceValue } from '#admin/components/utils/useDebounceValue'
import { ModalLayout } from '#admin/components/widgets/ModalLayout'
import { PaginatedTable } from '#admin/components/widgets/PaginatedTable'
import { SearchButton } from '#admin/components/widgets/SearchButton'
import { Text } from '#components/base/Text'
import { useOverlay } from '#components/overlay/hooks'
import { tw } from '#components/utils/tw'
import type { Topic, TopicInCategory } from '#graphql/codegen'
import { useSearchCategory, useSearchTopicInCategory } from '#graphql/codegen'
import { gql } from '#graphql/urql'
import { navigate } from '#navigator/helpers'
import type { SearchTopicItem } from '#types/topic'

import { RearrangeTopic } from './RearrangeTopic'
import { TopicModal } from './TopicModal'

export const Topics = observer(() => {
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [searchKeyword, setSearchKeyword] = useState('')
  const { openModal } = useOverlay()
  const [tags, setTags] = useState<
    { topicId: string; category: TopicInCategory[] }[]
  >([])
  const [loading, setLoading] = useState(false)
  const [filterKeyword, setFilterKeyword] = useState<{
    tags: string[]
    language: string[]
    level: string[]
  }>()

  const [searchTopic, setSearchTopic] = useState<Topic[]>()

  const [count, setCount] = useState(0)

  const [topicInCate, refetchTopicInCate] = useSearchTopicInCategory({
    variables: {
      filter:
        (searchTopic?.length ?? 0) > 0
          ? { topicId_in: searchTopic?.map(e => e.id) }
          : {},
      order: ['category_tagName_asc'],
      page: { limit: pageSize, offset: (currentPage - 1) * pageSize },
    },
  })

  const [searchTag, setSearchTag] = useState('')
  const [refetch, setRefetch] = useState(false)

  const [, setInputTag, debouncedKeyword] = useDebounceValue<string>('', 1000)

  const [category, refetchCategory] = useSearchCategory({
    variables: {
      filter: searchTag
        ? { tagName_iLike: `%${searchTag}%`, tagName_notLike: '' }
        : { tagName_notLike: '' },
      order: ['tagName_asc'],
      page: { limit: 10, offset: 0 },
    },
  })

  useEffect(() => {
    setCurrentPage(1)
  }, [searchKeyword])

  const filterData = async () => {
    setLoading(true)
    let dataTags: string[] = []
    if ((filterKeyword?.tags.length ?? 0) > 0) {
      const temp = await gql.searchTopicInCategory(
        {
          filter: {
            category_some: {
              tagName_in: filterKeyword?.tags?.map(e => e),
            },
          },
          order: ['topic_userId_asc'],
          // page: { limit: pageSize, offset: (currentPage - 1) * pageSize },
        },
        { requestPolicy: 'network-only' },
      )
      if (temp.data?.searchTopicInCategory.length === 0) {
        setSearchTopic([])
        setCount(0)
        return
      } else {
        dataTags = temp.data?.searchTopicInCategory.map(e => e.topicId) ?? []
      }
    }

    const andTopic = [
      dataTags.length > 0
        ? {
            id_in: dataTags,
          }
        : {},
      (filterKeyword?.level.length ?? 0) > 0
        ? {
            level_in: filterKeyword?.level,
          }
        : {},
      (filterKeyword?.language.length ?? 0) > 0
        ? {
            languageTopic_in: filterKeyword?.language,
          }
        : {},
      searchKeyword ? { name_iLike: `%${searchKeyword}%` } : {},
    ]

    const dataTopic = await gql.searchTopic(
      {
        filter: {
          AND: andTopic,
        },
        order: ['name_asc'],
        page: { limit: pageSize, offset: (currentPage - 1) * pageSize },
      },
      { requestPolicy: 'network-only' },
    )

    const countTotal = await gql.countTopic(
      {
        filter: {
          AND: andTopic,
        },
      },
      { requestPolicy: 'network-only' },
    )
    setCount(countTotal.data?.countTopic ?? 0)
    setSearchTopic(dataTopic.data?.searchTopic as Topic[])
    setLoading(false)
  }

  useEffect(() => {
    filterData()
  }, [JSON.stringify(filterKeyword), refetch, searchKeyword, currentPage])

  useEffect(() => {
    if (searchTopic) {
      searchTopic.map(e => {
        setTags([
          ...tags,
          {
            topicId: e.id,
            category: (topicInCate.data?.searchTopicInCategory ??
              []) as TopicInCategory[],
          },
        ])
      })
    }
  }, [searchTopic])

  useEffect(() => {
    setCurrentPage(1)
  }, [searchKeyword, filterKeyword])

  const handleAddData = () => {
    openModal(TopicModal, {
      onOk: onModalOk,
      containerProps: {
        closeOnOverlayClick: false,
      },
    })
  }

  const [options, setOptions] = useState(
    category?.data?.searchCategory.map(e => ({
      value: e.tagName,
    })),
  )

  const onModalOk = () => {
    refetchTopicInCate({ requestPolicy: 'network-only' })
    refetchCategory({ requestPolicy: 'network-only' })
    setRefetch(!refetch)
  }

  const reloadDelete = () => {
    refetchTopicInCate({ requestPolicy: 'network-only' })
    refetchCategory({ requestPolicy: 'network-only' })
    setRefetch(!refetch)

    if (count > 0) {
      const remainingItemsCount = count
      let newPageCount = Math.ceil(remainingItemsCount / pageSize)
      if (newPageCount < 1) {
        newPageCount = 1
      }
      if (currentPage > newPageCount) {
        setCurrentPage(newPageCount)
      }
    }
  }

  const onRowClick = (record: SearchTopicItem) => {
    navigate('Admin', {
      screen: 'AdminTopicDetail',
      params: { id: record.id, tab: 'word_and_phrases' },
    })
  }

  const actionsColumn = {
    title: 'Actions',
    key: 'actions',
    width: 100,
    render: (_, record) => (
      <Flex style={tw`justify-between`}>
        <Button
          icon={<EditOutlined />}
          onClick={e => {
            e.stopPropagation()
            openModal(TopicModal, {
              selectedTopic: record,
              onOk: onModalOk,
              topicInCate: topicInCate.data?.searchTopicInCategory.filter(
                t => t.topicId === record.id,
              ),
              containerProps: {
                closeOnOverlayClick: false,
              },
            })
          }}
          className='p-0 bg-transparent'
        />
        <Button
          icon={<DeleteOutlined />}
          onClick={e => {
            e.stopPropagation()
            openModal(ModalLayout, {
              onConfirm: async () => {
                await gql.deleteTopic(
                  { ids: [record.id] },
                  { requestPolicy: 'network-only' },
                )
                reloadDelete()
              },
              title: 'Confirm delete',
              content: 'Are you sure you want to delete this topics?',
              successMessage: 'Topic deleted successfully',
              errorMessage: 'Delete topic failed',
              containerProps: {
                closeOnOverlayClick: false,
              },
            })
          }}
          className='p-0 bg-transparent'
          danger={true}
        />
      </Flex>
    ),
  }

  const columns: ColumnsType<SearchTopicItem> = [
    // {
    //   title: 'Avatar',
    //   dataIndex: 'avatar',
    //   key: 'avatar',
    //   width: 100,
    //   render: (_, record) =>
    //     record?.character?.url ? (
    //       <Flex
    //         onClick={e => {
    //           e?.stopPropagation()
    //         }}
    //       >
    //         <Image
    //           src={record?.character?.url}
    //           style={tw.style('w-12 h-12 border-2 border-gray-300', {
    //             cursor: 'pointer',
    //           })}
    //           onClick={e => {
    //             e?.stopPropagation()
    //           }}
    //         />
    //       </Flex>
    //     ) : null,
    // },
    {
      title: 'Image',
      dataIndex: 'image',
      key: 'image',
      width: 100,
      render: (_, record) =>
        record?.media?.url ? (
          <Flex
            onClick={e => {
              e?.stopPropagation()
            }}
          >
            <Image
              src={record?.media?.url}
              style={tw.style('w-12 h-12 border-2 border-gray-300', {
                cursor: 'pointer',
              })}
              onClick={e => {
                e?.stopPropagation()
              }}
            />
          </Flex>
        ) : null,
    },
    {
      title: 'Topic Name',
      dataIndex: 'name',
      key: 'name',
      render: (_, data) => <div style={{ cursor: 'pointer' }}>{data.name}</div>,
    },
    {
      title: 'Tags',
      dataIndex: 'tags',
      key: 'tags',
      render: (_, data) => (
        <div
          style={tw.style({
            gap: '5px',
            display: 'flex',
            flexWrap: 'wrap',
            paddingTop: '2%',
            paddingBottom: '2%',
          })}
        >
          {
            <div>
              {topicInCate.data?.searchTopicInCategory
                .map(e => {
                  if (e.topicId === data.id) {
                    return e.category?.tagName
                  }
                  return
                })
                .filter(e => e)
                .join(', ')}
            </div>
          }
        </div>
      ),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: 300,
    },
    {
      title: 'Level',
      dataIndex: 'level',
      key: 'level',
      width: 70,
      render: (_, data) => (
        <div style={{ cursor: 'pointer' }}>{data.level}</div>
      ),
    },
    {
      title: 'Language',
      dataIndex: 'languageTopic',
      key: 'languageTopic',
      width: 100,
      render: (_, data) => (
        <div style={{ cursor: 'pointer' }}>{data.languageTopic}</div>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 100,
      render: (_, data) => (
        <div style={{ cursor: 'pointer' }}>{data.status}</div>
      ),
    },
    actionsColumn,
  ]

  const tagRender = props => {
    const { label, closable, onClose } = props
    const onPreventMouseDown = event => {
      event.preventDefault()
      event.stopPropagation()
    }
    return (
      <Tag
        color={'green'}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{
          marginInlineEnd: 4,
        }}
      >
        {label}
      </Tag>
    )
  }

  useEffect(() => {
    setOptions(category.data?.searchCategory.map(e => ({ value: e.tagName })))
  }, [JSON.stringify(category)])

  useEffect(() => {
    setSearchTag(debouncedKeyword)
  }, [debouncedKeyword, setSearchTag])

  const debounceFetcher = (value: string) => {
    setInputTag(value)
  }

  useEffect(() => {
    setSearchTag('')
  }, [filterKeyword?.tags])

  const handleSort = () => {
    openModal(RearrangeTopic, {
      containerProps: {},
    })
  }

  return (
    <Flex style={tw`flex-1 flex-col p-4 h-full`}>
      <Text style={tw`py-6 text-6`}>Topic</Text>
      <SearchButton
        title='Add'
        keyword={setSearchKeyword}
        handleAddData={handleAddData}
      />
      <Flex
        gap={12}
        style={tw.style({
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'flex-end',
          marginBottom: '15px',
        })}
      >
        <div style={tw`w-full`}>
          <div style={tw`mb-1`}>Tags</div>
          <Select
            mode='multiple'
            tagRender={tagRender}
            defaultValue={filterKeyword?.tags}
            onSearch={debounceFetcher}
            notFoundContent={category.fetching ? <Spin size='small' /> : null}
            style={{
              width: '100%',
            }}
            options={options}
            onChange={e =>
              setFilterKeyword(prev => ({
                ...prev,
                tags: e,
                language: prev?.language ?? [],
                level: prev?.level ?? [],
              }))
            }
          />
        </div>
        <div style={tw`w-full`}>
          <div style={tw`mb-1`}>Level</div>
          <Select
            mode='multiple'
            tagRender={tagRender}
            defaultValue={filterKeyword?.level}
            style={{
              width: '100%',
            }}
            options={[
              {
                value: 'A1',
              },
              {
                value: 'A2',
              },
              {
                value: 'B1',
              },
              {
                value: 'B2',
              },
            ]}
            onChange={e =>
              setFilterKeyword(prev => ({
                ...prev,
                level: e,
                tags: prev?.tags ?? [],
                language: prev?.language ?? [],
              }))
            }
          />
        </div>
        <div style={tw`w-full`}>
          <div style={tw`mb-1`}>Language</div>
          <Select
            mode='multiple'
            tagRender={tagRender}
            defaultValue={filterKeyword?.language}
            style={{
              width: '100%',
            }}
            options={[
              {
                value: 'English',
              },
              {
                value: 'Spanish',
              },
            ]}
            onChange={e =>
              setFilterKeyword(prev => ({
                ...prev,
                language: e,
                tags: prev?.tags ?? [],
                level: prev?.level ?? [],
              }))
            }
          />
        </div>
        <div style={tw`w-full`}>
          <Button onClick={handleSort} style={tw`w-full`}>
            <Flex gap={4}>
              <div>Manage Order</div>
              <FilterFilled />
            </Flex>
          </Button>
        </div>
      </Flex>
      <PaginatedTable<SearchTopicItem>
        dataSource={searchTopic ?? []}
        columns={columns}
        loading={loading}
        onRowClick={onRowClick}
        total={count}
        currentPage={currentPage}
        pageSize={pageSize}
        setCurrentPage={setCurrentPage}
        setPageSize={setPageSize}
        scrollHeight={'50vh'}
        scrollWidth={1200}
      />
    </Flex>
  )
})
