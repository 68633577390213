import type { Code } from '#graphql/codegen'
import { gql } from '#graphql/urql'

export const createPaymentIntentApi = async (
  amount: number,
  currency: Code | 'AUD',
  selectedMethod?: string,
  idPrice?: string,
  paymentMethodId?: string,
) => {
  if (!amount || amount < 0.5 || !idPrice || !selectedMethod || !idPrice) {
    return
  }
  const data = {
    amount,
    currency,
    payment_method: paymentMethodId,
    automatic_payment_methods: {
      enabled: true,
    },
    idPrice,
  }

  if (!paymentMethodId) {
    delete data.payment_method
  }
  if (selectedMethod === 'card') {
    delete (data as any).automatic_payment_methods
    data['payment_method_types'] = ['card']
  }
  const createResponse = await gql.createPaymentIntent({
    data,
  })
  const c = createResponse.data?.createPaymentIntent
  if (!c) {
    return
  }
  return {
    paymentMethodId,
    customerId: c.customer || '',
    paymentIntent: c.paymentIntent || '',
    cliSecret: c.clientSecret || '',
  }
}
