import type { FC } from 'react'
import { useState } from 'react'
import { TouchableOpacity, View } from 'react-native'

import { FlatList } from '#components/base/FlatList'
import { SystemIcon } from '#components/base/SystemIcon'
import { Text } from '#components/base/Text'
import { Radio } from '#components/form/Radio'
import { tw } from '#components/utils/tw'
import type { UserTask, UserTaskItem } from '#types/chat'

import { SubTaskItem } from './SubTaskItem'

type TaskItemProps = {
  item: UserTaskItem
  index: number
}

export const TaskItem: FC<TaskItemProps> = ({ item, index }) => {
  const [isShow, setIsShow] = useState<boolean>(false)

  const onPressShow = () => {
    if (item.subTask.length !== 0) {
      setIsShow(!isShow)
    }
  }

  const renderSubTask = ({
    item: subTaskItem,
    index: idx,
  }: {
    item: UserTask
    index: number
  }) => <SubTaskItem item={subTaskItem} index={idx} />

  const renderItemSeparatorSubTask = () => <View style={tw`h-2`} />

  return (
    <View style={tw`pt-2 px-3`}>
      <TouchableOpacity
        onPress={onPressShow}
        style={tw.style('flex-row', isShow ? ' mb-2' : '')}
      >
        <Text specialType='paragraph1' color={tw.color('text-1')}>
          {`${index + 1}.`}
        </Text>
        <View style={tw`flex-1 mx-2`}>
          <Text specialType='paragraph1' color={tw.color('text-1')}>
            {item.name}
          </Text>
        </View>
        {item.subTask.length !== 0 ? (
          <SystemIcon type='SAX' name={isShow ? 'ArrowUp2' : 'ArrowDown2'} />
        ) : (
          <Radio isChecked={item.isPassed} />
        )}
      </TouchableOpacity>
      {isShow && item.subTask.length !== 0 && (
        <FlatList
          data={item.subTask}
          renderItem={renderSubTask}
          scrollEnabled={false}
          ItemSeparatorComponent={renderItemSeparatorSubTask}
        />
      )}
    </View>
  )
}
