import { observer } from 'mobx-react-lite'
import type React from 'react'
import { View } from 'react-native'

import { Button } from '#components/base/Button/Button'
import { SystemIcon } from '#components/base/SystemIcon'
import { Text } from '#components/base/Text'
import { isLatestDateToday } from '#components/utils/streak'
import { tw } from '#components/utils/tw'
import { ActionSheet } from '#components/widgets/ActionSheet'
import type { TasksItem } from '#graphql/codegen'
import { useSearchStreakInUser, useSearchTopic } from '#graphql/codegen'
import { gql } from '#graphql/urql'
import { navigate, replace } from '#navigator/helpers'
import { S } from '#store'

import { convertToUserTasks } from './converseType'

type Pr = {
  closeActionsheet: () => void
  topicId: string
  topicInUserId: string
  onReload: () => void
}

export const ActionSheetChat: React.FC<Pr> = observer(
  ({ closeActionsheet, topicId, topicInUserId, onReload }) => {
    const [streakData] = useSearchStreakInUser({
      variables: { filter: { userId: S.shared.currentUser?.id } },
    })
    const streakInUser = streakData?.data?.searchStreakInUser || []
    const lastDate = streakInUser.length > 0 ? streakInUser[0].lastDate : ''

    const [topicData] = useSearchTopic({
      variables: { filter: { id: topicId } },
    })
    const topic =
      (topicData.data?.searchTopic || []).length > 0
        ? topicData.data?.searchTopic[0]
        : null

    const data: TasksItem[] = topic?.task?.data || []

    const onPressNextTopic = () => {
      closeActionsheet()
      S.shared.isShowBottomSheet = false
      replace('Home', { screen: 'Topics' })
      if (!isLatestDateToday(lastDate)) {
        navigate('App', { screen: 'StreakDaily', params: { isGoBack: true } })
      }
    }

    const onPressKeepPractising = async () => {
      closeActionsheet()
      S.shared.isShowBottomSheet = false
      if (!isLatestDateToday(lastDate)) {
        navigate('App', { screen: 'StreakDaily', params: { isGoBack: true } })
      }
      await gql.deleteMessageTopicInUser({ topicInUserId })
      if (data.length !== 0) {
        await gql.updateTopicInUser({
          id: topicInUserId,
          data: { task: convertToUserTasks(data) },
        })
      }
      onReload()
    }
    return (
      <ActionSheet isLoading={false}>
        <View style={tw.style('flex px-2 w-full items-center')}>
          <SystemIcon type='SVG' name='checkCircle' size={90} />
          <View style={tw.style('gap-2 ')}>
            <Text
              specialType='Headline3'
              color={tw.color('primary-400')}
              textAlign='center'
            >
              You completed missions
            </Text>
            <Text specialType='paragraph1' textAlign='center'>
              Would you like to exploring minigame or move on to a new topic?
            </Text>
          </View>
        </View>
        <View
          style={tw.style('flex-1 px-2 w-full items-center gap-3 pt-8 pb-4')}
        >
          <View style={tw.style('w-full')}>
            <Button tone='primary' onPress={onPressNextTopic}>
              Next topic
            </Button>
          </View>
          <View
            style={tw.style('w-full border border-primary-400 rounded-full')}
          >
            <Button tone='secondary' onPress={onPressKeepPractising}>
              Keep practicing
            </Button>
          </View>
        </View>
      </ActionSheet>
    )
  },
)
