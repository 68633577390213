import { gql } from '#graphql/urql'

export const resolveForgotPassword = async (
  secret: string,
  id: string,
  newPassword: string,
) => {
  try {
    const response = await gql.resolveForgotPassword({
      data: {
        secret,
        id,
      },
      newPassword,
    })
    return response.data
  } catch (e) {
    return null
  }
}
