import { Avatar, Flex, Select } from 'antd'

import { ModalImage } from '#admin/components/widgets/ModalImage'
import { Upload } from '#admin/components/widgets/Upload'
import { useOverlay } from '#components/overlay/hooks'
import { tw } from '#components/utils/tw'
import type { InputQuestionGame } from '#types/games'
import { resourceGameType } from '#types/games'
import type { ResponseFile } from '#types/media'
import type { SearchWordItem } from '#types/word'

type Props = {
  question: InputQuestionGame
  word: SearchWordItem | null
  imageData: (SelectItem & { key: string })[]
  handleSelectChange: (
    type: 'audio' | 'video' | 'image',
  ) => (data: SelectItem & { key: string }) => void
  isUploadImage: boolean
  setIsUploadImage: (value: boolean) => void
  handleMediaQuestionChange: (
    type: 'audio' | 'video' | 'image',
  ) => (data: ResponseFile) => void
}

type SelectItem = { label: string; value: string }

export const HandleQuesUploadImage = ({
  question,
  word,
  imageData,
  handleSelectChange,
  isUploadImage,
  setIsUploadImage,
  handleMediaQuestionChange,
}: Props) => {
  const { openModal } = useOverlay()

  const options = [
    {
      label: word?.media?.name ?? '',
      value: word?.media?.url ?? '',
      key: word?.mediaId ?? '',
    },
    ...imageData,
  ]
  return (
    <Flex gap={8} style={tw`mb-4 items-center`}>
      <Avatar
        shape='square'
        src={question?.thumbnail?.url}
        style={tw.style('w-12 h-12 border-2 border-gray-300', {
          cursor: 'pointer',
        })}
        onClick={e => {
          e?.stopPropagation()
          if (question?.thumbnail?.url) {
            openModal(ModalImage, {
              shape: 'square',
              avatarUrl: question?.thumbnail?.url,
              containerProps: {
                closeOnOverlayClick: false,
              },
            })
          }
        }}
      />
      <Select
        showSearch
        style={{ width: '84%' }}
        placeholder='Search to Select'
        optionFilterProp='label'
        options={options.filter(e => e.value)}
        value={{
          label: question?.thumbnail?.name || '',
          value: question?.thumbnail?.url || '',
          key: question?.thumbnail?.mediaId || '',
        }}
        labelInValue
        onChange={handleSelectChange('image')}
        disabled={isUploadImage}
      />
      <Upload
        resourceType={resourceGameType[question.typeGame]}
        url={question?.thumbnail?.url || ''}
        type={'image'}
        display='audio'
        required
        onChange={handleMediaQuestionChange('image')}
        isHiddenLabel
        onFileUploading={setIsUploadImage}
      />
    </Flex>
  )
}
