import type { FC } from 'react'
import { View } from 'react-native'

import { Text } from '#components/base/Text'
import { Radio } from '#components/form/Radio'
import { tw } from '#components/utils/tw'
import type { UserTask } from '#types/chat'

type SubTaskItemProps = {
  item: UserTask
  index: number
}

export const SubTaskItem: FC<SubTaskItemProps> = ({ item, index }) => (
  <View style={tw`flex-row items-center`} key={index}>
    <View style={tw`flex-row flex-1`}>
      <View style={tw`h-2 w-2 rounded-full bg-neutral-500 mt-1.5`} />
      <View style={tw`flex-1 mx-3`}>
        <Text specialType='paragraph1' color={tw.color('text-1')}>
          {item.name}
        </Text>
      </View>
    </View>
    <Radio isChecked={item.isPassed} />
  </View>
)
