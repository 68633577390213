/* eslint-disable */
import gql from 'graphql-tag'
import * as Urql from 'urql'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never }
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never
    }
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>
const operationsFactory = {} as OperationsFactory
export const operations = (client: Urql.Client): Operations =>
  Object.entries(operationsFactory).reduce<any>((m, [k, v]) => {
    m[k] = v(client)
    return m
  }, {})
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  Buffer: { input: any; output: any }
  DateTime: { input: Date; output: Date }
  Email: { input: string; output: string }
  Json: { input: any; output: any }
  NFloat: { input: number; output: number }
  NInt: { input: number; output: number }
  NeString: { input: string; output: string }
  NpFloat: { input: number; output: number }
  NpInt: { input: number; output: number }
  Null: { input: null; output: null }
  PFloat: { input: number; output: number }
  PInt: { input: number; output: number }
  Password: { input: string; output: string }
  SDate: { input: string; output: string }
  STime: { input: string; output: string }
  UFloat: { input: number; output: number }
  UInt: { input: number; output: number }
}

export type AiTutorMessage = {
  AIRole?: InputMaybe<Scalars['Boolean']['input']>
  content?: InputMaybe<Scalars['String']['input']>
}

export type Address = {
  city?: InputMaybe<Scalars['String']['input']>
  country?: InputMaybe<Scalars['String']['input']>
  line1?: InputMaybe<Scalars['String']['input']>
  line2?: InputMaybe<Scalars['String']['input']>
  postal_code?: InputMaybe<Scalars['String']['input']>
  state?: InputMaybe<Scalars['String']['input']>
}

export type AiTutor = {
  __typename?: 'AiTutor'
  address: Scalars['String']['output']
  createdAt: Scalars['DateTime']['output']
  description: Scalars['String']['output']
  gender: Scalars['String']['output']
  id: Scalars['ID']['output']
  languageAI: Scalars['String']['output']
  media?: Maybe<Resource>
  mediaId: Scalars['ID']['output']
  message?: Maybe<Message>
  name: Scalars['String']['output']
  status: Scalars['String']['output']
  thumbnail?: Maybe<Resource>
  thumbnailId: Scalars['ID']['output']
  user?: Maybe<User>
  userId: Scalars['ID']['output']
}

export type AiTutorFilter = {
  AND?: InputMaybe<Array<AiTutorFilter>>
  NOT?: InputMaybe<AiTutorFilter>
  OR?: InputMaybe<Array<AiTutorFilter>>
  address?: InputMaybe<Scalars['String']['input']>
  address_endsWith?: InputMaybe<Scalars['String']['input']>
  address_gt?: InputMaybe<Scalars['String']['input']>
  address_gte?: InputMaybe<Scalars['String']['input']>
  address_iLike?: InputMaybe<Scalars['String']['input']>
  address_in?: InputMaybe<Array<Scalars['String']['input']>>
  address_like?: InputMaybe<Scalars['String']['input']>
  address_lt?: InputMaybe<Scalars['String']['input']>
  address_lte?: InputMaybe<Scalars['String']['input']>
  address_ne?: InputMaybe<Scalars['String']['input']>
  address_notILike?: InputMaybe<Scalars['String']['input']>
  address_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  address_notLike?: InputMaybe<Scalars['String']['input']>
  address_startsWith?: InputMaybe<Scalars['String']['input']>
  address_substring?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_ne?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>
  description?: InputMaybe<Scalars['String']['input']>
  description_endsWith?: InputMaybe<Scalars['String']['input']>
  description_gt?: InputMaybe<Scalars['String']['input']>
  description_gte?: InputMaybe<Scalars['String']['input']>
  description_iLike?: InputMaybe<Scalars['String']['input']>
  description_in?: InputMaybe<Array<Scalars['String']['input']>>
  description_like?: InputMaybe<Scalars['String']['input']>
  description_lt?: InputMaybe<Scalars['String']['input']>
  description_lte?: InputMaybe<Scalars['String']['input']>
  description_ne?: InputMaybe<Scalars['String']['input']>
  description_notILike?: InputMaybe<Scalars['String']['input']>
  description_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  description_notLike?: InputMaybe<Scalars['String']['input']>
  description_startsWith?: InputMaybe<Scalars['String']['input']>
  description_substring?: InputMaybe<Scalars['String']['input']>
  gender?: InputMaybe<Scalars['String']['input']>
  gender_endsWith?: InputMaybe<Scalars['String']['input']>
  gender_gt?: InputMaybe<Scalars['String']['input']>
  gender_gte?: InputMaybe<Scalars['String']['input']>
  gender_iLike?: InputMaybe<Scalars['String']['input']>
  gender_in?: InputMaybe<Array<Scalars['String']['input']>>
  gender_like?: InputMaybe<Scalars['String']['input']>
  gender_lt?: InputMaybe<Scalars['String']['input']>
  gender_lte?: InputMaybe<Scalars['String']['input']>
  gender_ne?: InputMaybe<Scalars['String']['input']>
  gender_notILike?: InputMaybe<Scalars['String']['input']>
  gender_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  gender_notLike?: InputMaybe<Scalars['String']['input']>
  gender_startsWith?: InputMaybe<Scalars['String']['input']>
  gender_substring?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>
  id_ne?: InputMaybe<Scalars['ID']['input']>
  id_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  languageAI?: InputMaybe<Scalars['String']['input']>
  languageAI_endsWith?: InputMaybe<Scalars['String']['input']>
  languageAI_gt?: InputMaybe<Scalars['String']['input']>
  languageAI_gte?: InputMaybe<Scalars['String']['input']>
  languageAI_iLike?: InputMaybe<Scalars['String']['input']>
  languageAI_in?: InputMaybe<Array<Scalars['String']['input']>>
  languageAI_like?: InputMaybe<Scalars['String']['input']>
  languageAI_lt?: InputMaybe<Scalars['String']['input']>
  languageAI_lte?: InputMaybe<Scalars['String']['input']>
  languageAI_ne?: InputMaybe<Scalars['String']['input']>
  languageAI_notILike?: InputMaybe<Scalars['String']['input']>
  languageAI_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  languageAI_notLike?: InputMaybe<Scalars['String']['input']>
  languageAI_startsWith?: InputMaybe<Scalars['String']['input']>
  languageAI_substring?: InputMaybe<Scalars['String']['input']>
  mediaId?: InputMaybe<Scalars['ID']['input']>
  mediaId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  mediaId_ne?: InputMaybe<Scalars['ID']['input']>
  mediaId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  media_none?: InputMaybe<ResourceFilter>
  media_some?: InputMaybe<ResourceFilter>
  message_none?: InputMaybe<MessageFilter>
  message_some?: InputMaybe<MessageFilter>
  name?: InputMaybe<Scalars['String']['input']>
  name_endsWith?: InputMaybe<Scalars['String']['input']>
  name_gt?: InputMaybe<Scalars['String']['input']>
  name_gte?: InputMaybe<Scalars['String']['input']>
  name_iLike?: InputMaybe<Scalars['String']['input']>
  name_in?: InputMaybe<Array<Scalars['String']['input']>>
  name_like?: InputMaybe<Scalars['String']['input']>
  name_lt?: InputMaybe<Scalars['String']['input']>
  name_lte?: InputMaybe<Scalars['String']['input']>
  name_ne?: InputMaybe<Scalars['String']['input']>
  name_notILike?: InputMaybe<Scalars['String']['input']>
  name_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  name_notLike?: InputMaybe<Scalars['String']['input']>
  name_startsWith?: InputMaybe<Scalars['String']['input']>
  name_substring?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<Scalars['String']['input']>
  status_endsWith?: InputMaybe<Scalars['String']['input']>
  status_gt?: InputMaybe<Scalars['String']['input']>
  status_gte?: InputMaybe<Scalars['String']['input']>
  status_iLike?: InputMaybe<Scalars['String']['input']>
  status_in?: InputMaybe<Array<Scalars['String']['input']>>
  status_like?: InputMaybe<Scalars['String']['input']>
  status_lt?: InputMaybe<Scalars['String']['input']>
  status_lte?: InputMaybe<Scalars['String']['input']>
  status_ne?: InputMaybe<Scalars['String']['input']>
  status_notILike?: InputMaybe<Scalars['String']['input']>
  status_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  status_notLike?: InputMaybe<Scalars['String']['input']>
  status_startsWith?: InputMaybe<Scalars['String']['input']>
  status_substring?: InputMaybe<Scalars['String']['input']>
  thumbnailId?: InputMaybe<Scalars['ID']['input']>
  thumbnailId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  thumbnailId_ne?: InputMaybe<Scalars['ID']['input']>
  thumbnailId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  thumbnail_none?: InputMaybe<ResourceFilter>
  thumbnail_some?: InputMaybe<ResourceFilter>
  userId?: InputMaybe<Scalars['ID']['input']>
  userId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  userId_ne?: InputMaybe<Scalars['ID']['input']>
  userId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  user_none?: InputMaybe<UserFilter>
  user_some?: InputMaybe<UserFilter>
}

export type AiTutorOrderBy =
  | 'address_asc'
  | 'address_desc'
  | 'createdAt_asc'
  | 'createdAt_desc'
  | 'description_asc'
  | 'description_desc'
  | 'gender_asc'
  | 'gender_desc'
  | 'id_asc'
  | 'id_desc'
  | 'languageAI_asc'
  | 'languageAI_desc'
  | 'mediaId_asc'
  | 'mediaId_desc'
  | 'media_createdAt_asc'
  | 'media_createdAt_desc'
  | 'media_createdById_asc'
  | 'media_createdById_desc'
  | 'media_duration_asc'
  | 'media_duration_desc'
  | 'media_ffprobe_asc'
  | 'media_ffprobe_desc'
  | 'media_fileSize_asc'
  | 'media_fileSize_desc'
  | 'media_id_asc'
  | 'media_id_desc'
  | 'media_name_asc'
  | 'media_name_desc'
  | 'media_originalResourceId_asc'
  | 'media_originalResourceId_desc'
  | 'media_originalResourceState_asc'
  | 'media_originalResourceState_desc'
  | 'media_thumbnailResourceId_asc'
  | 'media_thumbnailResourceId_desc'
  | 'media_type_asc'
  | 'media_type_desc'
  | 'media_url_asc'
  | 'media_url_desc'
  | 'message_aiTutorId_asc'
  | 'message_aiTutorId_desc'
  | 'message_content_asc'
  | 'message_content_desc'
  | 'message_createdAt_asc'
  | 'message_createdAt_desc'
  | 'message_id_asc'
  | 'message_id_desc'
  | 'message_sentByUser_asc'
  | 'message_sentByUser_desc'
  | 'message_topicInUserId_asc'
  | 'message_topicInUserId_desc'
  | 'message_userId_asc'
  | 'message_userId_desc'
  | 'name_asc'
  | 'name_desc'
  | 'status_asc'
  | 'status_desc'
  | 'thumbnailId_asc'
  | 'thumbnailId_desc'
  | 'thumbnail_createdAt_asc'
  | 'thumbnail_createdAt_desc'
  | 'thumbnail_createdById_asc'
  | 'thumbnail_createdById_desc'
  | 'thumbnail_duration_asc'
  | 'thumbnail_duration_desc'
  | 'thumbnail_ffprobe_asc'
  | 'thumbnail_ffprobe_desc'
  | 'thumbnail_fileSize_asc'
  | 'thumbnail_fileSize_desc'
  | 'thumbnail_id_asc'
  | 'thumbnail_id_desc'
  | 'thumbnail_name_asc'
  | 'thumbnail_name_desc'
  | 'thumbnail_originalResourceId_asc'
  | 'thumbnail_originalResourceId_desc'
  | 'thumbnail_originalResourceState_asc'
  | 'thumbnail_originalResourceState_desc'
  | 'thumbnail_thumbnailResourceId_asc'
  | 'thumbnail_thumbnailResourceId_desc'
  | 'thumbnail_type_asc'
  | 'thumbnail_type_desc'
  | 'thumbnail_url_asc'
  | 'thumbnail_url_desc'
  | 'userId_asc'
  | 'userId_desc'
  | 'user_aiTutorId_asc'
  | 'user_aiTutorId_desc'
  | 'user_autoPlay_asc'
  | 'user_autoPlay_desc'
  | 'user_createdAt_asc'
  | 'user_createdAt_desc'
  | 'user_customer_asc'
  | 'user_customer_desc'
  | 'user_deactivate_asc'
  | 'user_deactivate_desc'
  | 'user_email_asc'
  | 'user_email_desc'
  | 'user_hashedPassword_asc'
  | 'user_hashedPassword_desc'
  | 'user_id_asc'
  | 'user_id_desc'
  | 'user_isAdmin_asc'
  | 'user_isAdmin_desc'
  | 'user_languageLearn_asc'
  | 'user_languageLearn_desc'
  | 'user_lastUsedDate_asc'
  | 'user_lastUsedDate_desc'
  | 'user_level_asc'
  | 'user_level_desc'
  | 'user_name_asc'
  | 'user_name_desc'
  | 'user_nativeLanguage_asc'
  | 'user_nativeLanguage_desc'
  | 'user_repair_asc'
  | 'user_repair_desc'
  | 'user_speed_asc'
  | 'user_speed_desc'
  | 'user_thumbnailId_asc'
  | 'user_thumbnailId_desc'

export type AiTutorStatusType = 'Active' | 'Deleted' | 'Pending'

export type AiTutorTypeGender = 'Female' | 'Male' | 'Other'

export type AllowRedirects = 'always' | 'never'

export type Answer = {
  __typename?: 'Answer'
  answerInQuestion?: Maybe<AnswerInQuestion>
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  index: Scalars['Int']['output']
  text: Scalars['String']['output']
  translate?: Maybe<Translate>
}

export type AnswerFilter = {
  AND?: InputMaybe<Array<AnswerFilter>>
  NOT?: InputMaybe<AnswerFilter>
  OR?: InputMaybe<Array<AnswerFilter>>
  answerInQuestion_none?: InputMaybe<AnswerInQuestionFilter>
  answerInQuestion_some?: InputMaybe<AnswerInQuestionFilter>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_ne?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>
  id?: InputMaybe<Scalars['ID']['input']>
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>
  id_ne?: InputMaybe<Scalars['ID']['input']>
  id_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  index?: InputMaybe<Scalars['Int']['input']>
  index_gt?: InputMaybe<Scalars['Int']['input']>
  index_gte?: InputMaybe<Scalars['Int']['input']>
  index_in?: InputMaybe<Array<Scalars['Int']['input']>>
  index_lt?: InputMaybe<Scalars['Int']['input']>
  index_lte?: InputMaybe<Scalars['Int']['input']>
  index_ne?: InputMaybe<Scalars['Int']['input']>
  index_notIn?: InputMaybe<Array<Scalars['Int']['input']>>
  text?: InputMaybe<Scalars['String']['input']>
  text_endsWith?: InputMaybe<Scalars['String']['input']>
  text_gt?: InputMaybe<Scalars['String']['input']>
  text_gte?: InputMaybe<Scalars['String']['input']>
  text_iLike?: InputMaybe<Scalars['String']['input']>
  text_in?: InputMaybe<Array<Scalars['String']['input']>>
  text_like?: InputMaybe<Scalars['String']['input']>
  text_lt?: InputMaybe<Scalars['String']['input']>
  text_lte?: InputMaybe<Scalars['String']['input']>
  text_ne?: InputMaybe<Scalars['String']['input']>
  text_notILike?: InputMaybe<Scalars['String']['input']>
  text_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  text_notLike?: InputMaybe<Scalars['String']['input']>
  text_startsWith?: InputMaybe<Scalars['String']['input']>
  text_substring?: InputMaybe<Scalars['String']['input']>
  translate_none?: InputMaybe<TranslateFilter>
  translate_some?: InputMaybe<TranslateFilter>
}

export type AnswerInQuestion = {
  __typename?: 'AnswerInQuestion'
  answer?: Maybe<Answer>
  answerId: Scalars['ID']['output']
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  isTrue: Scalars['Boolean']['output']
  media?: Maybe<Resource>
  mediaId: Scalars['ID']['output']
  question?: Maybe<Question>
  questionId: Scalars['ID']['output']
  thumbnail?: Maybe<Resource>
  thumbnailId: Scalars['ID']['output']
}

export type AnswerInQuestionFilter = {
  AND?: InputMaybe<Array<AnswerInQuestionFilter>>
  NOT?: InputMaybe<AnswerInQuestionFilter>
  OR?: InputMaybe<Array<AnswerInQuestionFilter>>
  answerId?: InputMaybe<Scalars['ID']['input']>
  answerId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  answerId_ne?: InputMaybe<Scalars['ID']['input']>
  answerId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  answer_none?: InputMaybe<AnswerFilter>
  answer_some?: InputMaybe<AnswerFilter>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_ne?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>
  id?: InputMaybe<Scalars['ID']['input']>
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>
  id_ne?: InputMaybe<Scalars['ID']['input']>
  id_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  isTrue?: InputMaybe<Scalars['Boolean']['input']>
  isTrue_ne?: InputMaybe<Scalars['Boolean']['input']>
  mediaId?: InputMaybe<Scalars['ID']['input']>
  mediaId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  mediaId_ne?: InputMaybe<Scalars['ID']['input']>
  mediaId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  media_none?: InputMaybe<ResourceFilter>
  media_some?: InputMaybe<ResourceFilter>
  questionId?: InputMaybe<Scalars['ID']['input']>
  questionId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  questionId_ne?: InputMaybe<Scalars['ID']['input']>
  questionId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  question_none?: InputMaybe<QuestionFilter>
  question_some?: InputMaybe<QuestionFilter>
  thumbnailId?: InputMaybe<Scalars['ID']['input']>
  thumbnailId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  thumbnailId_ne?: InputMaybe<Scalars['ID']['input']>
  thumbnailId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  thumbnail_none?: InputMaybe<ResourceFilter>
  thumbnail_some?: InputMaybe<ResourceFilter>
}

export type AnswerInQuestionOrderBy =
  | 'answerId_asc'
  | 'answerId_desc'
  | 'answer_createdAt_asc'
  | 'answer_createdAt_desc'
  | 'answer_id_asc'
  | 'answer_id_desc'
  | 'answer_index_asc'
  | 'answer_index_desc'
  | 'answer_text_asc'
  | 'answer_text_desc'
  | 'createdAt_asc'
  | 'createdAt_desc'
  | 'id_asc'
  | 'id_desc'
  | 'isTrue_asc'
  | 'isTrue_desc'
  | 'mediaId_asc'
  | 'mediaId_desc'
  | 'media_createdAt_asc'
  | 'media_createdAt_desc'
  | 'media_createdById_asc'
  | 'media_createdById_desc'
  | 'media_duration_asc'
  | 'media_duration_desc'
  | 'media_ffprobe_asc'
  | 'media_ffprobe_desc'
  | 'media_fileSize_asc'
  | 'media_fileSize_desc'
  | 'media_id_asc'
  | 'media_id_desc'
  | 'media_name_asc'
  | 'media_name_desc'
  | 'media_originalResourceId_asc'
  | 'media_originalResourceId_desc'
  | 'media_originalResourceState_asc'
  | 'media_originalResourceState_desc'
  | 'media_thumbnailResourceId_asc'
  | 'media_thumbnailResourceId_desc'
  | 'media_type_asc'
  | 'media_type_desc'
  | 'media_url_asc'
  | 'media_url_desc'
  | 'questionId_asc'
  | 'questionId_desc'
  | 'question_createdAt_asc'
  | 'question_createdAt_desc'
  | 'question_id_asc'
  | 'question_id_desc'
  | 'question_index_asc'
  | 'question_index_desc'
  | 'question_mediaId_asc'
  | 'question_mediaId_desc'
  | 'question_text_asc'
  | 'question_text_desc'
  | 'question_thumbnailId_asc'
  | 'question_thumbnailId_desc'
  | 'question_topicId_asc'
  | 'question_topicId_desc'
  | 'question_type_asc'
  | 'question_type_desc'
  | 'question_wordId_asc'
  | 'question_wordId_desc'
  | 'thumbnailId_asc'
  | 'thumbnailId_desc'
  | 'thumbnail_createdAt_asc'
  | 'thumbnail_createdAt_desc'
  | 'thumbnail_createdById_asc'
  | 'thumbnail_createdById_desc'
  | 'thumbnail_duration_asc'
  | 'thumbnail_duration_desc'
  | 'thumbnail_ffprobe_asc'
  | 'thumbnail_ffprobe_desc'
  | 'thumbnail_fileSize_asc'
  | 'thumbnail_fileSize_desc'
  | 'thumbnail_id_asc'
  | 'thumbnail_id_desc'
  | 'thumbnail_name_asc'
  | 'thumbnail_name_desc'
  | 'thumbnail_originalResourceId_asc'
  | 'thumbnail_originalResourceId_desc'
  | 'thumbnail_originalResourceState_asc'
  | 'thumbnail_originalResourceState_desc'
  | 'thumbnail_thumbnailResourceId_asc'
  | 'thumbnail_thumbnailResourceId_desc'
  | 'thumbnail_type_asc'
  | 'thumbnail_type_desc'
  | 'thumbnail_url_asc'
  | 'thumbnail_url_desc'

export type AnswerOrderBy =
  | 'answerInQuestion_answerId_asc'
  | 'answerInQuestion_answerId_desc'
  | 'answerInQuestion_createdAt_asc'
  | 'answerInQuestion_createdAt_desc'
  | 'answerInQuestion_id_asc'
  | 'answerInQuestion_id_desc'
  | 'answerInQuestion_isTrue_asc'
  | 'answerInQuestion_isTrue_desc'
  | 'answerInQuestion_mediaId_asc'
  | 'answerInQuestion_mediaId_desc'
  | 'answerInQuestion_questionId_asc'
  | 'answerInQuestion_questionId_desc'
  | 'answerInQuestion_thumbnailId_asc'
  | 'answerInQuestion_thumbnailId_desc'
  | 'createdAt_asc'
  | 'createdAt_desc'
  | 'id_asc'
  | 'id_desc'
  | 'index_asc'
  | 'index_desc'
  | 'text_asc'
  | 'text_desc'
  | 'translate_createdAt_asc'
  | 'translate_createdAt_desc'
  | 'translate_id_asc'
  | 'translate_id_desc'
  | 'translate_suportLanguage_asc'
  | 'translate_suportLanguage_desc'
  | 'translate_text_asc'
  | 'translate_text_desc'
  | 'translate_wQAId_asc'
  | 'translate_wQAId_desc'

export type AuthSession = {
  __typename?: 'AuthSession'
  createdAt: Scalars['DateTime']['output']
  devicePlatform: Scalars['String']['output']
  devicePnSecret: Scalars['String']['output']
  deviceUa: Scalars['String']['output']
  id: Scalars['ID']['output']
  ipAddress: Scalars['String']['output']
  secret: Scalars['String']['output']
  type: Scalars['String']['output']
  user?: Maybe<User>
  userId: Scalars['ID']['output']
}

export type AuthSessionFilter = {
  AND?: InputMaybe<Array<AuthSessionFilter>>
  NOT?: InputMaybe<AuthSessionFilter>
  OR?: InputMaybe<Array<AuthSessionFilter>>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_ne?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>
  devicePlatform?: InputMaybe<Scalars['String']['input']>
  devicePlatform_endsWith?: InputMaybe<Scalars['String']['input']>
  devicePlatform_gt?: InputMaybe<Scalars['String']['input']>
  devicePlatform_gte?: InputMaybe<Scalars['String']['input']>
  devicePlatform_iLike?: InputMaybe<Scalars['String']['input']>
  devicePlatform_in?: InputMaybe<Array<Scalars['String']['input']>>
  devicePlatform_like?: InputMaybe<Scalars['String']['input']>
  devicePlatform_lt?: InputMaybe<Scalars['String']['input']>
  devicePlatform_lte?: InputMaybe<Scalars['String']['input']>
  devicePlatform_ne?: InputMaybe<Scalars['String']['input']>
  devicePlatform_notILike?: InputMaybe<Scalars['String']['input']>
  devicePlatform_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  devicePlatform_notLike?: InputMaybe<Scalars['String']['input']>
  devicePlatform_startsWith?: InputMaybe<Scalars['String']['input']>
  devicePlatform_substring?: InputMaybe<Scalars['String']['input']>
  devicePnSecret?: InputMaybe<Scalars['String']['input']>
  devicePnSecret_endsWith?: InputMaybe<Scalars['String']['input']>
  devicePnSecret_gt?: InputMaybe<Scalars['String']['input']>
  devicePnSecret_gte?: InputMaybe<Scalars['String']['input']>
  devicePnSecret_iLike?: InputMaybe<Scalars['String']['input']>
  devicePnSecret_in?: InputMaybe<Array<Scalars['String']['input']>>
  devicePnSecret_like?: InputMaybe<Scalars['String']['input']>
  devicePnSecret_lt?: InputMaybe<Scalars['String']['input']>
  devicePnSecret_lte?: InputMaybe<Scalars['String']['input']>
  devicePnSecret_ne?: InputMaybe<Scalars['String']['input']>
  devicePnSecret_notILike?: InputMaybe<Scalars['String']['input']>
  devicePnSecret_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  devicePnSecret_notLike?: InputMaybe<Scalars['String']['input']>
  devicePnSecret_startsWith?: InputMaybe<Scalars['String']['input']>
  devicePnSecret_substring?: InputMaybe<Scalars['String']['input']>
  deviceUa?: InputMaybe<Scalars['String']['input']>
  deviceUa_endsWith?: InputMaybe<Scalars['String']['input']>
  deviceUa_gt?: InputMaybe<Scalars['String']['input']>
  deviceUa_gte?: InputMaybe<Scalars['String']['input']>
  deviceUa_iLike?: InputMaybe<Scalars['String']['input']>
  deviceUa_in?: InputMaybe<Array<Scalars['String']['input']>>
  deviceUa_like?: InputMaybe<Scalars['String']['input']>
  deviceUa_lt?: InputMaybe<Scalars['String']['input']>
  deviceUa_lte?: InputMaybe<Scalars['String']['input']>
  deviceUa_ne?: InputMaybe<Scalars['String']['input']>
  deviceUa_notILike?: InputMaybe<Scalars['String']['input']>
  deviceUa_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  deviceUa_notLike?: InputMaybe<Scalars['String']['input']>
  deviceUa_startsWith?: InputMaybe<Scalars['String']['input']>
  deviceUa_substring?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>
  id_ne?: InputMaybe<Scalars['ID']['input']>
  id_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  ipAddress?: InputMaybe<Scalars['String']['input']>
  ipAddress_endsWith?: InputMaybe<Scalars['String']['input']>
  ipAddress_gt?: InputMaybe<Scalars['String']['input']>
  ipAddress_gte?: InputMaybe<Scalars['String']['input']>
  ipAddress_iLike?: InputMaybe<Scalars['String']['input']>
  ipAddress_in?: InputMaybe<Array<Scalars['String']['input']>>
  ipAddress_like?: InputMaybe<Scalars['String']['input']>
  ipAddress_lt?: InputMaybe<Scalars['String']['input']>
  ipAddress_lte?: InputMaybe<Scalars['String']['input']>
  ipAddress_ne?: InputMaybe<Scalars['String']['input']>
  ipAddress_notILike?: InputMaybe<Scalars['String']['input']>
  ipAddress_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  ipAddress_notLike?: InputMaybe<Scalars['String']['input']>
  ipAddress_startsWith?: InputMaybe<Scalars['String']['input']>
  ipAddress_substring?: InputMaybe<Scalars['String']['input']>
  secret?: InputMaybe<Scalars['String']['input']>
  secret_endsWith?: InputMaybe<Scalars['String']['input']>
  secret_gt?: InputMaybe<Scalars['String']['input']>
  secret_gte?: InputMaybe<Scalars['String']['input']>
  secret_iLike?: InputMaybe<Scalars['String']['input']>
  secret_in?: InputMaybe<Array<Scalars['String']['input']>>
  secret_like?: InputMaybe<Scalars['String']['input']>
  secret_lt?: InputMaybe<Scalars['String']['input']>
  secret_lte?: InputMaybe<Scalars['String']['input']>
  secret_ne?: InputMaybe<Scalars['String']['input']>
  secret_notILike?: InputMaybe<Scalars['String']['input']>
  secret_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  secret_notLike?: InputMaybe<Scalars['String']['input']>
  secret_startsWith?: InputMaybe<Scalars['String']['input']>
  secret_substring?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<Scalars['String']['input']>
  type_endsWith?: InputMaybe<Scalars['String']['input']>
  type_gt?: InputMaybe<Scalars['String']['input']>
  type_gte?: InputMaybe<Scalars['String']['input']>
  type_iLike?: InputMaybe<Scalars['String']['input']>
  type_in?: InputMaybe<Array<Scalars['String']['input']>>
  type_like?: InputMaybe<Scalars['String']['input']>
  type_lt?: InputMaybe<Scalars['String']['input']>
  type_lte?: InputMaybe<Scalars['String']['input']>
  type_ne?: InputMaybe<Scalars['String']['input']>
  type_notILike?: InputMaybe<Scalars['String']['input']>
  type_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  type_notLike?: InputMaybe<Scalars['String']['input']>
  type_startsWith?: InputMaybe<Scalars['String']['input']>
  type_substring?: InputMaybe<Scalars['String']['input']>
  userId?: InputMaybe<Scalars['ID']['input']>
  userId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  userId_ne?: InputMaybe<Scalars['ID']['input']>
  userId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  user_none?: InputMaybe<UserFilter>
  user_some?: InputMaybe<UserFilter>
}

export type AutomaticPaymentMethods = {
  allow_redirects?: InputMaybe<AllowRedirects>
  enabled?: InputMaybe<Scalars['Boolean']['input']>
}

export type Benefit = {
  gifId?: InputMaybe<Scalars['ID']['input']>
  subTitleGif?: InputMaybe<Scalars['String']['input']>
  titleGif?: InputMaybe<Scalars['String']['input']>
  titlePlansGif?: InputMaybe<Scalars['String']['input']>
}

export type BillingDetails = {
  address?: InputMaybe<Address>
  email?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  phone?: InputMaybe<Scalars['String']['input']>
}

export type CancelSub = {
  id_subscription: Scalars['ID']['input']
}

export type Card = {
  exp_month?: InputMaybe<Address>
  exp_year?: InputMaybe<Scalars['String']['input']>
  networks?: InputMaybe<TypeNetWorks>
  phone?: InputMaybe<Scalars['String']['input']>
}

export type CardPayment = {
  cvc?: InputMaybe<Scalars['String']['input']>
  exp_month?: InputMaybe<Scalars['Int']['input']>
  exp_year?: InputMaybe<Scalars['Int']['input']>
  number?: InputMaybe<Scalars['String']['input']>
}

export type Category = {
  __typename?: 'Category'
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  tagName: Scalars['String']['output']
  topicInCategory?: Maybe<TopicInCategory>
}

export type CategoryFilter = {
  AND?: InputMaybe<Array<CategoryFilter>>
  NOT?: InputMaybe<CategoryFilter>
  OR?: InputMaybe<Array<CategoryFilter>>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_ne?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>
  id?: InputMaybe<Scalars['ID']['input']>
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>
  id_ne?: InputMaybe<Scalars['ID']['input']>
  id_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  tagName?: InputMaybe<Scalars['String']['input']>
  tagName_endsWith?: InputMaybe<Scalars['String']['input']>
  tagName_gt?: InputMaybe<Scalars['String']['input']>
  tagName_gte?: InputMaybe<Scalars['String']['input']>
  tagName_iLike?: InputMaybe<Scalars['String']['input']>
  tagName_in?: InputMaybe<Array<Scalars['String']['input']>>
  tagName_like?: InputMaybe<Scalars['String']['input']>
  tagName_lt?: InputMaybe<Scalars['String']['input']>
  tagName_lte?: InputMaybe<Scalars['String']['input']>
  tagName_ne?: InputMaybe<Scalars['String']['input']>
  tagName_notILike?: InputMaybe<Scalars['String']['input']>
  tagName_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  tagName_notLike?: InputMaybe<Scalars['String']['input']>
  tagName_startsWith?: InputMaybe<Scalars['String']['input']>
  tagName_substring?: InputMaybe<Scalars['String']['input']>
  topicInCategory_none?: InputMaybe<TopicInCategoryFilter>
  topicInCategory_some?: InputMaybe<TopicInCategoryFilter>
}

export type CategoryOrderBy =
  | 'createdAt_asc'
  | 'createdAt_desc'
  | 'id_asc'
  | 'id_desc'
  | 'tagName_asc'
  | 'tagName_desc'
  | 'topicInCategory_categoryId_asc'
  | 'topicInCategory_categoryId_desc'
  | 'topicInCategory_createdAt_asc'
  | 'topicInCategory_createdAt_desc'
  | 'topicInCategory_id_asc'
  | 'topicInCategory_id_desc'
  | 'topicInCategory_topicId_asc'
  | 'topicInCategory_topicId_desc'

export type CheckTicket = {
  code?: InputMaybe<Scalars['NeString']['input']>
  id: Scalars['ID']['input']
  secret?: InputMaybe<Scalars['NeString']['input']>
}

export type CheckTicketType = 'ForgotPassword'

export type Code =
  | 'AUD'
  | 'CHF'
  | 'CNY'
  | 'DKK'
  | 'GBP'
  | 'HKD'
  | 'KRW'
  | 'NZD'
  | 'PLN'
  | 'TRY'
  | 'USD'

export type Confirm = {
  code: Scalars['NeString']['input']
  id: Scalars['ID']['input']
  secret?: InputMaybe<Scalars['NeString']['input']>
}

export type CreateAiTutor = {
  address?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  gender?: InputMaybe<Scalars['String']['input']>
  languageAI?: InputMaybe<Scalars['String']['input']>
  mediaId?: InputMaybe<Scalars['ID']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  thumbnailId?: InputMaybe<Scalars['ID']['input']>
}

export type CreateAnswer = {
  index?: InputMaybe<Scalars['Int']['input']>
  text?: InputMaybe<Scalars['String']['input']>
}

export type CreateAnswerInQuestion = {
  answerId?: InputMaybe<Scalars['ID']['input']>
  isTrue?: InputMaybe<Scalars['Boolean']['input']>
  mediaId?: InputMaybe<Scalars['ID']['input']>
  questionId: Scalars['ID']['input']
  thumbnailId?: InputMaybe<Scalars['ID']['input']>
}

export type CreateCategory = {
  tagName: Scalars['String']['input']
}

export type CreateMessage = {
  AITutor?: InputMaybe<AiTutorMessage>
  botRole?: InputMaybe<Scalars['String']['input']>
  content?: InputMaybe<Scalars['String']['input']>
  scenario?: InputMaybe<Scalars['String']['input']>
  tasks?: InputMaybe<Array<InputMaybe<TasksItem>>>
  topicInUserId?: InputMaybe<Scalars['ID']['input']>
  topic_chat?: InputMaybe<Scalars['String']['input']>
  userRole?: InputMaybe<Scalars['String']['input']>
}

export type CreatePaymentMethod = {
  card?: InputMaybe<CardPayment>
  metadata?: InputMaybe<Scalars['Json']['input']>
  type?: InputMaybe<TypePayment>
}

export type CreateQuestion = {
  index?: InputMaybe<Scalars['Int']['input']>
  mediaId?: InputMaybe<Scalars['ID']['input']>
  text?: InputMaybe<Scalars['String']['input']>
  thumbnailId?: InputMaybe<Scalars['ID']['input']>
  topicId?: InputMaybe<Scalars['ID']['input']>
  type?: InputMaybe<Scalars['String']['input']>
  wordId?: InputMaybe<Scalars['ID']['input']>
}

export type CreateResourceInWord = {
  mediaId?: InputMaybe<Scalars['ID']['input']>
  type?: InputMaybe<Scalars['String']['input']>
  wordId?: InputMaybe<Scalars['ID']['input']>
}

export type CreateSplash = {
  subTitle?: InputMaybe<Scalars['String']['input']>
  thumbnailId?: InputMaybe<Scalars['ID']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type CreateStreak = {
  infor?: InputMaybe<Scalars['Json']['input']>
  mediaId?: InputMaybe<Scalars['ID']['input']>
  numberStreak?: InputMaybe<Scalars['Int']['input']>
  thumbnailId?: InputMaybe<Scalars['ID']['input']>
}

export type CreateStreakDaily = {
  name?: InputMaybe<Scalars['String']['input']>
}

export type CreateStreakInUser = {
  countStreak?: InputMaybe<Scalars['Int']['input']>
  dateStreak: DateStreak
  highestStreak?: InputMaybe<Scalars['Int']['input']>
  lastDate?: InputMaybe<Scalars['String']['input']>
  streakId: Scalars['ID']['input']
  userId: Scalars['ID']['input']
}

export type CreateSubscription = {
  customer: Scalars['ID']['input']
  idPrice: Scalars['ID']['input']
}

export type CreateTopic = {
  aiTutorRole?: InputMaybe<Scalars['String']['input']>
  characterId?: InputMaybe<Scalars['ID']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  languageTopic: Language
  level: LevelType
  mediaId?: InputMaybe<Scalars['ID']['input']>
  name: Scalars['NeString']['input']
  ordinalNumber?: InputMaybe<Scalars['Int']['input']>
  scenario?: InputMaybe<Scalars['String']['input']>
  task?: InputMaybe<Scalars['Json']['input']>
  userRole?: InputMaybe<Scalars['String']['input']>
}

export type CreateTopicInCategory = {
  categoryId?: InputMaybe<Scalars['ID']['input']>
  topicId?: InputMaybe<Scalars['ID']['input']>
}

export type CreateTopicInUser = {
  learning?: InputMaybe<Scalars['Boolean']['input']>
  ordinalNumber?: InputMaybe<Scalars['Int']['input']>
  percent?: InputMaybe<Scalars['Int']['input']>
  task?: InputMaybe<Scalars['Json']['input']>
  topicId?: InputMaybe<Scalars['ID']['input']>
  unlockTopic?: InputMaybe<Scalars['Boolean']['input']>
  userLearnId?: InputMaybe<Scalars['ID']['input']>
}

export type CreateUserAnswer = {
  answerId?: InputMaybe<Scalars['ID']['input']>
  questionId?: InputMaybe<Scalars['ID']['input']>
  times?: InputMaybe<Scalars['Int']['input']>
  topicId?: InputMaybe<Scalars['ID']['input']>
  userId?: InputMaybe<Scalars['ID']['input']>
  wordInUserId?: InputMaybe<Scalars['ID']['input']>
  wrongTimes?: InputMaybe<Scalars['Int']['input']>
}

export type CreateWord = {
  description?: InputMaybe<Scalars['String']['input']>
  isThumbnail?: InputMaybe<Scalars['Boolean']['input']>
  isTranslate?: InputMaybe<Scalars['Boolean']['input']>
  isVocabulary?: InputMaybe<Scalars['Boolean']['input']>
  mediaId?: InputMaybe<Scalars['ID']['input']>
  thumbnailId?: InputMaybe<Scalars['ID']['input']>
  topicId: Scalars['ID']['input']
  userId: Scalars['ID']['input']
  vocabAndPhrase?: InputMaybe<Scalars['String']['input']>
}

export type CreateWordInUser = {
  isCompleted?: InputMaybe<Scalars['Boolean']['input']>
  lastDay?: InputMaybe<Scalars['DateTime']['input']>
  milestone?: InputMaybe<Scalars['Int']['input']>
  nextDay?: InputMaybe<Scalars['DateTime']['input']>
  percentListen?: InputMaybe<Scalars['Int']['input']>
  times: Scalars['Int']['input']
  topicId?: InputMaybe<Scalars['ID']['input']>
  used?: InputMaybe<Scalars['Boolean']['input']>
  userId?: InputMaybe<Scalars['ID']['input']>
  wordId?: InputMaybe<Scalars['ID']['input']>
}

export type CreateWordbank = {
  topicId: Scalars['ID']['input']
  translationLanguage: Scalars['String']['input']
  userId: Scalars['ID']['input']
  word: Scalars['String']['input']
}

export type CreatedDay = {
  gt?: InputMaybe<Scalars['Int']['input']>
  gte?: InputMaybe<Scalars['Int']['input']>
  lt?: InputMaybe<Scalars['Int']['input']>
  lte?: InputMaybe<Scalars['Int']['input']>
}

export type CurrencyCode =
  | 'AUD'
  | 'CHF'
  | 'CNY'
  | 'DKK'
  | 'GBP'
  | 'HKD'
  | 'KRW'
  | 'NZD'
  | 'PLN'
  | 'TRY'
  | 'USD'

export type CurrencyOptionInput = {
  currency: Scalars['String']['input']
  symbol: Scalars['String']['input']
}

export type CurrencyOptions = {
  custom_unit_amount?: InputMaybe<CustomUnitAmount>
  tax_behavior?: InputMaybe<TaxBehavior>
  unit_amount?: InputMaybe<Scalars['Int']['input']>
  unit_amount_decimal?: InputMaybe<Scalars['String']['input']>
}

export type CustomUnitAmount = {
  enabled?: InputMaybe<Scalars['Boolean']['input']>
  maximum?: InputMaybe<Scalars['Int']['input']>
  minimum?: InputMaybe<Scalars['Int']['input']>
  preset?: InputMaybe<Scalars['Int']['input']>
}

export type Data = {
  description?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  isPassed?: InputMaybe<Scalars['Boolean']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  subTask?: InputMaybe<Array<InputMaybe<SubTask>>>
}

export type DateStreak = {
  attendance?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type DefaultPriceData = {
  currency?: InputMaybe<CurrencyCode>
  recurring?: InputMaybe<Recurring>
  tax_behavior?: InputMaybe<TaxBehavior>
  unit_amount?: InputMaybe<Scalars['Int']['input']>
  unit_amount_decimal?: InputMaybe<Scalars['String']['input']>
}

export type Devices = 'android' | 'ios' | 'web'

export type Features = {
  features?: InputMaybe<Array<InputMaybe<MFeatures>>>
}

export type FeaturesType = {
  __typename?: 'FeaturesType'
  features?: Maybe<Array<Maybe<MFeaturesType>>>
}

export type GameAnswer = {
  __typename?: 'GameAnswer'
  isCorrect: Scalars['Boolean']['output']
  text: Scalars['String']['output']
}

export type GameResult = {
  __typename?: 'GameResult'
  answers: Array<GameAnswer>
  id: Scalars['String']['output']
  index: Scalars['Int']['output']
  question: Scalars['String']['output']
  text: Scalars['String']['output']
  type: TypeGame
}

export type InputApple = {
  accessToken?: InputMaybe<Scalars['String']['input']>
  devicePlatform?: InputMaybe<Scalars['String']['input']>
  devicePnSecret?: InputMaybe<Scalars['String']['input']>
  email: Scalars['Email']['input']
  name?: InputMaybe<Scalars['String']['input']>
}

export type InputConfirmPaymentIntent = {
  customer_id?: InputMaybe<Scalars['ID']['input']>
  payment_intent_id?: InputMaybe<Scalars['ID']['input']>
  payment_method_id?: InputMaybe<Scalars['ID']['input']>
  return_url?: InputMaybe<Scalars['String']['input']>
}

export type InputCreatePaymentIntent = {
  amount: Scalars['Int']['input']
  automatic_payment_methods?: InputMaybe<AutomaticPaymentMethods>
  currency: Code
  description?: InputMaybe<Scalars['String']['input']>
  idPrice?: InputMaybe<Scalars['String']['input']>
  metadata?: InputMaybe<Scalars['Json']['input']>
  payment_method?: InputMaybe<Scalars['String']['input']>
  payment_method_types?: InputMaybe<Array<InputMaybe<TypePayment>>>
}

export type InputCreatePrice = {
  currency: Code
  product: Scalars['ID']['input']
  recurring: Recurring
  unit_amount: Scalars['Float']['input']
}

export type InputCreateProduct = {
  default_price?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  discountPercent?: InputMaybe<Scalars['Int']['input']>
  marketingFeatures?: InputMaybe<Features>
  marketing_features?: InputMaybe<Array<InputMaybe<MarketingFeature>>>
  metadata?: InputMaybe<Scalars['Json']['input']>
  name: Scalars['NeString']['input']
  tax_code?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<Type>
  unit_label?: InputMaybe<Scalars['String']['input']>
  url?: InputMaybe<Scalars['String']['input']>
}

export type InputFacebook = {
  accessToken?: InputMaybe<Scalars['String']['input']>
  devicePlatform?: InputMaybe<Scalars['String']['input']>
  devicePnSecret?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
}

export type InputGoogle = {
  accessToken?: InputMaybe<Scalars['String']['input']>
  devicePlatform?: InputMaybe<Scalars['String']['input']>
  devicePnSecret?: InputMaybe<Scalars['String']['input']>
  email: Scalars['Email']['input']
  emailVerified?: InputMaybe<Scalars['Boolean']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  photoURL?: InputMaybe<Scalars['String']['input']>
}

export type InputListPaymentIntent = {
  customer?: InputMaybe<Scalars['String']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
}

export type InputSearchPaymentIntent = {
  amount?: InputMaybe<Operater>
  created?: InputMaybe<Operater>
  currency?: InputMaybe<Scalars['String']['input']>
  customer?: InputMaybe<Scalars['String']['input']>
  metadata?: InputMaybe<Scalars['Json']['input']>
  status?: InputMaybe<Scalars['String']['input']>
}

export type InputTranslate = {
  suportLanguage?: InputMaybe<Languages>
  text?: InputMaybe<Scalars['String']['input']>
  wQAId?: InputMaybe<Scalars['ID']['input']>
}

export type InputUpdatePaymentIntent = {
  amount?: InputMaybe<Scalars['Int']['input']>
  application_fee_amount?: InputMaybe<Scalars['Int']['input']>
  automatic_payment_methods?: InputMaybe<AutomaticPaymentMethods>
  confirm?: InputMaybe<Scalars['Boolean']['input']>
  currency?: InputMaybe<Code>
  customer?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  metadata?: InputMaybe<Scalars['Json']['input']>
  payment_method?: InputMaybe<Scalars['ID']['input']>
  receipt_email?: InputMaybe<Scalars['String']['input']>
}

export type InputUpdateTopicInUser = {
  learning?: InputMaybe<Scalars['Boolean']['input']>
  percent?: InputMaybe<Scalars['Int']['input']>
  task?: InputMaybe<Task>
  topicId?: InputMaybe<Scalars['ID']['input']>
  unlockTopic?: InputMaybe<Scalars['Boolean']['input']>
  userLearnId?: InputMaybe<Scalars['ID']['input']>
}

export type InputWord = {
  id: Scalars['String']['input']
  isPhrase: Scalars['Boolean']['input']
  language: Language
  text: Scalars['String']['input']
  type: TypeGame
}

export type IntervalTime = 'day' | 'month' | 'week' | 'year'

export type Language = 'English' | 'Spanish'

export type LanguageCode =
  | 'af'
  | 'ar'
  | 'az'
  | 'be'
  | 'bg'
  | 'bn'
  | 'bs'
  | 'ca'
  | 'cs'
  | 'cy'
  | 'da'
  | 'de'
  | 'el'
  | 'en'
  | 'eo'
  | 'es'
  | 'et'
  | 'eu'
  | 'fa'
  | 'fi'
  | 'fil'
  | 'fr'
  | 'ga'
  | 'gl'
  | 'gu'
  | 'he'
  | 'hi'
  | 'hr'
  | 'hu'
  | 'hy'
  | 'id'
  | 'is'
  | 'it'
  | 'ja'
  | 'ka'
  | 'kk'
  | 'km'
  | 'kn'
  | 'ko'
  | 'ky'
  | 'lb'
  | 'lo'
  | 'lt'
  | 'lv'
  | 'mk'
  | 'ml'
  | 'mn'
  | 'mr'
  | 'ms'
  | 'my'
  | 'ne'
  | 'nl'
  | 'no'
  | 'or'
  | 'pa'
  | 'pl'
  | 'pt'
  | 'ro'
  | 'ru'
  | 'si'
  | 'sk'
  | 'sl'
  | 'sq'
  | 'sr'
  | 'sv'
  | 'sw'
  | 'ta'
  | 'te'
  | 'th'
  | 'tr'
  | 'uk'
  | 'ur'
  | 'uz'
  | 'vi'
  | 'zh'
  | 'zu'

export type Languages =
  | 'Afrikaans'
  | 'Albanian'
  | 'Arabic'
  | 'Armenian'
  | 'Azerbaijani'
  | 'Basque'
  | 'Belarusian'
  | 'Bengali'
  | 'Bosnian'
  | 'Bulgarian'
  | 'Burmese'
  | 'Catalan'
  | 'Chinese'
  | 'Croatian'
  | 'Czech'
  | 'Danish'
  | 'Dutch'
  | 'English'
  | 'Esperanto'
  | 'Estonian'
  | 'Filipino'
  | 'Finnish'
  | 'French'
  | 'Galician'
  | 'Georgian'
  | 'German'
  | 'Greek'
  | 'Gujarati'
  | 'Hebrew'
  | 'Hindi'
  | 'Hungarian'
  | 'Icelandic'
  | 'Indonesian'
  | 'Irish'
  | 'Italian'
  | 'Japanese'
  | 'Kannada'
  | 'Kazakh'
  | 'Khmer'
  | 'Korean'
  | 'Kyrgyz'
  | 'Lao'
  | 'Latvian'
  | 'Lithuanian'
  | 'Luxembourgish'
  | 'Macedonian'
  | 'Malay'
  | 'Malayalam'
  | 'Marathi'
  | 'Mongolian'
  | 'Nepali'
  | 'Norwegian'
  | 'Odia'
  | 'Persian'
  | 'Polish'
  | 'Portuguese'
  | 'Punjabi'
  | 'Romanian'
  | 'Russian'
  | 'Serbian'
  | 'Sinhala'
  | 'Slovak'
  | 'Slovenian'
  | 'Spanish'
  | 'Swahili'
  | 'Swedish'
  | 'Tamil'
  | 'Telugu'
  | 'Thai'
  | 'Turkish'
  | 'Ukrainian'
  | 'Urdu'
  | 'Uzbek'
  | 'Vietnamese'
  | 'Welsh'
  | 'Zulu'

export type Level = 'A1' | 'A2' | 'B1' | 'B2'

export type LevelTest = {
  current?: InputMaybe<Scalars['String']['input']>
  isShow?: InputMaybe<Scalars['Boolean']['input']>
}

export type LevelType = 'A1' | 'A2' | 'B1' | 'B2'

export type ListCustomerPaymentMethod = {
  customer?: InputMaybe<Scalars['ID']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
  type?: InputMaybe<TypePayment>
}

export type ListPaymentMethod = {
  customer?: InputMaybe<Scalars['ID']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
  type?: InputMaybe<TypePayment>
}

export type ListPaymentMethodCustomer = {
  allow_redisplay?: InputMaybe<Scalars['String']['input']>
  ending_before?: InputMaybe<Scalars['String']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
  starting_after?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<Scalars['String']['input']>
}

export type ListPrice = {
  active?: InputMaybe<Scalars['Boolean']['input']>
  created?: InputMaybe<CreatedDay>
  currency?: InputMaybe<Scalars['String']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
  product?: InputMaybe<Scalars['String']['input']>
  recurring?: InputMaybe<Recurring>
  type?: InputMaybe<PurchaseType>
}

export type MFeatures = {
  description?: InputMaybe<Scalars['String']['input']>
  idImage?: InputMaybe<Scalars['String']['input']>
  image?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type MFeaturesType = {
  __typename?: 'MFeaturesType'
  description?: Maybe<Scalars['String']['output']>
  idImage?: Maybe<Scalars['String']['output']>
  image?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
}

export type MarketingFeature = {
  name?: InputMaybe<Scalars['String']['input']>
}

export type Message = {
  __typename?: 'Message'
  aiTutor?: Maybe<AiTutor>
  aiTutorId: Scalars['ID']['output']
  content: Scalars['String']['output']
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  sentByUser: Scalars['Boolean']['output']
  topicInUser?: Maybe<TopicInUser>
  topicInUserId: Scalars['ID']['output']
  userId: Scalars['ID']['output']
}

export type MessageFilter = {
  AND?: InputMaybe<Array<MessageFilter>>
  NOT?: InputMaybe<MessageFilter>
  OR?: InputMaybe<Array<MessageFilter>>
  aiTutorId?: InputMaybe<Scalars['ID']['input']>
  aiTutorId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  aiTutorId_ne?: InputMaybe<Scalars['ID']['input']>
  aiTutorId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  aiTutor_none?: InputMaybe<AiTutorFilter>
  aiTutor_some?: InputMaybe<AiTutorFilter>
  content?: InputMaybe<Scalars['String']['input']>
  content_endsWith?: InputMaybe<Scalars['String']['input']>
  content_gt?: InputMaybe<Scalars['String']['input']>
  content_gte?: InputMaybe<Scalars['String']['input']>
  content_iLike?: InputMaybe<Scalars['String']['input']>
  content_in?: InputMaybe<Array<Scalars['String']['input']>>
  content_like?: InputMaybe<Scalars['String']['input']>
  content_lt?: InputMaybe<Scalars['String']['input']>
  content_lte?: InputMaybe<Scalars['String']['input']>
  content_ne?: InputMaybe<Scalars['String']['input']>
  content_notILike?: InputMaybe<Scalars['String']['input']>
  content_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  content_notLike?: InputMaybe<Scalars['String']['input']>
  content_startsWith?: InputMaybe<Scalars['String']['input']>
  content_substring?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_ne?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>
  id?: InputMaybe<Scalars['ID']['input']>
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>
  id_ne?: InputMaybe<Scalars['ID']['input']>
  id_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  sentByUser?: InputMaybe<Scalars['Boolean']['input']>
  sentByUser_ne?: InputMaybe<Scalars['Boolean']['input']>
  topicInUserId?: InputMaybe<Scalars['ID']['input']>
  topicInUserId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  topicInUserId_ne?: InputMaybe<Scalars['ID']['input']>
  topicInUserId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  topicInUser_none?: InputMaybe<TopicInUserFilter>
  topicInUser_some?: InputMaybe<TopicInUserFilter>
  userId?: InputMaybe<Scalars['ID']['input']>
  userId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  userId_ne?: InputMaybe<Scalars['ID']['input']>
  userId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type MessageOrderBy =
  | 'aiTutorId_asc'
  | 'aiTutorId_desc'
  | 'aiTutor_address_asc'
  | 'aiTutor_address_desc'
  | 'aiTutor_createdAt_asc'
  | 'aiTutor_createdAt_desc'
  | 'aiTutor_description_asc'
  | 'aiTutor_description_desc'
  | 'aiTutor_gender_asc'
  | 'aiTutor_gender_desc'
  | 'aiTutor_id_asc'
  | 'aiTutor_id_desc'
  | 'aiTutor_languageAI_asc'
  | 'aiTutor_languageAI_desc'
  | 'aiTutor_mediaId_asc'
  | 'aiTutor_mediaId_desc'
  | 'aiTutor_name_asc'
  | 'aiTutor_name_desc'
  | 'aiTutor_status_asc'
  | 'aiTutor_status_desc'
  | 'aiTutor_thumbnailId_asc'
  | 'aiTutor_thumbnailId_desc'
  | 'aiTutor_userId_asc'
  | 'aiTutor_userId_desc'
  | 'content_asc'
  | 'content_desc'
  | 'createdAt_asc'
  | 'createdAt_desc'
  | 'id_asc'
  | 'id_desc'
  | 'sentByUser_asc'
  | 'sentByUser_desc'
  | 'topicInUserId_asc'
  | 'topicInUserId_desc'
  | 'topicInUser_createdAt_asc'
  | 'topicInUser_createdAt_desc'
  | 'topicInUser_id_asc'
  | 'topicInUser_id_desc'
  | 'topicInUser_learning_asc'
  | 'topicInUser_learning_desc'
  | 'topicInUser_ordinalNumber_asc'
  | 'topicInUser_ordinalNumber_desc'
  | 'topicInUser_percent_asc'
  | 'topicInUser_percent_desc'
  | 'topicInUser_task_asc'
  | 'topicInUser_task_desc'
  | 'topicInUser_topicId_asc'
  | 'topicInUser_topicId_desc'
  | 'topicInUser_unlockTopic_asc'
  | 'topicInUser_unlockTopic_desc'
  | 'topicInUser_userLearnId_asc'
  | 'topicInUser_userLearnId_desc'
  | 'userId_asc'
  | 'userId_desc'

export type Mutation = {
  __typename?: 'Mutation'
  addBenefit: Scalars['Json']['output']
  cancelSubscription: Scalars['Json']['output']
  changePassword?: Maybe<Scalars['Null']['output']>
  confirmOTP: Scalars['String']['output']
  createAiTutor: AiTutor
  createAnswer: Answer
  createAnswerInQuestion: AnswerInQuestion
  createCategory: Category
  createCustomer: ResponeCustomer
  createMessage: Message
  createPaymentIntent: Respone
  createPaymentMethod: Scalars['Json']['output']
  createPrice: Price
  createProduct: Product
  createQuestion: Question
  createResourceInWord: ResourceInWord
  createSplash: Splash
  createStreak: Streak
  createStreakDaily: StreakDaily
  createStreakInUser: StreakInUser
  createSubscription: Scalars['Json']['output']
  createTopic: Topic
  createTopicInCategory: TopicInCategory
  createTopicInUser: TopicInUser
  createTranslate: Translate
  createUserAnswer: UserAnswer
  createWord: Word
  createWordInUser: WordInUser
  createWordbank: Wordbank
  deleteAiTutor?: Maybe<Scalars['Null']['output']>
  deleteAnswer?: Maybe<Scalars['Null']['output']>
  deleteAnswerInQuestion?: Maybe<Scalars['Null']['output']>
  deleteCategory?: Maybe<Scalars['Null']['output']>
  deleteMessage?: Maybe<Scalars['Null']['output']>
  deleteMessageTopicInUser: Scalars['Int']['output']
  deleteProduct?: Maybe<Scalars['Null']['output']>
  deleteQuestion?: Maybe<Scalars['Null']['output']>
  deleteResourceInWord?: Maybe<Scalars['Null']['output']>
  deleteSplash?: Maybe<Scalars['Null']['output']>
  deleteStreak?: Maybe<Scalars['Null']['output']>
  deleteStreakDaily?: Maybe<Scalars['Null']['output']>
  deleteStreakInUser?: Maybe<Scalars['Null']['output']>
  deleteTopic?: Maybe<Scalars['Null']['output']>
  deleteTopicInCategory?: Maybe<Scalars['Null']['output']>
  deleteTopicInUser?: Maybe<Scalars['Null']['output']>
  deleteTranslate?: Maybe<Scalars['Null']['output']>
  deleteUser?: Maybe<Scalars['Null']['output']>
  deleteUserByEmail?: Maybe<Scalars['Null']['output']>
  deleteUserById?: Maybe<Scalars['Null']['output']>
  deleteWord?: Maybe<Scalars['Null']['output']>
  deleteWordInUser?: Maybe<Scalars['Null']['output']>
  deleteWordbank?: Maybe<Scalars['Null']['output']>
  forgotPassword: Scalars['String']['output']
  login: AuthSession
  loginApple: AuthSession
  loginFacebook: AuthSession
  loginGoogle: AuthSession
  logout?: Maybe<Scalars['Null']['output']>
  movePosition: Array<Scalars['ID']['output']>
  movePositionTopicInUser: Array<Scalars['ID']['output']>
  register: Scalars['String']['output']
  resendTicket?: Maybe<Scalars['Null']['output']>
  resetPassword?: Maybe<Scalars['String']['output']>
  resolveForgotPassword: AuthSession
  resolveRegister: AuthSession
  setAdmin: User
  testCreateMessage: Message
  updateAiTutor: AiTutor
  updateAnswer: Answer
  updateAnswerInQuestion: AnswerInQuestion
  updateCategory: Category
  updateDeactivateUser: User
  updatePaymentIntent: Scalars['Json']['output']
  updatePaymentMethod: Scalars['Json']['output']
  updatePrice: Price
  updateProduct: Product
  updateQuestion: Question
  updateSplash: Splash
  updateStreak: Streak
  updateStreakDaily: StreakDaily
  updateStreakInUser: StreakInUser
  updateTopic: Topic
  updateTopicInCategory: TopicInCategory
  updateTopicInUser: TopicInUser
  updateTranslate: Translate
  updateUser: User
  updateUserAnswer: UserAnswer
  updateWord: Word
  updateWordInUser: WordInUser
  updateWordbank: Wordbank
}

export type MutationAddBenefitArgs = {
  data?: InputMaybe<Benefit>
}

export type MutationCancelSubscriptionArgs = {
  data?: InputMaybe<CancelSub>
}

export type MutationChangePasswordArgs = {
  oldPassword?: InputMaybe<Scalars['String']['input']>
  password: Scalars['Password']['input']
}

export type MutationConfirmOtpArgs = {
  data: Confirm
}

export type MutationCreateAiTutorArgs = {
  data: CreateAiTutor
}

export type MutationCreateAnswerArgs = {
  data: CreateAnswer
}

export type MutationCreateAnswerInQuestionArgs = {
  data: CreateAnswerInQuestion
}

export type MutationCreateCategoryArgs = {
  data: CreateCategory
}

export type MutationCreateMessageArgs = {
  data: CreateMessage
}

export type MutationCreatePaymentIntentArgs = {
  data?: InputMaybe<InputCreatePaymentIntent>
}

export type MutationCreatePaymentMethodArgs = {
  data?: InputMaybe<CreatePaymentMethod>
  payment_method_id?: InputMaybe<Scalars['ID']['input']>
}

export type MutationCreatePriceArgs = {
  data: InputCreatePrice
}

export type MutationCreateProductArgs = {
  data?: InputMaybe<InputCreateProduct>
}

export type MutationCreateQuestionArgs = {
  data: CreateQuestion
}

export type MutationCreateResourceInWordArgs = {
  data: CreateResourceInWord
}

export type MutationCreateSplashArgs = {
  data: CreateSplash
}

export type MutationCreateStreakArgs = {
  data: CreateStreak
}

export type MutationCreateStreakDailyArgs = {
  data: CreateStreakDaily
}

export type MutationCreateStreakInUserArgs = {
  data: CreateStreakInUser
}

export type MutationCreateSubscriptionArgs = {
  data?: InputMaybe<CreateSubscription>
}

export type MutationCreateTopicArgs = {
  data: CreateTopic
}

export type MutationCreateTopicInCategoryArgs = {
  data: CreateTopicInCategory
}

export type MutationCreateTopicInUserArgs = {
  data: CreateTopicInUser
}

export type MutationCreateTranslateArgs = {
  data: InputTranslate
}

export type MutationCreateUserAnswerArgs = {
  data: CreateUserAnswer
}

export type MutationCreateWordArgs = {
  data: CreateWord
}

export type MutationCreateWordInUserArgs = {
  data: CreateWordInUser
}

export type MutationCreateWordbankArgs = {
  data: CreateWordbank
}

export type MutationDeleteAiTutorArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteAnswerArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteAnswerInQuestionArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteCategoryArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteMessageTopicInUserArgs = {
  topicInUserId: Scalars['ID']['input']
}

export type MutationDeleteProductArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteQuestionArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteResourceInWordArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteSplashArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteStreakArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteStreakDailyArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteStreakInUserArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteTopicArgs = {
  ids: Array<Scalars['ID']['input']>
}

export type MutationDeleteTopicInCategoryArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteTopicInUserArgs = {
  ids: Scalars['ID']['input']
}

export type MutationDeleteTranslateArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteUserArgs = {
  email?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['String']['input']>
}

export type MutationDeleteUserByEmailArgs = {
  email: Scalars['String']['input']
}

export type MutationDeleteUserByIdArgs = {
  id: Scalars['String']['input']
}

export type MutationDeleteWordArgs = {
  ids: Array<Scalars['ID']['input']>
}

export type MutationDeleteWordInUserArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteWordbankArgs = {
  id: Scalars['ID']['input']
}

export type MutationForgotPasswordArgs = {
  email: Scalars['Email']['input']
}

export type MutationLoginArgs = {
  email: Scalars['Email']['input']
  password: Scalars['NeString']['input']
}

export type MutationLoginAppleArgs = {
  data?: InputMaybe<InputApple>
}

export type MutationLoginFacebookArgs = {
  data?: InputMaybe<InputFacebook>
}

export type MutationLoginGoogleArgs = {
  data?: InputMaybe<InputGoogle>
}

export type MutationMovePositionArgs = {
  ids: Array<Scalars['ID']['input']>
}

export type MutationMovePositionTopicInUserArgs = {
  ids: Array<Scalars['ID']['input']>
}

export type MutationRegisterArgs = {
  data: Register
}

export type MutationResendTicketArgs = {
  id: Scalars['ID']['input']
}

export type MutationResetPasswordArgs = {
  id: Scalars['ID']['input']
}

export type MutationResolveForgotPasswordArgs = {
  data: ResolveCheckTicket
  newPassword: Scalars['Password']['input']
}

export type MutationResolveRegisterArgs = {
  data: CheckTicket
}

export type MutationSetAdminArgs = {
  id: Scalars['ID']['input']
  isAdmin: Scalars['Boolean']['input']
}

export type MutationTestCreateMessageArgs = {
  data: TestCreateMessage
}

export type MutationUpdateAiTutorArgs = {
  data: UpdateAiTutor
  id: Scalars['ID']['input']
}

export type MutationUpdateAnswerArgs = {
  data: UpdateAnswer
  id: Scalars['ID']['input']
}

export type MutationUpdateAnswerInQuestionArgs = {
  data: UpdateAnswerInQuestion
  id: Scalars['ID']['input']
}

export type MutationUpdateCategoryArgs = {
  data: UpdateCategory
  id: Scalars['ID']['input']
}

export type MutationUpdateDeactivateUserArgs = {
  data: UpdateDeactivateUser
  id: Scalars['ID']['input']
}

export type MutationUpdatePaymentIntentArgs = {
  data?: InputMaybe<InputUpdatePaymentIntent>
  id: Scalars['NeString']['input']
}

export type MutationUpdatePaymentMethodArgs = {
  data?: InputMaybe<UpdatePaymentMethod>
  id?: InputMaybe<Scalars['ID']['input']>
}

export type MutationUpdatePriceArgs = {
  data: UpdatePrice
  id: Scalars['ID']['input']
}

export type MutationUpdateProductArgs = {
  data: UpdateProduct
  id: Scalars['ID']['input']
}

export type MutationUpdateQuestionArgs = {
  data: UpdateQuestion
  id: Scalars['ID']['input']
}

export type MutationUpdateSplashArgs = {
  data: UpdateSplash
  id: Scalars['ID']['input']
}

export type MutationUpdateStreakArgs = {
  data: UpdateStreak
  id: Scalars['ID']['input']
}

export type MutationUpdateStreakDailyArgs = {
  data: UpdateStreakDaily
  id: Scalars['ID']['input']
}

export type MutationUpdateStreakInUserArgs = {
  data?: InputMaybe<UpdateStreakInUser>
  id: Scalars['ID']['input']
}

export type MutationUpdateTopicArgs = {
  data: UpdateTopic
  id: Scalars['ID']['input']
}

export type MutationUpdateTopicInCategoryArgs = {
  data: UpdateTopicInCategory
  id: Scalars['ID']['input']
}

export type MutationUpdateTopicInUserArgs = {
  data?: InputMaybe<InputUpdateTopicInUser>
  id: Scalars['ID']['input']
}

export type MutationUpdateTranslateArgs = {
  data: InputTranslate
  id: Scalars['ID']['input']
  update?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationUpdateUserArgs = {
  data?: InputMaybe<UpdateMyProfile>
}

export type MutationUpdateUserAnswerArgs = {
  data: UpdateUserAnswer
  id: Scalars['ID']['input']
}

export type MutationUpdateWordArgs = {
  data: UpdateWord
  id: Scalars['ID']['input']
}

export type MutationUpdateWordInUserArgs = {
  data: UpdateWordInUser
  id: Scalars['ID']['input']
}

export type MutationUpdateWordbankArgs = {
  data: UpdateWordbank
  id: Scalars['ID']['input']
}

export type Networks = {
  city?: InputMaybe<Scalars['String']['input']>
  country?: InputMaybe<Scalars['String']['input']>
  line1?: InputMaybe<Scalars['String']['input']>
  line2?: InputMaybe<Scalars['String']['input']>
  postal_code?: InputMaybe<Scalars['String']['input']>
  state?: InputMaybe<Scalars['String']['input']>
}

export type Operater = {
  gte?: InputMaybe<Scalars['Int']['input']>
  lte?: InputMaybe<Scalars['Int']['input']>
}

export type Pagination = {
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
}

export type Price = {
  __typename?: 'Price'
  active: Scalars['Boolean']['output']
  billingScheme: Scalars['String']['output']
  createdAt: Scalars['DateTime']['output']
  currency: Scalars['String']['output']
  customUnitAmount: Scalars['String']['output']
  id: Scalars['ID']['output']
  metadata?: Maybe<Scalars['Json']['output']>
  object: Scalars['String']['output']
  plan?: Maybe<Product>
  planId: Scalars['ID']['output']
  price: Scalars['String']['output']
  product: Scalars['String']['output']
  recurring: Scalars['Json']['output']
  taxBehavior: Scalars['String']['output']
  type: Scalars['String']['output']
  unitAmount: Scalars['Float']['output']
  unitAmountDecimal: Scalars['String']['output']
}

export type PriceFilter = {
  AND?: InputMaybe<Array<PriceFilter>>
  NOT?: InputMaybe<PriceFilter>
  OR?: InputMaybe<Array<PriceFilter>>
  active?: InputMaybe<Scalars['Boolean']['input']>
  active_ne?: InputMaybe<Scalars['Boolean']['input']>
  billingScheme?: InputMaybe<Scalars['String']['input']>
  billingScheme_endsWith?: InputMaybe<Scalars['String']['input']>
  billingScheme_gt?: InputMaybe<Scalars['String']['input']>
  billingScheme_gte?: InputMaybe<Scalars['String']['input']>
  billingScheme_iLike?: InputMaybe<Scalars['String']['input']>
  billingScheme_in?: InputMaybe<Array<Scalars['String']['input']>>
  billingScheme_like?: InputMaybe<Scalars['String']['input']>
  billingScheme_lt?: InputMaybe<Scalars['String']['input']>
  billingScheme_lte?: InputMaybe<Scalars['String']['input']>
  billingScheme_ne?: InputMaybe<Scalars['String']['input']>
  billingScheme_notILike?: InputMaybe<Scalars['String']['input']>
  billingScheme_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  billingScheme_notLike?: InputMaybe<Scalars['String']['input']>
  billingScheme_startsWith?: InputMaybe<Scalars['String']['input']>
  billingScheme_substring?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_ne?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>
  currency?: InputMaybe<Scalars['String']['input']>
  currency_endsWith?: InputMaybe<Scalars['String']['input']>
  currency_gt?: InputMaybe<Scalars['String']['input']>
  currency_gte?: InputMaybe<Scalars['String']['input']>
  currency_iLike?: InputMaybe<Scalars['String']['input']>
  currency_in?: InputMaybe<Array<Scalars['String']['input']>>
  currency_like?: InputMaybe<Scalars['String']['input']>
  currency_lt?: InputMaybe<Scalars['String']['input']>
  currency_lte?: InputMaybe<Scalars['String']['input']>
  currency_ne?: InputMaybe<Scalars['String']['input']>
  currency_notILike?: InputMaybe<Scalars['String']['input']>
  currency_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  currency_notLike?: InputMaybe<Scalars['String']['input']>
  currency_startsWith?: InputMaybe<Scalars['String']['input']>
  currency_substring?: InputMaybe<Scalars['String']['input']>
  customUnitAmount?: InputMaybe<Scalars['String']['input']>
  customUnitAmount_endsWith?: InputMaybe<Scalars['String']['input']>
  customUnitAmount_gt?: InputMaybe<Scalars['String']['input']>
  customUnitAmount_gte?: InputMaybe<Scalars['String']['input']>
  customUnitAmount_iLike?: InputMaybe<Scalars['String']['input']>
  customUnitAmount_in?: InputMaybe<Array<Scalars['String']['input']>>
  customUnitAmount_like?: InputMaybe<Scalars['String']['input']>
  customUnitAmount_lt?: InputMaybe<Scalars['String']['input']>
  customUnitAmount_lte?: InputMaybe<Scalars['String']['input']>
  customUnitAmount_ne?: InputMaybe<Scalars['String']['input']>
  customUnitAmount_notILike?: InputMaybe<Scalars['String']['input']>
  customUnitAmount_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  customUnitAmount_notLike?: InputMaybe<Scalars['String']['input']>
  customUnitAmount_startsWith?: InputMaybe<Scalars['String']['input']>
  customUnitAmount_substring?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>
  id_ne?: InputMaybe<Scalars['ID']['input']>
  id_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  object?: InputMaybe<Scalars['String']['input']>
  object_endsWith?: InputMaybe<Scalars['String']['input']>
  object_gt?: InputMaybe<Scalars['String']['input']>
  object_gte?: InputMaybe<Scalars['String']['input']>
  object_iLike?: InputMaybe<Scalars['String']['input']>
  object_in?: InputMaybe<Array<Scalars['String']['input']>>
  object_like?: InputMaybe<Scalars['String']['input']>
  object_lt?: InputMaybe<Scalars['String']['input']>
  object_lte?: InputMaybe<Scalars['String']['input']>
  object_ne?: InputMaybe<Scalars['String']['input']>
  object_notILike?: InputMaybe<Scalars['String']['input']>
  object_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  object_notLike?: InputMaybe<Scalars['String']['input']>
  object_startsWith?: InputMaybe<Scalars['String']['input']>
  object_substring?: InputMaybe<Scalars['String']['input']>
  planId?: InputMaybe<Scalars['ID']['input']>
  planId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  planId_ne?: InputMaybe<Scalars['ID']['input']>
  planId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  plan_none?: InputMaybe<ProductFilter>
  plan_some?: InputMaybe<ProductFilter>
  price?: InputMaybe<Scalars['String']['input']>
  price_endsWith?: InputMaybe<Scalars['String']['input']>
  price_gt?: InputMaybe<Scalars['String']['input']>
  price_gte?: InputMaybe<Scalars['String']['input']>
  price_iLike?: InputMaybe<Scalars['String']['input']>
  price_in?: InputMaybe<Array<Scalars['String']['input']>>
  price_like?: InputMaybe<Scalars['String']['input']>
  price_lt?: InputMaybe<Scalars['String']['input']>
  price_lte?: InputMaybe<Scalars['String']['input']>
  price_ne?: InputMaybe<Scalars['String']['input']>
  price_notILike?: InputMaybe<Scalars['String']['input']>
  price_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  price_notLike?: InputMaybe<Scalars['String']['input']>
  price_startsWith?: InputMaybe<Scalars['String']['input']>
  price_substring?: InputMaybe<Scalars['String']['input']>
  product?: InputMaybe<Scalars['String']['input']>
  product_endsWith?: InputMaybe<Scalars['String']['input']>
  product_gt?: InputMaybe<Scalars['String']['input']>
  product_gte?: InputMaybe<Scalars['String']['input']>
  product_iLike?: InputMaybe<Scalars['String']['input']>
  product_in?: InputMaybe<Array<Scalars['String']['input']>>
  product_like?: InputMaybe<Scalars['String']['input']>
  product_lt?: InputMaybe<Scalars['String']['input']>
  product_lte?: InputMaybe<Scalars['String']['input']>
  product_ne?: InputMaybe<Scalars['String']['input']>
  product_notILike?: InputMaybe<Scalars['String']['input']>
  product_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  product_notLike?: InputMaybe<Scalars['String']['input']>
  product_startsWith?: InputMaybe<Scalars['String']['input']>
  product_substring?: InputMaybe<Scalars['String']['input']>
  taxBehavior?: InputMaybe<Scalars['String']['input']>
  taxBehavior_endsWith?: InputMaybe<Scalars['String']['input']>
  taxBehavior_gt?: InputMaybe<Scalars['String']['input']>
  taxBehavior_gte?: InputMaybe<Scalars['String']['input']>
  taxBehavior_iLike?: InputMaybe<Scalars['String']['input']>
  taxBehavior_in?: InputMaybe<Array<Scalars['String']['input']>>
  taxBehavior_like?: InputMaybe<Scalars['String']['input']>
  taxBehavior_lt?: InputMaybe<Scalars['String']['input']>
  taxBehavior_lte?: InputMaybe<Scalars['String']['input']>
  taxBehavior_ne?: InputMaybe<Scalars['String']['input']>
  taxBehavior_notILike?: InputMaybe<Scalars['String']['input']>
  taxBehavior_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  taxBehavior_notLike?: InputMaybe<Scalars['String']['input']>
  taxBehavior_startsWith?: InputMaybe<Scalars['String']['input']>
  taxBehavior_substring?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<Scalars['String']['input']>
  type_endsWith?: InputMaybe<Scalars['String']['input']>
  type_gt?: InputMaybe<Scalars['String']['input']>
  type_gte?: InputMaybe<Scalars['String']['input']>
  type_iLike?: InputMaybe<Scalars['String']['input']>
  type_in?: InputMaybe<Array<Scalars['String']['input']>>
  type_like?: InputMaybe<Scalars['String']['input']>
  type_lt?: InputMaybe<Scalars['String']['input']>
  type_lte?: InputMaybe<Scalars['String']['input']>
  type_ne?: InputMaybe<Scalars['String']['input']>
  type_notILike?: InputMaybe<Scalars['String']['input']>
  type_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  type_notLike?: InputMaybe<Scalars['String']['input']>
  type_startsWith?: InputMaybe<Scalars['String']['input']>
  type_substring?: InputMaybe<Scalars['String']['input']>
  unitAmount?: InputMaybe<Scalars['Float']['input']>
  unitAmountDecimal?: InputMaybe<Scalars['String']['input']>
  unitAmountDecimal_endsWith?: InputMaybe<Scalars['String']['input']>
  unitAmountDecimal_gt?: InputMaybe<Scalars['String']['input']>
  unitAmountDecimal_gte?: InputMaybe<Scalars['String']['input']>
  unitAmountDecimal_iLike?: InputMaybe<Scalars['String']['input']>
  unitAmountDecimal_in?: InputMaybe<Array<Scalars['String']['input']>>
  unitAmountDecimal_like?: InputMaybe<Scalars['String']['input']>
  unitAmountDecimal_lt?: InputMaybe<Scalars['String']['input']>
  unitAmountDecimal_lte?: InputMaybe<Scalars['String']['input']>
  unitAmountDecimal_ne?: InputMaybe<Scalars['String']['input']>
  unitAmountDecimal_notILike?: InputMaybe<Scalars['String']['input']>
  unitAmountDecimal_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  unitAmountDecimal_notLike?: InputMaybe<Scalars['String']['input']>
  unitAmountDecimal_startsWith?: InputMaybe<Scalars['String']['input']>
  unitAmountDecimal_substring?: InputMaybe<Scalars['String']['input']>
  unitAmount_gt?: InputMaybe<Scalars['Float']['input']>
  unitAmount_gte?: InputMaybe<Scalars['Float']['input']>
  unitAmount_in?: InputMaybe<Array<Scalars['Float']['input']>>
  unitAmount_lt?: InputMaybe<Scalars['Float']['input']>
  unitAmount_lte?: InputMaybe<Scalars['Float']['input']>
  unitAmount_ne?: InputMaybe<Scalars['Float']['input']>
  unitAmount_notIn?: InputMaybe<Array<Scalars['Float']['input']>>
}

export type PriceOrderBy =
  | 'active_asc'
  | 'active_desc'
  | 'billingScheme_asc'
  | 'billingScheme_desc'
  | 'createdAt_asc'
  | 'createdAt_desc'
  | 'currency_asc'
  | 'currency_desc'
  | 'customUnitAmount_asc'
  | 'customUnitAmount_desc'
  | 'id_asc'
  | 'id_desc'
  | 'object_asc'
  | 'object_desc'
  | 'planId_asc'
  | 'planId_desc'
  | 'plan_active_asc'
  | 'plan_active_desc'
  | 'plan_createdAt_asc'
  | 'plan_createdAt_desc'
  | 'plan_defaultPrice_asc'
  | 'plan_defaultPrice_desc'
  | 'plan_description_asc'
  | 'plan_description_desc'
  | 'plan_discountPercent_asc'
  | 'plan_discountPercent_desc'
  | 'plan_gifId_asc'
  | 'plan_gifId_desc'
  | 'plan_idPrice_asc'
  | 'plan_idPrice_desc'
  | 'plan_idProduct_asc'
  | 'plan_idProduct_desc'
  | 'plan_id_asc'
  | 'plan_id_desc'
  | 'plan_images_asc'
  | 'plan_images_desc'
  | 'plan_marketingFeatures_asc'
  | 'plan_marketingFeatures_desc'
  | 'plan_metadata_asc'
  | 'plan_metadata_desc'
  | 'plan_name_asc'
  | 'plan_name_desc'
  | 'plan_priceId_asc'
  | 'plan_priceId_desc'
  | 'plan_subTitleGif_asc'
  | 'plan_subTitleGif_desc'
  | 'plan_titleGif_asc'
  | 'plan_titleGif_desc'
  | 'plan_titlePlansGif_asc'
  | 'plan_titlePlansGif_desc'
  | 'plan_type_asc'
  | 'plan_type_desc'
  | 'price_asc'
  | 'price_desc'
  | 'product_asc'
  | 'product_desc'
  | 'taxBehavior_asc'
  | 'taxBehavior_desc'
  | 'type_asc'
  | 'type_desc'
  | 'unitAmountDecimal_asc'
  | 'unitAmountDecimal_desc'
  | 'unitAmount_asc'
  | 'unitAmount_desc'

export type Product = {
  __typename?: 'Product'
  active: Scalars['Boolean']['output']
  createdAt: Scalars['DateTime']['output']
  defaultPrice?: Maybe<Scalars['Json']['output']>
  description: Scalars['String']['output']
  discountPercent: Scalars['Int']['output']
  gif?: Maybe<Resource>
  gifId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  idPrice: Scalars['String']['output']
  idProduct: Scalars['String']['output']
  images?: Maybe<Scalars['Json']['output']>
  marketingFeatures?: Maybe<Scalars['Json']['output']>
  metadata?: Maybe<Scalars['Json']['output']>
  name: Scalars['String']['output']
  price?: Maybe<Price>
  priceId: Scalars['ID']['output']
  subTitleGif: Scalars['String']['output']
  titleGif: Scalars['String']['output']
  titlePlansGif: Scalars['String']['output']
  type: Scalars['String']['output']
}

export type ProductData = {
  name?: InputMaybe<Scalars['String']['input']>
}

export type ProductFilter = {
  AND?: InputMaybe<Array<ProductFilter>>
  NOT?: InputMaybe<ProductFilter>
  OR?: InputMaybe<Array<ProductFilter>>
  active?: InputMaybe<Scalars['Boolean']['input']>
  active_ne?: InputMaybe<Scalars['Boolean']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_ne?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>
  description?: InputMaybe<Scalars['String']['input']>
  description_endsWith?: InputMaybe<Scalars['String']['input']>
  description_gt?: InputMaybe<Scalars['String']['input']>
  description_gte?: InputMaybe<Scalars['String']['input']>
  description_iLike?: InputMaybe<Scalars['String']['input']>
  description_in?: InputMaybe<Array<Scalars['String']['input']>>
  description_like?: InputMaybe<Scalars['String']['input']>
  description_lt?: InputMaybe<Scalars['String']['input']>
  description_lte?: InputMaybe<Scalars['String']['input']>
  description_ne?: InputMaybe<Scalars['String']['input']>
  description_notILike?: InputMaybe<Scalars['String']['input']>
  description_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  description_notLike?: InputMaybe<Scalars['String']['input']>
  description_startsWith?: InputMaybe<Scalars['String']['input']>
  description_substring?: InputMaybe<Scalars['String']['input']>
  discountPercent?: InputMaybe<Scalars['Int']['input']>
  discountPercent_gt?: InputMaybe<Scalars['Int']['input']>
  discountPercent_gte?: InputMaybe<Scalars['Int']['input']>
  discountPercent_in?: InputMaybe<Array<Scalars['Int']['input']>>
  discountPercent_lt?: InputMaybe<Scalars['Int']['input']>
  discountPercent_lte?: InputMaybe<Scalars['Int']['input']>
  discountPercent_ne?: InputMaybe<Scalars['Int']['input']>
  discountPercent_notIn?: InputMaybe<Array<Scalars['Int']['input']>>
  gifId?: InputMaybe<Scalars['ID']['input']>
  gifId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  gifId_ne?: InputMaybe<Scalars['ID']['input']>
  gifId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  gif_none?: InputMaybe<ResourceFilter>
  gif_some?: InputMaybe<ResourceFilter>
  id?: InputMaybe<Scalars['ID']['input']>
  idPrice?: InputMaybe<Scalars['String']['input']>
  idPrice_endsWith?: InputMaybe<Scalars['String']['input']>
  idPrice_gt?: InputMaybe<Scalars['String']['input']>
  idPrice_gte?: InputMaybe<Scalars['String']['input']>
  idPrice_iLike?: InputMaybe<Scalars['String']['input']>
  idPrice_in?: InputMaybe<Array<Scalars['String']['input']>>
  idPrice_like?: InputMaybe<Scalars['String']['input']>
  idPrice_lt?: InputMaybe<Scalars['String']['input']>
  idPrice_lte?: InputMaybe<Scalars['String']['input']>
  idPrice_ne?: InputMaybe<Scalars['String']['input']>
  idPrice_notILike?: InputMaybe<Scalars['String']['input']>
  idPrice_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  idPrice_notLike?: InputMaybe<Scalars['String']['input']>
  idPrice_startsWith?: InputMaybe<Scalars['String']['input']>
  idPrice_substring?: InputMaybe<Scalars['String']['input']>
  idProduct?: InputMaybe<Scalars['String']['input']>
  idProduct_endsWith?: InputMaybe<Scalars['String']['input']>
  idProduct_gt?: InputMaybe<Scalars['String']['input']>
  idProduct_gte?: InputMaybe<Scalars['String']['input']>
  idProduct_iLike?: InputMaybe<Scalars['String']['input']>
  idProduct_in?: InputMaybe<Array<Scalars['String']['input']>>
  idProduct_like?: InputMaybe<Scalars['String']['input']>
  idProduct_lt?: InputMaybe<Scalars['String']['input']>
  idProduct_lte?: InputMaybe<Scalars['String']['input']>
  idProduct_ne?: InputMaybe<Scalars['String']['input']>
  idProduct_notILike?: InputMaybe<Scalars['String']['input']>
  idProduct_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  idProduct_notLike?: InputMaybe<Scalars['String']['input']>
  idProduct_startsWith?: InputMaybe<Scalars['String']['input']>
  idProduct_substring?: InputMaybe<Scalars['String']['input']>
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>
  id_ne?: InputMaybe<Scalars['ID']['input']>
  id_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  name?: InputMaybe<Scalars['String']['input']>
  name_endsWith?: InputMaybe<Scalars['String']['input']>
  name_gt?: InputMaybe<Scalars['String']['input']>
  name_gte?: InputMaybe<Scalars['String']['input']>
  name_iLike?: InputMaybe<Scalars['String']['input']>
  name_in?: InputMaybe<Array<Scalars['String']['input']>>
  name_like?: InputMaybe<Scalars['String']['input']>
  name_lt?: InputMaybe<Scalars['String']['input']>
  name_lte?: InputMaybe<Scalars['String']['input']>
  name_ne?: InputMaybe<Scalars['String']['input']>
  name_notILike?: InputMaybe<Scalars['String']['input']>
  name_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  name_notLike?: InputMaybe<Scalars['String']['input']>
  name_startsWith?: InputMaybe<Scalars['String']['input']>
  name_substring?: InputMaybe<Scalars['String']['input']>
  priceId?: InputMaybe<Scalars['ID']['input']>
  priceId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  priceId_ne?: InputMaybe<Scalars['ID']['input']>
  priceId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  price_none?: InputMaybe<PriceFilter>
  price_some?: InputMaybe<PriceFilter>
  subTitleGif?: InputMaybe<Scalars['String']['input']>
  subTitleGif_endsWith?: InputMaybe<Scalars['String']['input']>
  subTitleGif_gt?: InputMaybe<Scalars['String']['input']>
  subTitleGif_gte?: InputMaybe<Scalars['String']['input']>
  subTitleGif_iLike?: InputMaybe<Scalars['String']['input']>
  subTitleGif_in?: InputMaybe<Array<Scalars['String']['input']>>
  subTitleGif_like?: InputMaybe<Scalars['String']['input']>
  subTitleGif_lt?: InputMaybe<Scalars['String']['input']>
  subTitleGif_lte?: InputMaybe<Scalars['String']['input']>
  subTitleGif_ne?: InputMaybe<Scalars['String']['input']>
  subTitleGif_notILike?: InputMaybe<Scalars['String']['input']>
  subTitleGif_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  subTitleGif_notLike?: InputMaybe<Scalars['String']['input']>
  subTitleGif_startsWith?: InputMaybe<Scalars['String']['input']>
  subTitleGif_substring?: InputMaybe<Scalars['String']['input']>
  titleGif?: InputMaybe<Scalars['String']['input']>
  titleGif_endsWith?: InputMaybe<Scalars['String']['input']>
  titleGif_gt?: InputMaybe<Scalars['String']['input']>
  titleGif_gte?: InputMaybe<Scalars['String']['input']>
  titleGif_iLike?: InputMaybe<Scalars['String']['input']>
  titleGif_in?: InputMaybe<Array<Scalars['String']['input']>>
  titleGif_like?: InputMaybe<Scalars['String']['input']>
  titleGif_lt?: InputMaybe<Scalars['String']['input']>
  titleGif_lte?: InputMaybe<Scalars['String']['input']>
  titleGif_ne?: InputMaybe<Scalars['String']['input']>
  titleGif_notILike?: InputMaybe<Scalars['String']['input']>
  titleGif_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  titleGif_notLike?: InputMaybe<Scalars['String']['input']>
  titleGif_startsWith?: InputMaybe<Scalars['String']['input']>
  titleGif_substring?: InputMaybe<Scalars['String']['input']>
  titlePlansGif?: InputMaybe<Scalars['String']['input']>
  titlePlansGif_endsWith?: InputMaybe<Scalars['String']['input']>
  titlePlansGif_gt?: InputMaybe<Scalars['String']['input']>
  titlePlansGif_gte?: InputMaybe<Scalars['String']['input']>
  titlePlansGif_iLike?: InputMaybe<Scalars['String']['input']>
  titlePlansGif_in?: InputMaybe<Array<Scalars['String']['input']>>
  titlePlansGif_like?: InputMaybe<Scalars['String']['input']>
  titlePlansGif_lt?: InputMaybe<Scalars['String']['input']>
  titlePlansGif_lte?: InputMaybe<Scalars['String']['input']>
  titlePlansGif_ne?: InputMaybe<Scalars['String']['input']>
  titlePlansGif_notILike?: InputMaybe<Scalars['String']['input']>
  titlePlansGif_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  titlePlansGif_notLike?: InputMaybe<Scalars['String']['input']>
  titlePlansGif_startsWith?: InputMaybe<Scalars['String']['input']>
  titlePlansGif_substring?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<Scalars['String']['input']>
  type_endsWith?: InputMaybe<Scalars['String']['input']>
  type_gt?: InputMaybe<Scalars['String']['input']>
  type_gte?: InputMaybe<Scalars['String']['input']>
  type_iLike?: InputMaybe<Scalars['String']['input']>
  type_in?: InputMaybe<Array<Scalars['String']['input']>>
  type_like?: InputMaybe<Scalars['String']['input']>
  type_lt?: InputMaybe<Scalars['String']['input']>
  type_lte?: InputMaybe<Scalars['String']['input']>
  type_ne?: InputMaybe<Scalars['String']['input']>
  type_notILike?: InputMaybe<Scalars['String']['input']>
  type_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  type_notLike?: InputMaybe<Scalars['String']['input']>
  type_startsWith?: InputMaybe<Scalars['String']['input']>
  type_substring?: InputMaybe<Scalars['String']['input']>
}

export type ProductOrderBy =
  | 'active_asc'
  | 'active_desc'
  | 'createdAt_asc'
  | 'createdAt_desc'
  | 'description_asc'
  | 'description_desc'
  | 'discountPercent_asc'
  | 'discountPercent_desc'
  | 'gifId_asc'
  | 'gifId_desc'
  | 'gif_createdAt_asc'
  | 'gif_createdAt_desc'
  | 'gif_createdById_asc'
  | 'gif_createdById_desc'
  | 'gif_duration_asc'
  | 'gif_duration_desc'
  | 'gif_ffprobe_asc'
  | 'gif_ffprobe_desc'
  | 'gif_fileSize_asc'
  | 'gif_fileSize_desc'
  | 'gif_id_asc'
  | 'gif_id_desc'
  | 'gif_name_asc'
  | 'gif_name_desc'
  | 'gif_originalResourceId_asc'
  | 'gif_originalResourceId_desc'
  | 'gif_originalResourceState_asc'
  | 'gif_originalResourceState_desc'
  | 'gif_thumbnailResourceId_asc'
  | 'gif_thumbnailResourceId_desc'
  | 'gif_type_asc'
  | 'gif_type_desc'
  | 'gif_url_asc'
  | 'gif_url_desc'
  | 'idPrice_asc'
  | 'idPrice_desc'
  | 'idProduct_asc'
  | 'idProduct_desc'
  | 'id_asc'
  | 'id_desc'
  | 'name_asc'
  | 'name_desc'
  | 'priceId_asc'
  | 'priceId_desc'
  | 'price_active_asc'
  | 'price_active_desc'
  | 'price_billingScheme_asc'
  | 'price_billingScheme_desc'
  | 'price_createdAt_asc'
  | 'price_createdAt_desc'
  | 'price_currency_asc'
  | 'price_currency_desc'
  | 'price_customUnitAmount_asc'
  | 'price_customUnitAmount_desc'
  | 'price_id_asc'
  | 'price_id_desc'
  | 'price_metadata_asc'
  | 'price_metadata_desc'
  | 'price_object_asc'
  | 'price_object_desc'
  | 'price_planId_asc'
  | 'price_planId_desc'
  | 'price_price_asc'
  | 'price_price_desc'
  | 'price_product_asc'
  | 'price_product_desc'
  | 'price_recurring_asc'
  | 'price_recurring_desc'
  | 'price_taxBehavior_asc'
  | 'price_taxBehavior_desc'
  | 'price_type_asc'
  | 'price_type_desc'
  | 'price_unitAmountDecimal_asc'
  | 'price_unitAmountDecimal_desc'
  | 'price_unitAmount_asc'
  | 'price_unitAmount_desc'
  | 'subTitleGif_asc'
  | 'subTitleGif_desc'
  | 'titleGif_asc'
  | 'titleGif_desc'
  | 'titlePlansGif_asc'
  | 'titlePlansGif_desc'
  | 'type_asc'
  | 'type_desc'

export type PurchaseType = 'one_time' | 'recurring'

export type Query = {
  __typename?: 'Query'
  autoCorrectMessage?: Maybe<Scalars['String']['output']>
  checkEmail: Scalars['Boolean']['output']
  checkTask?: Maybe<Scalars['String']['output']>
  checkTicket: Scalars['Boolean']['output']
  checkUserSubscribed: Scalars['Json']['output']
  confirmPaymentIntent: Scalars['Json']['output']
  countAiTutor: Scalars['Int']['output']
  countAnswer: Scalars['Int']['output']
  countAnswerInQuestion: Scalars['Int']['output']
  countCategory: Scalars['Int']['output']
  countMessage: Scalars['Int']['output']
  countPrice: Scalars['Int']['output']
  countProduct: Scalars['Int']['output']
  countQuestion: Scalars['Int']['output']
  countResource: Scalars['Int']['output']
  countResourceInWord: Scalars['Int']['output']
  countSplash: Scalars['Int']['output']
  countStreak: Scalars['Int']['output']
  countStreakDaily: Scalars['Int']['output']
  countStreakInUser: Scalars['Int']['output']
  countTopic: Scalars['Int']['output']
  countTopicInCategory: Scalars['Int']['output']
  countTopicInUser: Scalars['Int']['output']
  countTranslate: Scalars['Int']['output']
  countUser: Scalars['Int']['output']
  countUserAnswer: Scalars['Int']['output']
  countVocabPhraseReview: Scalars['Int']['output']
  countWord: Scalars['Int']['output']
  countWordInUser: Scalars['Int']['output']
  countWordbank: Scalars['Int']['output']
  currentAuthSession?: Maybe<AuthSession>
  detailAiTutor: AiTutor
  detailTopic: Topic
  detailWord: Word
  generateCompleteTheDialogue?: Maybe<Scalars['String']['output']>
  generateFillTheBlank?: Maybe<Scalars['String']['output']>
  generateHardGame: Array<GameResult>
  listCustomerPaymentMethod: Scalars['Json']['output']
  listPaymentIntent: Scalars['Json']['output']
  listPaymentMethod: Scalars['Json']['output']
  listPrices: Scalars['Json']['output']
  retrieveCustomersPaymentMethod: Scalars['Json']['output']
  searchAiTutor: Array<AiTutor>
  searchAnswer: Array<Answer>
  searchAnswerInQuestion: Array<AnswerInQuestion>
  searchCategory: Array<Category>
  searchMessage: Array<Message>
  searchPaymentIntent: Scalars['Json']['output']
  searchPrice: Array<Price>
  searchProduct: Array<Product>
  searchQuery: Scalars['Json']['output']
  searchQuestion: Array<Question>
  searchResource: Array<Resource>
  searchResourceInWord: Array<ResourceInWord>
  searchReviewWordInUser: Scalars['Json']['output']
  searchSplash: Array<Splash>
  searchStreak: Array<Streak>
  searchStreakDaily: Array<StreakDaily>
  searchStreakInUser: Array<StreakInUser>
  searchTopic: Array<Topic>
  searchTopicInCategory: Array<TopicInCategory>
  searchTopicInUser: Array<TopicInUser>
  searchTranslate: Array<Translate>
  searchUser: Array<User>
  searchUserAnswer: Array<UserAnswer>
  searchVocabPhraseReview: Array<WordInUser>
  searchWord: Array<Word>
  searchWordInUser: Array<WordInUser>
  searchWordbank: Array<Wordbank>
  serverTime: Scalars['Float']['output']
  speechToText?: Maybe<Scalars['String']['output']>
  suggestMessage?: Maybe<Scalars['Json']['output']>
  suggestedTranslation?: Maybe<Scalars['String']['output']>
  testAutoCorrectMessage?: Maybe<Scalars['Json']['output']>
  textToSpeech: Scalars['String']['output']
  tracksUserCompletionByLevel: ResponseType
  translateMessage?: Maybe<Scalars['String']['output']>
}

export type QueryAutoCorrectMessageArgs = {
  content?: InputMaybe<Scalars['String']['input']>
}

export type QueryCheckEmailArgs = {
  email: Scalars['Email']['input']
}

export type QueryCheckTaskArgs = {
  content?: InputMaybe<Scalars['String']['input']>
  level?: InputMaybe<Level>
  task?: InputMaybe<Array<InputMaybe<TaskItemInput>>>
  topicInUserId?: InputMaybe<Scalars['ID']['input']>
}

export type QueryCheckTicketArgs = {
  data: CheckTicket
  type: CheckTicketType
}

export type QueryConfirmPaymentIntentArgs = {
  data?: InputMaybe<InputConfirmPaymentIntent>
}

export type QueryCountAiTutorArgs = {
  filter?: InputMaybe<AiTutorFilter>
}

export type QueryCountAnswerArgs = {
  filter?: InputMaybe<AnswerFilter>
}

export type QueryCountAnswerInQuestionArgs = {
  filter?: InputMaybe<AnswerInQuestionFilter>
}

export type QueryCountCategoryArgs = {
  filter?: InputMaybe<CategoryFilter>
}

export type QueryCountMessageArgs = {
  filter?: InputMaybe<MessageFilter>
}

export type QueryCountPriceArgs = {
  filter?: InputMaybe<PriceFilter>
}

export type QueryCountProductArgs = {
  filter?: InputMaybe<ProductFilter>
}

export type QueryCountQuestionArgs = {
  filter?: InputMaybe<QuestionFilter>
}

export type QueryCountResourceArgs = {
  filter?: InputMaybe<ResourceFilter>
}

export type QueryCountResourceInWordArgs = {
  filter?: InputMaybe<ResourceInWordFilter>
}

export type QueryCountSplashArgs = {
  filter?: InputMaybe<SplashFilter>
}

export type QueryCountStreakArgs = {
  filter?: InputMaybe<StreakFilter>
}

export type QueryCountStreakDailyArgs = {
  filter?: InputMaybe<StreakDailyFilter>
}

export type QueryCountStreakInUserArgs = {
  filter?: InputMaybe<StreakInUserFilter>
}

export type QueryCountTopicArgs = {
  filter?: InputMaybe<TopicFilter>
}

export type QueryCountTopicInCategoryArgs = {
  filter?: InputMaybe<TopicInCategoryFilter>
}

export type QueryCountTopicInUserArgs = {
  filter?: InputMaybe<TopicInUserFilter>
}

export type QueryCountTranslateArgs = {
  filter?: InputMaybe<TranslateFilter>
}

export type QueryCountUserArgs = {
  filter?: InputMaybe<UserFilter>
}

export type QueryCountUserAnswerArgs = {
  filter?: InputMaybe<UserAnswerFilter>
}

export type QueryCountVocabPhraseReviewArgs = {
  filter?: InputMaybe<WordInUserFilter>
}

export type QueryCountWordArgs = {
  filter?: InputMaybe<WordFilter>
}

export type QueryCountWordInUserArgs = {
  filter?: InputMaybe<WordInUserFilter>
}

export type QueryCountWordbankArgs = {
  filter?: InputMaybe<WordbankFilter>
}

export type QueryDetailAiTutorArgs = {
  id: Scalars['ID']['input']
}

export type QueryDetailTopicArgs = {
  id: Scalars['NeString']['input']
}

export type QueryDetailWordArgs = {
  id: Scalars['ID']['input']
}

export type QueryGenerateCompleteTheDialogueArgs = {
  content?: InputMaybe<Scalars['String']['input']>
  language?: InputMaybe<Language>
}

export type QueryGenerateFillTheBlankArgs = {
  content?: InputMaybe<Scalars['String']['input']>
  isPhrase?: InputMaybe<Scalars['Boolean']['input']>
  language?: InputMaybe<Language>
}

export type QueryGenerateHardGameArgs = {
  data: Array<InputWord>
}

export type QueryListPaymentIntentArgs = {
  data?: InputMaybe<InputListPaymentIntent>
}

export type QueryListPaymentMethodArgs = {
  data?: InputMaybe<ListPaymentMethod>
}

export type QueryListPricesArgs = {
  data?: InputMaybe<ListPrice>
}

export type QueryRetrieveCustomersPaymentMethodArgs = {
  data?: InputMaybe<RetrieveCustomersPaymentMethod>
}

export type QuerySearchAiTutorArgs = {
  filter?: InputMaybe<AiTutorFilter>
  order?: InputMaybe<Array<AiTutorOrderBy>>
  page?: InputMaybe<Pagination>
}

export type QuerySearchAnswerArgs = {
  filter?: InputMaybe<AnswerFilter>
  order?: InputMaybe<Array<AnswerOrderBy>>
  page?: InputMaybe<Pagination>
}

export type QuerySearchAnswerInQuestionArgs = {
  filter?: InputMaybe<AnswerInQuestionFilter>
  order?: InputMaybe<Array<AnswerInQuestionOrderBy>>
  page?: InputMaybe<Pagination>
}

export type QuerySearchCategoryArgs = {
  filter?: InputMaybe<CategoryFilter>
  order?: InputMaybe<Array<CategoryOrderBy>>
  page?: InputMaybe<Pagination>
}

export type QuerySearchMessageArgs = {
  filter?: InputMaybe<MessageFilter>
  order?: InputMaybe<Array<MessageOrderBy>>
  page?: InputMaybe<Pagination>
}

export type QuerySearchPaymentIntentArgs = {
  data?: InputMaybe<InputSearchPaymentIntent>
}

export type QuerySearchPriceArgs = {
  filter?: InputMaybe<PriceFilter>
  order?: InputMaybe<Array<PriceOrderBy>>
  page?: InputMaybe<Pagination>
}

export type QuerySearchProductArgs = {
  filter?: InputMaybe<ProductFilter>
  order?: InputMaybe<Array<ProductOrderBy>>
  page?: InputMaybe<Pagination>
}

export type QuerySearchQueryArgs = {
  data?: InputMaybe<SearchQuery>
  limit?: InputMaybe<Scalars['Int']['input']>
}

export type QuerySearchQuestionArgs = {
  filter?: InputMaybe<QuestionFilter>
  order?: InputMaybe<Array<QuestionOrderBy>>
  page?: InputMaybe<Pagination>
}

export type QuerySearchResourceArgs = {
  filter?: InputMaybe<ResourceFilter>
  order?: InputMaybe<Array<ResourceOrderBy>>
  page?: InputMaybe<Pagination>
}

export type QuerySearchResourceInWordArgs = {
  filter?: InputMaybe<ResourceInWordFilter>
  order?: InputMaybe<Array<ResourceInWordOrderBy>>
  page?: InputMaybe<Pagination>
}

export type QuerySearchReviewWordInUserArgs = {
  data?: InputMaybe<QueryReviewWordInUser>
}

export type QuerySearchSplashArgs = {
  filter?: InputMaybe<SplashFilter>
  order?: InputMaybe<Array<SplashOrderBy>>
  page?: InputMaybe<Pagination>
}

export type QuerySearchStreakArgs = {
  filter?: InputMaybe<StreakFilter>
  order?: InputMaybe<Array<StreakOrderBy>>
  page?: InputMaybe<Pagination>
}

export type QuerySearchStreakDailyArgs = {
  filter?: InputMaybe<StreakDailyFilter>
  order?: InputMaybe<Array<StreakDailyOrderBy>>
  page?: InputMaybe<Pagination>
}

export type QuerySearchStreakInUserArgs = {
  filter?: InputMaybe<StreakInUserFilter>
  order?: InputMaybe<Array<StreakInUserOrderBy>>
  page?: InputMaybe<Pagination>
}

export type QuerySearchTopicArgs = {
  filter?: InputMaybe<TopicFilter>
  order?: InputMaybe<Array<TopicOrderBy>>
  page?: InputMaybe<Pagination>
}

export type QuerySearchTopicInCategoryArgs = {
  filter?: InputMaybe<TopicInCategoryFilter>
  order?: InputMaybe<Array<TopicInCategoryOrderBy>>
  page?: InputMaybe<Pagination>
}

export type QuerySearchTopicInUserArgs = {
  filter?: InputMaybe<TopicInUserFilter>
  order?: InputMaybe<Array<TopicInUserOrderBy>>
  page?: InputMaybe<Pagination>
}

export type QuerySearchTranslateArgs = {
  filter?: InputMaybe<TranslateFilter>
  order?: InputMaybe<Array<TranslateOrderBy>>
  page?: InputMaybe<Pagination>
}

export type QuerySearchUserArgs = {
  filter?: InputMaybe<UserFilter>
  order?: InputMaybe<Array<UserOrderBy>>
  page?: InputMaybe<Pagination>
}

export type QuerySearchUserAnswerArgs = {
  filter?: InputMaybe<UserAnswerFilter>
  order?: InputMaybe<Array<UserAnswerOrderBy>>
  page?: InputMaybe<Pagination>
}

export type QuerySearchVocabPhraseReviewArgs = {
  filter?: InputMaybe<WordInUserFilter>
  order?: InputMaybe<Array<WordInUserOrderBy>>
  page?: InputMaybe<Pagination>
}

export type QuerySearchWordArgs = {
  filter?: InputMaybe<WordFilter>
  order?: InputMaybe<Array<WordOrderBy>>
  page?: InputMaybe<Pagination>
}

export type QuerySearchWordInUserArgs = {
  filter?: InputMaybe<WordInUserFilter>
  order?: InputMaybe<Array<WordInUserOrderBy>>
  page?: InputMaybe<Pagination>
}

export type QuerySearchWordbankArgs = {
  filter?: InputMaybe<WordbankFilter>
  order?: InputMaybe<Array<WordbankOrderBy>>
  page?: InputMaybe<Pagination>
}

export type QuerySpeechToTextArgs = {
  audioBuffer?: InputMaybe<Scalars['Buffer']['input']>
  devices?: InputMaybe<Devices>
}

export type QuerySuggestMessageArgs = {
  topicInUserId?: InputMaybe<Scalars['ID']['input']>
}

export type QuerySuggestedTranslationArgs = {
  language?: InputMaybe<Languages>
  text?: InputMaybe<Scalars['String']['input']>
}

export type QueryTestAutoCorrectMessageArgs = {
  content?: InputMaybe<Scalars['String']['input']>
}

export type QueryTextToSpeechArgs = {
  text: Scalars['String']['input']
}

export type QueryTranslateMessageArgs = {
  content?: InputMaybe<Scalars['String']['input']>
}

export type QueryProduct = {
  customerId?: InputMaybe<Scalars['String']['input']>
  payment_method_id?: InputMaybe<Scalars['String']['input']>
  price_id?: InputMaybe<Scalars['String']['input']>
  quantity?: InputMaybe<Scalars['Int']['input']>
}

export type QueryReviewWordInUser = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  id?: InputMaybe<Scalars['String']['input']>
  lastDay?: InputMaybe<Scalars['String']['input']>
  nextDay?: InputMaybe<Scalars['String']['input']>
  percentListen?: InputMaybe<Scalars['Int']['input']>
  times?: InputMaybe<Scalars['Int']['input']>
  topicId?: InputMaybe<Scalars['ID']['input']>
  used?: InputMaybe<Scalars['Boolean']['input']>
  userId?: InputMaybe<Scalars['ID']['input']>
  wordId?: InputMaybe<Scalars['String']['input']>
}

export type Question = {
  __typename?: 'Question'
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  index: Scalars['Int']['output']
  media?: Maybe<Resource>
  mediaId: Scalars['ID']['output']
  text: Scalars['String']['output']
  thumbnail?: Maybe<Resource>
  thumbnailId: Scalars['ID']['output']
  topic?: Maybe<Topic>
  topicId: Scalars['ID']['output']
  translate?: Maybe<Translate>
  type: Scalars['String']['output']
  word?: Maybe<Word>
  wordId: Scalars['ID']['output']
}

export type QuestionFilter = {
  AND?: InputMaybe<Array<QuestionFilter>>
  NOT?: InputMaybe<QuestionFilter>
  OR?: InputMaybe<Array<QuestionFilter>>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_ne?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>
  id?: InputMaybe<Scalars['ID']['input']>
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>
  id_ne?: InputMaybe<Scalars['ID']['input']>
  id_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  index?: InputMaybe<Scalars['Int']['input']>
  index_gt?: InputMaybe<Scalars['Int']['input']>
  index_gte?: InputMaybe<Scalars['Int']['input']>
  index_in?: InputMaybe<Array<Scalars['Int']['input']>>
  index_lt?: InputMaybe<Scalars['Int']['input']>
  index_lte?: InputMaybe<Scalars['Int']['input']>
  index_ne?: InputMaybe<Scalars['Int']['input']>
  index_notIn?: InputMaybe<Array<Scalars['Int']['input']>>
  mediaId?: InputMaybe<Scalars['ID']['input']>
  mediaId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  mediaId_ne?: InputMaybe<Scalars['ID']['input']>
  mediaId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  media_none?: InputMaybe<ResourceFilter>
  media_some?: InputMaybe<ResourceFilter>
  text?: InputMaybe<Scalars['String']['input']>
  text_endsWith?: InputMaybe<Scalars['String']['input']>
  text_gt?: InputMaybe<Scalars['String']['input']>
  text_gte?: InputMaybe<Scalars['String']['input']>
  text_iLike?: InputMaybe<Scalars['String']['input']>
  text_in?: InputMaybe<Array<Scalars['String']['input']>>
  text_like?: InputMaybe<Scalars['String']['input']>
  text_lt?: InputMaybe<Scalars['String']['input']>
  text_lte?: InputMaybe<Scalars['String']['input']>
  text_ne?: InputMaybe<Scalars['String']['input']>
  text_notILike?: InputMaybe<Scalars['String']['input']>
  text_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  text_notLike?: InputMaybe<Scalars['String']['input']>
  text_startsWith?: InputMaybe<Scalars['String']['input']>
  text_substring?: InputMaybe<Scalars['String']['input']>
  thumbnailId?: InputMaybe<Scalars['ID']['input']>
  thumbnailId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  thumbnailId_ne?: InputMaybe<Scalars['ID']['input']>
  thumbnailId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  thumbnail_none?: InputMaybe<ResourceFilter>
  thumbnail_some?: InputMaybe<ResourceFilter>
  topicId?: InputMaybe<Scalars['ID']['input']>
  topicId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  topicId_ne?: InputMaybe<Scalars['ID']['input']>
  topicId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  topic_none?: InputMaybe<TopicFilter>
  topic_some?: InputMaybe<TopicFilter>
  translate_none?: InputMaybe<TranslateFilter>
  translate_some?: InputMaybe<TranslateFilter>
  type?: InputMaybe<Scalars['String']['input']>
  type_endsWith?: InputMaybe<Scalars['String']['input']>
  type_gt?: InputMaybe<Scalars['String']['input']>
  type_gte?: InputMaybe<Scalars['String']['input']>
  type_iLike?: InputMaybe<Scalars['String']['input']>
  type_in?: InputMaybe<Array<Scalars['String']['input']>>
  type_like?: InputMaybe<Scalars['String']['input']>
  type_lt?: InputMaybe<Scalars['String']['input']>
  type_lte?: InputMaybe<Scalars['String']['input']>
  type_ne?: InputMaybe<Scalars['String']['input']>
  type_notILike?: InputMaybe<Scalars['String']['input']>
  type_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  type_notLike?: InputMaybe<Scalars['String']['input']>
  type_startsWith?: InputMaybe<Scalars['String']['input']>
  type_substring?: InputMaybe<Scalars['String']['input']>
  wordId?: InputMaybe<Scalars['ID']['input']>
  wordId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  wordId_ne?: InputMaybe<Scalars['ID']['input']>
  wordId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  word_none?: InputMaybe<WordFilter>
  word_some?: InputMaybe<WordFilter>
}

export type QuestionOrderBy =
  | 'createdAt_asc'
  | 'createdAt_desc'
  | 'id_asc'
  | 'id_desc'
  | 'index_asc'
  | 'index_desc'
  | 'mediaId_asc'
  | 'mediaId_desc'
  | 'media_createdAt_asc'
  | 'media_createdAt_desc'
  | 'media_createdById_asc'
  | 'media_createdById_desc'
  | 'media_duration_asc'
  | 'media_duration_desc'
  | 'media_ffprobe_asc'
  | 'media_ffprobe_desc'
  | 'media_fileSize_asc'
  | 'media_fileSize_desc'
  | 'media_id_asc'
  | 'media_id_desc'
  | 'media_name_asc'
  | 'media_name_desc'
  | 'media_originalResourceId_asc'
  | 'media_originalResourceId_desc'
  | 'media_originalResourceState_asc'
  | 'media_originalResourceState_desc'
  | 'media_thumbnailResourceId_asc'
  | 'media_thumbnailResourceId_desc'
  | 'media_type_asc'
  | 'media_type_desc'
  | 'media_url_asc'
  | 'media_url_desc'
  | 'text_asc'
  | 'text_desc'
  | 'thumbnailId_asc'
  | 'thumbnailId_desc'
  | 'thumbnail_createdAt_asc'
  | 'thumbnail_createdAt_desc'
  | 'thumbnail_createdById_asc'
  | 'thumbnail_createdById_desc'
  | 'thumbnail_duration_asc'
  | 'thumbnail_duration_desc'
  | 'thumbnail_ffprobe_asc'
  | 'thumbnail_ffprobe_desc'
  | 'thumbnail_fileSize_asc'
  | 'thumbnail_fileSize_desc'
  | 'thumbnail_id_asc'
  | 'thumbnail_id_desc'
  | 'thumbnail_name_asc'
  | 'thumbnail_name_desc'
  | 'thumbnail_originalResourceId_asc'
  | 'thumbnail_originalResourceId_desc'
  | 'thumbnail_originalResourceState_asc'
  | 'thumbnail_originalResourceState_desc'
  | 'thumbnail_thumbnailResourceId_asc'
  | 'thumbnail_thumbnailResourceId_desc'
  | 'thumbnail_type_asc'
  | 'thumbnail_type_desc'
  | 'thumbnail_url_asc'
  | 'thumbnail_url_desc'
  | 'topicId_asc'
  | 'topicId_desc'
  | 'topic_aiTutorRole_asc'
  | 'topic_aiTutorRole_desc'
  | 'topic_characterId_asc'
  | 'topic_characterId_desc'
  | 'topic_createdAt_asc'
  | 'topic_createdAt_desc'
  | 'topic_description_asc'
  | 'topic_description_desc'
  | 'topic_id_asc'
  | 'topic_id_desc'
  | 'topic_languageTopic_asc'
  | 'topic_languageTopic_desc'
  | 'topic_level_asc'
  | 'topic_level_desc'
  | 'topic_mediaId_asc'
  | 'topic_mediaId_desc'
  | 'topic_name_asc'
  | 'topic_name_desc'
  | 'topic_ordinalNumber_asc'
  | 'topic_ordinalNumber_desc'
  | 'topic_scenario_asc'
  | 'topic_scenario_desc'
  | 'topic_status_asc'
  | 'topic_status_desc'
  | 'topic_task_asc'
  | 'topic_task_desc'
  | 'topic_userId_asc'
  | 'topic_userId_desc'
  | 'topic_userRole_asc'
  | 'topic_userRole_desc'
  | 'translate_createdAt_asc'
  | 'translate_createdAt_desc'
  | 'translate_id_asc'
  | 'translate_id_desc'
  | 'translate_suportLanguage_asc'
  | 'translate_suportLanguage_desc'
  | 'translate_text_asc'
  | 'translate_text_desc'
  | 'translate_wQAId_asc'
  | 'translate_wQAId_desc'
  | 'type_asc'
  | 'type_desc'
  | 'wordId_asc'
  | 'wordId_desc'
  | 'word_createdAt_asc'
  | 'word_createdAt_desc'
  | 'word_description_asc'
  | 'word_description_desc'
  | 'word_id_asc'
  | 'word_id_desc'
  | 'word_isThumbnail_asc'
  | 'word_isThumbnail_desc'
  | 'word_isTranslate_asc'
  | 'word_isTranslate_desc'
  | 'word_isVocabulary_asc'
  | 'word_isVocabulary_desc'
  | 'word_mediaId_asc'
  | 'word_mediaId_desc'
  | 'word_thumbnailId_asc'
  | 'word_thumbnailId_desc'
  | 'word_topicId_asc'
  | 'word_topicId_desc'
  | 'word_userId_asc'
  | 'word_userId_desc'
  | 'word_vocabAndPhrase_asc'
  | 'word_vocabAndPhrase_desc'

export type Recurring = {
  interval: IntervalTime
  interval_count: Scalars['Int']['input']
  meter?: InputMaybe<Scalars['String']['input']>
}

export type Register = {
  devicePlatform?: InputMaybe<Scalars['String']['input']>
  devicePnSecret?: InputMaybe<Scalars['String']['input']>
  email: Scalars['Email']['input']
  password: Scalars['Password']['input']
}

export type Resource = {
  __typename?: 'Resource'
  createdAt: Scalars['DateTime']['output']
  createdById?: Maybe<Scalars['ID']['output']>
  duration: Scalars['Float']['output']
  ffprobe?: Maybe<Scalars['Json']['output']>
  fileSize: Scalars['Float']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  originalResource?: Maybe<Resource>
  originalResourceId?: Maybe<Scalars['ID']['output']>
  originalResourceState?: Maybe<Scalars['Json']['output']>
  question?: Maybe<Question>
  thumbnailResource?: Maybe<Resource>
  thumbnailResourceId?: Maybe<Scalars['ID']['output']>
  type: Scalars['String']['output']
  url: Scalars['String']['output']
  word?: Maybe<Word>
}

export type ResourceFilter = {
  AND?: InputMaybe<Array<ResourceFilter>>
  NOT?: InputMaybe<ResourceFilter>
  OR?: InputMaybe<Array<ResourceFilter>>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_ne?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>
  createdById?: InputMaybe<Scalars['ID']['input']>
  createdById_in?: InputMaybe<Array<Scalars['ID']['input']>>
  createdById_ne?: InputMaybe<Scalars['ID']['input']>
  createdById_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  duration?: InputMaybe<Scalars['Float']['input']>
  duration_gt?: InputMaybe<Scalars['Float']['input']>
  duration_gte?: InputMaybe<Scalars['Float']['input']>
  duration_in?: InputMaybe<Array<Scalars['Float']['input']>>
  duration_lt?: InputMaybe<Scalars['Float']['input']>
  duration_lte?: InputMaybe<Scalars['Float']['input']>
  duration_ne?: InputMaybe<Scalars['Float']['input']>
  duration_notIn?: InputMaybe<Array<Scalars['Float']['input']>>
  fileSize?: InputMaybe<Scalars['Float']['input']>
  fileSize_gt?: InputMaybe<Scalars['Float']['input']>
  fileSize_gte?: InputMaybe<Scalars['Float']['input']>
  fileSize_in?: InputMaybe<Array<Scalars['Float']['input']>>
  fileSize_lt?: InputMaybe<Scalars['Float']['input']>
  fileSize_lte?: InputMaybe<Scalars['Float']['input']>
  fileSize_ne?: InputMaybe<Scalars['Float']['input']>
  fileSize_notIn?: InputMaybe<Array<Scalars['Float']['input']>>
  id?: InputMaybe<Scalars['ID']['input']>
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>
  id_ne?: InputMaybe<Scalars['ID']['input']>
  id_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  name?: InputMaybe<Scalars['String']['input']>
  name_endsWith?: InputMaybe<Scalars['String']['input']>
  name_gt?: InputMaybe<Scalars['String']['input']>
  name_gte?: InputMaybe<Scalars['String']['input']>
  name_iLike?: InputMaybe<Scalars['String']['input']>
  name_in?: InputMaybe<Array<Scalars['String']['input']>>
  name_like?: InputMaybe<Scalars['String']['input']>
  name_lt?: InputMaybe<Scalars['String']['input']>
  name_lte?: InputMaybe<Scalars['String']['input']>
  name_ne?: InputMaybe<Scalars['String']['input']>
  name_notILike?: InputMaybe<Scalars['String']['input']>
  name_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  name_notLike?: InputMaybe<Scalars['String']['input']>
  name_startsWith?: InputMaybe<Scalars['String']['input']>
  name_substring?: InputMaybe<Scalars['String']['input']>
  originalResourceId?: InputMaybe<Scalars['ID']['input']>
  originalResourceId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  originalResourceId_ne?: InputMaybe<Scalars['ID']['input']>
  originalResourceId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  originalResource_none?: InputMaybe<ResourceFilter>
  originalResource_some?: InputMaybe<ResourceFilter>
  question_none?: InputMaybe<QuestionFilter>
  question_some?: InputMaybe<QuestionFilter>
  thumbnailResourceId?: InputMaybe<Scalars['ID']['input']>
  thumbnailResourceId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  thumbnailResourceId_ne?: InputMaybe<Scalars['ID']['input']>
  thumbnailResourceId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  thumbnailResource_none?: InputMaybe<ResourceFilter>
  thumbnailResource_some?: InputMaybe<ResourceFilter>
  type?: InputMaybe<Scalars['String']['input']>
  type_endsWith?: InputMaybe<Scalars['String']['input']>
  type_gt?: InputMaybe<Scalars['String']['input']>
  type_gte?: InputMaybe<Scalars['String']['input']>
  type_iLike?: InputMaybe<Scalars['String']['input']>
  type_in?: InputMaybe<Array<Scalars['String']['input']>>
  type_like?: InputMaybe<Scalars['String']['input']>
  type_lt?: InputMaybe<Scalars['String']['input']>
  type_lte?: InputMaybe<Scalars['String']['input']>
  type_ne?: InputMaybe<Scalars['String']['input']>
  type_notILike?: InputMaybe<Scalars['String']['input']>
  type_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  type_notLike?: InputMaybe<Scalars['String']['input']>
  type_startsWith?: InputMaybe<Scalars['String']['input']>
  type_substring?: InputMaybe<Scalars['String']['input']>
  url?: InputMaybe<Scalars['String']['input']>
  url_endsWith?: InputMaybe<Scalars['String']['input']>
  url_gt?: InputMaybe<Scalars['String']['input']>
  url_gte?: InputMaybe<Scalars['String']['input']>
  url_iLike?: InputMaybe<Scalars['String']['input']>
  url_in?: InputMaybe<Array<Scalars['String']['input']>>
  url_like?: InputMaybe<Scalars['String']['input']>
  url_lt?: InputMaybe<Scalars['String']['input']>
  url_lte?: InputMaybe<Scalars['String']['input']>
  url_ne?: InputMaybe<Scalars['String']['input']>
  url_notILike?: InputMaybe<Scalars['String']['input']>
  url_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  url_notLike?: InputMaybe<Scalars['String']['input']>
  url_startsWith?: InputMaybe<Scalars['String']['input']>
  url_substring?: InputMaybe<Scalars['String']['input']>
  word_none?: InputMaybe<WordFilter>
  word_some?: InputMaybe<WordFilter>
}

export type ResourceInWord = {
  __typename?: 'ResourceInWord'
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  media?: Maybe<Resource>
  mediaId: Scalars['ID']['output']
  type: Scalars['String']['output']
  word?: Maybe<Word>
  wordId: Scalars['ID']['output']
}

export type ResourceInWordFilter = {
  AND?: InputMaybe<Array<ResourceInWordFilter>>
  NOT?: InputMaybe<ResourceInWordFilter>
  OR?: InputMaybe<Array<ResourceInWordFilter>>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_ne?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>
  id?: InputMaybe<Scalars['ID']['input']>
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>
  id_ne?: InputMaybe<Scalars['ID']['input']>
  id_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  mediaId?: InputMaybe<Scalars['ID']['input']>
  mediaId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  mediaId_ne?: InputMaybe<Scalars['ID']['input']>
  mediaId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  media_none?: InputMaybe<ResourceFilter>
  media_some?: InputMaybe<ResourceFilter>
  type?: InputMaybe<Scalars['String']['input']>
  type_endsWith?: InputMaybe<Scalars['String']['input']>
  type_gt?: InputMaybe<Scalars['String']['input']>
  type_gte?: InputMaybe<Scalars['String']['input']>
  type_iLike?: InputMaybe<Scalars['String']['input']>
  type_in?: InputMaybe<Array<Scalars['String']['input']>>
  type_like?: InputMaybe<Scalars['String']['input']>
  type_lt?: InputMaybe<Scalars['String']['input']>
  type_lte?: InputMaybe<Scalars['String']['input']>
  type_ne?: InputMaybe<Scalars['String']['input']>
  type_notILike?: InputMaybe<Scalars['String']['input']>
  type_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  type_notLike?: InputMaybe<Scalars['String']['input']>
  type_startsWith?: InputMaybe<Scalars['String']['input']>
  type_substring?: InputMaybe<Scalars['String']['input']>
  wordId?: InputMaybe<Scalars['ID']['input']>
  wordId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  wordId_ne?: InputMaybe<Scalars['ID']['input']>
  wordId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  word_none?: InputMaybe<WordFilter>
  word_some?: InputMaybe<WordFilter>
}

export type ResourceInWordOrderBy =
  | 'createdAt_asc'
  | 'createdAt_desc'
  | 'id_asc'
  | 'id_desc'
  | 'mediaId_asc'
  | 'mediaId_desc'
  | 'media_createdAt_asc'
  | 'media_createdAt_desc'
  | 'media_createdById_asc'
  | 'media_createdById_desc'
  | 'media_duration_asc'
  | 'media_duration_desc'
  | 'media_ffprobe_asc'
  | 'media_ffprobe_desc'
  | 'media_fileSize_asc'
  | 'media_fileSize_desc'
  | 'media_id_asc'
  | 'media_id_desc'
  | 'media_name_asc'
  | 'media_name_desc'
  | 'media_originalResourceId_asc'
  | 'media_originalResourceId_desc'
  | 'media_originalResourceState_asc'
  | 'media_originalResourceState_desc'
  | 'media_thumbnailResourceId_asc'
  | 'media_thumbnailResourceId_desc'
  | 'media_type_asc'
  | 'media_type_desc'
  | 'media_url_asc'
  | 'media_url_desc'
  | 'type_asc'
  | 'type_desc'
  | 'wordId_asc'
  | 'wordId_desc'
  | 'word_createdAt_asc'
  | 'word_createdAt_desc'
  | 'word_description_asc'
  | 'word_description_desc'
  | 'word_id_asc'
  | 'word_id_desc'
  | 'word_isThumbnail_asc'
  | 'word_isThumbnail_desc'
  | 'word_isTranslate_asc'
  | 'word_isTranslate_desc'
  | 'word_isVocabulary_asc'
  | 'word_isVocabulary_desc'
  | 'word_mediaId_asc'
  | 'word_mediaId_desc'
  | 'word_thumbnailId_asc'
  | 'word_thumbnailId_desc'
  | 'word_topicId_asc'
  | 'word_topicId_desc'
  | 'word_userId_asc'
  | 'word_userId_desc'
  | 'word_vocabAndPhrase_asc'
  | 'word_vocabAndPhrase_desc'

export type ResourceOrderBy =
  | 'createdAt_asc'
  | 'createdAt_desc'
  | 'createdById_asc'
  | 'createdById_desc'
  | 'duration_asc'
  | 'duration_desc'
  | 'fileSize_asc'
  | 'fileSize_desc'
  | 'id_asc'
  | 'id_desc'
  | 'name_asc'
  | 'name_desc'
  | 'originalResourceId_asc'
  | 'originalResourceId_desc'
  | 'originalResource_createdAt_asc'
  | 'originalResource_createdAt_desc'
  | 'originalResource_createdById_asc'
  | 'originalResource_createdById_desc'
  | 'originalResource_duration_asc'
  | 'originalResource_duration_desc'
  | 'originalResource_ffprobe_asc'
  | 'originalResource_ffprobe_desc'
  | 'originalResource_fileSize_asc'
  | 'originalResource_fileSize_desc'
  | 'originalResource_id_asc'
  | 'originalResource_id_desc'
  | 'originalResource_name_asc'
  | 'originalResource_name_desc'
  | 'originalResource_originalResourceId_asc'
  | 'originalResource_originalResourceId_desc'
  | 'originalResource_originalResourceState_asc'
  | 'originalResource_originalResourceState_desc'
  | 'originalResource_thumbnailResourceId_asc'
  | 'originalResource_thumbnailResourceId_desc'
  | 'originalResource_type_asc'
  | 'originalResource_type_desc'
  | 'originalResource_url_asc'
  | 'originalResource_url_desc'
  | 'question_createdAt_asc'
  | 'question_createdAt_desc'
  | 'question_id_asc'
  | 'question_id_desc'
  | 'question_index_asc'
  | 'question_index_desc'
  | 'question_mediaId_asc'
  | 'question_mediaId_desc'
  | 'question_text_asc'
  | 'question_text_desc'
  | 'question_thumbnailId_asc'
  | 'question_thumbnailId_desc'
  | 'question_topicId_asc'
  | 'question_topicId_desc'
  | 'question_type_asc'
  | 'question_type_desc'
  | 'question_wordId_asc'
  | 'question_wordId_desc'
  | 'thumbnailResourceId_asc'
  | 'thumbnailResourceId_desc'
  | 'thumbnailResource_createdAt_asc'
  | 'thumbnailResource_createdAt_desc'
  | 'thumbnailResource_createdById_asc'
  | 'thumbnailResource_createdById_desc'
  | 'thumbnailResource_duration_asc'
  | 'thumbnailResource_duration_desc'
  | 'thumbnailResource_ffprobe_asc'
  | 'thumbnailResource_ffprobe_desc'
  | 'thumbnailResource_fileSize_asc'
  | 'thumbnailResource_fileSize_desc'
  | 'thumbnailResource_id_asc'
  | 'thumbnailResource_id_desc'
  | 'thumbnailResource_name_asc'
  | 'thumbnailResource_name_desc'
  | 'thumbnailResource_originalResourceId_asc'
  | 'thumbnailResource_originalResourceId_desc'
  | 'thumbnailResource_originalResourceState_asc'
  | 'thumbnailResource_originalResourceState_desc'
  | 'thumbnailResource_thumbnailResourceId_asc'
  | 'thumbnailResource_thumbnailResourceId_desc'
  | 'thumbnailResource_type_asc'
  | 'thumbnailResource_type_desc'
  | 'thumbnailResource_url_asc'
  | 'thumbnailResource_url_desc'
  | 'type_asc'
  | 'type_desc'
  | 'url_asc'
  | 'url_desc'
  | 'word_createdAt_asc'
  | 'word_createdAt_desc'
  | 'word_description_asc'
  | 'word_description_desc'
  | 'word_id_asc'
  | 'word_id_desc'
  | 'word_isThumbnail_asc'
  | 'word_isThumbnail_desc'
  | 'word_isTranslate_asc'
  | 'word_isTranslate_desc'
  | 'word_isVocabulary_asc'
  | 'word_isVocabulary_desc'
  | 'word_mediaId_asc'
  | 'word_mediaId_desc'
  | 'word_thumbnailId_asc'
  | 'word_thumbnailId_desc'
  | 'word_topicId_asc'
  | 'word_topicId_desc'
  | 'word_userId_asc'
  | 'word_userId_desc'
  | 'word_vocabAndPhrase_asc'
  | 'word_vocabAndPhrase_desc'

export type Respone = {
  __typename?: 'Respone'
  clientSecret?: Maybe<Scalars['String']['output']>
  customer?: Maybe<Scalars['String']['output']>
  paymentIntent?: Maybe<Scalars['String']['output']>
}

export type ResponeCustomer = {
  __typename?: 'ResponeCustomer'
  customer?: Maybe<Scalars['String']['output']>
}

export type ResponseType = {
  __typename?: 'ResponseType'
  percentCompleteByUser?: Maybe<Scalars['Float']['output']>
  totalCompletedUser?: Maybe<Scalars['Float']['output']>
  totalTopicByLevel?: Maybe<Scalars['Float']['output']>
}

export type RetrieveCustomersPaymentMethod = {
  customer?: InputMaybe<Scalars['ID']['input']>
  paymentMethod?: InputMaybe<Scalars['ID']['input']>
}

export type SearchProduct = {
  active?: InputMaybe<Scalars['Boolean']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  metadata?: InputMaybe<Scalars['Json']['input']>
  name?: InputMaybe<Scalars['String']['input']>
}

export type SearchQuery = {
  active?: InputMaybe<Scalars['Boolean']['input']>
  currency?: InputMaybe<Scalars['String']['input']>
  lookup_key?: InputMaybe<Scalars['String']['input']>
  metadata?: InputMaybe<Scalars['Json']['input']>
  product?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<PurchaseType>
}

export type Splash = {
  __typename?: 'Splash'
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  subTitle: Scalars['String']['output']
  thumbnail?: Maybe<Resource>
  thumbnailId: Scalars['ID']['output']
  title: Scalars['String']['output']
}

export type SplashFilter = {
  AND?: InputMaybe<Array<SplashFilter>>
  NOT?: InputMaybe<SplashFilter>
  OR?: InputMaybe<Array<SplashFilter>>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_ne?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>
  id?: InputMaybe<Scalars['ID']['input']>
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>
  id_ne?: InputMaybe<Scalars['ID']['input']>
  id_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  subTitle?: InputMaybe<Scalars['String']['input']>
  subTitle_endsWith?: InputMaybe<Scalars['String']['input']>
  subTitle_gt?: InputMaybe<Scalars['String']['input']>
  subTitle_gte?: InputMaybe<Scalars['String']['input']>
  subTitle_iLike?: InputMaybe<Scalars['String']['input']>
  subTitle_in?: InputMaybe<Array<Scalars['String']['input']>>
  subTitle_like?: InputMaybe<Scalars['String']['input']>
  subTitle_lt?: InputMaybe<Scalars['String']['input']>
  subTitle_lte?: InputMaybe<Scalars['String']['input']>
  subTitle_ne?: InputMaybe<Scalars['String']['input']>
  subTitle_notILike?: InputMaybe<Scalars['String']['input']>
  subTitle_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  subTitle_notLike?: InputMaybe<Scalars['String']['input']>
  subTitle_startsWith?: InputMaybe<Scalars['String']['input']>
  subTitle_substring?: InputMaybe<Scalars['String']['input']>
  thumbnailId?: InputMaybe<Scalars['ID']['input']>
  thumbnailId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  thumbnailId_ne?: InputMaybe<Scalars['ID']['input']>
  thumbnailId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  thumbnail_none?: InputMaybe<ResourceFilter>
  thumbnail_some?: InputMaybe<ResourceFilter>
  title?: InputMaybe<Scalars['String']['input']>
  title_endsWith?: InputMaybe<Scalars['String']['input']>
  title_gt?: InputMaybe<Scalars['String']['input']>
  title_gte?: InputMaybe<Scalars['String']['input']>
  title_iLike?: InputMaybe<Scalars['String']['input']>
  title_in?: InputMaybe<Array<Scalars['String']['input']>>
  title_like?: InputMaybe<Scalars['String']['input']>
  title_lt?: InputMaybe<Scalars['String']['input']>
  title_lte?: InputMaybe<Scalars['String']['input']>
  title_ne?: InputMaybe<Scalars['String']['input']>
  title_notILike?: InputMaybe<Scalars['String']['input']>
  title_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  title_notLike?: InputMaybe<Scalars['String']['input']>
  title_startsWith?: InputMaybe<Scalars['String']['input']>
  title_substring?: InputMaybe<Scalars['String']['input']>
}

export type SplashOrderBy =
  | 'createdAt_asc'
  | 'createdAt_desc'
  | 'id_asc'
  | 'id_desc'
  | 'subTitle_asc'
  | 'subTitle_desc'
  | 'thumbnailId_asc'
  | 'thumbnailId_desc'
  | 'thumbnail_createdAt_asc'
  | 'thumbnail_createdAt_desc'
  | 'thumbnail_createdById_asc'
  | 'thumbnail_createdById_desc'
  | 'thumbnail_duration_asc'
  | 'thumbnail_duration_desc'
  | 'thumbnail_ffprobe_asc'
  | 'thumbnail_ffprobe_desc'
  | 'thumbnail_fileSize_asc'
  | 'thumbnail_fileSize_desc'
  | 'thumbnail_id_asc'
  | 'thumbnail_id_desc'
  | 'thumbnail_name_asc'
  | 'thumbnail_name_desc'
  | 'thumbnail_originalResourceId_asc'
  | 'thumbnail_originalResourceId_desc'
  | 'thumbnail_originalResourceState_asc'
  | 'thumbnail_originalResourceState_desc'
  | 'thumbnail_thumbnailResourceId_asc'
  | 'thumbnail_thumbnailResourceId_desc'
  | 'thumbnail_type_asc'
  | 'thumbnail_type_desc'
  | 'thumbnail_url_asc'
  | 'thumbnail_url_desc'
  | 'title_asc'
  | 'title_desc'

export type Streak = {
  __typename?: 'Streak'
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  infor: Scalars['Json']['output']
  media?: Maybe<Resource>
  mediaId: Scalars['ID']['output']
  numberStreak: Scalars['Int']['output']
  streakInUser?: Maybe<StreakInUser>
  thumbnail?: Maybe<Resource>
  thumbnailId: Scalars['ID']['output']
}

export type StreakDaily = {
  __typename?: 'StreakDaily'
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type StreakDailyFilter = {
  AND?: InputMaybe<Array<StreakDailyFilter>>
  NOT?: InputMaybe<StreakDailyFilter>
  OR?: InputMaybe<Array<StreakDailyFilter>>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_ne?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>
  id?: InputMaybe<Scalars['ID']['input']>
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>
  id_ne?: InputMaybe<Scalars['ID']['input']>
  id_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  name?: InputMaybe<Scalars['String']['input']>
  name_endsWith?: InputMaybe<Scalars['String']['input']>
  name_gt?: InputMaybe<Scalars['String']['input']>
  name_gte?: InputMaybe<Scalars['String']['input']>
  name_iLike?: InputMaybe<Scalars['String']['input']>
  name_in?: InputMaybe<Array<Scalars['String']['input']>>
  name_like?: InputMaybe<Scalars['String']['input']>
  name_lt?: InputMaybe<Scalars['String']['input']>
  name_lte?: InputMaybe<Scalars['String']['input']>
  name_ne?: InputMaybe<Scalars['String']['input']>
  name_notILike?: InputMaybe<Scalars['String']['input']>
  name_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  name_notLike?: InputMaybe<Scalars['String']['input']>
  name_startsWith?: InputMaybe<Scalars['String']['input']>
  name_substring?: InputMaybe<Scalars['String']['input']>
}

export type StreakDailyOrderBy =
  | 'createdAt_asc'
  | 'createdAt_desc'
  | 'id_asc'
  | 'id_desc'
  | 'name_asc'
  | 'name_desc'

export type StreakFilter = {
  AND?: InputMaybe<Array<StreakFilter>>
  NOT?: InputMaybe<StreakFilter>
  OR?: InputMaybe<Array<StreakFilter>>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_ne?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>
  id?: InputMaybe<Scalars['ID']['input']>
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>
  id_ne?: InputMaybe<Scalars['ID']['input']>
  id_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  mediaId?: InputMaybe<Scalars['ID']['input']>
  mediaId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  mediaId_ne?: InputMaybe<Scalars['ID']['input']>
  mediaId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  media_none?: InputMaybe<ResourceFilter>
  media_some?: InputMaybe<ResourceFilter>
  numberStreak?: InputMaybe<Scalars['Int']['input']>
  numberStreak_gt?: InputMaybe<Scalars['Int']['input']>
  numberStreak_gte?: InputMaybe<Scalars['Int']['input']>
  numberStreak_in?: InputMaybe<Array<Scalars['Int']['input']>>
  numberStreak_lt?: InputMaybe<Scalars['Int']['input']>
  numberStreak_lte?: InputMaybe<Scalars['Int']['input']>
  numberStreak_ne?: InputMaybe<Scalars['Int']['input']>
  numberStreak_notIn?: InputMaybe<Array<Scalars['Int']['input']>>
  streakInUser_none?: InputMaybe<StreakInUserFilter>
  streakInUser_some?: InputMaybe<StreakInUserFilter>
  thumbnailId?: InputMaybe<Scalars['ID']['input']>
  thumbnailId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  thumbnailId_ne?: InputMaybe<Scalars['ID']['input']>
  thumbnailId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  thumbnail_none?: InputMaybe<ResourceFilter>
  thumbnail_some?: InputMaybe<ResourceFilter>
}

export type StreakInUser = {
  __typename?: 'StreakInUser'
  countStreak: Scalars['Int']['output']
  createdAt: Scalars['DateTime']['output']
  dateStreak: Scalars['Json']['output']
  highestStreak: Scalars['Int']['output']
  id: Scalars['ID']['output']
  lastDate: Scalars['String']['output']
  streak?: Maybe<Streak>
  streakId: Scalars['ID']['output']
  user?: Maybe<User>
  userId: Scalars['ID']['output']
}

export type StreakInUserFilter = {
  AND?: InputMaybe<Array<StreakInUserFilter>>
  NOT?: InputMaybe<StreakInUserFilter>
  OR?: InputMaybe<Array<StreakInUserFilter>>
  countStreak?: InputMaybe<Scalars['Int']['input']>
  countStreak_gt?: InputMaybe<Scalars['Int']['input']>
  countStreak_gte?: InputMaybe<Scalars['Int']['input']>
  countStreak_in?: InputMaybe<Array<Scalars['Int']['input']>>
  countStreak_lt?: InputMaybe<Scalars['Int']['input']>
  countStreak_lte?: InputMaybe<Scalars['Int']['input']>
  countStreak_ne?: InputMaybe<Scalars['Int']['input']>
  countStreak_notIn?: InputMaybe<Array<Scalars['Int']['input']>>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_ne?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>
  highestStreak?: InputMaybe<Scalars['Int']['input']>
  highestStreak_gt?: InputMaybe<Scalars['Int']['input']>
  highestStreak_gte?: InputMaybe<Scalars['Int']['input']>
  highestStreak_in?: InputMaybe<Array<Scalars['Int']['input']>>
  highestStreak_lt?: InputMaybe<Scalars['Int']['input']>
  highestStreak_lte?: InputMaybe<Scalars['Int']['input']>
  highestStreak_ne?: InputMaybe<Scalars['Int']['input']>
  highestStreak_notIn?: InputMaybe<Array<Scalars['Int']['input']>>
  id?: InputMaybe<Scalars['ID']['input']>
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>
  id_ne?: InputMaybe<Scalars['ID']['input']>
  id_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  lastDate?: InputMaybe<Scalars['String']['input']>
  lastDate_endsWith?: InputMaybe<Scalars['String']['input']>
  lastDate_gt?: InputMaybe<Scalars['String']['input']>
  lastDate_gte?: InputMaybe<Scalars['String']['input']>
  lastDate_iLike?: InputMaybe<Scalars['String']['input']>
  lastDate_in?: InputMaybe<Array<Scalars['String']['input']>>
  lastDate_like?: InputMaybe<Scalars['String']['input']>
  lastDate_lt?: InputMaybe<Scalars['String']['input']>
  lastDate_lte?: InputMaybe<Scalars['String']['input']>
  lastDate_ne?: InputMaybe<Scalars['String']['input']>
  lastDate_notILike?: InputMaybe<Scalars['String']['input']>
  lastDate_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  lastDate_notLike?: InputMaybe<Scalars['String']['input']>
  lastDate_startsWith?: InputMaybe<Scalars['String']['input']>
  lastDate_substring?: InputMaybe<Scalars['String']['input']>
  streakId?: InputMaybe<Scalars['ID']['input']>
  streakId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  streakId_ne?: InputMaybe<Scalars['ID']['input']>
  streakId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  streak_none?: InputMaybe<StreakFilter>
  streak_some?: InputMaybe<StreakFilter>
  userId?: InputMaybe<Scalars['ID']['input']>
  userId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  userId_ne?: InputMaybe<Scalars['ID']['input']>
  userId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  user_none?: InputMaybe<UserFilter>
  user_some?: InputMaybe<UserFilter>
}

export type StreakInUserOrderBy =
  | 'countStreak_asc'
  | 'countStreak_desc'
  | 'createdAt_asc'
  | 'createdAt_desc'
  | 'highestStreak_asc'
  | 'highestStreak_desc'
  | 'id_asc'
  | 'id_desc'
  | 'lastDate_asc'
  | 'lastDate_desc'
  | 'streakId_asc'
  | 'streakId_desc'
  | 'streak_createdAt_asc'
  | 'streak_createdAt_desc'
  | 'streak_id_asc'
  | 'streak_id_desc'
  | 'streak_infor_asc'
  | 'streak_infor_desc'
  | 'streak_mediaId_asc'
  | 'streak_mediaId_desc'
  | 'streak_numberStreak_asc'
  | 'streak_numberStreak_desc'
  | 'streak_thumbnailId_asc'
  | 'streak_thumbnailId_desc'
  | 'userId_asc'
  | 'userId_desc'
  | 'user_aiTutorId_asc'
  | 'user_aiTutorId_desc'
  | 'user_autoPlay_asc'
  | 'user_autoPlay_desc'
  | 'user_createdAt_asc'
  | 'user_createdAt_desc'
  | 'user_customer_asc'
  | 'user_customer_desc'
  | 'user_deactivate_asc'
  | 'user_deactivate_desc'
  | 'user_email_asc'
  | 'user_email_desc'
  | 'user_hashedPassword_asc'
  | 'user_hashedPassword_desc'
  | 'user_id_asc'
  | 'user_id_desc'
  | 'user_isAdmin_asc'
  | 'user_isAdmin_desc'
  | 'user_languageLearn_asc'
  | 'user_languageLearn_desc'
  | 'user_lastUsedDate_asc'
  | 'user_lastUsedDate_desc'
  | 'user_level_asc'
  | 'user_level_desc'
  | 'user_name_asc'
  | 'user_name_desc'
  | 'user_nativeLanguage_asc'
  | 'user_nativeLanguage_desc'
  | 'user_repair_asc'
  | 'user_repair_desc'
  | 'user_speed_asc'
  | 'user_speed_desc'
  | 'user_thumbnailId_asc'
  | 'user_thumbnailId_desc'

export type StreakOrderBy =
  | 'createdAt_asc'
  | 'createdAt_desc'
  | 'id_asc'
  | 'id_desc'
  | 'mediaId_asc'
  | 'mediaId_desc'
  | 'media_createdAt_asc'
  | 'media_createdAt_desc'
  | 'media_createdById_asc'
  | 'media_createdById_desc'
  | 'media_duration_asc'
  | 'media_duration_desc'
  | 'media_ffprobe_asc'
  | 'media_ffprobe_desc'
  | 'media_fileSize_asc'
  | 'media_fileSize_desc'
  | 'media_id_asc'
  | 'media_id_desc'
  | 'media_name_asc'
  | 'media_name_desc'
  | 'media_originalResourceId_asc'
  | 'media_originalResourceId_desc'
  | 'media_originalResourceState_asc'
  | 'media_originalResourceState_desc'
  | 'media_thumbnailResourceId_asc'
  | 'media_thumbnailResourceId_desc'
  | 'media_type_asc'
  | 'media_type_desc'
  | 'media_url_asc'
  | 'media_url_desc'
  | 'numberStreak_asc'
  | 'numberStreak_desc'
  | 'streakInUser_countStreak_asc'
  | 'streakInUser_countStreak_desc'
  | 'streakInUser_createdAt_asc'
  | 'streakInUser_createdAt_desc'
  | 'streakInUser_dateStreak_asc'
  | 'streakInUser_dateStreak_desc'
  | 'streakInUser_highestStreak_asc'
  | 'streakInUser_highestStreak_desc'
  | 'streakInUser_id_asc'
  | 'streakInUser_id_desc'
  | 'streakInUser_lastDate_asc'
  | 'streakInUser_lastDate_desc'
  | 'streakInUser_streakId_asc'
  | 'streakInUser_streakId_desc'
  | 'streakInUser_userId_asc'
  | 'streakInUser_userId_desc'
  | 'thumbnailId_asc'
  | 'thumbnailId_desc'
  | 'thumbnail_createdAt_asc'
  | 'thumbnail_createdAt_desc'
  | 'thumbnail_createdById_asc'
  | 'thumbnail_createdById_desc'
  | 'thumbnail_duration_asc'
  | 'thumbnail_duration_desc'
  | 'thumbnail_ffprobe_asc'
  | 'thumbnail_ffprobe_desc'
  | 'thumbnail_fileSize_asc'
  | 'thumbnail_fileSize_desc'
  | 'thumbnail_id_asc'
  | 'thumbnail_id_desc'
  | 'thumbnail_name_asc'
  | 'thumbnail_name_desc'
  | 'thumbnail_originalResourceId_asc'
  | 'thumbnail_originalResourceId_desc'
  | 'thumbnail_originalResourceState_asc'
  | 'thumbnail_originalResourceState_desc'
  | 'thumbnail_thumbnailResourceId_asc'
  | 'thumbnail_thumbnailResourceId_desc'
  | 'thumbnail_type_asc'
  | 'thumbnail_type_desc'
  | 'thumbnail_url_asc'
  | 'thumbnail_url_desc'

export type SubTask = {
  description?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  isPassed?: InputMaybe<Scalars['Boolean']['input']>
  name?: InputMaybe<Scalars['String']['input']>
}

export type Task = {
  data?: InputMaybe<Array<InputMaybe<Data>>>
}

export type TaskInput = {
  description: Scalars['String']['input']
  id: Scalars['String']['input']
  isPassed?: InputMaybe<Scalars['Boolean']['input']>
  name: Scalars['String']['input']
}

export type TaskItemInput = {
  description: Scalars['String']['input']
  id: Scalars['String']['input']
  isPassed?: InputMaybe<Scalars['Boolean']['input']>
  name: Scalars['String']['input']
  subTask: Array<InputMaybe<TaskInput>>
}

export type Tasks = {
  description?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['String']['input']>
  isPassed?: InputMaybe<Scalars['Boolean']['input']>
  name?: InputMaybe<Scalars['String']['input']>
}

export type TasksItem = {
  description?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['String']['input']>
  isPassed?: InputMaybe<Scalars['Boolean']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  subTask?: InputMaybe<Array<InputMaybe<Tasks>>>
}

export type TaxBehavior = 'exclusive' | 'inclusive' | 'unspecified'

export type TestCreateMessage = {
  AITutor?: InputMaybe<AiTutorMessage>
  botRole?: InputMaybe<Scalars['String']['input']>
  content?: InputMaybe<Scalars['String']['input']>
  scenario?: InputMaybe<Scalars['String']['input']>
  tasks?: InputMaybe<Array<InputMaybe<TasksItem>>>
  topicInUserId: Scalars['ID']['input']
  topic_chat?: InputMaybe<Scalars['String']['input']>
  userRole?: InputMaybe<Scalars['String']['input']>
}

export type Ticket = {
  __typename?: 'Ticket'
  createdAt: Scalars['DateTime']['output']
  data: Scalars['Json']['output']
  email: Scalars['String']['output']
  id: Scalars['ID']['output']
  invitationExpired: Scalars['Boolean']['output']
  resentAt: Scalars['DateTime']['output']
  type: Scalars['String']['output']
}

export type TicketFilter = {
  AND?: InputMaybe<Array<TicketFilter>>
  NOT?: InputMaybe<TicketFilter>
  OR?: InputMaybe<Array<TicketFilter>>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_ne?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>
  email?: InputMaybe<Scalars['String']['input']>
  email_endsWith?: InputMaybe<Scalars['String']['input']>
  email_gt?: InputMaybe<Scalars['String']['input']>
  email_gte?: InputMaybe<Scalars['String']['input']>
  email_iLike?: InputMaybe<Scalars['String']['input']>
  email_in?: InputMaybe<Array<Scalars['String']['input']>>
  email_like?: InputMaybe<Scalars['String']['input']>
  email_lt?: InputMaybe<Scalars['String']['input']>
  email_lte?: InputMaybe<Scalars['String']['input']>
  email_ne?: InputMaybe<Scalars['String']['input']>
  email_notILike?: InputMaybe<Scalars['String']['input']>
  email_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  email_notLike?: InputMaybe<Scalars['String']['input']>
  email_startsWith?: InputMaybe<Scalars['String']['input']>
  email_substring?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>
  id_ne?: InputMaybe<Scalars['ID']['input']>
  id_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  resentAt?: InputMaybe<Scalars['DateTime']['input']>
  resentAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  resentAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  resentAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>
  resentAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  resentAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  resentAt_ne?: InputMaybe<Scalars['DateTime']['input']>
  resentAt_notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>
  type?: InputMaybe<Scalars['String']['input']>
  type_endsWith?: InputMaybe<Scalars['String']['input']>
  type_gt?: InputMaybe<Scalars['String']['input']>
  type_gte?: InputMaybe<Scalars['String']['input']>
  type_iLike?: InputMaybe<Scalars['String']['input']>
  type_in?: InputMaybe<Array<Scalars['String']['input']>>
  type_like?: InputMaybe<Scalars['String']['input']>
  type_lt?: InputMaybe<Scalars['String']['input']>
  type_lte?: InputMaybe<Scalars['String']['input']>
  type_ne?: InputMaybe<Scalars['String']['input']>
  type_notILike?: InputMaybe<Scalars['String']['input']>
  type_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  type_notLike?: InputMaybe<Scalars['String']['input']>
  type_startsWith?: InputMaybe<Scalars['String']['input']>
  type_substring?: InputMaybe<Scalars['String']['input']>
}

export type Tier = {
  flat_amount?: InputMaybe<Scalars['Int']['input']>
  flat_amount_decimal?: InputMaybe<Scalars['String']['input']>
  unit_amount?: InputMaybe<Scalars['Int']['input']>
  unit_amount_decimal?: InputMaybe<Scalars['String']['input']>
  up_to?: InputMaybe<Scalars['Int']['input']>
}

export type Topic = {
  __typename?: 'Topic'
  aiTutorRole: Scalars['String']['output']
  character?: Maybe<Resource>
  characterId: Scalars['ID']['output']
  createdAt: Scalars['DateTime']['output']
  description: Scalars['String']['output']
  id: Scalars['ID']['output']
  languageTopic: Scalars['String']['output']
  level: Scalars['String']['output']
  media?: Maybe<Resource>
  mediaId: Scalars['ID']['output']
  name: Scalars['String']['output']
  ordinalNumber: Scalars['Int']['output']
  scenario: Scalars['String']['output']
  status: Scalars['String']['output']
  task: Scalars['Json']['output']
  topicInCategory?: Maybe<TopicInCategory>
  topicInUser?: Maybe<TopicInUser>
  user?: Maybe<User>
  userAnswer?: Maybe<UserAnswer>
  userId: Scalars['ID']['output']
  userRole: Scalars['String']['output']
  wordInUser?: Maybe<WordInUser>
}

export type TopicFilter = {
  AND?: InputMaybe<Array<TopicFilter>>
  NOT?: InputMaybe<TopicFilter>
  OR?: InputMaybe<Array<TopicFilter>>
  aiTutorRole?: InputMaybe<Scalars['String']['input']>
  aiTutorRole_endsWith?: InputMaybe<Scalars['String']['input']>
  aiTutorRole_gt?: InputMaybe<Scalars['String']['input']>
  aiTutorRole_gte?: InputMaybe<Scalars['String']['input']>
  aiTutorRole_iLike?: InputMaybe<Scalars['String']['input']>
  aiTutorRole_in?: InputMaybe<Array<Scalars['String']['input']>>
  aiTutorRole_like?: InputMaybe<Scalars['String']['input']>
  aiTutorRole_lt?: InputMaybe<Scalars['String']['input']>
  aiTutorRole_lte?: InputMaybe<Scalars['String']['input']>
  aiTutorRole_ne?: InputMaybe<Scalars['String']['input']>
  aiTutorRole_notILike?: InputMaybe<Scalars['String']['input']>
  aiTutorRole_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  aiTutorRole_notLike?: InputMaybe<Scalars['String']['input']>
  aiTutorRole_startsWith?: InputMaybe<Scalars['String']['input']>
  aiTutorRole_substring?: InputMaybe<Scalars['String']['input']>
  characterId?: InputMaybe<Scalars['ID']['input']>
  characterId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  characterId_ne?: InputMaybe<Scalars['ID']['input']>
  characterId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  character_none?: InputMaybe<ResourceFilter>
  character_some?: InputMaybe<ResourceFilter>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_ne?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>
  description?: InputMaybe<Scalars['String']['input']>
  description_endsWith?: InputMaybe<Scalars['String']['input']>
  description_gt?: InputMaybe<Scalars['String']['input']>
  description_gte?: InputMaybe<Scalars['String']['input']>
  description_iLike?: InputMaybe<Scalars['String']['input']>
  description_in?: InputMaybe<Array<Scalars['String']['input']>>
  description_like?: InputMaybe<Scalars['String']['input']>
  description_lt?: InputMaybe<Scalars['String']['input']>
  description_lte?: InputMaybe<Scalars['String']['input']>
  description_ne?: InputMaybe<Scalars['String']['input']>
  description_notILike?: InputMaybe<Scalars['String']['input']>
  description_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  description_notLike?: InputMaybe<Scalars['String']['input']>
  description_startsWith?: InputMaybe<Scalars['String']['input']>
  description_substring?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>
  id_ne?: InputMaybe<Scalars['ID']['input']>
  id_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  languageTopic?: InputMaybe<Scalars['String']['input']>
  languageTopic_endsWith?: InputMaybe<Scalars['String']['input']>
  languageTopic_gt?: InputMaybe<Scalars['String']['input']>
  languageTopic_gte?: InputMaybe<Scalars['String']['input']>
  languageTopic_iLike?: InputMaybe<Scalars['String']['input']>
  languageTopic_in?: InputMaybe<Array<Scalars['String']['input']>>
  languageTopic_like?: InputMaybe<Scalars['String']['input']>
  languageTopic_lt?: InputMaybe<Scalars['String']['input']>
  languageTopic_lte?: InputMaybe<Scalars['String']['input']>
  languageTopic_ne?: InputMaybe<Scalars['String']['input']>
  languageTopic_notILike?: InputMaybe<Scalars['String']['input']>
  languageTopic_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  languageTopic_notLike?: InputMaybe<Scalars['String']['input']>
  languageTopic_startsWith?: InputMaybe<Scalars['String']['input']>
  languageTopic_substring?: InputMaybe<Scalars['String']['input']>
  level?: InputMaybe<Scalars['String']['input']>
  level_endsWith?: InputMaybe<Scalars['String']['input']>
  level_gt?: InputMaybe<Scalars['String']['input']>
  level_gte?: InputMaybe<Scalars['String']['input']>
  level_iLike?: InputMaybe<Scalars['String']['input']>
  level_in?: InputMaybe<Array<Scalars['String']['input']>>
  level_like?: InputMaybe<Scalars['String']['input']>
  level_lt?: InputMaybe<Scalars['String']['input']>
  level_lte?: InputMaybe<Scalars['String']['input']>
  level_ne?: InputMaybe<Scalars['String']['input']>
  level_notILike?: InputMaybe<Scalars['String']['input']>
  level_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  level_notLike?: InputMaybe<Scalars['String']['input']>
  level_startsWith?: InputMaybe<Scalars['String']['input']>
  level_substring?: InputMaybe<Scalars['String']['input']>
  mediaId?: InputMaybe<Scalars['ID']['input']>
  mediaId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  mediaId_ne?: InputMaybe<Scalars['ID']['input']>
  mediaId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  media_none?: InputMaybe<ResourceFilter>
  media_some?: InputMaybe<ResourceFilter>
  name?: InputMaybe<Scalars['String']['input']>
  name_endsWith?: InputMaybe<Scalars['String']['input']>
  name_gt?: InputMaybe<Scalars['String']['input']>
  name_gte?: InputMaybe<Scalars['String']['input']>
  name_iLike?: InputMaybe<Scalars['String']['input']>
  name_in?: InputMaybe<Array<Scalars['String']['input']>>
  name_like?: InputMaybe<Scalars['String']['input']>
  name_lt?: InputMaybe<Scalars['String']['input']>
  name_lte?: InputMaybe<Scalars['String']['input']>
  name_ne?: InputMaybe<Scalars['String']['input']>
  name_notILike?: InputMaybe<Scalars['String']['input']>
  name_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  name_notLike?: InputMaybe<Scalars['String']['input']>
  name_startsWith?: InputMaybe<Scalars['String']['input']>
  name_substring?: InputMaybe<Scalars['String']['input']>
  ordinalNumber?: InputMaybe<Scalars['Int']['input']>
  ordinalNumber_gt?: InputMaybe<Scalars['Int']['input']>
  ordinalNumber_gte?: InputMaybe<Scalars['Int']['input']>
  ordinalNumber_in?: InputMaybe<Array<Scalars['Int']['input']>>
  ordinalNumber_lt?: InputMaybe<Scalars['Int']['input']>
  ordinalNumber_lte?: InputMaybe<Scalars['Int']['input']>
  ordinalNumber_ne?: InputMaybe<Scalars['Int']['input']>
  ordinalNumber_notIn?: InputMaybe<Array<Scalars['Int']['input']>>
  scenario?: InputMaybe<Scalars['String']['input']>
  scenario_endsWith?: InputMaybe<Scalars['String']['input']>
  scenario_gt?: InputMaybe<Scalars['String']['input']>
  scenario_gte?: InputMaybe<Scalars['String']['input']>
  scenario_iLike?: InputMaybe<Scalars['String']['input']>
  scenario_in?: InputMaybe<Array<Scalars['String']['input']>>
  scenario_like?: InputMaybe<Scalars['String']['input']>
  scenario_lt?: InputMaybe<Scalars['String']['input']>
  scenario_lte?: InputMaybe<Scalars['String']['input']>
  scenario_ne?: InputMaybe<Scalars['String']['input']>
  scenario_notILike?: InputMaybe<Scalars['String']['input']>
  scenario_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  scenario_notLike?: InputMaybe<Scalars['String']['input']>
  scenario_startsWith?: InputMaybe<Scalars['String']['input']>
  scenario_substring?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<Scalars['String']['input']>
  status_endsWith?: InputMaybe<Scalars['String']['input']>
  status_gt?: InputMaybe<Scalars['String']['input']>
  status_gte?: InputMaybe<Scalars['String']['input']>
  status_iLike?: InputMaybe<Scalars['String']['input']>
  status_in?: InputMaybe<Array<Scalars['String']['input']>>
  status_like?: InputMaybe<Scalars['String']['input']>
  status_lt?: InputMaybe<Scalars['String']['input']>
  status_lte?: InputMaybe<Scalars['String']['input']>
  status_ne?: InputMaybe<Scalars['String']['input']>
  status_notILike?: InputMaybe<Scalars['String']['input']>
  status_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  status_notLike?: InputMaybe<Scalars['String']['input']>
  status_startsWith?: InputMaybe<Scalars['String']['input']>
  status_substring?: InputMaybe<Scalars['String']['input']>
  topicInCategory_none?: InputMaybe<TopicInCategoryFilter>
  topicInCategory_some?: InputMaybe<TopicInCategoryFilter>
  topicInUser_none?: InputMaybe<TopicInUserFilter>
  topicInUser_some?: InputMaybe<TopicInUserFilter>
  userAnswer_none?: InputMaybe<UserAnswerFilter>
  userAnswer_some?: InputMaybe<UserAnswerFilter>
  userId?: InputMaybe<Scalars['ID']['input']>
  userId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  userId_ne?: InputMaybe<Scalars['ID']['input']>
  userId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  userRole?: InputMaybe<Scalars['String']['input']>
  userRole_endsWith?: InputMaybe<Scalars['String']['input']>
  userRole_gt?: InputMaybe<Scalars['String']['input']>
  userRole_gte?: InputMaybe<Scalars['String']['input']>
  userRole_iLike?: InputMaybe<Scalars['String']['input']>
  userRole_in?: InputMaybe<Array<Scalars['String']['input']>>
  userRole_like?: InputMaybe<Scalars['String']['input']>
  userRole_lt?: InputMaybe<Scalars['String']['input']>
  userRole_lte?: InputMaybe<Scalars['String']['input']>
  userRole_ne?: InputMaybe<Scalars['String']['input']>
  userRole_notILike?: InputMaybe<Scalars['String']['input']>
  userRole_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  userRole_notLike?: InputMaybe<Scalars['String']['input']>
  userRole_startsWith?: InputMaybe<Scalars['String']['input']>
  userRole_substring?: InputMaybe<Scalars['String']['input']>
  user_none?: InputMaybe<UserFilter>
  user_some?: InputMaybe<UserFilter>
  wordInUser_none?: InputMaybe<WordInUserFilter>
  wordInUser_some?: InputMaybe<WordInUserFilter>
}

export type TopicInCategory = {
  __typename?: 'TopicInCategory'
  category?: Maybe<Category>
  categoryId: Scalars['ID']['output']
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  topic?: Maybe<Topic>
  topicId: Scalars['ID']['output']
}

export type TopicInCategoryFilter = {
  AND?: InputMaybe<Array<TopicInCategoryFilter>>
  NOT?: InputMaybe<TopicInCategoryFilter>
  OR?: InputMaybe<Array<TopicInCategoryFilter>>
  categoryId?: InputMaybe<Scalars['ID']['input']>
  categoryId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  categoryId_ne?: InputMaybe<Scalars['ID']['input']>
  categoryId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  category_none?: InputMaybe<CategoryFilter>
  category_some?: InputMaybe<CategoryFilter>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_ne?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>
  id?: InputMaybe<Scalars['ID']['input']>
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>
  id_ne?: InputMaybe<Scalars['ID']['input']>
  id_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  topicId?: InputMaybe<Scalars['ID']['input']>
  topicId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  topicId_ne?: InputMaybe<Scalars['ID']['input']>
  topicId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  topic_none?: InputMaybe<TopicFilter>
  topic_some?: InputMaybe<TopicFilter>
}

export type TopicInCategoryOrderBy =
  | 'categoryId_asc'
  | 'categoryId_desc'
  | 'category_createdAt_asc'
  | 'category_createdAt_desc'
  | 'category_id_asc'
  | 'category_id_desc'
  | 'category_tagName_asc'
  | 'category_tagName_desc'
  | 'createdAt_asc'
  | 'createdAt_desc'
  | 'id_asc'
  | 'id_desc'
  | 'topicId_asc'
  | 'topicId_desc'
  | 'topic_aiTutorRole_asc'
  | 'topic_aiTutorRole_desc'
  | 'topic_characterId_asc'
  | 'topic_characterId_desc'
  | 'topic_createdAt_asc'
  | 'topic_createdAt_desc'
  | 'topic_description_asc'
  | 'topic_description_desc'
  | 'topic_id_asc'
  | 'topic_id_desc'
  | 'topic_languageTopic_asc'
  | 'topic_languageTopic_desc'
  | 'topic_level_asc'
  | 'topic_level_desc'
  | 'topic_mediaId_asc'
  | 'topic_mediaId_desc'
  | 'topic_name_asc'
  | 'topic_name_desc'
  | 'topic_ordinalNumber_asc'
  | 'topic_ordinalNumber_desc'
  | 'topic_scenario_asc'
  | 'topic_scenario_desc'
  | 'topic_status_asc'
  | 'topic_status_desc'
  | 'topic_task_asc'
  | 'topic_task_desc'
  | 'topic_userId_asc'
  | 'topic_userId_desc'
  | 'topic_userRole_asc'
  | 'topic_userRole_desc'

export type TopicInUser = {
  __typename?: 'TopicInUser'
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  learning: Scalars['Boolean']['output']
  ordinalNumber: Scalars['Int']['output']
  percent: Scalars['Int']['output']
  task: Scalars['Json']['output']
  topic?: Maybe<Topic>
  topicId: Scalars['ID']['output']
  unlockTopic: Scalars['Boolean']['output']
  userLearn?: Maybe<User>
  userLearnId: Scalars['ID']['output']
}

export type TopicInUserFilter = {
  AND?: InputMaybe<Array<TopicInUserFilter>>
  NOT?: InputMaybe<TopicInUserFilter>
  OR?: InputMaybe<Array<TopicInUserFilter>>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_ne?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>
  id?: InputMaybe<Scalars['ID']['input']>
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>
  id_ne?: InputMaybe<Scalars['ID']['input']>
  id_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  learning?: InputMaybe<Scalars['Boolean']['input']>
  learning_ne?: InputMaybe<Scalars['Boolean']['input']>
  ordinalNumber?: InputMaybe<Scalars['Int']['input']>
  ordinalNumber_gt?: InputMaybe<Scalars['Int']['input']>
  ordinalNumber_gte?: InputMaybe<Scalars['Int']['input']>
  ordinalNumber_in?: InputMaybe<Array<Scalars['Int']['input']>>
  ordinalNumber_lt?: InputMaybe<Scalars['Int']['input']>
  ordinalNumber_lte?: InputMaybe<Scalars['Int']['input']>
  ordinalNumber_ne?: InputMaybe<Scalars['Int']['input']>
  ordinalNumber_notIn?: InputMaybe<Array<Scalars['Int']['input']>>
  percent?: InputMaybe<Scalars['Int']['input']>
  percent_gt?: InputMaybe<Scalars['Int']['input']>
  percent_gte?: InputMaybe<Scalars['Int']['input']>
  percent_in?: InputMaybe<Array<Scalars['Int']['input']>>
  percent_lt?: InputMaybe<Scalars['Int']['input']>
  percent_lte?: InputMaybe<Scalars['Int']['input']>
  percent_ne?: InputMaybe<Scalars['Int']['input']>
  percent_notIn?: InputMaybe<Array<Scalars['Int']['input']>>
  topicId?: InputMaybe<Scalars['ID']['input']>
  topicId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  topicId_ne?: InputMaybe<Scalars['ID']['input']>
  topicId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  topic_none?: InputMaybe<TopicFilter>
  topic_some?: InputMaybe<TopicFilter>
  unlockTopic?: InputMaybe<Scalars['Boolean']['input']>
  unlockTopic_ne?: InputMaybe<Scalars['Boolean']['input']>
  userLearnId?: InputMaybe<Scalars['ID']['input']>
  userLearnId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  userLearnId_ne?: InputMaybe<Scalars['ID']['input']>
  userLearnId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  userLearn_none?: InputMaybe<UserFilter>
  userLearn_some?: InputMaybe<UserFilter>
}

export type TopicInUserOrderBy =
  | 'createdAt_asc'
  | 'createdAt_desc'
  | 'id_asc'
  | 'id_desc'
  | 'learning_asc'
  | 'learning_desc'
  | 'ordinalNumber_asc'
  | 'ordinalNumber_desc'
  | 'percent_asc'
  | 'percent_desc'
  | 'topicId_asc'
  | 'topicId_desc'
  | 'topic_aiTutorRole_asc'
  | 'topic_aiTutorRole_desc'
  | 'topic_characterId_asc'
  | 'topic_characterId_desc'
  | 'topic_createdAt_asc'
  | 'topic_createdAt_desc'
  | 'topic_description_asc'
  | 'topic_description_desc'
  | 'topic_id_asc'
  | 'topic_id_desc'
  | 'topic_languageTopic_asc'
  | 'topic_languageTopic_desc'
  | 'topic_level_asc'
  | 'topic_level_desc'
  | 'topic_mediaId_asc'
  | 'topic_mediaId_desc'
  | 'topic_name_asc'
  | 'topic_name_desc'
  | 'topic_ordinalNumber_asc'
  | 'topic_ordinalNumber_desc'
  | 'topic_scenario_asc'
  | 'topic_scenario_desc'
  | 'topic_status_asc'
  | 'topic_status_desc'
  | 'topic_task_asc'
  | 'topic_task_desc'
  | 'topic_userId_asc'
  | 'topic_userId_desc'
  | 'topic_userRole_asc'
  | 'topic_userRole_desc'
  | 'unlockTopic_asc'
  | 'unlockTopic_desc'
  | 'userLearnId_asc'
  | 'userLearnId_desc'
  | 'userLearn_aiTutorId_asc'
  | 'userLearn_aiTutorId_desc'
  | 'userLearn_autoPlay_asc'
  | 'userLearn_autoPlay_desc'
  | 'userLearn_createdAt_asc'
  | 'userLearn_createdAt_desc'
  | 'userLearn_customer_asc'
  | 'userLearn_customer_desc'
  | 'userLearn_deactivate_asc'
  | 'userLearn_deactivate_desc'
  | 'userLearn_email_asc'
  | 'userLearn_email_desc'
  | 'userLearn_hashedPassword_asc'
  | 'userLearn_hashedPassword_desc'
  | 'userLearn_id_asc'
  | 'userLearn_id_desc'
  | 'userLearn_isAdmin_asc'
  | 'userLearn_isAdmin_desc'
  | 'userLearn_languageLearn_asc'
  | 'userLearn_languageLearn_desc'
  | 'userLearn_lastUsedDate_asc'
  | 'userLearn_lastUsedDate_desc'
  | 'userLearn_level_asc'
  | 'userLearn_level_desc'
  | 'userLearn_name_asc'
  | 'userLearn_name_desc'
  | 'userLearn_nativeLanguage_asc'
  | 'userLearn_nativeLanguage_desc'
  | 'userLearn_repair_asc'
  | 'userLearn_repair_desc'
  | 'userLearn_speed_asc'
  | 'userLearn_speed_desc'
  | 'userLearn_thumbnailId_asc'
  | 'userLearn_thumbnailId_desc'

export type TopicOrderBy =
  | 'aiTutorRole_asc'
  | 'aiTutorRole_desc'
  | 'characterId_asc'
  | 'characterId_desc'
  | 'character_createdAt_asc'
  | 'character_createdAt_desc'
  | 'character_createdById_asc'
  | 'character_createdById_desc'
  | 'character_duration_asc'
  | 'character_duration_desc'
  | 'character_ffprobe_asc'
  | 'character_ffprobe_desc'
  | 'character_fileSize_asc'
  | 'character_fileSize_desc'
  | 'character_id_asc'
  | 'character_id_desc'
  | 'character_name_asc'
  | 'character_name_desc'
  | 'character_originalResourceId_asc'
  | 'character_originalResourceId_desc'
  | 'character_originalResourceState_asc'
  | 'character_originalResourceState_desc'
  | 'character_thumbnailResourceId_asc'
  | 'character_thumbnailResourceId_desc'
  | 'character_type_asc'
  | 'character_type_desc'
  | 'character_url_asc'
  | 'character_url_desc'
  | 'createdAt_asc'
  | 'createdAt_desc'
  | 'description_asc'
  | 'description_desc'
  | 'id_asc'
  | 'id_desc'
  | 'languageTopic_asc'
  | 'languageTopic_desc'
  | 'level_asc'
  | 'level_desc'
  | 'mediaId_asc'
  | 'mediaId_desc'
  | 'media_createdAt_asc'
  | 'media_createdAt_desc'
  | 'media_createdById_asc'
  | 'media_createdById_desc'
  | 'media_duration_asc'
  | 'media_duration_desc'
  | 'media_ffprobe_asc'
  | 'media_ffprobe_desc'
  | 'media_fileSize_asc'
  | 'media_fileSize_desc'
  | 'media_id_asc'
  | 'media_id_desc'
  | 'media_name_asc'
  | 'media_name_desc'
  | 'media_originalResourceId_asc'
  | 'media_originalResourceId_desc'
  | 'media_originalResourceState_asc'
  | 'media_originalResourceState_desc'
  | 'media_thumbnailResourceId_asc'
  | 'media_thumbnailResourceId_desc'
  | 'media_type_asc'
  | 'media_type_desc'
  | 'media_url_asc'
  | 'media_url_desc'
  | 'name_asc'
  | 'name_desc'
  | 'ordinalNumber_asc'
  | 'ordinalNumber_desc'
  | 'scenario_asc'
  | 'scenario_desc'
  | 'status_asc'
  | 'status_desc'
  | 'topicInCategory_categoryId_asc'
  | 'topicInCategory_categoryId_desc'
  | 'topicInCategory_createdAt_asc'
  | 'topicInCategory_createdAt_desc'
  | 'topicInCategory_id_asc'
  | 'topicInCategory_id_desc'
  | 'topicInCategory_topicId_asc'
  | 'topicInCategory_topicId_desc'
  | 'topicInUser_createdAt_asc'
  | 'topicInUser_createdAt_desc'
  | 'topicInUser_id_asc'
  | 'topicInUser_id_desc'
  | 'topicInUser_learning_asc'
  | 'topicInUser_learning_desc'
  | 'topicInUser_ordinalNumber_asc'
  | 'topicInUser_ordinalNumber_desc'
  | 'topicInUser_percent_asc'
  | 'topicInUser_percent_desc'
  | 'topicInUser_task_asc'
  | 'topicInUser_task_desc'
  | 'topicInUser_topicId_asc'
  | 'topicInUser_topicId_desc'
  | 'topicInUser_unlockTopic_asc'
  | 'topicInUser_unlockTopic_desc'
  | 'topicInUser_userLearnId_asc'
  | 'topicInUser_userLearnId_desc'
  | 'userAnswer_answerId_asc'
  | 'userAnswer_answerId_desc'
  | 'userAnswer_createdAt_asc'
  | 'userAnswer_createdAt_desc'
  | 'userAnswer_id_asc'
  | 'userAnswer_id_desc'
  | 'userAnswer_questionId_asc'
  | 'userAnswer_questionId_desc'
  | 'userAnswer_times_asc'
  | 'userAnswer_times_desc'
  | 'userAnswer_topicId_asc'
  | 'userAnswer_topicId_desc'
  | 'userAnswer_userId_asc'
  | 'userAnswer_userId_desc'
  | 'userAnswer_wordInUserId_asc'
  | 'userAnswer_wordInUserId_desc'
  | 'userAnswer_wrongTimes_asc'
  | 'userAnswer_wrongTimes_desc'
  | 'userId_asc'
  | 'userId_desc'
  | 'userRole_asc'
  | 'userRole_desc'
  | 'user_aiTutorId_asc'
  | 'user_aiTutorId_desc'
  | 'user_autoPlay_asc'
  | 'user_autoPlay_desc'
  | 'user_createdAt_asc'
  | 'user_createdAt_desc'
  | 'user_customer_asc'
  | 'user_customer_desc'
  | 'user_deactivate_asc'
  | 'user_deactivate_desc'
  | 'user_email_asc'
  | 'user_email_desc'
  | 'user_hashedPassword_asc'
  | 'user_hashedPassword_desc'
  | 'user_id_asc'
  | 'user_id_desc'
  | 'user_isAdmin_asc'
  | 'user_isAdmin_desc'
  | 'user_languageLearn_asc'
  | 'user_languageLearn_desc'
  | 'user_lastUsedDate_asc'
  | 'user_lastUsedDate_desc'
  | 'user_level_asc'
  | 'user_level_desc'
  | 'user_name_asc'
  | 'user_name_desc'
  | 'user_nativeLanguage_asc'
  | 'user_nativeLanguage_desc'
  | 'user_repair_asc'
  | 'user_repair_desc'
  | 'user_speed_asc'
  | 'user_speed_desc'
  | 'user_thumbnailId_asc'
  | 'user_thumbnailId_desc'
  | 'wordInUser_createdAt_asc'
  | 'wordInUser_createdAt_desc'
  | 'wordInUser_id_asc'
  | 'wordInUser_id_desc'
  | 'wordInUser_isCompleted_asc'
  | 'wordInUser_isCompleted_desc'
  | 'wordInUser_lastDay_asc'
  | 'wordInUser_lastDay_desc'
  | 'wordInUser_milestone_asc'
  | 'wordInUser_milestone_desc'
  | 'wordInUser_nextDay_asc'
  | 'wordInUser_nextDay_desc'
  | 'wordInUser_percentListen_asc'
  | 'wordInUser_percentListen_desc'
  | 'wordInUser_times_asc'
  | 'wordInUser_times_desc'
  | 'wordInUser_topicId_asc'
  | 'wordInUser_topicId_desc'
  | 'wordInUser_used_asc'
  | 'wordInUser_used_desc'
  | 'wordInUser_userId_asc'
  | 'wordInUser_userId_desc'
  | 'wordInUser_wordId_asc'
  | 'wordInUser_wordId_desc'

export type TopicStatusType = 'Active' | 'Deleted' | 'Pending'

export type Translate = {
  __typename?: 'Translate'
  Answer?: Maybe<Answer>
  Question?: Maybe<Question>
  Word?: Maybe<Word>
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  suportLanguage: Scalars['String']['output']
  text: Scalars['String']['output']
  wQAId: Scalars['ID']['output']
}

export type TranslateFilter = {
  AND?: InputMaybe<Array<TranslateFilter>>
  Answer_none?: InputMaybe<AnswerFilter>
  Answer_some?: InputMaybe<AnswerFilter>
  NOT?: InputMaybe<TranslateFilter>
  OR?: InputMaybe<Array<TranslateFilter>>
  Question_none?: InputMaybe<QuestionFilter>
  Question_some?: InputMaybe<QuestionFilter>
  Word_none?: InputMaybe<WordFilter>
  Word_some?: InputMaybe<WordFilter>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_ne?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>
  id?: InputMaybe<Scalars['ID']['input']>
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>
  id_ne?: InputMaybe<Scalars['ID']['input']>
  id_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  suportLanguage?: InputMaybe<Scalars['String']['input']>
  suportLanguage_endsWith?: InputMaybe<Scalars['String']['input']>
  suportLanguage_gt?: InputMaybe<Scalars['String']['input']>
  suportLanguage_gte?: InputMaybe<Scalars['String']['input']>
  suportLanguage_iLike?: InputMaybe<Scalars['String']['input']>
  suportLanguage_in?: InputMaybe<Array<Scalars['String']['input']>>
  suportLanguage_like?: InputMaybe<Scalars['String']['input']>
  suportLanguage_lt?: InputMaybe<Scalars['String']['input']>
  suportLanguage_lte?: InputMaybe<Scalars['String']['input']>
  suportLanguage_ne?: InputMaybe<Scalars['String']['input']>
  suportLanguage_notILike?: InputMaybe<Scalars['String']['input']>
  suportLanguage_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  suportLanguage_notLike?: InputMaybe<Scalars['String']['input']>
  suportLanguage_startsWith?: InputMaybe<Scalars['String']['input']>
  suportLanguage_substring?: InputMaybe<Scalars['String']['input']>
  text?: InputMaybe<Scalars['String']['input']>
  text_endsWith?: InputMaybe<Scalars['String']['input']>
  text_gt?: InputMaybe<Scalars['String']['input']>
  text_gte?: InputMaybe<Scalars['String']['input']>
  text_iLike?: InputMaybe<Scalars['String']['input']>
  text_in?: InputMaybe<Array<Scalars['String']['input']>>
  text_like?: InputMaybe<Scalars['String']['input']>
  text_lt?: InputMaybe<Scalars['String']['input']>
  text_lte?: InputMaybe<Scalars['String']['input']>
  text_ne?: InputMaybe<Scalars['String']['input']>
  text_notILike?: InputMaybe<Scalars['String']['input']>
  text_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  text_notLike?: InputMaybe<Scalars['String']['input']>
  text_startsWith?: InputMaybe<Scalars['String']['input']>
  text_substring?: InputMaybe<Scalars['String']['input']>
  wQAId?: InputMaybe<Scalars['ID']['input']>
  wQAId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  wQAId_ne?: InputMaybe<Scalars['ID']['input']>
  wQAId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type TranslateOrderBy =
  | 'Answer_createdAt_asc'
  | 'Answer_createdAt_desc'
  | 'Answer_id_asc'
  | 'Answer_id_desc'
  | 'Answer_index_asc'
  | 'Answer_index_desc'
  | 'Answer_text_asc'
  | 'Answer_text_desc'
  | 'Question_createdAt_asc'
  | 'Question_createdAt_desc'
  | 'Question_id_asc'
  | 'Question_id_desc'
  | 'Question_index_asc'
  | 'Question_index_desc'
  | 'Question_mediaId_asc'
  | 'Question_mediaId_desc'
  | 'Question_text_asc'
  | 'Question_text_desc'
  | 'Question_thumbnailId_asc'
  | 'Question_thumbnailId_desc'
  | 'Question_topicId_asc'
  | 'Question_topicId_desc'
  | 'Question_type_asc'
  | 'Question_type_desc'
  | 'Question_wordId_asc'
  | 'Question_wordId_desc'
  | 'Word_createdAt_asc'
  | 'Word_createdAt_desc'
  | 'Word_description_asc'
  | 'Word_description_desc'
  | 'Word_id_asc'
  | 'Word_id_desc'
  | 'Word_isThumbnail_asc'
  | 'Word_isThumbnail_desc'
  | 'Word_isTranslate_asc'
  | 'Word_isTranslate_desc'
  | 'Word_isVocabulary_asc'
  | 'Word_isVocabulary_desc'
  | 'Word_mediaId_asc'
  | 'Word_mediaId_desc'
  | 'Word_thumbnailId_asc'
  | 'Word_thumbnailId_desc'
  | 'Word_topicId_asc'
  | 'Word_topicId_desc'
  | 'Word_userId_asc'
  | 'Word_userId_desc'
  | 'Word_vocabAndPhrase_asc'
  | 'Word_vocabAndPhrase_desc'
  | 'createdAt_asc'
  | 'createdAt_desc'
  | 'id_asc'
  | 'id_desc'
  | 'suportLanguage_asc'
  | 'suportLanguage_desc'
  | 'text_asc'
  | 'text_desc'
  | 'wQAId_asc'
  | 'wQAId_desc'

export type Type = 'good' | 'service'

export type TypeGame =
  | 'completeTheDialogue'
  | 'correctPhrase'
  | 'correctTranslation'
  | 'fillTheBlank'
  | 'hearAudio'
  | 'hearVideo'
  | 'multipleChoice'
  | 'rearrangement'
  | 'sameOrDifferent'
  | 'selectImage'
  | 'yesNo'

export type TypeNetWorks = 'cartes_bancaires' | 'mastercard' | 'visa'

export type TypePayment =
  | 'alipay'
  | 'amazon_pay'
  | 'boleto'
  | 'card'
  | 'cashapp'
  | 'paypal'
  | 'wechat_pay'

export type UpdateAiTutor = {
  address?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  gender?: InputMaybe<AiTutorTypeGender>
  languageAI?: InputMaybe<Scalars['String']['input']>
  mediaId?: InputMaybe<Scalars['ID']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<AiTutorStatusType>
  thumbnailId?: InputMaybe<Scalars['ID']['input']>
}

export type UpdateAnswer = {
  index?: InputMaybe<Scalars['Int']['input']>
  text?: InputMaybe<Scalars['String']['input']>
}

export type UpdateAnswerInQuestion = {
  answerId?: InputMaybe<Scalars['ID']['input']>
  isTrue?: InputMaybe<Scalars['Boolean']['input']>
  mediaId?: InputMaybe<Scalars['ID']['input']>
  questionId?: InputMaybe<Scalars['ID']['input']>
  thumbnailId?: InputMaybe<Scalars['ID']['input']>
}

export type UpdateCategory = {
  tagName?: InputMaybe<Scalars['String']['input']>
}

export type UpdateDeactivateUser = {
  deactivate?: InputMaybe<Scalars['Boolean']['input']>
}

export type UpdateMyProfile = {
  aiTutorId?: InputMaybe<Scalars['String']['input']>
  autoPlay?: InputMaybe<Scalars['Boolean']['input']>
  deactivate?: InputMaybe<Scalars['String']['input']>
  email?: InputMaybe<Scalars['String']['input']>
  languageLearn?: InputMaybe<Scalars['NeString']['input']>
  lastUsedDate?: InputMaybe<Scalars['String']['input']>
  level?: InputMaybe<LevelTest>
  name?: InputMaybe<Scalars['NeString']['input']>
  nativeLanguage?: InputMaybe<Scalars['NeString']['input']>
  repair?: InputMaybe<Scalars['Int']['input']>
  speed?: InputMaybe<Scalars['Float']['input']>
  thumbnailId?: InputMaybe<Scalars['String']['input']>
}

export type UpdatePaymentMethod = {
  billing_details?: InputMaybe<BillingDetails>
  metadata?: InputMaybe<Scalars['Json']['input']>
}

export type UpdatePrice = {
  active?: InputMaybe<Scalars['Boolean']['input']>
  currency_options?: InputMaybe<Array<InputMaybe<CurrencyOptionInput>>>
  expand?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  lookup_key?: InputMaybe<Scalars['String']['input']>
  metadata?: InputMaybe<Scalars['Json']['input']>
  nickname?: InputMaybe<Scalars['String']['input']>
  price_id: Scalars['ID']['input']
  tax_behavior?: InputMaybe<TaxBehavior>
  transfer_lookup_key?: InputMaybe<Scalars['Boolean']['input']>
}

export type UpdateProduct = {
  active?: InputMaybe<Scalars['Boolean']['input']>
  defaultPrice?: InputMaybe<Scalars['Json']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  discountPercent?: InputMaybe<Scalars['Int']['input']>
  gifId?: InputMaybe<Scalars['ID']['input']>
  idPrice?: InputMaybe<Scalars['String']['input']>
  idProduct?: InputMaybe<Scalars['String']['input']>
  images?: InputMaybe<Scalars['Json']['input']>
  marketingFeatures?: InputMaybe<Scalars['Json']['input']>
  metadata?: InputMaybe<Scalars['Json']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  priceId?: InputMaybe<Scalars['ID']['input']>
  subTitleGif?: InputMaybe<Scalars['String']['input']>
  titleGif?: InputMaybe<Scalars['String']['input']>
  titlePlansGif?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<Scalars['String']['input']>
}

export type UpdateQuestion = {
  index?: InputMaybe<Scalars['Int']['input']>
  mediaId?: InputMaybe<Scalars['ID']['input']>
  text?: InputMaybe<Scalars['String']['input']>
  thumbnailId?: InputMaybe<Scalars['ID']['input']>
  topicId?: InputMaybe<Scalars['ID']['input']>
  type?: InputMaybe<Scalars['String']['input']>
}

export type UpdateSplash = {
  subTitle?: InputMaybe<Scalars['String']['input']>
  thumbnailId?: InputMaybe<Scalars['ID']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type UpdateStreak = {
  infor?: InputMaybe<Scalars['Json']['input']>
  mediaId?: InputMaybe<Scalars['ID']['input']>
  numberStreak?: InputMaybe<Scalars['Int']['input']>
  thumbnailId?: InputMaybe<Scalars['ID']['input']>
}

export type UpdateStreakDaily = {
  name?: InputMaybe<Scalars['String']['input']>
}

export type UpdateStreakInUser = {
  countStreak?: InputMaybe<Scalars['Int']['input']>
  dateStreak: DateStreak
  highestStreak?: InputMaybe<Scalars['Int']['input']>
  lastDate?: InputMaybe<Scalars['String']['input']>
}

export type UpdateTopic = {
  aiTutorRole?: InputMaybe<Scalars['String']['input']>
  characterId?: InputMaybe<Scalars['ID']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  index?: InputMaybe<Scalars['Int']['input']>
  languageTopic?: InputMaybe<Scalars['String']['input']>
  level?: InputMaybe<LevelType>
  mediaId?: InputMaybe<Scalars['ID']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  ordinalNumber?: InputMaybe<Scalars['Int']['input']>
  scenario?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<TopicStatusType>
  task?: InputMaybe<Scalars['Json']['input']>
  userRole?: InputMaybe<Scalars['String']['input']>
}

export type UpdateTopicInCategory = {
  categoryId?: InputMaybe<Scalars['ID']['input']>
  topicId?: InputMaybe<Scalars['ID']['input']>
}

export type UpdateUserAnswer = {
  answerId?: InputMaybe<Scalars['ID']['input']>
  questionId?: InputMaybe<Scalars['ID']['input']>
  times?: InputMaybe<Scalars['Int']['input']>
  topicId?: InputMaybe<Scalars['ID']['input']>
  userId?: InputMaybe<Scalars['ID']['input']>
  wordInUserId?: InputMaybe<Scalars['ID']['input']>
  wrongTimes?: InputMaybe<Scalars['Int']['input']>
}

export type UpdateWord = {
  description?: InputMaybe<Scalars['String']['input']>
  isThumbnail?: InputMaybe<Scalars['Boolean']['input']>
  isTranslate?: InputMaybe<Scalars['Boolean']['input']>
  isVocabulary?: InputMaybe<Scalars['Boolean']['input']>
  mediaId?: InputMaybe<Scalars['ID']['input']>
  thumbnailId?: InputMaybe<Scalars['ID']['input']>
  topicId?: InputMaybe<Scalars['ID']['input']>
  userId?: InputMaybe<Scalars['ID']['input']>
  vocabAndPhrase?: InputMaybe<Scalars['String']['input']>
}

export type UpdateWordInUser = {
  isCompleted?: InputMaybe<Scalars['Boolean']['input']>
  lastDay?: InputMaybe<Scalars['DateTime']['input']>
  milestone?: InputMaybe<Scalars['Int']['input']>
  nextDay?: InputMaybe<Scalars['DateTime']['input']>
  percentListen?: InputMaybe<Scalars['Int']['input']>
  times?: InputMaybe<Scalars['Int']['input']>
  topicId?: InputMaybe<Scalars['ID']['input']>
  used?: InputMaybe<Scalars['Boolean']['input']>
  userId?: InputMaybe<Scalars['ID']['input']>
  wordId?: InputMaybe<Scalars['ID']['input']>
}

export type UpdateWordbank = {
  isPhrase?: InputMaybe<Scalars['Boolean']['input']>
  topicId?: InputMaybe<Scalars['ID']['input']>
  translatedWords?: InputMaybe<Scalars['String']['input']>
  userId?: InputMaybe<Scalars['ID']['input']>
  word?: InputMaybe<Scalars['String']['input']>
}

export type User = {
  __typename?: 'User'
  aiTutor?: Maybe<AiTutor>
  aiTutorId: Scalars['ID']['output']
  autoPlay: Scalars['Boolean']['output']
  createdAt: Scalars['DateTime']['output']
  customer: Scalars['String']['output']
  deactivate: Scalars['Boolean']['output']
  email: Scalars['String']['output']
  id: Scalars['ID']['output']
  isAdmin: Scalars['Boolean']['output']
  languageLearn: Scalars['String']['output']
  lastUsedDate: Scalars['String']['output']
  level: Scalars['Json']['output']
  name: Scalars['String']['output']
  nativeLanguage: Scalars['String']['output']
  repair: Scalars['Int']['output']
  speed: Scalars['Float']['output']
  streakInUser?: Maybe<StreakInUser>
  thumbnail?: Maybe<Resource>
  thumbnailId: Scalars['ID']['output']
  topicInUser?: Maybe<TopicInUser>
  wordInUser?: Maybe<WordInUser>
}

export type UserAnswer = {
  __typename?: 'UserAnswer'
  answer?: Maybe<Answer>
  answerId: Scalars['ID']['output']
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  question?: Maybe<Question>
  questionId: Scalars['ID']['output']
  times: Scalars['Int']['output']
  topic?: Maybe<Topic>
  topicId: Scalars['ID']['output']
  user?: Maybe<User>
  userId: Scalars['ID']['output']
  wordInUser?: Maybe<WordInUser>
  wordInUserId: Scalars['ID']['output']
  wrongTimes: Scalars['Int']['output']
}

export type UserAnswerFilter = {
  AND?: InputMaybe<Array<UserAnswerFilter>>
  NOT?: InputMaybe<UserAnswerFilter>
  OR?: InputMaybe<Array<UserAnswerFilter>>
  answerId?: InputMaybe<Scalars['ID']['input']>
  answerId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  answerId_ne?: InputMaybe<Scalars['ID']['input']>
  answerId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  answer_none?: InputMaybe<AnswerFilter>
  answer_some?: InputMaybe<AnswerFilter>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_ne?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>
  id?: InputMaybe<Scalars['ID']['input']>
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>
  id_ne?: InputMaybe<Scalars['ID']['input']>
  id_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  questionId?: InputMaybe<Scalars['ID']['input']>
  questionId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  questionId_ne?: InputMaybe<Scalars['ID']['input']>
  questionId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  question_none?: InputMaybe<QuestionFilter>
  question_some?: InputMaybe<QuestionFilter>
  times?: InputMaybe<Scalars['Int']['input']>
  times_gt?: InputMaybe<Scalars['Int']['input']>
  times_gte?: InputMaybe<Scalars['Int']['input']>
  times_in?: InputMaybe<Array<Scalars['Int']['input']>>
  times_lt?: InputMaybe<Scalars['Int']['input']>
  times_lte?: InputMaybe<Scalars['Int']['input']>
  times_ne?: InputMaybe<Scalars['Int']['input']>
  times_notIn?: InputMaybe<Array<Scalars['Int']['input']>>
  topicId?: InputMaybe<Scalars['ID']['input']>
  topicId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  topicId_ne?: InputMaybe<Scalars['ID']['input']>
  topicId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  topic_none?: InputMaybe<TopicFilter>
  topic_some?: InputMaybe<TopicFilter>
  userId?: InputMaybe<Scalars['ID']['input']>
  userId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  userId_ne?: InputMaybe<Scalars['ID']['input']>
  userId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  user_none?: InputMaybe<UserFilter>
  user_some?: InputMaybe<UserFilter>
  wordInUserId?: InputMaybe<Scalars['ID']['input']>
  wordInUserId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  wordInUserId_ne?: InputMaybe<Scalars['ID']['input']>
  wordInUserId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  wordInUser_none?: InputMaybe<WordInUserFilter>
  wordInUser_some?: InputMaybe<WordInUserFilter>
  wrongTimes?: InputMaybe<Scalars['Int']['input']>
  wrongTimes_gt?: InputMaybe<Scalars['Int']['input']>
  wrongTimes_gte?: InputMaybe<Scalars['Int']['input']>
  wrongTimes_in?: InputMaybe<Array<Scalars['Int']['input']>>
  wrongTimes_lt?: InputMaybe<Scalars['Int']['input']>
  wrongTimes_lte?: InputMaybe<Scalars['Int']['input']>
  wrongTimes_ne?: InputMaybe<Scalars['Int']['input']>
  wrongTimes_notIn?: InputMaybe<Array<Scalars['Int']['input']>>
}

export type UserAnswerOrderBy =
  | 'answerId_asc'
  | 'answerId_desc'
  | 'answer_createdAt_asc'
  | 'answer_createdAt_desc'
  | 'answer_id_asc'
  | 'answer_id_desc'
  | 'answer_index_asc'
  | 'answer_index_desc'
  | 'answer_text_asc'
  | 'answer_text_desc'
  | 'createdAt_asc'
  | 'createdAt_desc'
  | 'id_asc'
  | 'id_desc'
  | 'questionId_asc'
  | 'questionId_desc'
  | 'question_createdAt_asc'
  | 'question_createdAt_desc'
  | 'question_id_asc'
  | 'question_id_desc'
  | 'question_index_asc'
  | 'question_index_desc'
  | 'question_mediaId_asc'
  | 'question_mediaId_desc'
  | 'question_text_asc'
  | 'question_text_desc'
  | 'question_thumbnailId_asc'
  | 'question_thumbnailId_desc'
  | 'question_topicId_asc'
  | 'question_topicId_desc'
  | 'question_type_asc'
  | 'question_type_desc'
  | 'question_wordId_asc'
  | 'question_wordId_desc'
  | 'times_asc'
  | 'times_desc'
  | 'topicId_asc'
  | 'topicId_desc'
  | 'topic_aiTutorRole_asc'
  | 'topic_aiTutorRole_desc'
  | 'topic_characterId_asc'
  | 'topic_characterId_desc'
  | 'topic_createdAt_asc'
  | 'topic_createdAt_desc'
  | 'topic_description_asc'
  | 'topic_description_desc'
  | 'topic_id_asc'
  | 'topic_id_desc'
  | 'topic_languageTopic_asc'
  | 'topic_languageTopic_desc'
  | 'topic_level_asc'
  | 'topic_level_desc'
  | 'topic_mediaId_asc'
  | 'topic_mediaId_desc'
  | 'topic_name_asc'
  | 'topic_name_desc'
  | 'topic_ordinalNumber_asc'
  | 'topic_ordinalNumber_desc'
  | 'topic_scenario_asc'
  | 'topic_scenario_desc'
  | 'topic_status_asc'
  | 'topic_status_desc'
  | 'topic_task_asc'
  | 'topic_task_desc'
  | 'topic_userId_asc'
  | 'topic_userId_desc'
  | 'topic_userRole_asc'
  | 'topic_userRole_desc'
  | 'userId_asc'
  | 'userId_desc'
  | 'user_aiTutorId_asc'
  | 'user_aiTutorId_desc'
  | 'user_autoPlay_asc'
  | 'user_autoPlay_desc'
  | 'user_createdAt_asc'
  | 'user_createdAt_desc'
  | 'user_customer_asc'
  | 'user_customer_desc'
  | 'user_deactivate_asc'
  | 'user_deactivate_desc'
  | 'user_email_asc'
  | 'user_email_desc'
  | 'user_hashedPassword_asc'
  | 'user_hashedPassword_desc'
  | 'user_id_asc'
  | 'user_id_desc'
  | 'user_isAdmin_asc'
  | 'user_isAdmin_desc'
  | 'user_languageLearn_asc'
  | 'user_languageLearn_desc'
  | 'user_lastUsedDate_asc'
  | 'user_lastUsedDate_desc'
  | 'user_level_asc'
  | 'user_level_desc'
  | 'user_name_asc'
  | 'user_name_desc'
  | 'user_nativeLanguage_asc'
  | 'user_nativeLanguage_desc'
  | 'user_repair_asc'
  | 'user_repair_desc'
  | 'user_speed_asc'
  | 'user_speed_desc'
  | 'user_thumbnailId_asc'
  | 'user_thumbnailId_desc'
  | 'wordInUserId_asc'
  | 'wordInUserId_desc'
  | 'wordInUser_createdAt_asc'
  | 'wordInUser_createdAt_desc'
  | 'wordInUser_id_asc'
  | 'wordInUser_id_desc'
  | 'wordInUser_isCompleted_asc'
  | 'wordInUser_isCompleted_desc'
  | 'wordInUser_lastDay_asc'
  | 'wordInUser_lastDay_desc'
  | 'wordInUser_milestone_asc'
  | 'wordInUser_milestone_desc'
  | 'wordInUser_nextDay_asc'
  | 'wordInUser_nextDay_desc'
  | 'wordInUser_percentListen_asc'
  | 'wordInUser_percentListen_desc'
  | 'wordInUser_times_asc'
  | 'wordInUser_times_desc'
  | 'wordInUser_topicId_asc'
  | 'wordInUser_topicId_desc'
  | 'wordInUser_used_asc'
  | 'wordInUser_used_desc'
  | 'wordInUser_userId_asc'
  | 'wordInUser_userId_desc'
  | 'wordInUser_wordId_asc'
  | 'wordInUser_wordId_desc'
  | 'wrongTimes_asc'
  | 'wrongTimes_desc'

export type UserFilter = {
  AND?: InputMaybe<Array<UserFilter>>
  NOT?: InputMaybe<UserFilter>
  OR?: InputMaybe<Array<UserFilter>>
  aiTutorId?: InputMaybe<Scalars['ID']['input']>
  aiTutorId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  aiTutorId_ne?: InputMaybe<Scalars['ID']['input']>
  aiTutorId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  aiTutor_none?: InputMaybe<AiTutorFilter>
  aiTutor_some?: InputMaybe<AiTutorFilter>
  autoPlay?: InputMaybe<Scalars['Boolean']['input']>
  autoPlay_ne?: InputMaybe<Scalars['Boolean']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_ne?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>
  customer?: InputMaybe<Scalars['String']['input']>
  customer_endsWith?: InputMaybe<Scalars['String']['input']>
  customer_gt?: InputMaybe<Scalars['String']['input']>
  customer_gte?: InputMaybe<Scalars['String']['input']>
  customer_iLike?: InputMaybe<Scalars['String']['input']>
  customer_in?: InputMaybe<Array<Scalars['String']['input']>>
  customer_like?: InputMaybe<Scalars['String']['input']>
  customer_lt?: InputMaybe<Scalars['String']['input']>
  customer_lte?: InputMaybe<Scalars['String']['input']>
  customer_ne?: InputMaybe<Scalars['String']['input']>
  customer_notILike?: InputMaybe<Scalars['String']['input']>
  customer_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  customer_notLike?: InputMaybe<Scalars['String']['input']>
  customer_startsWith?: InputMaybe<Scalars['String']['input']>
  customer_substring?: InputMaybe<Scalars['String']['input']>
  deactivate?: InputMaybe<Scalars['Boolean']['input']>
  deactivate_ne?: InputMaybe<Scalars['Boolean']['input']>
  email?: InputMaybe<Scalars['String']['input']>
  email_endsWith?: InputMaybe<Scalars['String']['input']>
  email_gt?: InputMaybe<Scalars['String']['input']>
  email_gte?: InputMaybe<Scalars['String']['input']>
  email_iLike?: InputMaybe<Scalars['String']['input']>
  email_in?: InputMaybe<Array<Scalars['String']['input']>>
  email_like?: InputMaybe<Scalars['String']['input']>
  email_lt?: InputMaybe<Scalars['String']['input']>
  email_lte?: InputMaybe<Scalars['String']['input']>
  email_ne?: InputMaybe<Scalars['String']['input']>
  email_notILike?: InputMaybe<Scalars['String']['input']>
  email_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  email_notLike?: InputMaybe<Scalars['String']['input']>
  email_startsWith?: InputMaybe<Scalars['String']['input']>
  email_substring?: InputMaybe<Scalars['String']['input']>
  hashedPassword?: InputMaybe<Scalars['String']['input']>
  hashedPassword_endsWith?: InputMaybe<Scalars['String']['input']>
  hashedPassword_gt?: InputMaybe<Scalars['String']['input']>
  hashedPassword_gte?: InputMaybe<Scalars['String']['input']>
  hashedPassword_iLike?: InputMaybe<Scalars['String']['input']>
  hashedPassword_in?: InputMaybe<Array<Scalars['String']['input']>>
  hashedPassword_like?: InputMaybe<Scalars['String']['input']>
  hashedPassword_lt?: InputMaybe<Scalars['String']['input']>
  hashedPassword_lte?: InputMaybe<Scalars['String']['input']>
  hashedPassword_ne?: InputMaybe<Scalars['String']['input']>
  hashedPassword_notILike?: InputMaybe<Scalars['String']['input']>
  hashedPassword_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  hashedPassword_notLike?: InputMaybe<Scalars['String']['input']>
  hashedPassword_startsWith?: InputMaybe<Scalars['String']['input']>
  hashedPassword_substring?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>
  id_ne?: InputMaybe<Scalars['ID']['input']>
  id_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  isAdmin?: InputMaybe<Scalars['Boolean']['input']>
  isAdmin_ne?: InputMaybe<Scalars['Boolean']['input']>
  languageLearn?: InputMaybe<Scalars['String']['input']>
  languageLearn_endsWith?: InputMaybe<Scalars['String']['input']>
  languageLearn_gt?: InputMaybe<Scalars['String']['input']>
  languageLearn_gte?: InputMaybe<Scalars['String']['input']>
  languageLearn_iLike?: InputMaybe<Scalars['String']['input']>
  languageLearn_in?: InputMaybe<Array<Scalars['String']['input']>>
  languageLearn_like?: InputMaybe<Scalars['String']['input']>
  languageLearn_lt?: InputMaybe<Scalars['String']['input']>
  languageLearn_lte?: InputMaybe<Scalars['String']['input']>
  languageLearn_ne?: InputMaybe<Scalars['String']['input']>
  languageLearn_notILike?: InputMaybe<Scalars['String']['input']>
  languageLearn_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  languageLearn_notLike?: InputMaybe<Scalars['String']['input']>
  languageLearn_startsWith?: InputMaybe<Scalars['String']['input']>
  languageLearn_substring?: InputMaybe<Scalars['String']['input']>
  lastUsedDate?: InputMaybe<Scalars['String']['input']>
  lastUsedDate_endsWith?: InputMaybe<Scalars['String']['input']>
  lastUsedDate_gt?: InputMaybe<Scalars['String']['input']>
  lastUsedDate_gte?: InputMaybe<Scalars['String']['input']>
  lastUsedDate_iLike?: InputMaybe<Scalars['String']['input']>
  lastUsedDate_in?: InputMaybe<Array<Scalars['String']['input']>>
  lastUsedDate_like?: InputMaybe<Scalars['String']['input']>
  lastUsedDate_lt?: InputMaybe<Scalars['String']['input']>
  lastUsedDate_lte?: InputMaybe<Scalars['String']['input']>
  lastUsedDate_ne?: InputMaybe<Scalars['String']['input']>
  lastUsedDate_notILike?: InputMaybe<Scalars['String']['input']>
  lastUsedDate_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  lastUsedDate_notLike?: InputMaybe<Scalars['String']['input']>
  lastUsedDate_startsWith?: InputMaybe<Scalars['String']['input']>
  lastUsedDate_substring?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  name_endsWith?: InputMaybe<Scalars['String']['input']>
  name_gt?: InputMaybe<Scalars['String']['input']>
  name_gte?: InputMaybe<Scalars['String']['input']>
  name_iLike?: InputMaybe<Scalars['String']['input']>
  name_in?: InputMaybe<Array<Scalars['String']['input']>>
  name_like?: InputMaybe<Scalars['String']['input']>
  name_lt?: InputMaybe<Scalars['String']['input']>
  name_lte?: InputMaybe<Scalars['String']['input']>
  name_ne?: InputMaybe<Scalars['String']['input']>
  name_notILike?: InputMaybe<Scalars['String']['input']>
  name_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  name_notLike?: InputMaybe<Scalars['String']['input']>
  name_startsWith?: InputMaybe<Scalars['String']['input']>
  name_substring?: InputMaybe<Scalars['String']['input']>
  nativeLanguage?: InputMaybe<Scalars['String']['input']>
  nativeLanguage_endsWith?: InputMaybe<Scalars['String']['input']>
  nativeLanguage_gt?: InputMaybe<Scalars['String']['input']>
  nativeLanguage_gte?: InputMaybe<Scalars['String']['input']>
  nativeLanguage_iLike?: InputMaybe<Scalars['String']['input']>
  nativeLanguage_in?: InputMaybe<Array<Scalars['String']['input']>>
  nativeLanguage_like?: InputMaybe<Scalars['String']['input']>
  nativeLanguage_lt?: InputMaybe<Scalars['String']['input']>
  nativeLanguage_lte?: InputMaybe<Scalars['String']['input']>
  nativeLanguage_ne?: InputMaybe<Scalars['String']['input']>
  nativeLanguage_notILike?: InputMaybe<Scalars['String']['input']>
  nativeLanguage_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  nativeLanguage_notLike?: InputMaybe<Scalars['String']['input']>
  nativeLanguage_startsWith?: InputMaybe<Scalars['String']['input']>
  nativeLanguage_substring?: InputMaybe<Scalars['String']['input']>
  repair?: InputMaybe<Scalars['Int']['input']>
  repair_gt?: InputMaybe<Scalars['Int']['input']>
  repair_gte?: InputMaybe<Scalars['Int']['input']>
  repair_in?: InputMaybe<Array<Scalars['Int']['input']>>
  repair_lt?: InputMaybe<Scalars['Int']['input']>
  repair_lte?: InputMaybe<Scalars['Int']['input']>
  repair_ne?: InputMaybe<Scalars['Int']['input']>
  repair_notIn?: InputMaybe<Array<Scalars['Int']['input']>>
  speed?: InputMaybe<Scalars['Float']['input']>
  speed_gt?: InputMaybe<Scalars['Float']['input']>
  speed_gte?: InputMaybe<Scalars['Float']['input']>
  speed_in?: InputMaybe<Array<Scalars['Float']['input']>>
  speed_lt?: InputMaybe<Scalars['Float']['input']>
  speed_lte?: InputMaybe<Scalars['Float']['input']>
  speed_ne?: InputMaybe<Scalars['Float']['input']>
  speed_notIn?: InputMaybe<Array<Scalars['Float']['input']>>
  streakInUser_none?: InputMaybe<StreakInUserFilter>
  streakInUser_some?: InputMaybe<StreakInUserFilter>
  thumbnailId?: InputMaybe<Scalars['ID']['input']>
  thumbnailId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  thumbnailId_ne?: InputMaybe<Scalars['ID']['input']>
  thumbnailId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  thumbnail_none?: InputMaybe<ResourceFilter>
  thumbnail_some?: InputMaybe<ResourceFilter>
  topicInUser_none?: InputMaybe<TopicInUserFilter>
  topicInUser_some?: InputMaybe<TopicInUserFilter>
  wordInUser_none?: InputMaybe<WordInUserFilter>
  wordInUser_some?: InputMaybe<WordInUserFilter>
}

export type UserOrderBy =
  | 'aiTutorId_asc'
  | 'aiTutorId_desc'
  | 'aiTutor_address_asc'
  | 'aiTutor_address_desc'
  | 'aiTutor_createdAt_asc'
  | 'aiTutor_createdAt_desc'
  | 'aiTutor_description_asc'
  | 'aiTutor_description_desc'
  | 'aiTutor_gender_asc'
  | 'aiTutor_gender_desc'
  | 'aiTutor_id_asc'
  | 'aiTutor_id_desc'
  | 'aiTutor_languageAI_asc'
  | 'aiTutor_languageAI_desc'
  | 'aiTutor_mediaId_asc'
  | 'aiTutor_mediaId_desc'
  | 'aiTutor_name_asc'
  | 'aiTutor_name_desc'
  | 'aiTutor_status_asc'
  | 'aiTutor_status_desc'
  | 'aiTutor_thumbnailId_asc'
  | 'aiTutor_thumbnailId_desc'
  | 'aiTutor_userId_asc'
  | 'aiTutor_userId_desc'
  | 'autoPlay_asc'
  | 'autoPlay_desc'
  | 'createdAt_asc'
  | 'createdAt_desc'
  | 'customer_asc'
  | 'customer_desc'
  | 'deactivate_asc'
  | 'deactivate_desc'
  | 'email_asc'
  | 'email_desc'
  | 'hashedPassword_asc'
  | 'hashedPassword_desc'
  | 'id_asc'
  | 'id_desc'
  | 'isAdmin_asc'
  | 'isAdmin_desc'
  | 'languageLearn_asc'
  | 'languageLearn_desc'
  | 'lastUsedDate_asc'
  | 'lastUsedDate_desc'
  | 'name_asc'
  | 'name_desc'
  | 'nativeLanguage_asc'
  | 'nativeLanguage_desc'
  | 'repair_asc'
  | 'repair_desc'
  | 'speed_asc'
  | 'speed_desc'
  | 'streakInUser_countStreak_asc'
  | 'streakInUser_countStreak_desc'
  | 'streakInUser_createdAt_asc'
  | 'streakInUser_createdAt_desc'
  | 'streakInUser_dateStreak_asc'
  | 'streakInUser_dateStreak_desc'
  | 'streakInUser_highestStreak_asc'
  | 'streakInUser_highestStreak_desc'
  | 'streakInUser_id_asc'
  | 'streakInUser_id_desc'
  | 'streakInUser_lastDate_asc'
  | 'streakInUser_lastDate_desc'
  | 'streakInUser_streakId_asc'
  | 'streakInUser_streakId_desc'
  | 'streakInUser_userId_asc'
  | 'streakInUser_userId_desc'
  | 'thumbnailId_asc'
  | 'thumbnailId_desc'
  | 'thumbnail_createdAt_asc'
  | 'thumbnail_createdAt_desc'
  | 'thumbnail_createdById_asc'
  | 'thumbnail_createdById_desc'
  | 'thumbnail_duration_asc'
  | 'thumbnail_duration_desc'
  | 'thumbnail_ffprobe_asc'
  | 'thumbnail_ffprobe_desc'
  | 'thumbnail_fileSize_asc'
  | 'thumbnail_fileSize_desc'
  | 'thumbnail_id_asc'
  | 'thumbnail_id_desc'
  | 'thumbnail_name_asc'
  | 'thumbnail_name_desc'
  | 'thumbnail_originalResourceId_asc'
  | 'thumbnail_originalResourceId_desc'
  | 'thumbnail_originalResourceState_asc'
  | 'thumbnail_originalResourceState_desc'
  | 'thumbnail_thumbnailResourceId_asc'
  | 'thumbnail_thumbnailResourceId_desc'
  | 'thumbnail_type_asc'
  | 'thumbnail_type_desc'
  | 'thumbnail_url_asc'
  | 'thumbnail_url_desc'
  | 'topicInUser_createdAt_asc'
  | 'topicInUser_createdAt_desc'
  | 'topicInUser_id_asc'
  | 'topicInUser_id_desc'
  | 'topicInUser_learning_asc'
  | 'topicInUser_learning_desc'
  | 'topicInUser_ordinalNumber_asc'
  | 'topicInUser_ordinalNumber_desc'
  | 'topicInUser_percent_asc'
  | 'topicInUser_percent_desc'
  | 'topicInUser_task_asc'
  | 'topicInUser_task_desc'
  | 'topicInUser_topicId_asc'
  | 'topicInUser_topicId_desc'
  | 'topicInUser_unlockTopic_asc'
  | 'topicInUser_unlockTopic_desc'
  | 'topicInUser_userLearnId_asc'
  | 'topicInUser_userLearnId_desc'
  | 'wordInUser_createdAt_asc'
  | 'wordInUser_createdAt_desc'
  | 'wordInUser_id_asc'
  | 'wordInUser_id_desc'
  | 'wordInUser_isCompleted_asc'
  | 'wordInUser_isCompleted_desc'
  | 'wordInUser_lastDay_asc'
  | 'wordInUser_lastDay_desc'
  | 'wordInUser_milestone_asc'
  | 'wordInUser_milestone_desc'
  | 'wordInUser_nextDay_asc'
  | 'wordInUser_nextDay_desc'
  | 'wordInUser_percentListen_asc'
  | 'wordInUser_percentListen_desc'
  | 'wordInUser_times_asc'
  | 'wordInUser_times_desc'
  | 'wordInUser_topicId_asc'
  | 'wordInUser_topicId_desc'
  | 'wordInUser_used_asc'
  | 'wordInUser_used_desc'
  | 'wordInUser_userId_asc'
  | 'wordInUser_userId_desc'
  | 'wordInUser_wordId_asc'
  | 'wordInUser_wordId_desc'

export type Word = {
  __typename?: 'Word'
  createdAt: Scalars['DateTime']['output']
  description: Scalars['String']['output']
  id: Scalars['ID']['output']
  isThumbnail: Scalars['Boolean']['output']
  isTranslate: Scalars['Boolean']['output']
  isVocabulary: Scalars['Boolean']['output']
  media?: Maybe<Resource>
  mediaId: Scalars['ID']['output']
  question?: Maybe<Question>
  resourceInWord?: Maybe<ResourceInWord>
  thumbnail?: Maybe<Resource>
  thumbnailId: Scalars['ID']['output']
  topic?: Maybe<Topic>
  topicId: Scalars['ID']['output']
  translate?: Maybe<Translate>
  userId: Scalars['ID']['output']
  vocabAndPhrase: Scalars['String']['output']
  wordInUser?: Maybe<WordInUser>
}

export type WordFilter = {
  AND?: InputMaybe<Array<WordFilter>>
  NOT?: InputMaybe<WordFilter>
  OR?: InputMaybe<Array<WordFilter>>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_ne?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>
  description?: InputMaybe<Scalars['String']['input']>
  description_endsWith?: InputMaybe<Scalars['String']['input']>
  description_gt?: InputMaybe<Scalars['String']['input']>
  description_gte?: InputMaybe<Scalars['String']['input']>
  description_iLike?: InputMaybe<Scalars['String']['input']>
  description_in?: InputMaybe<Array<Scalars['String']['input']>>
  description_like?: InputMaybe<Scalars['String']['input']>
  description_lt?: InputMaybe<Scalars['String']['input']>
  description_lte?: InputMaybe<Scalars['String']['input']>
  description_ne?: InputMaybe<Scalars['String']['input']>
  description_notILike?: InputMaybe<Scalars['String']['input']>
  description_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  description_notLike?: InputMaybe<Scalars['String']['input']>
  description_startsWith?: InputMaybe<Scalars['String']['input']>
  description_substring?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>
  id_ne?: InputMaybe<Scalars['ID']['input']>
  id_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  isThumbnail?: InputMaybe<Scalars['Boolean']['input']>
  isThumbnail_ne?: InputMaybe<Scalars['Boolean']['input']>
  isTranslate?: InputMaybe<Scalars['Boolean']['input']>
  isTranslate_ne?: InputMaybe<Scalars['Boolean']['input']>
  isVocabulary?: InputMaybe<Scalars['Boolean']['input']>
  isVocabulary_ne?: InputMaybe<Scalars['Boolean']['input']>
  mediaId?: InputMaybe<Scalars['ID']['input']>
  mediaId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  mediaId_ne?: InputMaybe<Scalars['ID']['input']>
  mediaId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  media_none?: InputMaybe<ResourceFilter>
  media_some?: InputMaybe<ResourceFilter>
  question_none?: InputMaybe<QuestionFilter>
  question_some?: InputMaybe<QuestionFilter>
  resourceInWord_none?: InputMaybe<ResourceInWordFilter>
  resourceInWord_some?: InputMaybe<ResourceInWordFilter>
  thumbnailId?: InputMaybe<Scalars['ID']['input']>
  thumbnailId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  thumbnailId_ne?: InputMaybe<Scalars['ID']['input']>
  thumbnailId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  thumbnail_none?: InputMaybe<ResourceFilter>
  thumbnail_some?: InputMaybe<ResourceFilter>
  topicId?: InputMaybe<Scalars['ID']['input']>
  topicId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  topicId_ne?: InputMaybe<Scalars['ID']['input']>
  topicId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  topic_none?: InputMaybe<TopicFilter>
  topic_some?: InputMaybe<TopicFilter>
  translate_none?: InputMaybe<TranslateFilter>
  translate_some?: InputMaybe<TranslateFilter>
  userId?: InputMaybe<Scalars['ID']['input']>
  userId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  userId_ne?: InputMaybe<Scalars['ID']['input']>
  userId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  vocabAndPhrase?: InputMaybe<Scalars['String']['input']>
  vocabAndPhrase_endsWith?: InputMaybe<Scalars['String']['input']>
  vocabAndPhrase_gt?: InputMaybe<Scalars['String']['input']>
  vocabAndPhrase_gte?: InputMaybe<Scalars['String']['input']>
  vocabAndPhrase_iLike?: InputMaybe<Scalars['String']['input']>
  vocabAndPhrase_in?: InputMaybe<Array<Scalars['String']['input']>>
  vocabAndPhrase_like?: InputMaybe<Scalars['String']['input']>
  vocabAndPhrase_lt?: InputMaybe<Scalars['String']['input']>
  vocabAndPhrase_lte?: InputMaybe<Scalars['String']['input']>
  vocabAndPhrase_ne?: InputMaybe<Scalars['String']['input']>
  vocabAndPhrase_notILike?: InputMaybe<Scalars['String']['input']>
  vocabAndPhrase_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  vocabAndPhrase_notLike?: InputMaybe<Scalars['String']['input']>
  vocabAndPhrase_startsWith?: InputMaybe<Scalars['String']['input']>
  vocabAndPhrase_substring?: InputMaybe<Scalars['String']['input']>
  wordInUser_none?: InputMaybe<WordInUserFilter>
  wordInUser_some?: InputMaybe<WordInUserFilter>
}

export type WordInUser = {
  __typename?: 'WordInUser'
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  isCompleted: Scalars['Boolean']['output']
  lastDay?: Maybe<Scalars['DateTime']['output']>
  milestone: Scalars['Int']['output']
  nextDay?: Maybe<Scalars['DateTime']['output']>
  percentListen: Scalars['Int']['output']
  times: Scalars['Int']['output']
  topic?: Maybe<Topic>
  topicId: Scalars['ID']['output']
  used: Scalars['Boolean']['output']
  user?: Maybe<User>
  userAnswer?: Maybe<UserAnswer>
  userId: Scalars['ID']['output']
  word?: Maybe<Word>
  wordId: Scalars['ID']['output']
}

export type WordInUserFilter = {
  AND?: InputMaybe<Array<WordInUserFilter>>
  NOT?: InputMaybe<WordInUserFilter>
  OR?: InputMaybe<Array<WordInUserFilter>>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_ne?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>
  id?: InputMaybe<Scalars['ID']['input']>
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>
  id_ne?: InputMaybe<Scalars['ID']['input']>
  id_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  isCompleted?: InputMaybe<Scalars['Boolean']['input']>
  isCompleted_ne?: InputMaybe<Scalars['Boolean']['input']>
  lastDay?: InputMaybe<Scalars['DateTime']['input']>
  lastDay_gt?: InputMaybe<Scalars['DateTime']['input']>
  lastDay_gte?: InputMaybe<Scalars['DateTime']['input']>
  lastDay_in?: InputMaybe<Array<Scalars['DateTime']['input']>>
  lastDay_lt?: InputMaybe<Scalars['DateTime']['input']>
  lastDay_lte?: InputMaybe<Scalars['DateTime']['input']>
  lastDay_ne?: InputMaybe<Scalars['DateTime']['input']>
  lastDay_notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>
  milestone?: InputMaybe<Scalars['Int']['input']>
  milestone_gt?: InputMaybe<Scalars['Int']['input']>
  milestone_gte?: InputMaybe<Scalars['Int']['input']>
  milestone_in?: InputMaybe<Array<Scalars['Int']['input']>>
  milestone_lt?: InputMaybe<Scalars['Int']['input']>
  milestone_lte?: InputMaybe<Scalars['Int']['input']>
  milestone_ne?: InputMaybe<Scalars['Int']['input']>
  milestone_notIn?: InputMaybe<Array<Scalars['Int']['input']>>
  nextDay?: InputMaybe<Scalars['DateTime']['input']>
  nextDay_gt?: InputMaybe<Scalars['DateTime']['input']>
  nextDay_gte?: InputMaybe<Scalars['DateTime']['input']>
  nextDay_in?: InputMaybe<Array<Scalars['DateTime']['input']>>
  nextDay_lt?: InputMaybe<Scalars['DateTime']['input']>
  nextDay_lte?: InputMaybe<Scalars['DateTime']['input']>
  nextDay_ne?: InputMaybe<Scalars['DateTime']['input']>
  nextDay_notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>
  percentListen?: InputMaybe<Scalars['Int']['input']>
  percentListen_gt?: InputMaybe<Scalars['Int']['input']>
  percentListen_gte?: InputMaybe<Scalars['Int']['input']>
  percentListen_in?: InputMaybe<Array<Scalars['Int']['input']>>
  percentListen_lt?: InputMaybe<Scalars['Int']['input']>
  percentListen_lte?: InputMaybe<Scalars['Int']['input']>
  percentListen_ne?: InputMaybe<Scalars['Int']['input']>
  percentListen_notIn?: InputMaybe<Array<Scalars['Int']['input']>>
  times?: InputMaybe<Scalars['Int']['input']>
  times_gt?: InputMaybe<Scalars['Int']['input']>
  times_gte?: InputMaybe<Scalars['Int']['input']>
  times_in?: InputMaybe<Array<Scalars['Int']['input']>>
  times_lt?: InputMaybe<Scalars['Int']['input']>
  times_lte?: InputMaybe<Scalars['Int']['input']>
  times_ne?: InputMaybe<Scalars['Int']['input']>
  times_notIn?: InputMaybe<Array<Scalars['Int']['input']>>
  topicId?: InputMaybe<Scalars['ID']['input']>
  topicId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  topicId_ne?: InputMaybe<Scalars['ID']['input']>
  topicId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  topic_none?: InputMaybe<TopicFilter>
  topic_some?: InputMaybe<TopicFilter>
  used?: InputMaybe<Scalars['Boolean']['input']>
  used_ne?: InputMaybe<Scalars['Boolean']['input']>
  userAnswer_none?: InputMaybe<UserAnswerFilter>
  userAnswer_some?: InputMaybe<UserAnswerFilter>
  userId?: InputMaybe<Scalars['ID']['input']>
  userId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  userId_ne?: InputMaybe<Scalars['ID']['input']>
  userId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  user_none?: InputMaybe<UserFilter>
  user_some?: InputMaybe<UserFilter>
  wordId?: InputMaybe<Scalars['ID']['input']>
  wordId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  wordId_ne?: InputMaybe<Scalars['ID']['input']>
  wordId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  word_none?: InputMaybe<WordFilter>
  word_some?: InputMaybe<WordFilter>
}

export type WordInUserOrderBy =
  | 'createdAt_asc'
  | 'createdAt_desc'
  | 'id_asc'
  | 'id_desc'
  | 'isCompleted_asc'
  | 'isCompleted_desc'
  | 'lastDay_asc'
  | 'lastDay_desc'
  | 'milestone_asc'
  | 'milestone_desc'
  | 'nextDay_asc'
  | 'nextDay_desc'
  | 'percentListen_asc'
  | 'percentListen_desc'
  | 'times_asc'
  | 'times_desc'
  | 'topicId_asc'
  | 'topicId_desc'
  | 'topic_aiTutorRole_asc'
  | 'topic_aiTutorRole_desc'
  | 'topic_characterId_asc'
  | 'topic_characterId_desc'
  | 'topic_createdAt_asc'
  | 'topic_createdAt_desc'
  | 'topic_description_asc'
  | 'topic_description_desc'
  | 'topic_id_asc'
  | 'topic_id_desc'
  | 'topic_languageTopic_asc'
  | 'topic_languageTopic_desc'
  | 'topic_level_asc'
  | 'topic_level_desc'
  | 'topic_mediaId_asc'
  | 'topic_mediaId_desc'
  | 'topic_name_asc'
  | 'topic_name_desc'
  | 'topic_ordinalNumber_asc'
  | 'topic_ordinalNumber_desc'
  | 'topic_scenario_asc'
  | 'topic_scenario_desc'
  | 'topic_status_asc'
  | 'topic_status_desc'
  | 'topic_task_asc'
  | 'topic_task_desc'
  | 'topic_userId_asc'
  | 'topic_userId_desc'
  | 'topic_userRole_asc'
  | 'topic_userRole_desc'
  | 'used_asc'
  | 'used_desc'
  | 'userAnswer_answerId_asc'
  | 'userAnswer_answerId_desc'
  | 'userAnswer_createdAt_asc'
  | 'userAnswer_createdAt_desc'
  | 'userAnswer_id_asc'
  | 'userAnswer_id_desc'
  | 'userAnswer_questionId_asc'
  | 'userAnswer_questionId_desc'
  | 'userAnswer_times_asc'
  | 'userAnswer_times_desc'
  | 'userAnswer_topicId_asc'
  | 'userAnswer_topicId_desc'
  | 'userAnswer_userId_asc'
  | 'userAnswer_userId_desc'
  | 'userAnswer_wordInUserId_asc'
  | 'userAnswer_wordInUserId_desc'
  | 'userAnswer_wrongTimes_asc'
  | 'userAnswer_wrongTimes_desc'
  | 'userId_asc'
  | 'userId_desc'
  | 'user_aiTutorId_asc'
  | 'user_aiTutorId_desc'
  | 'user_autoPlay_asc'
  | 'user_autoPlay_desc'
  | 'user_createdAt_asc'
  | 'user_createdAt_desc'
  | 'user_customer_asc'
  | 'user_customer_desc'
  | 'user_deactivate_asc'
  | 'user_deactivate_desc'
  | 'user_email_asc'
  | 'user_email_desc'
  | 'user_hashedPassword_asc'
  | 'user_hashedPassword_desc'
  | 'user_id_asc'
  | 'user_id_desc'
  | 'user_isAdmin_asc'
  | 'user_isAdmin_desc'
  | 'user_languageLearn_asc'
  | 'user_languageLearn_desc'
  | 'user_lastUsedDate_asc'
  | 'user_lastUsedDate_desc'
  | 'user_level_asc'
  | 'user_level_desc'
  | 'user_name_asc'
  | 'user_name_desc'
  | 'user_nativeLanguage_asc'
  | 'user_nativeLanguage_desc'
  | 'user_repair_asc'
  | 'user_repair_desc'
  | 'user_speed_asc'
  | 'user_speed_desc'
  | 'user_thumbnailId_asc'
  | 'user_thumbnailId_desc'
  | 'wordId_asc'
  | 'wordId_desc'
  | 'word_createdAt_asc'
  | 'word_createdAt_desc'
  | 'word_description_asc'
  | 'word_description_desc'
  | 'word_id_asc'
  | 'word_id_desc'
  | 'word_isThumbnail_asc'
  | 'word_isThumbnail_desc'
  | 'word_isTranslate_asc'
  | 'word_isTranslate_desc'
  | 'word_isVocabulary_asc'
  | 'word_isVocabulary_desc'
  | 'word_mediaId_asc'
  | 'word_mediaId_desc'
  | 'word_thumbnailId_asc'
  | 'word_thumbnailId_desc'
  | 'word_topicId_asc'
  | 'word_topicId_desc'
  | 'word_userId_asc'
  | 'word_userId_desc'
  | 'word_vocabAndPhrase_asc'
  | 'word_vocabAndPhrase_desc'

export type WordOrderBy =
  | 'createdAt_asc'
  | 'createdAt_desc'
  | 'description_asc'
  | 'description_desc'
  | 'id_asc'
  | 'id_desc'
  | 'isThumbnail_asc'
  | 'isThumbnail_desc'
  | 'isTranslate_asc'
  | 'isTranslate_desc'
  | 'isVocabulary_asc'
  | 'isVocabulary_desc'
  | 'mediaId_asc'
  | 'mediaId_desc'
  | 'media_createdAt_asc'
  | 'media_createdAt_desc'
  | 'media_createdById_asc'
  | 'media_createdById_desc'
  | 'media_duration_asc'
  | 'media_duration_desc'
  | 'media_ffprobe_asc'
  | 'media_ffprobe_desc'
  | 'media_fileSize_asc'
  | 'media_fileSize_desc'
  | 'media_id_asc'
  | 'media_id_desc'
  | 'media_name_asc'
  | 'media_name_desc'
  | 'media_originalResourceId_asc'
  | 'media_originalResourceId_desc'
  | 'media_originalResourceState_asc'
  | 'media_originalResourceState_desc'
  | 'media_thumbnailResourceId_asc'
  | 'media_thumbnailResourceId_desc'
  | 'media_type_asc'
  | 'media_type_desc'
  | 'media_url_asc'
  | 'media_url_desc'
  | 'question_createdAt_asc'
  | 'question_createdAt_desc'
  | 'question_id_asc'
  | 'question_id_desc'
  | 'question_index_asc'
  | 'question_index_desc'
  | 'question_mediaId_asc'
  | 'question_mediaId_desc'
  | 'question_text_asc'
  | 'question_text_desc'
  | 'question_thumbnailId_asc'
  | 'question_thumbnailId_desc'
  | 'question_topicId_asc'
  | 'question_topicId_desc'
  | 'question_type_asc'
  | 'question_type_desc'
  | 'question_wordId_asc'
  | 'question_wordId_desc'
  | 'resourceInWord_createdAt_asc'
  | 'resourceInWord_createdAt_desc'
  | 'resourceInWord_id_asc'
  | 'resourceInWord_id_desc'
  | 'resourceInWord_mediaId_asc'
  | 'resourceInWord_mediaId_desc'
  | 'resourceInWord_type_asc'
  | 'resourceInWord_type_desc'
  | 'resourceInWord_wordId_asc'
  | 'resourceInWord_wordId_desc'
  | 'thumbnailId_asc'
  | 'thumbnailId_desc'
  | 'thumbnail_createdAt_asc'
  | 'thumbnail_createdAt_desc'
  | 'thumbnail_createdById_asc'
  | 'thumbnail_createdById_desc'
  | 'thumbnail_duration_asc'
  | 'thumbnail_duration_desc'
  | 'thumbnail_ffprobe_asc'
  | 'thumbnail_ffprobe_desc'
  | 'thumbnail_fileSize_asc'
  | 'thumbnail_fileSize_desc'
  | 'thumbnail_id_asc'
  | 'thumbnail_id_desc'
  | 'thumbnail_name_asc'
  | 'thumbnail_name_desc'
  | 'thumbnail_originalResourceId_asc'
  | 'thumbnail_originalResourceId_desc'
  | 'thumbnail_originalResourceState_asc'
  | 'thumbnail_originalResourceState_desc'
  | 'thumbnail_thumbnailResourceId_asc'
  | 'thumbnail_thumbnailResourceId_desc'
  | 'thumbnail_type_asc'
  | 'thumbnail_type_desc'
  | 'thumbnail_url_asc'
  | 'thumbnail_url_desc'
  | 'topicId_asc'
  | 'topicId_desc'
  | 'topic_aiTutorRole_asc'
  | 'topic_aiTutorRole_desc'
  | 'topic_characterId_asc'
  | 'topic_characterId_desc'
  | 'topic_createdAt_asc'
  | 'topic_createdAt_desc'
  | 'topic_description_asc'
  | 'topic_description_desc'
  | 'topic_id_asc'
  | 'topic_id_desc'
  | 'topic_languageTopic_asc'
  | 'topic_languageTopic_desc'
  | 'topic_level_asc'
  | 'topic_level_desc'
  | 'topic_mediaId_asc'
  | 'topic_mediaId_desc'
  | 'topic_name_asc'
  | 'topic_name_desc'
  | 'topic_ordinalNumber_asc'
  | 'topic_ordinalNumber_desc'
  | 'topic_scenario_asc'
  | 'topic_scenario_desc'
  | 'topic_status_asc'
  | 'topic_status_desc'
  | 'topic_task_asc'
  | 'topic_task_desc'
  | 'topic_userId_asc'
  | 'topic_userId_desc'
  | 'topic_userRole_asc'
  | 'topic_userRole_desc'
  | 'translate_createdAt_asc'
  | 'translate_createdAt_desc'
  | 'translate_id_asc'
  | 'translate_id_desc'
  | 'translate_suportLanguage_asc'
  | 'translate_suportLanguage_desc'
  | 'translate_text_asc'
  | 'translate_text_desc'
  | 'translate_wQAId_asc'
  | 'translate_wQAId_desc'
  | 'userId_asc'
  | 'userId_desc'
  | 'vocabAndPhrase_asc'
  | 'vocabAndPhrase_desc'
  | 'wordInUser_createdAt_asc'
  | 'wordInUser_createdAt_desc'
  | 'wordInUser_id_asc'
  | 'wordInUser_id_desc'
  | 'wordInUser_isCompleted_asc'
  | 'wordInUser_isCompleted_desc'
  | 'wordInUser_lastDay_asc'
  | 'wordInUser_lastDay_desc'
  | 'wordInUser_milestone_asc'
  | 'wordInUser_milestone_desc'
  | 'wordInUser_nextDay_asc'
  | 'wordInUser_nextDay_desc'
  | 'wordInUser_percentListen_asc'
  | 'wordInUser_percentListen_desc'
  | 'wordInUser_times_asc'
  | 'wordInUser_times_desc'
  | 'wordInUser_topicId_asc'
  | 'wordInUser_topicId_desc'
  | 'wordInUser_used_asc'
  | 'wordInUser_used_desc'
  | 'wordInUser_userId_asc'
  | 'wordInUser_userId_desc'
  | 'wordInUser_wordId_asc'
  | 'wordInUser_wordId_desc'

export type Wordbank = {
  __typename?: 'Wordbank'
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  isPhrase: Scalars['Boolean']['output']
  topic?: Maybe<Topic>
  topicId: Scalars['ID']['output']
  translatedWords: Scalars['String']['output']
  user?: Maybe<User>
  userId: Scalars['ID']['output']
  word: Scalars['String']['output']
}

export type WordbankFilter = {
  AND?: InputMaybe<Array<WordbankFilter>>
  NOT?: InputMaybe<WordbankFilter>
  OR?: InputMaybe<Array<WordbankFilter>>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_ne?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>
  id?: InputMaybe<Scalars['ID']['input']>
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>
  id_ne?: InputMaybe<Scalars['ID']['input']>
  id_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  isPhrase?: InputMaybe<Scalars['Boolean']['input']>
  isPhrase_ne?: InputMaybe<Scalars['Boolean']['input']>
  topicId?: InputMaybe<Scalars['ID']['input']>
  topicId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  topicId_ne?: InputMaybe<Scalars['ID']['input']>
  topicId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  topic_none?: InputMaybe<TopicFilter>
  topic_some?: InputMaybe<TopicFilter>
  translatedWords?: InputMaybe<Scalars['String']['input']>
  translatedWords_endsWith?: InputMaybe<Scalars['String']['input']>
  translatedWords_gt?: InputMaybe<Scalars['String']['input']>
  translatedWords_gte?: InputMaybe<Scalars['String']['input']>
  translatedWords_iLike?: InputMaybe<Scalars['String']['input']>
  translatedWords_in?: InputMaybe<Array<Scalars['String']['input']>>
  translatedWords_like?: InputMaybe<Scalars['String']['input']>
  translatedWords_lt?: InputMaybe<Scalars['String']['input']>
  translatedWords_lte?: InputMaybe<Scalars['String']['input']>
  translatedWords_ne?: InputMaybe<Scalars['String']['input']>
  translatedWords_notILike?: InputMaybe<Scalars['String']['input']>
  translatedWords_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  translatedWords_notLike?: InputMaybe<Scalars['String']['input']>
  translatedWords_startsWith?: InputMaybe<Scalars['String']['input']>
  translatedWords_substring?: InputMaybe<Scalars['String']['input']>
  userId?: InputMaybe<Scalars['ID']['input']>
  userId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  userId_ne?: InputMaybe<Scalars['ID']['input']>
  userId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  user_none?: InputMaybe<UserFilter>
  user_some?: InputMaybe<UserFilter>
  word?: InputMaybe<Scalars['String']['input']>
  word_endsWith?: InputMaybe<Scalars['String']['input']>
  word_gt?: InputMaybe<Scalars['String']['input']>
  word_gte?: InputMaybe<Scalars['String']['input']>
  word_iLike?: InputMaybe<Scalars['String']['input']>
  word_in?: InputMaybe<Array<Scalars['String']['input']>>
  word_like?: InputMaybe<Scalars['String']['input']>
  word_lt?: InputMaybe<Scalars['String']['input']>
  word_lte?: InputMaybe<Scalars['String']['input']>
  word_ne?: InputMaybe<Scalars['String']['input']>
  word_notILike?: InputMaybe<Scalars['String']['input']>
  word_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  word_notLike?: InputMaybe<Scalars['String']['input']>
  word_startsWith?: InputMaybe<Scalars['String']['input']>
  word_substring?: InputMaybe<Scalars['String']['input']>
}

export type WordbankOrderBy =
  | 'createdAt_asc'
  | 'createdAt_desc'
  | 'id_asc'
  | 'id_desc'
  | 'isPhrase_asc'
  | 'isPhrase_desc'
  | 'topicId_asc'
  | 'topicId_desc'
  | 'topic_aiTutorRole_asc'
  | 'topic_aiTutorRole_desc'
  | 'topic_characterId_asc'
  | 'topic_characterId_desc'
  | 'topic_createdAt_asc'
  | 'topic_createdAt_desc'
  | 'topic_description_asc'
  | 'topic_description_desc'
  | 'topic_id_asc'
  | 'topic_id_desc'
  | 'topic_languageTopic_asc'
  | 'topic_languageTopic_desc'
  | 'topic_level_asc'
  | 'topic_level_desc'
  | 'topic_mediaId_asc'
  | 'topic_mediaId_desc'
  | 'topic_name_asc'
  | 'topic_name_desc'
  | 'topic_ordinalNumber_asc'
  | 'topic_ordinalNumber_desc'
  | 'topic_scenario_asc'
  | 'topic_scenario_desc'
  | 'topic_status_asc'
  | 'topic_status_desc'
  | 'topic_task_asc'
  | 'topic_task_desc'
  | 'topic_userId_asc'
  | 'topic_userId_desc'
  | 'topic_userRole_asc'
  | 'topic_userRole_desc'
  | 'translatedWords_asc'
  | 'translatedWords_desc'
  | 'userId_asc'
  | 'userId_desc'
  | 'user_aiTutorId_asc'
  | 'user_aiTutorId_desc'
  | 'user_autoPlay_asc'
  | 'user_autoPlay_desc'
  | 'user_createdAt_asc'
  | 'user_createdAt_desc'
  | 'user_customer_asc'
  | 'user_customer_desc'
  | 'user_deactivate_asc'
  | 'user_deactivate_desc'
  | 'user_email_asc'
  | 'user_email_desc'
  | 'user_hashedPassword_asc'
  | 'user_hashedPassword_desc'
  | 'user_id_asc'
  | 'user_id_desc'
  | 'user_isAdmin_asc'
  | 'user_isAdmin_desc'
  | 'user_languageLearn_asc'
  | 'user_languageLearn_desc'
  | 'user_lastUsedDate_asc'
  | 'user_lastUsedDate_desc'
  | 'user_level_asc'
  | 'user_level_desc'
  | 'user_name_asc'
  | 'user_name_desc'
  | 'user_nativeLanguage_asc'
  | 'user_nativeLanguage_desc'
  | 'user_repair_asc'
  | 'user_repair_desc'
  | 'user_speed_asc'
  | 'user_speed_desc'
  | 'user_thumbnailId_asc'
  | 'user_thumbnailId_desc'
  | 'word_asc'
  | 'word_desc'

export type ResolveCheckTicket = {
  code?: InputMaybe<Scalars['NeString']['input']>
  id: Scalars['ID']['input']
  secret: Scalars['NeString']['input']
}

export type AiTutorFFragment = {
  __typename?: 'AiTutor'
  name: string
  description: string
  thumbnailId: string
  address: string
  mediaId: string
  status: string
  gender: string
  userId: string
  languageAI: string
  id: string
  createdAt: Date
  user?: {
    __typename?: 'User'
    name: string
    email: string
    nativeLanguage: string
    languageLearn: string
    level: any
    thumbnailId: string
    isAdmin: boolean
    deactivate: boolean
    id: string
    createdAt: Date
  } | null
  media?: {
    __typename?: 'Resource'
    type: string
    name: string
    url: string
    fileSize: number
    duration: number
    createdById?: string | null
    id: string
    createdAt: Date
  } | null
  thumbnail?: {
    __typename?: 'Resource'
    type: string
    name: string
    url: string
    fileSize: number
    duration: number
    thumbnailResourceId?: string | null
    createdById?: string | null
    id: string
    createdAt: Date
  } | null
}

export type SearchAiTutorQueryVariables = Exact<{
  filter?: InputMaybe<AiTutorFilter>
  order?: InputMaybe<Array<AiTutorOrderBy> | AiTutorOrderBy>
  page?: InputMaybe<Pagination>
}>

export type SearchAiTutorQuery = {
  __typename?: 'Query'
  searchAiTutor: Array<{
    __typename?: 'AiTutor'
    name: string
    description: string
    thumbnailId: string
    address: string
    mediaId: string
    status: string
    gender: string
    userId: string
    languageAI: string
    id: string
    createdAt: Date
    user?: {
      __typename?: 'User'
      name: string
      email: string
      nativeLanguage: string
      languageLearn: string
      level: any
      thumbnailId: string
      isAdmin: boolean
      deactivate: boolean
      id: string
      createdAt: Date
    } | null
    media?: {
      __typename?: 'Resource'
      type: string
      name: string
      url: string
      fileSize: number
      duration: number
      createdById?: string | null
      id: string
      createdAt: Date
    } | null
    thumbnail?: {
      __typename?: 'Resource'
      type: string
      name: string
      url: string
      fileSize: number
      duration: number
      thumbnailResourceId?: string | null
      createdById?: string | null
      id: string
      createdAt: Date
    } | null
  }>
}

export type CountAiTutorQueryVariables = Exact<{
  filter?: InputMaybe<AiTutorFilter>
}>

export type CountAiTutorQuery = { __typename?: 'Query'; countAiTutor: number }

export type CreateAiTutorMutationVariables = Exact<{
  data: CreateAiTutor
}>

export type CreateAiTutorMutation = {
  __typename?: 'Mutation'
  createAiTutor: {
    __typename?: 'AiTutor'
    name: string
    description: string
    thumbnailId: string
    address: string
    mediaId: string
    status: string
    gender: string
    userId: string
    languageAI: string
    id: string
    createdAt: Date
    user?: {
      __typename?: 'User'
      name: string
      email: string
      nativeLanguage: string
      languageLearn: string
      level: any
      thumbnailId: string
      isAdmin: boolean
      deactivate: boolean
      id: string
      createdAt: Date
    } | null
    media?: {
      __typename?: 'Resource'
      type: string
      name: string
      url: string
      fileSize: number
      duration: number
      createdById?: string | null
      id: string
      createdAt: Date
    } | null
    thumbnail?: {
      __typename?: 'Resource'
      type: string
      name: string
      url: string
      fileSize: number
      duration: number
      thumbnailResourceId?: string | null
      createdById?: string | null
      id: string
      createdAt: Date
    } | null
  }
}

export type UpdateAiTutorMutationVariables = Exact<{
  id: Scalars['ID']['input']
  data: UpdateAiTutor
}>

export type UpdateAiTutorMutation = {
  __typename?: 'Mutation'
  updateAiTutor: {
    __typename?: 'AiTutor'
    name: string
    description: string
    thumbnailId: string
    address: string
    mediaId: string
    status: string
    gender: string
    userId: string
    languageAI: string
    id: string
    createdAt: Date
    user?: {
      __typename?: 'User'
      name: string
      email: string
      nativeLanguage: string
      languageLearn: string
      level: any
      thumbnailId: string
      isAdmin: boolean
      deactivate: boolean
      id: string
      createdAt: Date
    } | null
    media?: {
      __typename?: 'Resource'
      type: string
      name: string
      url: string
      fileSize: number
      duration: number
      createdById?: string | null
      id: string
      createdAt: Date
    } | null
    thumbnail?: {
      __typename?: 'Resource'
      type: string
      name: string
      url: string
      fileSize: number
      duration: number
      thumbnailResourceId?: string | null
      createdById?: string | null
      id: string
      createdAt: Date
    } | null
  }
}

export type DeleteAiTutorMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DeleteAiTutorMutation = {
  __typename?: 'Mutation'
  deleteAiTutor?: null | null
}

export type AnswerFFragment = {
  __typename?: 'Answer'
  text: string
  id: string
  createdAt: Date
  index: number
  translate?: {
    __typename?: 'Translate'
    wQAId: string
    text: string
    id: string
    createdAt: Date
    suportLanguage: string
  } | null
}

export type SearchAnswerQueryVariables = Exact<{
  filter?: InputMaybe<AnswerFilter>
  order?: InputMaybe<Array<AnswerOrderBy> | AnswerOrderBy>
  page?: InputMaybe<Pagination>
}>

export type SearchAnswerQuery = {
  __typename?: 'Query'
  searchAnswer: Array<{
    __typename?: 'Answer'
    text: string
    id: string
    createdAt: Date
    index: number
    translate?: {
      __typename?: 'Translate'
      wQAId: string
      text: string
      id: string
      createdAt: Date
      suportLanguage: string
    } | null
  }>
}

export type CountAnswerQueryVariables = Exact<{
  filter?: InputMaybe<AnswerFilter>
}>

export type CountAnswerQuery = { __typename?: 'Query'; countAnswer: number }

export type CreateAnswerMutationVariables = Exact<{
  data: CreateAnswer
}>

export type CreateAnswerMutation = {
  __typename?: 'Mutation'
  createAnswer: {
    __typename?: 'Answer'
    text: string
    id: string
    createdAt: Date
    index: number
    translate?: {
      __typename?: 'Translate'
      wQAId: string
      text: string
      id: string
      createdAt: Date
      suportLanguage: string
    } | null
  }
}

export type UpdateAnswerMutationVariables = Exact<{
  id: Scalars['ID']['input']
  data: UpdateAnswer
}>

export type UpdateAnswerMutation = {
  __typename?: 'Mutation'
  updateAnswer: {
    __typename?: 'Answer'
    text: string
    id: string
    createdAt: Date
    index: number
    translate?: {
      __typename?: 'Translate'
      wQAId: string
      text: string
      id: string
      createdAt: Date
      suportLanguage: string
    } | null
  }
}

export type DeleteAnswerMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DeleteAnswerMutation = {
  __typename?: 'Mutation'
  deleteAnswer?: null | null
}

export type AnswerInQuestionFFragment = {
  __typename?: 'AnswerInQuestion'
  answerId: string
  questionId: string
  isTrue: boolean
  mediaId: string
  thumbnailId: string
  id: string
  createdAt: Date
  question?: {
    __typename?: 'Question'
    topicId: string
    type: string
    text: string
    wordId: string
    mediaId: string
    id: string
    createdAt: Date
    topic?: { __typename?: 'Topic'; languageTopic: string; name: string } | null
  } | null
  answer?: {
    __typename?: 'Answer'
    text: string
    id: string
    createdAt: Date
    translate?: {
      __typename?: 'Translate'
      wQAId: string
      text: string
      suportLanguage: string
      id: string
      createdAt: Date
    } | null
  } | null
  media?: {
    __typename?: 'Resource'
    type: string
    name: string
    url: string
    fileSize: number
    duration: number
    createdById?: string | null
    id: string
    createdAt: Date
  } | null
  thumbnail?: {
    __typename?: 'Resource'
    type: string
    name: string
    url: string
    fileSize: number
    duration: number
    thumbnailResourceId?: string | null
    createdById?: string | null
    id: string
    createdAt: Date
  } | null
}

export type SearchAnswerInQuestionQueryVariables = Exact<{
  filter?: InputMaybe<AnswerInQuestionFilter>
  order?: InputMaybe<Array<AnswerInQuestionOrderBy> | AnswerInQuestionOrderBy>
  page?: InputMaybe<Pagination>
}>

export type SearchAnswerInQuestionQuery = {
  __typename?: 'Query'
  searchAnswerInQuestion: Array<{
    __typename?: 'AnswerInQuestion'
    answerId: string
    questionId: string
    isTrue: boolean
    mediaId: string
    thumbnailId: string
    id: string
    createdAt: Date
    question?: {
      __typename?: 'Question'
      topicId: string
      type: string
      text: string
      wordId: string
      mediaId: string
      id: string
      createdAt: Date
      topic?: {
        __typename?: 'Topic'
        languageTopic: string
        name: string
      } | null
    } | null
    answer?: {
      __typename?: 'Answer'
      text: string
      id: string
      createdAt: Date
      translate?: {
        __typename?: 'Translate'
        wQAId: string
        text: string
        suportLanguage: string
        id: string
        createdAt: Date
      } | null
    } | null
    media?: {
      __typename?: 'Resource'
      type: string
      name: string
      url: string
      fileSize: number
      duration: number
      createdById?: string | null
      id: string
      createdAt: Date
    } | null
    thumbnail?: {
      __typename?: 'Resource'
      type: string
      name: string
      url: string
      fileSize: number
      duration: number
      thumbnailResourceId?: string | null
      createdById?: string | null
      id: string
      createdAt: Date
    } | null
  }>
}

export type CountAnswerInQuestionQueryVariables = Exact<{
  filter?: InputMaybe<AnswerInQuestionFilter>
}>

export type CountAnswerInQuestionQuery = {
  __typename?: 'Query'
  countAnswerInQuestion: number
}

export type CreateAnswerInQuestionMutationVariables = Exact<{
  data: CreateAnswerInQuestion
}>

export type CreateAnswerInQuestionMutation = {
  __typename?: 'Mutation'
  createAnswerInQuestion: {
    __typename?: 'AnswerInQuestion'
    answerId: string
    questionId: string
    isTrue: boolean
    mediaId: string
    thumbnailId: string
    id: string
    createdAt: Date
    question?: {
      __typename?: 'Question'
      topicId: string
      type: string
      text: string
      wordId: string
      mediaId: string
      id: string
      createdAt: Date
      topic?: {
        __typename?: 'Topic'
        languageTopic: string
        name: string
      } | null
    } | null
    answer?: {
      __typename?: 'Answer'
      text: string
      id: string
      createdAt: Date
      translate?: {
        __typename?: 'Translate'
        wQAId: string
        text: string
        suportLanguage: string
        id: string
        createdAt: Date
      } | null
    } | null
    media?: {
      __typename?: 'Resource'
      type: string
      name: string
      url: string
      fileSize: number
      duration: number
      createdById?: string | null
      id: string
      createdAt: Date
    } | null
    thumbnail?: {
      __typename?: 'Resource'
      type: string
      name: string
      url: string
      fileSize: number
      duration: number
      thumbnailResourceId?: string | null
      createdById?: string | null
      id: string
      createdAt: Date
    } | null
  }
}

export type UpdateAnswerInQuestionMutationVariables = Exact<{
  id: Scalars['ID']['input']
  data: UpdateAnswerInQuestion
}>

export type UpdateAnswerInQuestionMutation = {
  __typename?: 'Mutation'
  updateAnswerInQuestion: {
    __typename?: 'AnswerInQuestion'
    answerId: string
    questionId: string
    isTrue: boolean
    mediaId: string
    thumbnailId: string
    id: string
    createdAt: Date
    question?: {
      __typename?: 'Question'
      topicId: string
      type: string
      text: string
      wordId: string
      mediaId: string
      id: string
      createdAt: Date
      topic?: {
        __typename?: 'Topic'
        languageTopic: string
        name: string
      } | null
    } | null
    answer?: {
      __typename?: 'Answer'
      text: string
      id: string
      createdAt: Date
      translate?: {
        __typename?: 'Translate'
        wQAId: string
        text: string
        suportLanguage: string
        id: string
        createdAt: Date
      } | null
    } | null
    media?: {
      __typename?: 'Resource'
      type: string
      name: string
      url: string
      fileSize: number
      duration: number
      createdById?: string | null
      id: string
      createdAt: Date
    } | null
    thumbnail?: {
      __typename?: 'Resource'
      type: string
      name: string
      url: string
      fileSize: number
      duration: number
      thumbnailResourceId?: string | null
      createdById?: string | null
      id: string
      createdAt: Date
    } | null
  }
}

export type DeleteAnswerInQuestionMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DeleteAnswerInQuestionMutation = {
  __typename?: 'Mutation'
  deleteAnswerInQuestion?: null | null
}

export type AuthSessionFFragment = {
  __typename?: 'AuthSession'
  type: string
  userId: string
  secret: string
  ipAddress: string
  deviceUa: string
  devicePlatform: string
  devicePnSecret: string
  id: string
  createdAt: Date
  user?: {
    __typename?: 'User'
    name: string
    email: string
    nativeLanguage: string
    languageLearn: string
    level: any
    thumbnailId: string
    aiTutorId: string
    customer: string
    isAdmin: boolean
    deactivate: boolean
    speed: number
    repair: number
    autoPlay: boolean
    id: string
    createdAt: Date
    lastUsedDate: string
    thumbnail?: {
      __typename?: 'Resource'
      type: string
      name: string
      url: string
      fileSize: number
      duration: number
      thumbnailResourceId?: string | null
      id: string
      createdAt: Date
    } | null
  } | null
}

export type LoginMutationVariables = Exact<{
  email: Scalars['Email']['input']
  password: Scalars['NeString']['input']
}>

export type LoginMutation = {
  __typename?: 'Mutation'
  login: {
    __typename?: 'AuthSession'
    type: string
    userId: string
    secret: string
    ipAddress: string
    deviceUa: string
    devicePlatform: string
    devicePnSecret: string
    id: string
    createdAt: Date
    user?: {
      __typename?: 'User'
      name: string
      email: string
      nativeLanguage: string
      languageLearn: string
      level: any
      thumbnailId: string
      aiTutorId: string
      customer: string
      isAdmin: boolean
      deactivate: boolean
      speed: number
      repair: number
      autoPlay: boolean
      id: string
      createdAt: Date
      lastUsedDate: string
      thumbnail?: {
        __typename?: 'Resource'
        type: string
        name: string
        url: string
        fileSize: number
        duration: number
        thumbnailResourceId?: string | null
        id: string
        createdAt: Date
      } | null
    } | null
  }
}

export type LogoutMutationVariables = Exact<{ [key: string]: never }>

export type LogoutMutation = { __typename?: 'Mutation'; logout?: null | null }

export type RegisterMutationVariables = Exact<{
  data: Register
}>

export type RegisterMutation = { __typename?: 'Mutation'; register: string }

export type ResolveRegisterMutationVariables = Exact<{
  data: CheckTicket
}>

export type ResolveRegisterMutation = {
  __typename?: 'Mutation'
  resolveRegister: {
    __typename?: 'AuthSession'
    type: string
    userId: string
    secret: string
    ipAddress: string
    deviceUa: string
    devicePlatform: string
    devicePnSecret: string
    id: string
    createdAt: Date
    user?: {
      __typename?: 'User'
      name: string
      email: string
      nativeLanguage: string
      languageLearn: string
      level: any
      thumbnailId: string
      aiTutorId: string
      customer: string
      isAdmin: boolean
      deactivate: boolean
      speed: number
      repair: number
      autoPlay: boolean
      id: string
      createdAt: Date
      lastUsedDate: string
      thumbnail?: {
        __typename?: 'Resource'
        type: string
        name: string
        url: string
        fileSize: number
        duration: number
        thumbnailResourceId?: string | null
        id: string
        createdAt: Date
      } | null
    } | null
  }
}

export type ResetPasswordMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type ResetPasswordMutation = {
  __typename?: 'Mutation'
  resetPassword?: string | null
}

export type ForgotPasswordMutationVariables = Exact<{
  email: Scalars['Email']['input']
}>

export type ForgotPasswordMutation = {
  __typename?: 'Mutation'
  forgotPassword: string
}

export type ConfirmOtpMutationVariables = Exact<{
  data: Confirm
}>

export type ConfirmOtpMutation = { __typename?: 'Mutation'; confirmOTP: string }

export type ResolveForgotPasswordMutationVariables = Exact<{
  data: ResolveCheckTicket
  newPassword: Scalars['Password']['input']
}>

export type ResolveForgotPasswordMutation = {
  __typename?: 'Mutation'
  resolveForgotPassword: {
    __typename?: 'AuthSession'
    type: string
    userId: string
    secret: string
    ipAddress: string
    deviceUa: string
    devicePlatform: string
    devicePnSecret: string
    id: string
    createdAt: Date
    user?: {
      __typename?: 'User'
      name: string
      email: string
      nativeLanguage: string
      languageLearn: string
      level: any
      thumbnailId: string
      aiTutorId: string
      customer: string
      isAdmin: boolean
      deactivate: boolean
      speed: number
      repair: number
      autoPlay: boolean
      id: string
      createdAt: Date
      lastUsedDate: string
      thumbnail?: {
        __typename?: 'Resource'
        type: string
        name: string
        url: string
        fileSize: number
        duration: number
        thumbnailResourceId?: string | null
        id: string
        createdAt: Date
      } | null
    } | null
  }
}

export type ResendTicketMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type ResendTicketMutation = {
  __typename?: 'Mutation'
  resendTicket?: null | null
}

export type CurrentAuthSessionQueryVariables = Exact<{ [key: string]: never }>

export type CurrentAuthSessionQuery = {
  __typename?: 'Query'
  currentAuthSession?: {
    __typename?: 'AuthSession'
    type: string
    userId: string
    secret: string
    ipAddress: string
    deviceUa: string
    devicePlatform: string
    devicePnSecret: string
    id: string
    createdAt: Date
    user?: {
      __typename?: 'User'
      name: string
      email: string
      nativeLanguage: string
      languageLearn: string
      level: any
      thumbnailId: string
      aiTutorId: string
      customer: string
      isAdmin: boolean
      deactivate: boolean
      speed: number
      repair: number
      autoPlay: boolean
      id: string
      createdAt: Date
      lastUsedDate: string
      thumbnail?: {
        __typename?: 'Resource'
        type: string
        name: string
        url: string
        fileSize: number
        duration: number
        thumbnailResourceId?: string | null
        id: string
        createdAt: Date
      } | null
    } | null
  } | null
}

export type CheckEmailQueryVariables = Exact<{
  email: Scalars['Email']['input']
}>

export type CheckEmailQuery = { __typename?: 'Query'; checkEmail: boolean }

export type LoginGoogleMutationVariables = Exact<{
  data: InputGoogle
}>

export type LoginGoogleMutation = {
  __typename?: 'Mutation'
  loginGoogle: {
    __typename?: 'AuthSession'
    type: string
    userId: string
    secret: string
    ipAddress: string
    deviceUa: string
    devicePlatform: string
    devicePnSecret: string
    id: string
    createdAt: Date
    user?: {
      __typename?: 'User'
      name: string
      email: string
      nativeLanguage: string
      languageLearn: string
      level: any
      thumbnailId: string
      aiTutorId: string
      customer: string
      isAdmin: boolean
      deactivate: boolean
      speed: number
      repair: number
      autoPlay: boolean
      id: string
      createdAt: Date
      lastUsedDate: string
      thumbnail?: {
        __typename?: 'Resource'
        type: string
        name: string
        url: string
        fileSize: number
        duration: number
        thumbnailResourceId?: string | null
        id: string
        createdAt: Date
      } | null
    } | null
  }
}

export type LoginFacebookMutationVariables = Exact<{
  data: InputFacebook
}>

export type LoginFacebookMutation = {
  __typename?: 'Mutation'
  loginFacebook: {
    __typename?: 'AuthSession'
    type: string
    userId: string
    secret: string
    ipAddress: string
    deviceUa: string
    devicePlatform: string
    devicePnSecret: string
    id: string
    createdAt: Date
    user?: {
      __typename?: 'User'
      name: string
      email: string
      nativeLanguage: string
      languageLearn: string
      level: any
      thumbnailId: string
      aiTutorId: string
      customer: string
      isAdmin: boolean
      deactivate: boolean
      speed: number
      repair: number
      autoPlay: boolean
      id: string
      createdAt: Date
      lastUsedDate: string
      thumbnail?: {
        __typename?: 'Resource'
        type: string
        name: string
        url: string
        fileSize: number
        duration: number
        thumbnailResourceId?: string | null
        id: string
        createdAt: Date
      } | null
    } | null
  }
}

export type CategoryFFragment = {
  __typename?: 'Category'
  id: string
  tagName: string
  createdAt: Date
  topicInCategory?: {
    __typename?: 'TopicInCategory'
    id: string
    createdAt: Date
    topicId: string
    categoryId: string
  } | null
}

export type SearchCategoryQueryVariables = Exact<{
  filter?: InputMaybe<CategoryFilter>
  order?: InputMaybe<Array<CategoryOrderBy> | CategoryOrderBy>
  page?: InputMaybe<Pagination>
}>

export type SearchCategoryQuery = {
  __typename?: 'Query'
  searchCategory: Array<{
    __typename?: 'Category'
    id: string
    tagName: string
    createdAt: Date
    topicInCategory?: {
      __typename?: 'TopicInCategory'
      id: string
      createdAt: Date
      topicId: string
      categoryId: string
    } | null
  }>
}

export type CountCategoryQueryVariables = Exact<{
  filter?: InputMaybe<CategoryFilter>
}>

export type CountCategoryQuery = { __typename?: 'Query'; countCategory: number }

export type CreateCategoryMutationVariables = Exact<{
  data: CreateCategory
}>

export type CreateCategoryMutation = {
  __typename?: 'Mutation'
  createCategory: {
    __typename?: 'Category'
    id: string
    tagName: string
    createdAt: Date
    topicInCategory?: {
      __typename?: 'TopicInCategory'
      id: string
      createdAt: Date
      topicId: string
      categoryId: string
    } | null
  }
}

export type UpdateCategoryMutationVariables = Exact<{
  id: Scalars['ID']['input']
  data: UpdateCategory
}>

export type UpdateCategoryMutation = {
  __typename?: 'Mutation'
  updateCategory: {
    __typename?: 'Category'
    id: string
    tagName: string
    createdAt: Date
    topicInCategory?: {
      __typename?: 'TopicInCategory'
      id: string
      createdAt: Date
      topicId: string
      categoryId: string
    } | null
  }
}

export type DeleteCategoryMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DeleteCategoryMutation = {
  __typename?: 'Mutation'
  deleteCategory?: null | null
}

export type MessageFFragment = {
  __typename?: 'Message'
  content: string
  userId: string
  topicInUserId: string
  sentByUser: boolean
  id: string
  createdAt: Date
  topicInUser?: {
    __typename?: 'TopicInUser'
    topicId: string
    userLearnId: string
    id: string
    createdAt: Date
  } | null
}

export type SearchMessageQueryVariables = Exact<{
  filter?: InputMaybe<MessageFilter>
  order?: InputMaybe<Array<MessageOrderBy> | MessageOrderBy>
  page?: InputMaybe<Pagination>
}>

export type SearchMessageQuery = {
  __typename?: 'Query'
  searchMessage: Array<{
    __typename?: 'Message'
    content: string
    userId: string
    topicInUserId: string
    sentByUser: boolean
    id: string
    createdAt: Date
    topicInUser?: {
      __typename?: 'TopicInUser'
      topicId: string
      userLearnId: string
      id: string
      createdAt: Date
    } | null
  }>
}

export type CountMessageQueryVariables = Exact<{
  filter?: InputMaybe<MessageFilter>
}>

export type CountMessageQuery = { __typename?: 'Query'; countMessage: number }

export type SearchMessageListQueryVariables = Exact<{
  filter?: InputMaybe<MessageFilter>
  order?: InputMaybe<Array<MessageOrderBy> | MessageOrderBy>
  page?: InputMaybe<Pagination>
}>

export type SearchMessageListQuery = {
  __typename?: 'Query'
  total: number
  messages: Array<{
    __typename?: 'Message'
    content: string
    userId: string
    topicInUserId: string
    sentByUser: boolean
    id: string
    createdAt: Date
    topicInUser?: {
      __typename?: 'TopicInUser'
      topicId: string
      userLearnId: string
      id: string
      createdAt: Date
    } | null
  }>
}

export type CreateMessageMutationVariables = Exact<{
  data: CreateMessage
}>

export type CreateMessageMutation = {
  __typename?: 'Mutation'
  createMessage: {
    __typename?: 'Message'
    content: string
    userId: string
    topicInUserId: string
    sentByUser: boolean
    id: string
    createdAt: Date
    topicInUser?: {
      __typename?: 'TopicInUser'
      topicId: string
      userLearnId: string
      id: string
      createdAt: Date
    } | null
  }
}

export type SuggestMessageQueryVariables = Exact<{
  topicInUserId?: InputMaybe<Scalars['ID']['input']>
}>

export type SuggestMessageQuery = {
  __typename?: 'Query'
  suggestMessage?: any | null
}

export type TranslateMessageQueryVariables = Exact<{
  content?: InputMaybe<Scalars['String']['input']>
}>

export type TranslateMessageQuery = {
  __typename?: 'Query'
  translateMessage?: string | null
}

export type CheckTaskQueryVariables = Exact<{
  content?: InputMaybe<Scalars['String']['input']>
  task?: InputMaybe<
    Array<InputMaybe<TaskItemInput>> | InputMaybe<TaskItemInput>
  >
  level?: InputMaybe<Level>
  topicInUserId?: InputMaybe<Scalars['ID']['input']>
}>

export type CheckTaskQuery = { __typename?: 'Query'; checkTask?: string | null }

export type SpeechToTextQueryVariables = Exact<{
  audioBuffer?: InputMaybe<Scalars['Buffer']['input']>
  devices?: InputMaybe<Devices>
}>

export type SpeechToTextQuery = {
  __typename?: 'Query'
  speechToText?: string | null
}

export type TextToSpeechQueryVariables = Exact<{
  text: Scalars['String']['input']
}>

export type TextToSpeechQuery = { __typename?: 'Query'; textToSpeech: string }

export type DeleteMessageMutationVariables = Exact<{ [key: string]: never }>

export type DeleteMessageMutation = {
  __typename?: 'Mutation'
  deleteMessage?: null | null
}

export type AutoCorrectMessageQueryVariables = Exact<{
  content?: InputMaybe<Scalars['String']['input']>
}>

export type AutoCorrectMessageQuery = {
  __typename?: 'Query'
  autoCorrectMessage?: string | null
}

export type TestAutoCorrectMessageQueryVariables = Exact<{
  content?: InputMaybe<Scalars['String']['input']>
}>

export type TestAutoCorrectMessageQuery = {
  __typename?: 'Query'
  testAutoCorrectMessage?: any | null
}

export type DeleteMessageTopicInUserMutationVariables = Exact<{
  topicInUserId: Scalars['ID']['input']
}>

export type DeleteMessageTopicInUserMutation = {
  __typename?: 'Mutation'
  deleteMessageTopicInUser: number
}

export type GenerateCompleteTheDialogueQueryVariables = Exact<{
  content?: InputMaybe<Scalars['String']['input']>
  language?: InputMaybe<Language>
}>

export type GenerateCompleteTheDialogueQuery = {
  __typename?: 'Query'
  generateCompleteTheDialogue?: string | null
}

export type GenerateFillTheBlankQueryVariables = Exact<{
  content?: InputMaybe<Scalars['String']['input']>
  isPhrase?: InputMaybe<Scalars['Boolean']['input']>
  language?: InputMaybe<Language>
}>

export type GenerateFillTheBlankQuery = {
  __typename?: 'Query'
  generateFillTheBlank?: string | null
}

export type GenerateGameQueryVariables = Exact<{
  filterWordInUser?: InputMaybe<WordInUserFilter>
  orderWordInUser?: InputMaybe<Array<WordInUserOrderBy> | WordInUserOrderBy>
  filterWord?: InputMaybe<WordFilter>
  filterResourceInWord?: InputMaybe<ResourceInWordFilter>
  filterTranslate?: InputMaybe<TranslateFilter>
}>

export type GenerateGameQuery = {
  __typename?: 'Query'
  wordInUser: Array<{
    __typename?: 'WordInUser'
    wordId: string
    userId: string
    topicId: string
    times: number
    used: boolean
    percentListen: number
    lastDay?: Date | null
    nextDay?: Date | null
    milestone: number
    isCompleted: boolean
    id: string
    createdAt: Date
    word?: {
      __typename?: 'Word'
      userId: string
      topicId: string
      thumbnailId: string
      mediaId: string
      vocabAndPhrase: string
      description: string
      isVocabulary: boolean
      isThumbnail: boolean
      isTranslate: boolean
      id: string
      createdAt: Date
      translate?: {
        __typename?: 'Translate'
        text: string
        wQAId: string
        suportLanguage: string
        id: string
        createdAt: Date
      } | null
    } | null
    user?: {
      __typename?: 'User'
      thumbnailId: string
      aiTutorId: string
      name: string
      email: string
      nativeLanguage: string
      customer: string
      languageLearn: string
      level: any
      isAdmin: boolean
      deactivate: boolean
      speed: number
      repair: number
      autoPlay: boolean
      lastUsedDate: string
      id: string
    } | null
    topic?: {
      __typename?: 'Topic'
      userId: string
      mediaId: string
      characterId: string
      name: string
      description: string
      level: string
      languageTopic: string
      status: string
      ordinalNumber: number
      scenario: string
      userRole: string
      aiTutorRole: string
      task: any
      id: string
      createdAt: Date
    } | null
    userAnswer?: {
      __typename?: 'UserAnswer'
      questionId: string
      answerId: string
      topicId: string
      userId: string
      wordInUserId: string
      times: number
      wrongTimes: number
      id: string
      createdAt: Date
    } | null
  }>
  word: Array<{
    __typename?: 'Word'
    userId: string
    topicId: string
    vocabAndPhrase: string
    mediaId: string
    description: string
    isVocabulary: boolean
    isThumbnail: boolean
    isTranslate: boolean
    id: string
    createdAt: Date
    topic?: {
      __typename?: 'Topic'
      userId: string
      name: string
      description: string
      level: string
      mediaId: string
      languageTopic: string
      status: string
      id: string
      createdAt: Date
    } | null
    media?: {
      __typename?: 'Resource'
      type: string
      name: string
      url: string
      fileSize: number
      duration: number
      thumbnailResourceId?: string | null
      createdById?: string | null
      id: string
      createdAt: Date
    } | null
    translate?: {
      __typename?: 'Translate'
      wQAId: string
      text: string
      id: string
      createdAt: Date
      suportLanguage: string
    } | null
    wordInUser?: {
      __typename?: 'WordInUser'
      wordId: string
      userId: string
      topicId: string
      times: number
      used: boolean
      percentListen: number
      id: string
      createdAt: Date
    } | null
  }>
  resourceInWord: Array<{
    __typename?: 'ResourceInWord'
    wordId: string
    type: string
    mediaId: string
    id: string
    createdAt: Date
    word?: {
      __typename?: 'Word'
      userId: string
      topicId: string
      vocabAndPhrase: string
      mediaId: string
      description: string
      isVocabulary: boolean
      isThumbnail: boolean
      isTranslate: boolean
      id: string
      createdAt: Date
    } | null
    media?: {
      __typename?: 'Resource'
      type: string
      name: string
      url: string
      fileSize: number
      duration: number
      thumbnailResourceId?: string | null
      createdById?: string | null
      id: string
      createdAt: Date
    } | null
  }>
  translate: Array<{
    __typename?: 'Translate'
    wQAId: string
    text: string
    id: string
    createdAt: Date
    Word?: {
      __typename?: 'Word'
      userId: string
      topicId: string
      thumbnailId: string
      mediaId: string
      vocabAndPhrase: string
      description: string
      isVocabulary: boolean
      id: string
      createdAt: Date
    } | null
  }>
}

export type GenerateHardGameQueryVariables = Exact<{
  data: Array<InputWord> | InputWord
}>

export type GenerateHardGameQuery = {
  __typename?: 'Query'
  generateHardGame: Array<{
    __typename?: 'GameResult'
    type: TypeGame
    text: string
    index: number
    id: string
    question: string
    answers: Array<{
      __typename?: 'GameAnswer'
      isCorrect: boolean
      text: string
    }>
  }>
}

export type GenerateReviewQueryVariables = Exact<{
  filterTopic?: InputMaybe<TopicFilter>
  filterWord?: InputMaybe<WordFilter>
  filterResourceInWord?: InputMaybe<ResourceInWordFilter>
  filterTranslate?: InputMaybe<TranslateFilter>
  dataHardGame: Array<InputWord> | InputWord
}>

export type GenerateReviewQuery = {
  __typename?: 'Query'
  topic: Array<{
    __typename?: 'Topic'
    userId: string
    mediaId: string
    characterId: string
    name: string
    description: string
    level: string
    languageTopic: string
    status: string
    ordinalNumber: number
    scenario: string
    userRole: string
    aiTutorRole: string
    task: any
    id: string
    createdAt: Date
    user?: {
      __typename?: 'User'
      thumbnailId: string
      aiTutorId: string
      name: string
      email: string
      nativeLanguage: string
      customer: string
      languageLearn: string
      level: any
      isAdmin: boolean
      deactivate: boolean
      speed: number
      repair: number
      autoPlay: boolean
      lastUsedDate: string
      id: string
      createdAt: Date
    } | null
    media?: {
      __typename?: 'Resource'
      type: string
      name: string
      url: string
      fileSize: number
      duration: number
      thumbnailResourceId?: string | null
      id: string
    } | null
    character?: {
      __typename?: 'Resource'
      type: string
      name: string
      url: string
      fileSize: number
      duration: number
      thumbnailResourceId?: string | null
      id: string
    } | null
    wordInUser?: {
      __typename?: 'WordInUser'
      wordId: string
      userId: string
      topicId: string
      times: number
      used: boolean
      percentListen: number
      lastDay?: Date | null
      nextDay?: Date | null
      milestone: number
      isCompleted: boolean
      id: string
      createdAt: Date
    } | null
    topicInCategory?: {
      __typename?: 'TopicInCategory'
      topicId: string
      categoryId: string
      id: string
      createdAt: Date
    } | null
    userAnswer?: {
      __typename?: 'UserAnswer'
      questionId: string
      answerId: string
      topicId: string
      userId: string
      wordInUserId: string
      times: number
      wrongTimes: number
      id: string
      createdAt: Date
    } | null
    topicInUser?: {
      __typename?: 'TopicInUser'
      topicId: string
      userLearnId: string
      unlockTopic: boolean
      percent: number
      id: string
      createdAt: Date
    } | null
  }>
  word: Array<{
    __typename?: 'Word'
    userId: string
    topicId: string
    vocabAndPhrase: string
    mediaId: string
    description: string
    isVocabulary: boolean
    isThumbnail: boolean
    isTranslate: boolean
    id: string
    createdAt: Date
    topic?: {
      __typename?: 'Topic'
      userId: string
      name: string
      description: string
      level: string
      mediaId: string
      languageTopic: string
      status: string
      id: string
      createdAt: Date
    } | null
    media?: {
      __typename?: 'Resource'
      type: string
      name: string
      url: string
      fileSize: number
      duration: number
      thumbnailResourceId?: string | null
      createdById?: string | null
      id: string
      createdAt: Date
    } | null
    translate?: {
      __typename?: 'Translate'
      wQAId: string
      text: string
      id: string
      createdAt: Date
      suportLanguage: string
    } | null
    wordInUser?: {
      __typename?: 'WordInUser'
      wordId: string
      userId: string
      topicId: string
      times: number
      used: boolean
      percentListen: number
      id: string
      createdAt: Date
    } | null
  }>
  resourceInWord: Array<{
    __typename?: 'ResourceInWord'
    wordId: string
    type: string
    mediaId: string
    id: string
    createdAt: Date
    word?: {
      __typename?: 'Word'
      userId: string
      topicId: string
      vocabAndPhrase: string
      mediaId: string
      description: string
      isVocabulary: boolean
      isThumbnail: boolean
      isTranslate: boolean
      id: string
      createdAt: Date
    } | null
    media?: {
      __typename?: 'Resource'
      type: string
      name: string
      url: string
      fileSize: number
      duration: number
      thumbnailResourceId?: string | null
      createdById?: string | null
      id: string
      createdAt: Date
    } | null
  }>
  translate: Array<{
    __typename?: 'Translate'
    wQAId: string
    text: string
    id: string
    createdAt: Date
    Word?: {
      __typename?: 'Word'
      userId: string
      topicId: string
      thumbnailId: string
      mediaId: string
      vocabAndPhrase: string
      description: string
      isVocabulary: boolean
      id: string
      createdAt: Date
    } | null
  }>
  hardGame: Array<{
    __typename?: 'GameResult'
    type: TypeGame
    text: string
    id: string
    index: number
    question: string
    answers: Array<{
      __typename?: 'GameAnswer'
      isCorrect: boolean
      text: string
    }>
  }>
}

export type SearchTestTotalQueryVariables = Exact<{
  filterQ?: InputMaybe<QuestionFilter>
  filterW?: InputMaybe<WordFilter>
  filterP?: InputMaybe<WordFilter>
}>

export type SearchTestTotalQuery = {
  __typename?: 'Query'
  listen: number
  word: number
  phrase: number
}

export type CreatePaymentIntentMutationVariables = Exact<{
  data?: InputMaybe<InputCreatePaymentIntent>
}>

export type CreatePaymentIntentMutation = {
  __typename?: 'Mutation'
  createPaymentIntent: {
    __typename?: 'Respone'
    clientSecret?: string | null
    customer?: string | null
    paymentIntent?: string | null
  }
}

export type ConfirmPaymentIntentQueryVariables = Exact<{
  data?: InputMaybe<InputConfirmPaymentIntent>
}>

export type ConfirmPaymentIntentQuery = {
  __typename?: 'Query'
  confirmPaymentIntent: any
}

export type ListCustomerPaymentMethodQueryVariables = Exact<{
  [key: string]: never
}>

export type ListCustomerPaymentMethodQuery = {
  __typename?: 'Query'
  listCustomerPaymentMethod: any
}

export type CheckUserSubscribedQueryVariables = Exact<{ [key: string]: never }>

export type CheckUserSubscribedQuery = {
  __typename?: 'Query'
  checkUserSubscribed: any
}

export type MovePositionMutationVariables = Exact<{
  ids: Array<Scalars['ID']['input']> | Scalars['ID']['input']
}>

export type MovePositionMutation = {
  __typename?: 'Mutation'
  movePosition: Array<string>
}

export type PriceFFragment = {
  __typename?: 'Price'
  planId: string
  price: string
  object: string
  active: boolean
  billingScheme: string
  currency: string
  customUnitAmount: string
  metadata?: any | null
  product: string
  recurring: any
  taxBehavior: string
  type: string
  unitAmount: number
  unitAmountDecimal: string
  id: string
  createdAt: Date
  plan?: {
    __typename?: 'Product'
    idProduct: string
    name: string
    description: string
    images?: any | null
    marketingFeatures?: any | null
    active: boolean
    type: string
    defaultPrice?: any | null
    metadata?: any | null
    gifId: string
    titleGif: string
    titlePlansGif: string
    subTitleGif: string
    id: string
    createdAt: Date
  } | null
}

export type CreatePriceMutationVariables = Exact<{
  data: InputCreatePrice
}>

export type CreatePriceMutation = {
  __typename?: 'Mutation'
  createPrice: {
    __typename?: 'Price'
    planId: string
    price: string
    object: string
    active: boolean
    billingScheme: string
    currency: string
    customUnitAmount: string
    metadata?: any | null
    product: string
    recurring: any
    taxBehavior: string
    type: string
    unitAmount: number
    unitAmountDecimal: string
    id: string
    createdAt: Date
    plan?: {
      __typename?: 'Product'
      idProduct: string
      name: string
      description: string
      images?: any | null
      marketingFeatures?: any | null
      active: boolean
      type: string
      defaultPrice?: any | null
      metadata?: any | null
      gifId: string
      titleGif: string
      titlePlansGif: string
      subTitleGif: string
      id: string
      createdAt: Date
    } | null
  }
}

export type UpdatePriceMutationVariables = Exact<{
  id: Scalars['ID']['input']
  data: UpdatePrice
}>

export type UpdatePriceMutation = {
  __typename?: 'Mutation'
  updatePrice: {
    __typename?: 'Price'
    planId: string
    price: string
    object: string
    active: boolean
    billingScheme: string
    currency: string
    customUnitAmount: string
    metadata?: any | null
    product: string
    recurring: any
    taxBehavior: string
    type: string
    unitAmount: number
    unitAmountDecimal: string
    id: string
    createdAt: Date
    plan?: {
      __typename?: 'Product'
      idProduct: string
      name: string
      description: string
      images?: any | null
      marketingFeatures?: any | null
      active: boolean
      type: string
      defaultPrice?: any | null
      metadata?: any | null
      gifId: string
      titleGif: string
      titlePlansGif: string
      subTitleGif: string
      id: string
      createdAt: Date
    } | null
  }
}

export type SearchPriceQueryVariables = Exact<{
  filter?: InputMaybe<PriceFilter>
  order?: InputMaybe<Array<PriceOrderBy> | PriceOrderBy>
  page?: InputMaybe<Pagination>
}>

export type SearchPriceQuery = {
  __typename?: 'Query'
  searchPrice: Array<{
    __typename?: 'Price'
    planId: string
    price: string
    object: string
    active: boolean
    billingScheme: string
    currency: string
    customUnitAmount: string
    metadata?: any | null
    product: string
    recurring: any
    taxBehavior: string
    type: string
    unitAmount: number
    unitAmountDecimal: string
    id: string
    createdAt: Date
    plan?: {
      __typename?: 'Product'
      idProduct: string
      name: string
      description: string
      images?: any | null
      marketingFeatures?: any | null
      active: boolean
      type: string
      defaultPrice?: any | null
      metadata?: any | null
      gifId: string
      titleGif: string
      titlePlansGif: string
      subTitleGif: string
      id: string
      createdAt: Date
    } | null
  }>
}

export type ProductFFragment = {
  __typename?: 'Product'
  idProduct: string
  priceId: string
  idPrice: string
  name: string
  discountPercent: number
  description: string
  images?: any | null
  marketingFeatures?: any | null
  active: boolean
  type: string
  defaultPrice?: any | null
  metadata?: any | null
  gifId: string
  titleGif: string
  titlePlansGif: string
  subTitleGif: string
  id: string
  createdAt: Date
  gif?: {
    __typename?: 'Resource'
    type: string
    name: string
    url: string
    fileSize: number
    originalResourceId?: string | null
    originalResourceState?: any | null
    duration: number
    thumbnailResourceId?: string | null
    createdById?: string | null
    id: string
    createdAt: Date
  } | null
  price?: {
    __typename?: 'Price'
    planId: string
    price: string
    object: string
    active: boolean
    billingScheme: string
    currency: string
    customUnitAmount: string
    metadata?: any | null
    product: string
    recurring: any
    taxBehavior: string
    type: string
    unitAmount: number
    unitAmountDecimal: string
    id: string
    createdAt: Date
  } | null
}

export type CreateProductMutationVariables = Exact<{
  data?: InputMaybe<InputCreateProduct>
}>

export type CreateProductMutation = {
  __typename?: 'Mutation'
  createProduct: {
    __typename?: 'Product'
    idProduct: string
    priceId: string
    idPrice: string
    name: string
    discountPercent: number
    description: string
    images?: any | null
    marketingFeatures?: any | null
    active: boolean
    type: string
    defaultPrice?: any | null
    metadata?: any | null
    gifId: string
    titleGif: string
    titlePlansGif: string
    subTitleGif: string
    id: string
    createdAt: Date
    gif?: {
      __typename?: 'Resource'
      type: string
      name: string
      url: string
      fileSize: number
      originalResourceId?: string | null
      originalResourceState?: any | null
      duration: number
      thumbnailResourceId?: string | null
      createdById?: string | null
      id: string
      createdAt: Date
    } | null
    price?: {
      __typename?: 'Price'
      planId: string
      price: string
      object: string
      active: boolean
      billingScheme: string
      currency: string
      customUnitAmount: string
      metadata?: any | null
      product: string
      recurring: any
      taxBehavior: string
      type: string
      unitAmount: number
      unitAmountDecimal: string
      id: string
      createdAt: Date
    } | null
  }
}

export type UpdateProductMutationVariables = Exact<{
  id: Scalars['ID']['input']
  data: UpdateProduct
}>

export type UpdateProductMutation = {
  __typename?: 'Mutation'
  updateProduct: {
    __typename?: 'Product'
    idProduct: string
    priceId: string
    idPrice: string
    name: string
    discountPercent: number
    description: string
    images?: any | null
    marketingFeatures?: any | null
    active: boolean
    type: string
    defaultPrice?: any | null
    metadata?: any | null
    gifId: string
    titleGif: string
    titlePlansGif: string
    subTitleGif: string
    id: string
    createdAt: Date
    gif?: {
      __typename?: 'Resource'
      type: string
      name: string
      url: string
      fileSize: number
      originalResourceId?: string | null
      originalResourceState?: any | null
      duration: number
      thumbnailResourceId?: string | null
      createdById?: string | null
      id: string
      createdAt: Date
    } | null
    price?: {
      __typename?: 'Price'
      planId: string
      price: string
      object: string
      active: boolean
      billingScheme: string
      currency: string
      customUnitAmount: string
      metadata?: any | null
      product: string
      recurring: any
      taxBehavior: string
      type: string
      unitAmount: number
      unitAmountDecimal: string
      id: string
      createdAt: Date
    } | null
  }
}

export type DeleteProductMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DeleteProductMutation = {
  __typename?: 'Mutation'
  deleteProduct?: null | null
}

export type SearchProductQueryVariables = Exact<{
  filter?: InputMaybe<ProductFilter>
  order?: InputMaybe<Array<ProductOrderBy> | ProductOrderBy>
  page?: InputMaybe<Pagination>
}>

export type SearchProductQuery = {
  __typename?: 'Query'
  count: number
  searchProduct: Array<{
    __typename?: 'Product'
    idProduct: string
    priceId: string
    idPrice: string
    name: string
    discountPercent: number
    description: string
    images?: any | null
    marketingFeatures?: any | null
    active: boolean
    type: string
    defaultPrice?: any | null
    metadata?: any | null
    gifId: string
    titleGif: string
    titlePlansGif: string
    subTitleGif: string
    id: string
    createdAt: Date
    gif?: {
      __typename?: 'Resource'
      type: string
      name: string
      url: string
      fileSize: number
      originalResourceId?: string | null
      originalResourceState?: any | null
      duration: number
      thumbnailResourceId?: string | null
      createdById?: string | null
      id: string
      createdAt: Date
    } | null
    price?: {
      __typename?: 'Price'
      planId: string
      price: string
      object: string
      active: boolean
      billingScheme: string
      currency: string
      customUnitAmount: string
      metadata?: any | null
      product: string
      recurring: any
      taxBehavior: string
      type: string
      unitAmount: number
      unitAmountDecimal: string
      id: string
      createdAt: Date
    } | null
  }>
}

export type QuestionFFragment = {
  __typename?: 'Question'
  topicId: string
  type: string
  text: string
  index: number
  wordId: string
  mediaId: string
  thumbnailId: string
  id: string
  createdAt: Date
  topic?: {
    __typename?: 'Topic'
    userId: string
    name: string
    description: string
    level: string
    mediaId: string
    languageTopic: string
    status: string
    id: string
    createdAt: Date
    media?: { __typename?: 'Resource'; id: string; url: string } | null
  } | null
  word?: {
    __typename?: 'Word'
    userId: string
    topicId: string
    vocabAndPhrase: string
    mediaId: string
    description: string
    isVocabulary: boolean
    id: string
    createdAt: Date
    media?: { __typename?: 'Resource'; id: string; url: string } | null
  } | null
  media?: {
    __typename?: 'Resource'
    type: string
    name: string
    url: string
    fileSize: number
    duration: number
    thumbnailResourceId?: string | null
    createdById?: string | null
    id: string
    createdAt: Date
  } | null
  thumbnail?: {
    __typename?: 'Resource'
    type: string
    name: string
    url: string
    fileSize: number
    duration: number
    thumbnailResourceId?: string | null
    createdById?: string | null
    id: string
    createdAt: Date
  } | null
  translate?: {
    __typename?: 'Translate'
    wQAId: string
    text: string
    id: string
    createdAt: Date
    suportLanguage: string
  } | null
}

export type SearchQuestionQueryVariables = Exact<{
  filter?: InputMaybe<QuestionFilter>
  order?: InputMaybe<Array<QuestionOrderBy> | QuestionOrderBy>
  page?: InputMaybe<Pagination>
}>

export type SearchQuestionQuery = {
  __typename?: 'Query'
  searchQuestion: Array<{
    __typename?: 'Question'
    topicId: string
    type: string
    text: string
    index: number
    wordId: string
    mediaId: string
    thumbnailId: string
    id: string
    createdAt: Date
    topic?: {
      __typename?: 'Topic'
      userId: string
      name: string
      description: string
      level: string
      mediaId: string
      languageTopic: string
      status: string
      id: string
      createdAt: Date
      media?: { __typename?: 'Resource'; id: string; url: string } | null
    } | null
    word?: {
      __typename?: 'Word'
      userId: string
      topicId: string
      vocabAndPhrase: string
      mediaId: string
      description: string
      isVocabulary: boolean
      id: string
      createdAt: Date
      media?: { __typename?: 'Resource'; id: string; url: string } | null
    } | null
    media?: {
      __typename?: 'Resource'
      type: string
      name: string
      url: string
      fileSize: number
      duration: number
      thumbnailResourceId?: string | null
      createdById?: string | null
      id: string
      createdAt: Date
    } | null
    thumbnail?: {
      __typename?: 'Resource'
      type: string
      name: string
      url: string
      fileSize: number
      duration: number
      thumbnailResourceId?: string | null
      createdById?: string | null
      id: string
      createdAt: Date
    } | null
    translate?: {
      __typename?: 'Translate'
      wQAId: string
      text: string
      id: string
      createdAt: Date
      suportLanguage: string
    } | null
  }>
}

export type CountQuestionQueryVariables = Exact<{
  filter?: InputMaybe<QuestionFilter>
}>

export type CountQuestionQuery = { __typename?: 'Query'; countQuestion: number }

export type CreateQuestionMutationVariables = Exact<{
  data: CreateQuestion
}>

export type CreateQuestionMutation = {
  __typename?: 'Mutation'
  createQuestion: {
    __typename?: 'Question'
    topicId: string
    type: string
    text: string
    index: number
    wordId: string
    mediaId: string
    thumbnailId: string
    id: string
    createdAt: Date
    topic?: {
      __typename?: 'Topic'
      userId: string
      name: string
      description: string
      level: string
      mediaId: string
      languageTopic: string
      status: string
      id: string
      createdAt: Date
      media?: { __typename?: 'Resource'; id: string; url: string } | null
    } | null
    word?: {
      __typename?: 'Word'
      userId: string
      topicId: string
      vocabAndPhrase: string
      mediaId: string
      description: string
      isVocabulary: boolean
      id: string
      createdAt: Date
      media?: { __typename?: 'Resource'; id: string; url: string } | null
    } | null
    media?: {
      __typename?: 'Resource'
      type: string
      name: string
      url: string
      fileSize: number
      duration: number
      thumbnailResourceId?: string | null
      createdById?: string | null
      id: string
      createdAt: Date
    } | null
    thumbnail?: {
      __typename?: 'Resource'
      type: string
      name: string
      url: string
      fileSize: number
      duration: number
      thumbnailResourceId?: string | null
      createdById?: string | null
      id: string
      createdAt: Date
    } | null
    translate?: {
      __typename?: 'Translate'
      wQAId: string
      text: string
      id: string
      createdAt: Date
      suportLanguage: string
    } | null
  }
}

export type UpdateQuestionMutationVariables = Exact<{
  id: Scalars['ID']['input']
  data: UpdateQuestion
}>

export type UpdateQuestionMutation = {
  __typename?: 'Mutation'
  updateQuestion: {
    __typename?: 'Question'
    topicId: string
    type: string
    text: string
    index: number
    wordId: string
    mediaId: string
    thumbnailId: string
    id: string
    createdAt: Date
    topic?: {
      __typename?: 'Topic'
      userId: string
      name: string
      description: string
      level: string
      mediaId: string
      languageTopic: string
      status: string
      id: string
      createdAt: Date
      media?: { __typename?: 'Resource'; id: string; url: string } | null
    } | null
    word?: {
      __typename?: 'Word'
      userId: string
      topicId: string
      vocabAndPhrase: string
      mediaId: string
      description: string
      isVocabulary: boolean
      id: string
      createdAt: Date
      media?: { __typename?: 'Resource'; id: string; url: string } | null
    } | null
    media?: {
      __typename?: 'Resource'
      type: string
      name: string
      url: string
      fileSize: number
      duration: number
      thumbnailResourceId?: string | null
      createdById?: string | null
      id: string
      createdAt: Date
    } | null
    thumbnail?: {
      __typename?: 'Resource'
      type: string
      name: string
      url: string
      fileSize: number
      duration: number
      thumbnailResourceId?: string | null
      createdById?: string | null
      id: string
      createdAt: Date
    } | null
    translate?: {
      __typename?: 'Translate'
      wQAId: string
      text: string
      id: string
      createdAt: Date
      suportLanguage: string
    } | null
  }
}

export type DeleteQuestionMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DeleteQuestionMutation = {
  __typename?: 'Mutation'
  deleteQuestion?: null | null
}

export type ResourceInWordFFragment = {
  __typename?: 'ResourceInWord'
  wordId: string
  type: string
  mediaId: string
  id: string
  createdAt: Date
  word?: {
    __typename?: 'Word'
    userId: string
    topicId: string
    vocabAndPhrase: string
    mediaId: string
    description: string
    isVocabulary: boolean
    isThumbnail: boolean
    isTranslate: boolean
    id: string
    createdAt: Date
  } | null
  media?: {
    __typename?: 'Resource'
    type: string
    name: string
    url: string
    fileSize: number
    duration: number
    thumbnailResourceId?: string | null
    createdById?: string | null
    id: string
    createdAt: Date
  } | null
}

export type SearchResourceInWordQueryVariables = Exact<{
  filter?: InputMaybe<ResourceInWordFilter>
  order?: InputMaybe<Array<ResourceInWordOrderBy> | ResourceInWordOrderBy>
  page?: InputMaybe<Pagination>
}>

export type SearchResourceInWordQuery = {
  __typename?: 'Query'
  searchResourceInWord: Array<{
    __typename?: 'ResourceInWord'
    wordId: string
    type: string
    mediaId: string
    id: string
    createdAt: Date
    word?: {
      __typename?: 'Word'
      userId: string
      topicId: string
      vocabAndPhrase: string
      mediaId: string
      description: string
      isVocabulary: boolean
      isThumbnail: boolean
      isTranslate: boolean
      id: string
      createdAt: Date
    } | null
    media?: {
      __typename?: 'Resource'
      type: string
      name: string
      url: string
      fileSize: number
      duration: number
      thumbnailResourceId?: string | null
      createdById?: string | null
      id: string
      createdAt: Date
    } | null
  }>
}

export type CountResourceInWordQueryVariables = Exact<{
  filter?: InputMaybe<ResourceInWordFilter>
}>

export type CountResourceInWordQuery = {
  __typename?: 'Query'
  countResourceInWord: number
}

export type CreateResourceInWordMutationVariables = Exact<{
  data: CreateResourceInWord
}>

export type CreateResourceInWordMutation = {
  __typename?: 'Mutation'
  createResourceInWord: {
    __typename?: 'ResourceInWord'
    wordId: string
    type: string
    mediaId: string
    id: string
    createdAt: Date
    word?: {
      __typename?: 'Word'
      userId: string
      topicId: string
      vocabAndPhrase: string
      mediaId: string
      description: string
      isVocabulary: boolean
      isThumbnail: boolean
      isTranslate: boolean
      id: string
      createdAt: Date
    } | null
    media?: {
      __typename?: 'Resource'
      type: string
      name: string
      url: string
      fileSize: number
      duration: number
      thumbnailResourceId?: string | null
      createdById?: string | null
      id: string
      createdAt: Date
    } | null
  }
}

export type DeleteResourceInWordMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DeleteResourceInWordMutation = {
  __typename?: 'Mutation'
  deleteResourceInWord?: null | null
}

export type ServerTimeQueryVariables = Exact<{ [key: string]: never }>

export type ServerTimeQuery = { __typename?: 'Query'; serverTime: number }

export type SplashFFragment = {
  __typename?: 'Splash'
  thumbnailId: string
  title: string
  subTitle: string
  id: string
  createdAt: Date
  thumbnail?: {
    __typename?: 'Resource'
    type: string
    name: string
    url: string
    fileSize: number
    originalResourceId?: string | null
    originalResourceState?: any | null
    duration: number
    thumbnailResourceId?: string | null
    createdById?: string | null
    id: string
    createdAt: Date
  } | null
}

export type SearchSplashQueryVariables = Exact<{
  filter?: InputMaybe<SplashFilter>
  order?: InputMaybe<Array<SplashOrderBy> | SplashOrderBy>
  page?: InputMaybe<Pagination>
}>

export type SearchSplashQuery = {
  __typename?: 'Query'
  searchSplash: Array<{
    __typename?: 'Splash'
    thumbnailId: string
    title: string
    subTitle: string
    id: string
    createdAt: Date
    thumbnail?: {
      __typename?: 'Resource'
      type: string
      name: string
      url: string
      fileSize: number
      originalResourceId?: string | null
      originalResourceState?: any | null
      duration: number
      thumbnailResourceId?: string | null
      createdById?: string | null
      id: string
      createdAt: Date
    } | null
  }>
}

export type CountSplashQueryVariables = Exact<{
  filter?: InputMaybe<SplashFilter>
}>

export type CountSplashQuery = { __typename?: 'Query'; countSplash: number }

export type CreateSplashMutationVariables = Exact<{
  data: CreateSplash
}>

export type CreateSplashMutation = {
  __typename?: 'Mutation'
  createSplash: {
    __typename?: 'Splash'
    thumbnailId: string
    title: string
    subTitle: string
    id: string
    createdAt: Date
    thumbnail?: {
      __typename?: 'Resource'
      type: string
      name: string
      url: string
      fileSize: number
      originalResourceId?: string | null
      originalResourceState?: any | null
      duration: number
      thumbnailResourceId?: string | null
      createdById?: string | null
      id: string
      createdAt: Date
    } | null
  }
}

export type UpdateSplashMutationVariables = Exact<{
  id: Scalars['ID']['input']
  data: UpdateSplash
}>

export type UpdateSplashMutation = {
  __typename?: 'Mutation'
  updateSplash: {
    __typename?: 'Splash'
    thumbnailId: string
    title: string
    subTitle: string
    id: string
    createdAt: Date
    thumbnail?: {
      __typename?: 'Resource'
      type: string
      name: string
      url: string
      fileSize: number
      originalResourceId?: string | null
      originalResourceState?: any | null
      duration: number
      thumbnailResourceId?: string | null
      createdById?: string | null
      id: string
      createdAt: Date
    } | null
  }
}

export type DeleteSplashMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DeleteSplashMutation = {
  __typename?: 'Mutation'
  deleteSplash?: null | null
}

export type StreakFFragment = {
  __typename?: 'Streak'
  mediaId: string
  thumbnailId: string
  numberStreak: number
  infor: any
  id: string
  createdAt: Date
  media?: {
    __typename?: 'Resource'
    type: string
    name: string
    url: string
    fileSize: number
  } | null
  thumbnail?: {
    __typename?: 'Resource'
    type: string
    name: string
    url: string
    fileSize: number
  } | null
}

export type SearchStreakQueryVariables = Exact<{
  filter?: InputMaybe<StreakFilter>
  order?: InputMaybe<Array<StreakOrderBy> | StreakOrderBy>
  page?: InputMaybe<Pagination>
}>

export type SearchStreakQuery = {
  __typename?: 'Query'
  searchStreak: Array<{
    __typename?: 'Streak'
    mediaId: string
    thumbnailId: string
    numberStreak: number
    infor: any
    id: string
    createdAt: Date
    media?: {
      __typename?: 'Resource'
      type: string
      name: string
      url: string
      fileSize: number
    } | null
    thumbnail?: {
      __typename?: 'Resource'
      type: string
      name: string
      url: string
      fileSize: number
    } | null
  }>
}

export type CountStreakQueryVariables = Exact<{
  filter?: InputMaybe<StreakFilter>
}>

export type CountStreakQuery = { __typename?: 'Query'; countStreak: number }

export type SearchStreaksQueryVariables = Exact<{
  filter?: InputMaybe<StreakFilter>
  order?: InputMaybe<Array<StreakOrderBy> | StreakOrderBy>
  page?: InputMaybe<Pagination>
}>

export type SearchStreaksQuery = {
  __typename?: 'Query'
  count: number
  searchStreaks: Array<{
    __typename?: 'Streak'
    mediaId: string
    thumbnailId: string
    numberStreak: number
    infor: any
    id: string
    createdAt: Date
    media?: {
      __typename?: 'Resource'
      type: string
      name: string
      url: string
      fileSize: number
    } | null
    thumbnail?: {
      __typename?: 'Resource'
      type: string
      name: string
      url: string
      fileSize: number
    } | null
  }>
}

export type CreateStreakMutationVariables = Exact<{
  data: CreateStreak
}>

export type CreateStreakMutation = {
  __typename?: 'Mutation'
  createStreak: {
    __typename?: 'Streak'
    mediaId: string
    thumbnailId: string
    numberStreak: number
    infor: any
    id: string
    createdAt: Date
    media?: {
      __typename?: 'Resource'
      type: string
      name: string
      url: string
      fileSize: number
    } | null
    thumbnail?: {
      __typename?: 'Resource'
      type: string
      name: string
      url: string
      fileSize: number
    } | null
  }
}

export type UpdateStreakMutationVariables = Exact<{
  id: Scalars['ID']['input']
  data: UpdateStreak
}>

export type UpdateStreakMutation = {
  __typename?: 'Mutation'
  updateStreak: {
    __typename?: 'Streak'
    mediaId: string
    thumbnailId: string
    numberStreak: number
    infor: any
    id: string
    createdAt: Date
    media?: {
      __typename?: 'Resource'
      type: string
      name: string
      url: string
      fileSize: number
    } | null
    thumbnail?: {
      __typename?: 'Resource'
      type: string
      name: string
      url: string
      fileSize: number
    } | null
  }
}

export type DeleteStreakMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DeleteStreakMutation = {
  __typename?: 'Mutation'
  deleteStreak?: null | null
}

export type StreakDailyFFragment = {
  __typename?: 'StreakDaily'
  name: string
  id: string
  createdAt: Date
}

export type SearchStreakDailyQueryVariables = Exact<{
  filter?: InputMaybe<StreakDailyFilter>
  order?: InputMaybe<Array<StreakDailyOrderBy> | StreakDailyOrderBy>
  page?: InputMaybe<Pagination>
}>

export type SearchStreakDailyQuery = {
  __typename?: 'Query'
  searchStreakDaily: Array<{
    __typename?: 'StreakDaily'
    name: string
    id: string
    createdAt: Date
  }>
}

export type CountStreakDailyQueryVariables = Exact<{
  filter?: InputMaybe<StreakDailyFilter>
}>

export type CountStreakDailyQuery = {
  __typename?: 'Query'
  countStreakDaily: number
}

export type SearchStreakDailysQueryVariables = Exact<{
  filter?: InputMaybe<StreakDailyFilter>
  order?: InputMaybe<Array<StreakDailyOrderBy> | StreakDailyOrderBy>
  page?: InputMaybe<Pagination>
}>

export type SearchStreakDailysQuery = {
  __typename?: 'Query'
  count: number
  searchStreakDailys: Array<{
    __typename?: 'StreakDaily'
    name: string
    id: string
    createdAt: Date
  }>
}

export type CreateStreakDailyMutationVariables = Exact<{
  data: CreateStreakDaily
}>

export type CreateStreakDailyMutation = {
  __typename?: 'Mutation'
  createStreakDaily: {
    __typename?: 'StreakDaily'
    name: string
    id: string
    createdAt: Date
  }
}

export type UpdateStreakDailyMutationVariables = Exact<{
  id: Scalars['ID']['input']
  data: UpdateStreakDaily
}>

export type UpdateStreakDailyMutation = {
  __typename?: 'Mutation'
  updateStreakDaily: {
    __typename?: 'StreakDaily'
    name: string
    id: string
    createdAt: Date
  }
}

export type DeleteStreakDailyMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DeleteStreakDailyMutation = {
  __typename?: 'Mutation'
  deleteStreakDaily?: null | null
}

export type StreakInUserFFragment = {
  __typename?: 'StreakInUser'
  userId: string
  streakId: string
  countStreak: number
  highestStreak: number
  dateStreak: any
  lastDate: string
  id: string
  createdAt: Date
  user?: {
    __typename?: 'User'
    thumbnailId: string
    aiTutorId: string
    name: string
    email: string
    nativeLanguage: string
    customer: string
    languageLearn: string
    level: any
    isAdmin: boolean
    deactivate: boolean
    speed: number
    repair: number
    autoPlay: boolean
    lastUsedDate: string
    id: string
    createdAt: Date
  } | null
  streak?: {
    __typename?: 'Streak'
    mediaId: string
    thumbnailId: string
    numberStreak: number
    infor: any
    id: string
    createdAt: Date
  } | null
}

export type SearchStreakInUserQueryVariables = Exact<{
  filter?: InputMaybe<StreakInUserFilter>
  order?: InputMaybe<Array<StreakInUserOrderBy> | StreakInUserOrderBy>
  page?: InputMaybe<Pagination>
}>

export type SearchStreakInUserQuery = {
  __typename?: 'Query'
  searchStreakInUser: Array<{
    __typename?: 'StreakInUser'
    userId: string
    streakId: string
    countStreak: number
    highestStreak: number
    dateStreak: any
    lastDate: string
    id: string
    createdAt: Date
    user?: {
      __typename?: 'User'
      thumbnailId: string
      aiTutorId: string
      name: string
      email: string
      nativeLanguage: string
      customer: string
      languageLearn: string
      level: any
      isAdmin: boolean
      deactivate: boolean
      speed: number
      repair: number
      autoPlay: boolean
      lastUsedDate: string
      id: string
      createdAt: Date
    } | null
    streak?: {
      __typename?: 'Streak'
      mediaId: string
      thumbnailId: string
      numberStreak: number
      infor: any
      id: string
      createdAt: Date
    } | null
  }>
}

export type CountStreakInUserQueryVariables = Exact<{
  filter?: InputMaybe<StreakInUserFilter>
}>

export type CountStreakInUserQuery = {
  __typename?: 'Query'
  countStreakInUser: number
}

export type SearchStreakInUsersQueryVariables = Exact<{
  filter?: InputMaybe<StreakInUserFilter>
  order?: InputMaybe<Array<StreakInUserOrderBy> | StreakInUserOrderBy>
  page?: InputMaybe<Pagination>
}>

export type SearchStreakInUsersQuery = {
  __typename?: 'Query'
  count: number
  searchStreakInUsers: Array<{
    __typename?: 'StreakInUser'
    userId: string
    streakId: string
    countStreak: number
    highestStreak: number
    dateStreak: any
    lastDate: string
    id: string
    createdAt: Date
    user?: {
      __typename?: 'User'
      thumbnailId: string
      aiTutorId: string
      name: string
      email: string
      nativeLanguage: string
      customer: string
      languageLearn: string
      level: any
      isAdmin: boolean
      deactivate: boolean
      speed: number
      repair: number
      autoPlay: boolean
      lastUsedDate: string
      id: string
      createdAt: Date
    } | null
    streak?: {
      __typename?: 'Streak'
      mediaId: string
      thumbnailId: string
      numberStreak: number
      infor: any
      id: string
      createdAt: Date
    } | null
  }>
}

export type CreateStreakInUserMutationVariables = Exact<{
  data: CreateStreakInUser
}>

export type CreateStreakInUserMutation = {
  __typename?: 'Mutation'
  createStreakInUser: {
    __typename?: 'StreakInUser'
    userId: string
    streakId: string
    countStreak: number
    highestStreak: number
    dateStreak: any
    lastDate: string
    id: string
    createdAt: Date
    user?: {
      __typename?: 'User'
      thumbnailId: string
      aiTutorId: string
      name: string
      email: string
      nativeLanguage: string
      customer: string
      languageLearn: string
      level: any
      isAdmin: boolean
      deactivate: boolean
      speed: number
      repair: number
      autoPlay: boolean
      lastUsedDate: string
      id: string
      createdAt: Date
    } | null
    streak?: {
      __typename?: 'Streak'
      mediaId: string
      thumbnailId: string
      numberStreak: number
      infor: any
      id: string
      createdAt: Date
    } | null
  }
}

export type UpdateStreakInUserMutationVariables = Exact<{
  id: Scalars['ID']['input']
  data: UpdateStreakInUser
}>

export type UpdateStreakInUserMutation = {
  __typename?: 'Mutation'
  updateStreakInUser: {
    __typename?: 'StreakInUser'
    userId: string
    streakId: string
    countStreak: number
    highestStreak: number
    dateStreak: any
    lastDate: string
    id: string
    createdAt: Date
    user?: {
      __typename?: 'User'
      thumbnailId: string
      aiTutorId: string
      name: string
      email: string
      nativeLanguage: string
      customer: string
      languageLearn: string
      level: any
      isAdmin: boolean
      deactivate: boolean
      speed: number
      repair: number
      autoPlay: boolean
      lastUsedDate: string
      id: string
      createdAt: Date
    } | null
    streak?: {
      __typename?: 'Streak'
      mediaId: string
      thumbnailId: string
      numberStreak: number
      infor: any
      id: string
      createdAt: Date
    } | null
  }
}

export type DeleteStreakInUserMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DeleteStreakInUserMutation = {
  __typename?: 'Mutation'
  deleteStreakInUser?: null | null
}

export type TopicFFragment = {
  __typename?: 'Topic'
  userId: string
  mediaId: string
  characterId: string
  name: string
  description: string
  level: string
  languageTopic: string
  status: string
  ordinalNumber: number
  scenario: string
  userRole: string
  aiTutorRole: string
  task: any
  id: string
  createdAt: Date
  user?: {
    __typename?: 'User'
    thumbnailId: string
    aiTutorId: string
    name: string
    email: string
    nativeLanguage: string
    customer: string
    languageLearn: string
    level: any
    isAdmin: boolean
    deactivate: boolean
    speed: number
    repair: number
    autoPlay: boolean
    lastUsedDate: string
    id: string
    createdAt: Date
  } | null
  media?: {
    __typename?: 'Resource'
    type: string
    name: string
    url: string
    fileSize: number
    duration: number
    thumbnailResourceId?: string | null
    id: string
  } | null
  character?: {
    __typename?: 'Resource'
    type: string
    name: string
    url: string
    fileSize: number
    duration: number
    thumbnailResourceId?: string | null
    id: string
  } | null
  wordInUser?: {
    __typename?: 'WordInUser'
    wordId: string
    userId: string
    topicId: string
    times: number
    used: boolean
    percentListen: number
    lastDay?: Date | null
    nextDay?: Date | null
    milestone: number
    isCompleted: boolean
    id: string
    createdAt: Date
  } | null
  topicInCategory?: {
    __typename?: 'TopicInCategory'
    topicId: string
    categoryId: string
    id: string
    createdAt: Date
  } | null
  userAnswer?: {
    __typename?: 'UserAnswer'
    questionId: string
    answerId: string
    topicId: string
    userId: string
    wordInUserId: string
    times: number
    wrongTimes: number
    id: string
    createdAt: Date
  } | null
  topicInUser?: {
    __typename?: 'TopicInUser'
    topicId: string
    userLearnId: string
    unlockTopic: boolean
    percent: number
    id: string
    createdAt: Date
  } | null
}

export type SearchTopicQueryVariables = Exact<{
  filter?: InputMaybe<TopicFilter>
  order?: InputMaybe<Array<TopicOrderBy> | TopicOrderBy>
  page?: InputMaybe<Pagination>
}>

export type SearchTopicQuery = {
  __typename?: 'Query'
  searchTopic: Array<{
    __typename?: 'Topic'
    userId: string
    mediaId: string
    characterId: string
    name: string
    description: string
    level: string
    languageTopic: string
    status: string
    ordinalNumber: number
    scenario: string
    userRole: string
    aiTutorRole: string
    task: any
    id: string
    createdAt: Date
    user?: {
      __typename?: 'User'
      thumbnailId: string
      aiTutorId: string
      name: string
      email: string
      nativeLanguage: string
      customer: string
      languageLearn: string
      level: any
      isAdmin: boolean
      deactivate: boolean
      speed: number
      repair: number
      autoPlay: boolean
      lastUsedDate: string
      id: string
      createdAt: Date
    } | null
    media?: {
      __typename?: 'Resource'
      type: string
      name: string
      url: string
      fileSize: number
      duration: number
      thumbnailResourceId?: string | null
      id: string
    } | null
    character?: {
      __typename?: 'Resource'
      type: string
      name: string
      url: string
      fileSize: number
      duration: number
      thumbnailResourceId?: string | null
      id: string
    } | null
    wordInUser?: {
      __typename?: 'WordInUser'
      wordId: string
      userId: string
      topicId: string
      times: number
      used: boolean
      percentListen: number
      lastDay?: Date | null
      nextDay?: Date | null
      milestone: number
      isCompleted: boolean
      id: string
      createdAt: Date
    } | null
    topicInCategory?: {
      __typename?: 'TopicInCategory'
      topicId: string
      categoryId: string
      id: string
      createdAt: Date
    } | null
    userAnswer?: {
      __typename?: 'UserAnswer'
      questionId: string
      answerId: string
      topicId: string
      userId: string
      wordInUserId: string
      times: number
      wrongTimes: number
      id: string
      createdAt: Date
    } | null
    topicInUser?: {
      __typename?: 'TopicInUser'
      topicId: string
      userLearnId: string
      unlockTopic: boolean
      percent: number
      id: string
      createdAt: Date
    } | null
  }>
}

export type CountTopicQueryVariables = Exact<{
  filter?: InputMaybe<TopicFilter>
}>

export type CountTopicQuery = { __typename?: 'Query'; countTopic: number }

export type SearchTopicsQueryVariables = Exact<{
  filter?: InputMaybe<TopicFilter>
  order?: InputMaybe<Array<TopicOrderBy> | TopicOrderBy>
  page?: InputMaybe<Pagination>
}>

export type SearchTopicsQuery = {
  __typename?: 'Query'
  count: number
  searchTopics: Array<{
    __typename?: 'Topic'
    userId: string
    mediaId: string
    characterId: string
    name: string
    description: string
    level: string
    languageTopic: string
    status: string
    ordinalNumber: number
    scenario: string
    userRole: string
    aiTutorRole: string
    task: any
    id: string
    createdAt: Date
    user?: {
      __typename?: 'User'
      thumbnailId: string
      aiTutorId: string
      name: string
      email: string
      nativeLanguage: string
      customer: string
      languageLearn: string
      level: any
      isAdmin: boolean
      deactivate: boolean
      speed: number
      repair: number
      autoPlay: boolean
      lastUsedDate: string
      id: string
      createdAt: Date
    } | null
    media?: {
      __typename?: 'Resource'
      type: string
      name: string
      url: string
      fileSize: number
      duration: number
      thumbnailResourceId?: string | null
      id: string
    } | null
    character?: {
      __typename?: 'Resource'
      type: string
      name: string
      url: string
      fileSize: number
      duration: number
      thumbnailResourceId?: string | null
      id: string
    } | null
    wordInUser?: {
      __typename?: 'WordInUser'
      wordId: string
      userId: string
      topicId: string
      times: number
      used: boolean
      percentListen: number
      lastDay?: Date | null
      nextDay?: Date | null
      milestone: number
      isCompleted: boolean
      id: string
      createdAt: Date
    } | null
    topicInCategory?: {
      __typename?: 'TopicInCategory'
      topicId: string
      categoryId: string
      id: string
      createdAt: Date
    } | null
    userAnswer?: {
      __typename?: 'UserAnswer'
      questionId: string
      answerId: string
      topicId: string
      userId: string
      wordInUserId: string
      times: number
      wrongTimes: number
      id: string
      createdAt: Date
    } | null
    topicInUser?: {
      __typename?: 'TopicInUser'
      topicId: string
      userLearnId: string
      unlockTopic: boolean
      percent: number
      id: string
      createdAt: Date
    } | null
  }>
}

export type DetailTopicQueryVariables = Exact<{
  id: Scalars['NeString']['input']
}>

export type DetailTopicQuery = {
  __typename?: 'Query'
  detailTopic: {
    __typename?: 'Topic'
    userId: string
    mediaId: string
    characterId: string
    name: string
    description: string
    level: string
    languageTopic: string
    status: string
    ordinalNumber: number
    scenario: string
    userRole: string
    aiTutorRole: string
    task: any
    id: string
    createdAt: Date
    user?: {
      __typename?: 'User'
      thumbnailId: string
      aiTutorId: string
      name: string
      email: string
      nativeLanguage: string
      customer: string
      languageLearn: string
      level: any
      isAdmin: boolean
      deactivate: boolean
      speed: number
      repair: number
      autoPlay: boolean
      lastUsedDate: string
      id: string
      createdAt: Date
    } | null
    media?: {
      __typename?: 'Resource'
      type: string
      name: string
      url: string
      fileSize: number
      duration: number
      thumbnailResourceId?: string | null
      id: string
    } | null
    character?: {
      __typename?: 'Resource'
      type: string
      name: string
      url: string
      fileSize: number
      duration: number
      thumbnailResourceId?: string | null
      id: string
    } | null
    wordInUser?: {
      __typename?: 'WordInUser'
      wordId: string
      userId: string
      topicId: string
      times: number
      used: boolean
      percentListen: number
      lastDay?: Date | null
      nextDay?: Date | null
      milestone: number
      isCompleted: boolean
      id: string
      createdAt: Date
    } | null
    topicInCategory?: {
      __typename?: 'TopicInCategory'
      topicId: string
      categoryId: string
      id: string
      createdAt: Date
    } | null
    userAnswer?: {
      __typename?: 'UserAnswer'
      questionId: string
      answerId: string
      topicId: string
      userId: string
      wordInUserId: string
      times: number
      wrongTimes: number
      id: string
      createdAt: Date
    } | null
    topicInUser?: {
      __typename?: 'TopicInUser'
      topicId: string
      userLearnId: string
      unlockTopic: boolean
      percent: number
      id: string
      createdAt: Date
    } | null
  }
}

export type CreateTopicMutationVariables = Exact<{
  data: CreateTopic
}>

export type CreateTopicMutation = {
  __typename?: 'Mutation'
  createTopic: {
    __typename?: 'Topic'
    userId: string
    mediaId: string
    characterId: string
    name: string
    description: string
    level: string
    languageTopic: string
    status: string
    ordinalNumber: number
    scenario: string
    userRole: string
    aiTutorRole: string
    task: any
    id: string
    createdAt: Date
    user?: {
      __typename?: 'User'
      thumbnailId: string
      aiTutorId: string
      name: string
      email: string
      nativeLanguage: string
      customer: string
      languageLearn: string
      level: any
      isAdmin: boolean
      deactivate: boolean
      speed: number
      repair: number
      autoPlay: boolean
      lastUsedDate: string
      id: string
      createdAt: Date
    } | null
    media?: {
      __typename?: 'Resource'
      type: string
      name: string
      url: string
      fileSize: number
      duration: number
      thumbnailResourceId?: string | null
      id: string
    } | null
    character?: {
      __typename?: 'Resource'
      type: string
      name: string
      url: string
      fileSize: number
      duration: number
      thumbnailResourceId?: string | null
      id: string
    } | null
    wordInUser?: {
      __typename?: 'WordInUser'
      wordId: string
      userId: string
      topicId: string
      times: number
      used: boolean
      percentListen: number
      lastDay?: Date | null
      nextDay?: Date | null
      milestone: number
      isCompleted: boolean
      id: string
      createdAt: Date
    } | null
    topicInCategory?: {
      __typename?: 'TopicInCategory'
      topicId: string
      categoryId: string
      id: string
      createdAt: Date
    } | null
    userAnswer?: {
      __typename?: 'UserAnswer'
      questionId: string
      answerId: string
      topicId: string
      userId: string
      wordInUserId: string
      times: number
      wrongTimes: number
      id: string
      createdAt: Date
    } | null
    topicInUser?: {
      __typename?: 'TopicInUser'
      topicId: string
      userLearnId: string
      unlockTopic: boolean
      percent: number
      id: string
      createdAt: Date
    } | null
  }
}

export type UpdateTopicMutationVariables = Exact<{
  id: Scalars['ID']['input']
  data: UpdateTopic
}>

export type UpdateTopicMutation = {
  __typename?: 'Mutation'
  updateTopic: {
    __typename?: 'Topic'
    userId: string
    mediaId: string
    characterId: string
    name: string
    description: string
    level: string
    languageTopic: string
    status: string
    ordinalNumber: number
    scenario: string
    userRole: string
    aiTutorRole: string
    task: any
    id: string
    createdAt: Date
    user?: {
      __typename?: 'User'
      thumbnailId: string
      aiTutorId: string
      name: string
      email: string
      nativeLanguage: string
      customer: string
      languageLearn: string
      level: any
      isAdmin: boolean
      deactivate: boolean
      speed: number
      repair: number
      autoPlay: boolean
      lastUsedDate: string
      id: string
      createdAt: Date
    } | null
    media?: {
      __typename?: 'Resource'
      type: string
      name: string
      url: string
      fileSize: number
      duration: number
      thumbnailResourceId?: string | null
      id: string
    } | null
    character?: {
      __typename?: 'Resource'
      type: string
      name: string
      url: string
      fileSize: number
      duration: number
      thumbnailResourceId?: string | null
      id: string
    } | null
    wordInUser?: {
      __typename?: 'WordInUser'
      wordId: string
      userId: string
      topicId: string
      times: number
      used: boolean
      percentListen: number
      lastDay?: Date | null
      nextDay?: Date | null
      milestone: number
      isCompleted: boolean
      id: string
      createdAt: Date
    } | null
    topicInCategory?: {
      __typename?: 'TopicInCategory'
      topicId: string
      categoryId: string
      id: string
      createdAt: Date
    } | null
    userAnswer?: {
      __typename?: 'UserAnswer'
      questionId: string
      answerId: string
      topicId: string
      userId: string
      wordInUserId: string
      times: number
      wrongTimes: number
      id: string
      createdAt: Date
    } | null
    topicInUser?: {
      __typename?: 'TopicInUser'
      topicId: string
      userLearnId: string
      unlockTopic: boolean
      percent: number
      id: string
      createdAt: Date
    } | null
  }
}

export type DeleteTopicMutationVariables = Exact<{
  ids: Array<Scalars['ID']['input']> | Scalars['ID']['input']
}>

export type DeleteTopicMutation = {
  __typename?: 'Mutation'
  deleteTopic?: null | null
}

export type TopicInCategoryFFragment = {
  __typename?: 'TopicInCategory'
  topicId: string
  id: string
  createdAt: Date
  categoryId: string
  topic?: {
    __typename?: 'Topic'
    userId: string
    name: string
    description: string
    level: string
    mediaId: string
    languageTopic: string
    status: string
    id: string
    createdAt: Date
    media?: {
      __typename?: 'Resource'
      type: string
      name: string
      url: string
      fileSize: number
      duration: number
      thumbnailResourceId?: string | null
      createdById?: string | null
      id: string
      createdAt: Date
    } | null
  } | null
  category?: {
    __typename?: 'Category'
    id: string
    tagName: string
    createdAt: Date
  } | null
}

export type SearchTopicInCategoryQueryVariables = Exact<{
  filter?: InputMaybe<TopicInCategoryFilter>
  order?: InputMaybe<Array<TopicInCategoryOrderBy> | TopicInCategoryOrderBy>
  page?: InputMaybe<Pagination>
}>

export type SearchTopicInCategoryQuery = {
  __typename?: 'Query'
  searchTopicInCategory: Array<{
    __typename?: 'TopicInCategory'
    topicId: string
    id: string
    createdAt: Date
    categoryId: string
    topic?: {
      __typename?: 'Topic'
      userId: string
      name: string
      description: string
      level: string
      mediaId: string
      languageTopic: string
      status: string
      id: string
      createdAt: Date
      media?: {
        __typename?: 'Resource'
        type: string
        name: string
        url: string
        fileSize: number
        duration: number
        thumbnailResourceId?: string | null
        createdById?: string | null
        id: string
        createdAt: Date
      } | null
    } | null
    category?: {
      __typename?: 'Category'
      id: string
      tagName: string
      createdAt: Date
    } | null
  }>
}

export type CountTopicInCategoryQueryVariables = Exact<{
  filter?: InputMaybe<TopicInCategoryFilter>
}>

export type CountTopicInCategoryQuery = {
  __typename?: 'Query'
  countTopicInCategory: number
}

export type CreateTopicInCategoryMutationVariables = Exact<{
  data: CreateTopicInCategory
}>

export type CreateTopicInCategoryMutation = {
  __typename?: 'Mutation'
  createTopicInCategory: {
    __typename?: 'TopicInCategory'
    topicId: string
    id: string
    createdAt: Date
    categoryId: string
    topic?: {
      __typename?: 'Topic'
      userId: string
      name: string
      description: string
      level: string
      mediaId: string
      languageTopic: string
      status: string
      id: string
      createdAt: Date
      media?: {
        __typename?: 'Resource'
        type: string
        name: string
        url: string
        fileSize: number
        duration: number
        thumbnailResourceId?: string | null
        createdById?: string | null
        id: string
        createdAt: Date
      } | null
    } | null
    category?: {
      __typename?: 'Category'
      id: string
      tagName: string
      createdAt: Date
    } | null
  }
}

export type UpdateTopicInCategoryMutationVariables = Exact<{
  id: Scalars['ID']['input']
  data: UpdateTopicInCategory
}>

export type UpdateTopicInCategoryMutation = {
  __typename?: 'Mutation'
  updateTopicInCategory: {
    __typename?: 'TopicInCategory'
    topicId: string
    id: string
    createdAt: Date
    categoryId: string
    topic?: {
      __typename?: 'Topic'
      userId: string
      name: string
      description: string
      level: string
      mediaId: string
      languageTopic: string
      status: string
      id: string
      createdAt: Date
      media?: {
        __typename?: 'Resource'
        type: string
        name: string
        url: string
        fileSize: number
        duration: number
        thumbnailResourceId?: string | null
        createdById?: string | null
        id: string
        createdAt: Date
      } | null
    } | null
    category?: {
      __typename?: 'Category'
      id: string
      tagName: string
      createdAt: Date
    } | null
  }
}

export type DeleteTopicInCategoryMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DeleteTopicInCategoryMutation = {
  __typename?: 'Mutation'
  deleteTopicInCategory?: null | null
}

export type TopicInUserFFragment = {
  __typename?: 'TopicInUser'
  topicId: string
  userLearnId: string
  unlockTopic: boolean
  percent: number
  task: any
  id: string
  createdAt: Date
  userLearn?: {
    __typename?: 'User'
    thumbnailId: string
    aiTutorId: string
    name: string
    email: string
    nativeLanguage: string
    customer: string
    languageLearn: string
    level: any
    isAdmin: boolean
    deactivate: boolean
    speed: number
    repair: number
    autoPlay: boolean
    lastUsedDate: string
    id: string
    createdAt: Date
  } | null
  topic?: {
    __typename?: 'Topic'
    userId: string
    mediaId: string
    characterId: string
    name: string
    description: string
    level: string
    languageTopic: string
    status: string
    ordinalNumber: number
    scenario: string
    userRole: string
    aiTutorRole: string
    task: any
    id: string
    createdAt: Date
  } | null
}

export type SearchTopicInUserQueryVariables = Exact<{
  filter?: InputMaybe<TopicInUserFilter>
  order?: InputMaybe<Array<TopicInUserOrderBy> | TopicInUserOrderBy>
  page?: InputMaybe<Pagination>
}>

export type SearchTopicInUserQuery = {
  __typename?: 'Query'
  searchTopicInUser: Array<{
    __typename?: 'TopicInUser'
    topicId: string
    userLearnId: string
    unlockTopic: boolean
    percent: number
    task: any
    id: string
    createdAt: Date
    userLearn?: {
      __typename?: 'User'
      thumbnailId: string
      aiTutorId: string
      name: string
      email: string
      nativeLanguage: string
      customer: string
      languageLearn: string
      level: any
      isAdmin: boolean
      deactivate: boolean
      speed: number
      repair: number
      autoPlay: boolean
      lastUsedDate: string
      id: string
      createdAt: Date
    } | null
    topic?: {
      __typename?: 'Topic'
      userId: string
      mediaId: string
      characterId: string
      name: string
      description: string
      level: string
      languageTopic: string
      status: string
      ordinalNumber: number
      scenario: string
      userRole: string
      aiTutorRole: string
      task: any
      id: string
      createdAt: Date
    } | null
  }>
}

export type CreateTopicInUserMutationVariables = Exact<{
  data: CreateTopicInUser
}>

export type CreateTopicInUserMutation = {
  __typename?: 'Mutation'
  createTopicInUser: {
    __typename?: 'TopicInUser'
    topicId: string
    userLearnId: string
    unlockTopic: boolean
    percent: number
    task: any
    id: string
    createdAt: Date
    userLearn?: {
      __typename?: 'User'
      thumbnailId: string
      aiTutorId: string
      name: string
      email: string
      nativeLanguage: string
      customer: string
      languageLearn: string
      level: any
      isAdmin: boolean
      deactivate: boolean
      speed: number
      repair: number
      autoPlay: boolean
      lastUsedDate: string
      id: string
      createdAt: Date
    } | null
    topic?: {
      __typename?: 'Topic'
      userId: string
      mediaId: string
      characterId: string
      name: string
      description: string
      level: string
      languageTopic: string
      status: string
      ordinalNumber: number
      scenario: string
      userRole: string
      aiTutorRole: string
      task: any
      id: string
      createdAt: Date
    } | null
  }
}

export type UpdateTopicInUserMutationVariables = Exact<{
  id: Scalars['ID']['input']
  data?: InputMaybe<InputUpdateTopicInUser>
}>

export type UpdateTopicInUserMutation = {
  __typename?: 'Mutation'
  updateTopicInUser: {
    __typename?: 'TopicInUser'
    topicId: string
    userLearnId: string
    unlockTopic: boolean
    percent: number
    task: any
    id: string
    createdAt: Date
    userLearn?: {
      __typename?: 'User'
      thumbnailId: string
      aiTutorId: string
      name: string
      email: string
      nativeLanguage: string
      customer: string
      languageLearn: string
      level: any
      isAdmin: boolean
      deactivate: boolean
      speed: number
      repair: number
      autoPlay: boolean
      lastUsedDate: string
      id: string
      createdAt: Date
    } | null
    topic?: {
      __typename?: 'Topic'
      userId: string
      mediaId: string
      characterId: string
      name: string
      description: string
      level: string
      languageTopic: string
      status: string
      ordinalNumber: number
      scenario: string
      userRole: string
      aiTutorRole: string
      task: any
      id: string
      createdAt: Date
    } | null
  }
}

export type DeleteTopicInUserMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DeleteTopicInUserMutation = {
  __typename?: 'Mutation'
  deleteTopicInUser?: null | null
}

export type TranslateFFragment = {
  __typename?: 'Translate'
  wQAId: string
  text: string
  id: string
  createdAt: Date
  Word?: {
    __typename?: 'Word'
    userId: string
    topicId: string
    thumbnailId: string
    mediaId: string
    vocabAndPhrase: string
    description: string
    isVocabulary: boolean
    id: string
    createdAt: Date
  } | null
}

export type CreateTranslateMutationVariables = Exact<{
  data: InputTranslate
}>

export type CreateTranslateMutation = {
  __typename?: 'Mutation'
  createTranslate: {
    __typename?: 'Translate'
    wQAId: string
    text: string
    id: string
    createdAt: Date
    Word?: {
      __typename?: 'Word'
      userId: string
      topicId: string
      thumbnailId: string
      mediaId: string
      vocabAndPhrase: string
      description: string
      isVocabulary: boolean
      id: string
      createdAt: Date
    } | null
  }
}

export type UpdateTranslateMutationVariables = Exact<{
  id: Scalars['ID']['input']
  data: InputTranslate
  update?: InputMaybe<Scalars['Boolean']['input']>
}>

export type UpdateTranslateMutation = {
  __typename?: 'Mutation'
  updateTranslate: {
    __typename?: 'Translate'
    wQAId: string
    text: string
    id: string
    createdAt: Date
    Word?: {
      __typename?: 'Word'
      userId: string
      topicId: string
      thumbnailId: string
      mediaId: string
      vocabAndPhrase: string
      description: string
      isVocabulary: boolean
      id: string
      createdAt: Date
    } | null
  }
}

export type DeleteTranslateMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DeleteTranslateMutation = {
  __typename?: 'Mutation'
  deleteTranslate?: null | null
}

export type SearchTranslateQueryVariables = Exact<{
  filter?: InputMaybe<TranslateFilter>
  order?: InputMaybe<Array<TranslateOrderBy> | TranslateOrderBy>
  page?: InputMaybe<Pagination>
}>

export type SearchTranslateQuery = {
  __typename?: 'Query'
  searchTranslate: Array<{
    __typename?: 'Translate'
    wQAId: string
    text: string
    id: string
    createdAt: Date
    Word?: {
      __typename?: 'Word'
      userId: string
      topicId: string
      thumbnailId: string
      mediaId: string
      vocabAndPhrase: string
      description: string
      isVocabulary: boolean
      id: string
      createdAt: Date
    } | null
  }>
}

export type SuggestedTranslationQueryVariables = Exact<{
  text?: InputMaybe<Scalars['String']['input']>
  language?: InputMaybe<Languages>
}>

export type SuggestedTranslationQuery = {
  __typename?: 'Query'
  suggestedTranslation?: string | null
}

export type UserFFragment = {
  __typename?: 'User'
  thumbnailId: string
  aiTutorId: string
  name: string
  email: string
  nativeLanguage: string
  customer: string
  languageLearn: string
  level: any
  isAdmin: boolean
  deactivate: boolean
  speed: number
  repair: number
  autoPlay: boolean
  id: string
  createdAt: Date
  lastUsedDate: string
  thumbnail?: {
    __typename?: 'Resource'
    type: string
    name: string
    url: string
    fileSize: number
    originalResourceId?: string | null
    originalResourceState?: any | null
    duration: number
    ffprobe?: any | null
    thumbnailResourceId?: string | null
    createdById?: string | null
    id: string
    createdAt: Date
  } | null
}

export type UpdateUserMutationVariables = Exact<{
  data?: InputMaybe<UpdateMyProfile>
}>

export type UpdateUserMutation = {
  __typename?: 'Mutation'
  updateUser: {
    __typename?: 'User'
    thumbnailId: string
    aiTutorId: string
    name: string
    email: string
    nativeLanguage: string
    customer: string
    languageLearn: string
    level: any
    isAdmin: boolean
    deactivate: boolean
    speed: number
    repair: number
    autoPlay: boolean
    id: string
    createdAt: Date
    lastUsedDate: string
    thumbnail?: {
      __typename?: 'Resource'
      type: string
      name: string
      url: string
      fileSize: number
      originalResourceId?: string | null
      originalResourceState?: any | null
      duration: number
      ffprobe?: any | null
      thumbnailResourceId?: string | null
      createdById?: string | null
      id: string
      createdAt: Date
    } | null
  }
}

export type SearchUserQueryVariables = Exact<{
  filter?: InputMaybe<UserFilter>
  order?: InputMaybe<Array<UserOrderBy> | UserOrderBy>
  page?: InputMaybe<Pagination>
}>

export type SearchUserQuery = {
  __typename?: 'Query'
  searchUser: Array<{
    __typename?: 'User'
    thumbnailId: string
    aiTutorId: string
    name: string
    email: string
    nativeLanguage: string
    customer: string
    languageLearn: string
    level: any
    isAdmin: boolean
    deactivate: boolean
    speed: number
    repair: number
    autoPlay: boolean
    id: string
    createdAt: Date
    lastUsedDate: string
    thumbnail?: {
      __typename?: 'Resource'
      type: string
      name: string
      url: string
      fileSize: number
      originalResourceId?: string | null
      originalResourceState?: any | null
      duration: number
      ffprobe?: any | null
      thumbnailResourceId?: string | null
      createdById?: string | null
      id: string
      createdAt: Date
    } | null
  }>
}

export type CountUserQueryVariables = Exact<{
  filter?: InputMaybe<UserFilter>
}>

export type CountUserQuery = { __typename?: 'Query'; countUser: number }

export type SetAdminMutationVariables = Exact<{
  isAdmin: Scalars['Boolean']['input']
  id: Scalars['ID']['input']
}>

export type SetAdminMutation = {
  __typename?: 'Mutation'
  setAdmin: {
    __typename?: 'User'
    thumbnailId: string
    aiTutorId: string
    name: string
    email: string
    nativeLanguage: string
    customer: string
    languageLearn: string
    level: any
    isAdmin: boolean
    deactivate: boolean
    speed: number
    repair: number
    autoPlay: boolean
    id: string
    createdAt: Date
    lastUsedDate: string
    thumbnail?: {
      __typename?: 'Resource'
      type: string
      name: string
      url: string
      fileSize: number
      originalResourceId?: string | null
      originalResourceState?: any | null
      duration: number
      ffprobe?: any | null
      thumbnailResourceId?: string | null
      createdById?: string | null
      id: string
      createdAt: Date
    } | null
  }
}

export type DeleteUserMutationVariables = Exact<{
  email?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['String']['input']>
}>

export type DeleteUserMutation = {
  __typename?: 'Mutation'
  deleteUser?: null | null
}

export type UpdateDeactivateUserMutationVariables = Exact<{
  id: Scalars['ID']['input']
  data: UpdateDeactivateUser
}>

export type UpdateDeactivateUserMutation = {
  __typename?: 'Mutation'
  updateDeactivateUser: {
    __typename?: 'User'
    thumbnailId: string
    aiTutorId: string
    name: string
    email: string
    nativeLanguage: string
    customer: string
    languageLearn: string
    level: any
    isAdmin: boolean
    deactivate: boolean
    speed: number
    repair: number
    autoPlay: boolean
    id: string
    createdAt: Date
    lastUsedDate: string
    thumbnail?: {
      __typename?: 'Resource'
      type: string
      name: string
      url: string
      fileSize: number
      originalResourceId?: string | null
      originalResourceState?: any | null
      duration: number
      ffprobe?: any | null
      thumbnailResourceId?: string | null
      createdById?: string | null
      id: string
      createdAt: Date
    } | null
  }
}

export type ChangePasswordMutationVariables = Exact<{
  oldPassword?: InputMaybe<Scalars['String']['input']>
  password: Scalars['Password']['input']
}>

export type ChangePasswordMutation = {
  __typename?: 'Mutation'
  changePassword?: null | null
}

export type DeleteUserByIdMutationVariables = Exact<{
  id: Scalars['String']['input']
}>

export type DeleteUserByIdMutation = {
  __typename?: 'Mutation'
  deleteUserById?: null | null
}

export type DeleteUserByEmailMutationVariables = Exact<{
  email: Scalars['String']['input']
}>

export type DeleteUserByEmailMutation = {
  __typename?: 'Mutation'
  deleteUserByEmail?: null | null
}

export type WordFFragment = {
  __typename?: 'Word'
  userId: string
  topicId: string
  vocabAndPhrase: string
  mediaId: string
  description: string
  isVocabulary: boolean
  isThumbnail: boolean
  isTranslate: boolean
  id: string
  createdAt: Date
  topic?: {
    __typename?: 'Topic'
    userId: string
    name: string
    description: string
    level: string
    mediaId: string
    languageTopic: string
    status: string
    id: string
    createdAt: Date
  } | null
  media?: {
    __typename?: 'Resource'
    type: string
    name: string
    url: string
    fileSize: number
    duration: number
    thumbnailResourceId?: string | null
    createdById?: string | null
    id: string
    createdAt: Date
  } | null
  translate?: {
    __typename?: 'Translate'
    wQAId: string
    text: string
    id: string
    createdAt: Date
    suportLanguage: string
  } | null
  wordInUser?: {
    __typename?: 'WordInUser'
    wordId: string
    userId: string
    topicId: string
    times: number
    used: boolean
    percentListen: number
    id: string
    createdAt: Date
  } | null
}

export type SearchWordQueryVariables = Exact<{
  filter?: InputMaybe<WordFilter>
  order?: InputMaybe<Array<WordOrderBy> | WordOrderBy>
  page?: InputMaybe<Pagination>
}>

export type SearchWordQuery = {
  __typename?: 'Query'
  searchWord: Array<{
    __typename?: 'Word'
    userId: string
    topicId: string
    vocabAndPhrase: string
    mediaId: string
    description: string
    isVocabulary: boolean
    isThumbnail: boolean
    isTranslate: boolean
    id: string
    createdAt: Date
    topic?: {
      __typename?: 'Topic'
      userId: string
      name: string
      description: string
      level: string
      mediaId: string
      languageTopic: string
      status: string
      id: string
      createdAt: Date
    } | null
    media?: {
      __typename?: 'Resource'
      type: string
      name: string
      url: string
      fileSize: number
      duration: number
      thumbnailResourceId?: string | null
      createdById?: string | null
      id: string
      createdAt: Date
    } | null
    translate?: {
      __typename?: 'Translate'
      wQAId: string
      text: string
      id: string
      createdAt: Date
      suportLanguage: string
    } | null
    wordInUser?: {
      __typename?: 'WordInUser'
      wordId: string
      userId: string
      topicId: string
      times: number
      used: boolean
      percentListen: number
      id: string
      createdAt: Date
    } | null
  }>
}

export type SearchMultipleWordQueryVariables = Exact<{
  filter?: InputMaybe<WordFilter>
  order?: InputMaybe<Array<WordOrderBy> | WordOrderBy>
  page1?: InputMaybe<Pagination>
  page2?: InputMaybe<Pagination>
  page3?: InputMaybe<Pagination>
}>

export type SearchMultipleWordQuery = {
  __typename?: 'Query'
  word1: Array<{
    __typename?: 'Word'
    userId: string
    topicId: string
    vocabAndPhrase: string
    mediaId: string
    description: string
    isVocabulary: boolean
    isThumbnail: boolean
    isTranslate: boolean
    id: string
    createdAt: Date
    topic?: {
      __typename?: 'Topic'
      userId: string
      name: string
      description: string
      level: string
      mediaId: string
      languageTopic: string
      status: string
      id: string
      createdAt: Date
    } | null
    media?: {
      __typename?: 'Resource'
      type: string
      name: string
      url: string
      fileSize: number
      duration: number
      thumbnailResourceId?: string | null
      createdById?: string | null
      id: string
      createdAt: Date
    } | null
    translate?: {
      __typename?: 'Translate'
      wQAId: string
      text: string
      id: string
      createdAt: Date
      suportLanguage: string
    } | null
    wordInUser?: {
      __typename?: 'WordInUser'
      wordId: string
      userId: string
      topicId: string
      times: number
      used: boolean
      percentListen: number
      id: string
      createdAt: Date
    } | null
  }>
  word2: Array<{
    __typename?: 'Word'
    userId: string
    topicId: string
    vocabAndPhrase: string
    mediaId: string
    description: string
    isVocabulary: boolean
    isThumbnail: boolean
    isTranslate: boolean
    id: string
    createdAt: Date
    topic?: {
      __typename?: 'Topic'
      userId: string
      name: string
      description: string
      level: string
      mediaId: string
      languageTopic: string
      status: string
      id: string
      createdAt: Date
    } | null
    media?: {
      __typename?: 'Resource'
      type: string
      name: string
      url: string
      fileSize: number
      duration: number
      thumbnailResourceId?: string | null
      createdById?: string | null
      id: string
      createdAt: Date
    } | null
    translate?: {
      __typename?: 'Translate'
      wQAId: string
      text: string
      id: string
      createdAt: Date
      suportLanguage: string
    } | null
    wordInUser?: {
      __typename?: 'WordInUser'
      wordId: string
      userId: string
      topicId: string
      times: number
      used: boolean
      percentListen: number
      id: string
      createdAt: Date
    } | null
  }>
  word3: Array<{
    __typename?: 'Word'
    userId: string
    topicId: string
    vocabAndPhrase: string
    mediaId: string
    description: string
    isVocabulary: boolean
    isThumbnail: boolean
    isTranslate: boolean
    id: string
    createdAt: Date
    topic?: {
      __typename?: 'Topic'
      userId: string
      name: string
      description: string
      level: string
      mediaId: string
      languageTopic: string
      status: string
      id: string
      createdAt: Date
    } | null
    media?: {
      __typename?: 'Resource'
      type: string
      name: string
      url: string
      fileSize: number
      duration: number
      thumbnailResourceId?: string | null
      createdById?: string | null
      id: string
      createdAt: Date
    } | null
    translate?: {
      __typename?: 'Translate'
      wQAId: string
      text: string
      id: string
      createdAt: Date
      suportLanguage: string
    } | null
    wordInUser?: {
      __typename?: 'WordInUser'
      wordId: string
      userId: string
      topicId: string
      times: number
      used: boolean
      percentListen: number
      id: string
      createdAt: Date
    } | null
  }>
}

export type CountWordQueryVariables = Exact<{
  filter?: InputMaybe<WordFilter>
}>

export type CountWordQuery = { __typename?: 'Query'; countWord: number }

export type CreateWordMutationVariables = Exact<{
  data: CreateWord
}>

export type CreateWordMutation = {
  __typename?: 'Mutation'
  createWord: {
    __typename?: 'Word'
    userId: string
    topicId: string
    vocabAndPhrase: string
    mediaId: string
    description: string
    isVocabulary: boolean
    isThumbnail: boolean
    isTranslate: boolean
    id: string
    createdAt: Date
    topic?: {
      __typename?: 'Topic'
      userId: string
      name: string
      description: string
      level: string
      mediaId: string
      languageTopic: string
      status: string
      id: string
      createdAt: Date
    } | null
    media?: {
      __typename?: 'Resource'
      type: string
      name: string
      url: string
      fileSize: number
      duration: number
      thumbnailResourceId?: string | null
      createdById?: string | null
      id: string
      createdAt: Date
    } | null
    translate?: {
      __typename?: 'Translate'
      wQAId: string
      text: string
      id: string
      createdAt: Date
      suportLanguage: string
    } | null
    wordInUser?: {
      __typename?: 'WordInUser'
      wordId: string
      userId: string
      topicId: string
      times: number
      used: boolean
      percentListen: number
      id: string
      createdAt: Date
    } | null
  }
}

export type UpdateWordMutationVariables = Exact<{
  id: Scalars['ID']['input']
  data: UpdateWord
}>

export type UpdateWordMutation = {
  __typename?: 'Mutation'
  updateWord: {
    __typename?: 'Word'
    userId: string
    topicId: string
    vocabAndPhrase: string
    mediaId: string
    description: string
    isVocabulary: boolean
    isThumbnail: boolean
    isTranslate: boolean
    id: string
    createdAt: Date
    topic?: {
      __typename?: 'Topic'
      userId: string
      name: string
      description: string
      level: string
      mediaId: string
      languageTopic: string
      status: string
      id: string
      createdAt: Date
    } | null
    media?: {
      __typename?: 'Resource'
      type: string
      name: string
      url: string
      fileSize: number
      duration: number
      thumbnailResourceId?: string | null
      createdById?: string | null
      id: string
      createdAt: Date
    } | null
    translate?: {
      __typename?: 'Translate'
      wQAId: string
      text: string
      id: string
      createdAt: Date
      suportLanguage: string
    } | null
    wordInUser?: {
      __typename?: 'WordInUser'
      wordId: string
      userId: string
      topicId: string
      times: number
      used: boolean
      percentListen: number
      id: string
      createdAt: Date
    } | null
  }
}

export type DeleteWordMutationVariables = Exact<{
  ids: Array<Scalars['ID']['input']> | Scalars['ID']['input']
}>

export type DeleteWordMutation = {
  __typename?: 'Mutation'
  deleteWord?: null | null
}

export type DetailWordQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DetailWordQuery = {
  __typename?: 'Query'
  detailWord: {
    __typename?: 'Word'
    userId: string
    topicId: string
    vocabAndPhrase: string
    mediaId: string
    description: string
    isVocabulary: boolean
    isThumbnail: boolean
    isTranslate: boolean
    id: string
    createdAt: Date
    topic?: {
      __typename?: 'Topic'
      userId: string
      name: string
      description: string
      level: string
      mediaId: string
      languageTopic: string
      status: string
      id: string
      createdAt: Date
    } | null
    media?: {
      __typename?: 'Resource'
      type: string
      name: string
      url: string
      fileSize: number
      duration: number
      thumbnailResourceId?: string | null
      createdById?: string | null
      id: string
      createdAt: Date
    } | null
    translate?: {
      __typename?: 'Translate'
      wQAId: string
      text: string
      id: string
      createdAt: Date
      suportLanguage: string
    } | null
    wordInUser?: {
      __typename?: 'WordInUser'
      wordId: string
      userId: string
      topicId: string
      times: number
      used: boolean
      percentListen: number
      id: string
      createdAt: Date
    } | null
  }
}

export type SearchResourceWordQueryVariables = Exact<{
  filterTranslate?: InputMaybe<TranslateFilter>
  filterResource?: InputMaybe<ResourceInWordFilter>
}>

export type SearchResourceWordQuery = {
  __typename?: 'Query'
  translate: Array<{
    __typename?: 'Translate'
    wQAId: string
    text: string
    id: string
    createdAt: Date
    Word?: {
      __typename?: 'Word'
      userId: string
      topicId: string
      thumbnailId: string
      mediaId: string
      vocabAndPhrase: string
      description: string
      isVocabulary: boolean
      id: string
      createdAt: Date
    } | null
  }>
  resource: Array<{
    __typename?: 'ResourceInWord'
    wordId: string
    type: string
    mediaId: string
    id: string
    createdAt: Date
    word?: {
      __typename?: 'Word'
      userId: string
      topicId: string
      vocabAndPhrase: string
      mediaId: string
      description: string
      isVocabulary: boolean
      isThumbnail: boolean
      isTranslate: boolean
      id: string
      createdAt: Date
    } | null
    media?: {
      __typename?: 'Resource'
      type: string
      name: string
      url: string
      fileSize: number
      duration: number
      thumbnailResourceId?: string | null
      createdById?: string | null
      id: string
      createdAt: Date
    } | null
  }>
}

export type WordInUserFFragment = {
  __typename?: 'WordInUser'
  wordId: string
  userId: string
  topicId: string
  times: number
  used: boolean
  percentListen: number
  lastDay?: Date | null
  nextDay?: Date | null
  milestone: number
  isCompleted: boolean
  id: string
  createdAt: Date
  word?: {
    __typename?: 'Word'
    userId: string
    topicId: string
    thumbnailId: string
    mediaId: string
    vocabAndPhrase: string
    description: string
    isVocabulary: boolean
    isThumbnail: boolean
    isTranslate: boolean
    id: string
    createdAt: Date
    translate?: {
      __typename?: 'Translate'
      text: string
      wQAId: string
      suportLanguage: string
      id: string
      createdAt: Date
    } | null
  } | null
  user?: {
    __typename?: 'User'
    thumbnailId: string
    aiTutorId: string
    name: string
    email: string
    nativeLanguage: string
    customer: string
    languageLearn: string
    level: any
    isAdmin: boolean
    deactivate: boolean
    speed: number
    repair: number
    autoPlay: boolean
    lastUsedDate: string
    id: string
  } | null
  topic?: {
    __typename?: 'Topic'
    userId: string
    mediaId: string
    characterId: string
    name: string
    description: string
    level: string
    languageTopic: string
    status: string
    ordinalNumber: number
    scenario: string
    userRole: string
    aiTutorRole: string
    task: any
    id: string
    createdAt: Date
  } | null
  userAnswer?: {
    __typename?: 'UserAnswer'
    questionId: string
    answerId: string
    topicId: string
    userId: string
    wordInUserId: string
    times: number
    wrongTimes: number
    id: string
    createdAt: Date
  } | null
}

export type SearchWordInUserQueryVariables = Exact<{
  filter?: InputMaybe<WordInUserFilter>
  order?: InputMaybe<Array<WordInUserOrderBy> | WordInUserOrderBy>
  page?: InputMaybe<Pagination>
}>

export type SearchWordInUserQuery = {
  __typename?: 'Query'
  searchWordInUser: Array<{
    __typename?: 'WordInUser'
    wordId: string
    userId: string
    topicId: string
    times: number
    used: boolean
    percentListen: number
    lastDay?: Date | null
    nextDay?: Date | null
    milestone: number
    isCompleted: boolean
    id: string
    createdAt: Date
    word?: {
      __typename?: 'Word'
      userId: string
      topicId: string
      thumbnailId: string
      mediaId: string
      vocabAndPhrase: string
      description: string
      isVocabulary: boolean
      isThumbnail: boolean
      isTranslate: boolean
      id: string
      createdAt: Date
      translate?: {
        __typename?: 'Translate'
        text: string
        wQAId: string
        suportLanguage: string
        id: string
        createdAt: Date
      } | null
    } | null
    user?: {
      __typename?: 'User'
      thumbnailId: string
      aiTutorId: string
      name: string
      email: string
      nativeLanguage: string
      customer: string
      languageLearn: string
      level: any
      isAdmin: boolean
      deactivate: boolean
      speed: number
      repair: number
      autoPlay: boolean
      lastUsedDate: string
      id: string
    } | null
    topic?: {
      __typename?: 'Topic'
      userId: string
      mediaId: string
      characterId: string
      name: string
      description: string
      level: string
      languageTopic: string
      status: string
      ordinalNumber: number
      scenario: string
      userRole: string
      aiTutorRole: string
      task: any
      id: string
      createdAt: Date
    } | null
    userAnswer?: {
      __typename?: 'UserAnswer'
      questionId: string
      answerId: string
      topicId: string
      userId: string
      wordInUserId: string
      times: number
      wrongTimes: number
      id: string
      createdAt: Date
    } | null
  }>
}

export type CountWordInUserQueryVariables = Exact<{
  filter?: InputMaybe<WordInUserFilter>
}>

export type CountWordInUserQuery = {
  __typename?: 'Query'
  countWordInUser: number
}

export type SearchReviewWordInUserQueryVariables = Exact<{
  data?: InputMaybe<QueryReviewWordInUser>
}>

export type SearchReviewWordInUserQuery = {
  __typename?: 'Query'
  searchReviewWordInUser: any
}

export type SearchVocabPhraseReviewQueryVariables = Exact<{
  filter?: InputMaybe<WordInUserFilter>
  order?: InputMaybe<Array<WordInUserOrderBy> | WordInUserOrderBy>
  page?: InputMaybe<Pagination>
}>

export type SearchVocabPhraseReviewQuery = {
  __typename?: 'Query'
  searchVocabPhraseReview: Array<{
    __typename?: 'WordInUser'
    wordId: string
    userId: string
    topicId: string
    times: number
    used: boolean
    percentListen: number
    lastDay?: Date | null
    nextDay?: Date | null
    milestone: number
    isCompleted: boolean
    id: string
    createdAt: Date
    word?: {
      __typename?: 'Word'
      userId: string
      topicId: string
      thumbnailId: string
      mediaId: string
      vocabAndPhrase: string
      description: string
      isVocabulary: boolean
      isThumbnail: boolean
      isTranslate: boolean
      id: string
      createdAt: Date
      translate?: {
        __typename?: 'Translate'
        text: string
        wQAId: string
        suportLanguage: string
        id: string
        createdAt: Date
      } | null
    } | null
    user?: {
      __typename?: 'User'
      thumbnailId: string
      aiTutorId: string
      name: string
      email: string
      nativeLanguage: string
      customer: string
      languageLearn: string
      level: any
      isAdmin: boolean
      deactivate: boolean
      speed: number
      repair: number
      autoPlay: boolean
      lastUsedDate: string
      id: string
    } | null
    topic?: {
      __typename?: 'Topic'
      userId: string
      mediaId: string
      characterId: string
      name: string
      description: string
      level: string
      languageTopic: string
      status: string
      ordinalNumber: number
      scenario: string
      userRole: string
      aiTutorRole: string
      task: any
      id: string
      createdAt: Date
    } | null
    userAnswer?: {
      __typename?: 'UserAnswer'
      questionId: string
      answerId: string
      topicId: string
      userId: string
      wordInUserId: string
      times: number
      wrongTimes: number
      id: string
      createdAt: Date
    } | null
  }>
}

export type CountVocabPhraseReviewQueryVariables = Exact<{
  filter?: InputMaybe<WordInUserFilter>
}>

export type CountVocabPhraseReviewQuery = {
  __typename?: 'Query'
  countVocabPhraseReview: number
}

export type CreateWordInUserMutationVariables = Exact<{
  data: CreateWordInUser
}>

export type CreateWordInUserMutation = {
  __typename?: 'Mutation'
  createWordInUser: {
    __typename?: 'WordInUser'
    wordId: string
    userId: string
    topicId: string
    times: number
    used: boolean
    percentListen: number
    lastDay?: Date | null
    nextDay?: Date | null
    milestone: number
    isCompleted: boolean
    id: string
    createdAt: Date
    word?: {
      __typename?: 'Word'
      userId: string
      topicId: string
      thumbnailId: string
      mediaId: string
      vocabAndPhrase: string
      description: string
      isVocabulary: boolean
      isThumbnail: boolean
      isTranslate: boolean
      id: string
      createdAt: Date
      translate?: {
        __typename?: 'Translate'
        text: string
        wQAId: string
        suportLanguage: string
        id: string
        createdAt: Date
      } | null
    } | null
    user?: {
      __typename?: 'User'
      thumbnailId: string
      aiTutorId: string
      name: string
      email: string
      nativeLanguage: string
      customer: string
      languageLearn: string
      level: any
      isAdmin: boolean
      deactivate: boolean
      speed: number
      repair: number
      autoPlay: boolean
      lastUsedDate: string
      id: string
    } | null
    topic?: {
      __typename?: 'Topic'
      userId: string
      mediaId: string
      characterId: string
      name: string
      description: string
      level: string
      languageTopic: string
      status: string
      ordinalNumber: number
      scenario: string
      userRole: string
      aiTutorRole: string
      task: any
      id: string
      createdAt: Date
    } | null
    userAnswer?: {
      __typename?: 'UserAnswer'
      questionId: string
      answerId: string
      topicId: string
      userId: string
      wordInUserId: string
      times: number
      wrongTimes: number
      id: string
      createdAt: Date
    } | null
  }
}

export type UpdateWordInUserMutationVariables = Exact<{
  id: Scalars['ID']['input']
  data: UpdateWordInUser
}>

export type UpdateWordInUserMutation = {
  __typename?: 'Mutation'
  updateWordInUser: {
    __typename?: 'WordInUser'
    wordId: string
    userId: string
    topicId: string
    times: number
    used: boolean
    percentListen: number
    lastDay?: Date | null
    nextDay?: Date | null
    milestone: number
    isCompleted: boolean
    id: string
    createdAt: Date
    word?: {
      __typename?: 'Word'
      userId: string
      topicId: string
      thumbnailId: string
      mediaId: string
      vocabAndPhrase: string
      description: string
      isVocabulary: boolean
      isThumbnail: boolean
      isTranslate: boolean
      id: string
      createdAt: Date
      translate?: {
        __typename?: 'Translate'
        text: string
        wQAId: string
        suportLanguage: string
        id: string
        createdAt: Date
      } | null
    } | null
    user?: {
      __typename?: 'User'
      thumbnailId: string
      aiTutorId: string
      name: string
      email: string
      nativeLanguage: string
      customer: string
      languageLearn: string
      level: any
      isAdmin: boolean
      deactivate: boolean
      speed: number
      repair: number
      autoPlay: boolean
      lastUsedDate: string
      id: string
    } | null
    topic?: {
      __typename?: 'Topic'
      userId: string
      mediaId: string
      characterId: string
      name: string
      description: string
      level: string
      languageTopic: string
      status: string
      ordinalNumber: number
      scenario: string
      userRole: string
      aiTutorRole: string
      task: any
      id: string
      createdAt: Date
    } | null
    userAnswer?: {
      __typename?: 'UserAnswer'
      questionId: string
      answerId: string
      topicId: string
      userId: string
      wordInUserId: string
      times: number
      wrongTimes: number
      id: string
      createdAt: Date
    } | null
  }
}

export type DeleteWordInUserMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DeleteWordInUserMutation = {
  __typename?: 'Mutation'
  deleteWordInUser?: null | null
}

export type CheckWordInUserQueryVariables = Exact<{
  filter?: InputMaybe<WordInUserFilter>
  filterKnown?: InputMaybe<WordInUserFilter>
  filterUnKnown?: InputMaybe<WordInUserFilter>
  filterVocabularyUnknown?: InputMaybe<WordInUserFilter>
  filterVocabulary?: InputMaybe<WordInUserFilter>
  filterPhrase?: InputMaybe<WordInUserFilter>
  filterPhraseUnKnown?: InputMaybe<WordInUserFilter>
  filterTopicInUser?: InputMaybe<TopicInUserFilter>
  filterQuestion?: InputMaybe<QuestionFilter>
  filterWordInUser?: InputMaybe<WordInUserFilter>
  filterVocabPhraseReview?: InputMaybe<WordInUserFilter>
  filterWord?: InputMaybe<WordFilter>
}>

export type CheckWordInUserQuery = {
  __typename?: 'Query'
  count: number
  countKnown: number
  countUnKnown: number
  countVocabularyUnKnown: number
  countVocabulary: number
  countPhrase: number
  countPhraseUnknown: number
  countQuestion: number
  countVocabPhraseReview: number
  countWord: number
  topicInUser: Array<{
    __typename?: 'TopicInUser'
    id: string
    percent: number
    task: any
    unlockTopic: boolean
    learning: boolean
  }>
  wordInUser: Array<{ __typename?: 'WordInUser'; percentListen: number }>
}

export type WordbankFFragment = {
  __typename?: 'Wordbank'
  userId: string
  topicId: string
  word: string
  translatedWords: string
  isPhrase: boolean
  id: string
  createdAt: Date
  user?: {
    __typename?: 'User'
    thumbnailId: string
    aiTutorId: string
    name: string
    email: string
    nativeLanguage: string
    customer: string
    languageLearn: string
    level: any
    isAdmin: boolean
    deactivate: boolean
    speed: number
    repair: number
    autoPlay: boolean
    lastUsedDate: string
    id: string
    createdAt: Date
  } | null
}

export type SearchWordbankQueryVariables = Exact<{
  filter?: InputMaybe<WordbankFilter>
  order?: InputMaybe<Array<WordbankOrderBy> | WordbankOrderBy>
  page?: InputMaybe<Pagination>
}>

export type SearchWordbankQuery = {
  __typename?: 'Query'
  searchWordbank: Array<{
    __typename?: 'Wordbank'
    userId: string
    topicId: string
    word: string
    translatedWords: string
    isPhrase: boolean
    id: string
    createdAt: Date
    user?: {
      __typename?: 'User'
      thumbnailId: string
      aiTutorId: string
      name: string
      email: string
      nativeLanguage: string
      customer: string
      languageLearn: string
      level: any
      isAdmin: boolean
      deactivate: boolean
      speed: number
      repair: number
      autoPlay: boolean
      lastUsedDate: string
      id: string
      createdAt: Date
    } | null
  }>
}

export type CountWordbankQueryVariables = Exact<{
  filter?: InputMaybe<WordbankFilter>
}>

export type CountWordbankQuery = { __typename?: 'Query'; countWordbank: number }

export type SearchWordbanksQueryVariables = Exact<{
  filter?: InputMaybe<WordbankFilter>
  order?: InputMaybe<Array<WordbankOrderBy> | WordbankOrderBy>
  page?: InputMaybe<Pagination>
}>

export type SearchWordbanksQuery = {
  __typename?: 'Query'
  count: number
  searchWordbanks: Array<{
    __typename?: 'Wordbank'
    userId: string
    topicId: string
    word: string
    translatedWords: string
    isPhrase: boolean
    id: string
    createdAt: Date
    user?: {
      __typename?: 'User'
      thumbnailId: string
      aiTutorId: string
      name: string
      email: string
      nativeLanguage: string
      customer: string
      languageLearn: string
      level: any
      isAdmin: boolean
      deactivate: boolean
      speed: number
      repair: number
      autoPlay: boolean
      lastUsedDate: string
      id: string
      createdAt: Date
    } | null
  }>
}

export type CreateWordbankMutationVariables = Exact<{
  data: CreateWordbank
}>

export type CreateWordbankMutation = {
  __typename?: 'Mutation'
  createWordbank: {
    __typename?: 'Wordbank'
    userId: string
    topicId: string
    word: string
    translatedWords: string
    isPhrase: boolean
    id: string
    createdAt: Date
    user?: {
      __typename?: 'User'
      thumbnailId: string
      aiTutorId: string
      name: string
      email: string
      nativeLanguage: string
      customer: string
      languageLearn: string
      level: any
      isAdmin: boolean
      deactivate: boolean
      speed: number
      repair: number
      autoPlay: boolean
      lastUsedDate: string
      id: string
      createdAt: Date
    } | null
  }
}

export type UpdateWordbankMutationVariables = Exact<{
  id: Scalars['ID']['input']
  data: UpdateWordbank
}>

export type UpdateWordbankMutation = {
  __typename?: 'Mutation'
  updateWordbank: {
    __typename?: 'Wordbank'
    userId: string
    topicId: string
    word: string
    translatedWords: string
    isPhrase: boolean
    id: string
    createdAt: Date
    user?: {
      __typename?: 'User'
      thumbnailId: string
      aiTutorId: string
      name: string
      email: string
      nativeLanguage: string
      customer: string
      languageLearn: string
      level: any
      isAdmin: boolean
      deactivate: boolean
      speed: number
      repair: number
      autoPlay: boolean
      lastUsedDate: string
      id: string
      createdAt: Date
    } | null
  }
}

export type DeleteWordbankMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DeleteWordbankMutation = {
  __typename?: 'Mutation'
  deleteWordbank?: null | null
}

export const AiTutorFFragmentDoc = gql`
  fragment AiTutorF on AiTutor {
    name
    description
    thumbnailId
    address
    mediaId
    status
    gender
    userId
    languageAI
    id
    createdAt
    user {
      name
      email
      nativeLanguage
      languageLearn
      level
      thumbnailId
      isAdmin
      deactivate
      id
      createdAt
    }
    media {
      type
      name
      url
      fileSize
      duration
      createdById
      id
      createdAt
    }
    thumbnail {
      type
      name
      url
      fileSize
      duration
      thumbnailResourceId
      createdById
      id
      createdAt
    }
  }
`
export const AnswerFFragmentDoc = gql`
  fragment AnswerF on Answer {
    text
    id
    createdAt
    index
    translate {
      wQAId
      text
      id
      createdAt
      suportLanguage
    }
  }
`
export const AnswerInQuestionFFragmentDoc = gql`
  fragment AnswerInQuestionF on AnswerInQuestion {
    answerId
    questionId
    isTrue
    mediaId
    thumbnailId
    id
    createdAt
    question {
      topicId
      type
      text
      wordId
      mediaId
      id
      createdAt
      topic {
        languageTopic
        name
      }
    }
    answer {
      text
      id
      createdAt
      translate {
        wQAId
        text
        suportLanguage
        id
        createdAt
      }
    }
    media {
      type
      name
      url
      fileSize
      duration
      createdById
      id
      createdAt
    }
    thumbnail {
      type
      name
      url
      fileSize
      duration
      thumbnailResourceId
      createdById
      id
      createdAt
    }
  }
`
export const AuthSessionFFragmentDoc = gql`
  fragment AuthSessionF on AuthSession {
    type
    userId
    secret
    ipAddress
    deviceUa
    devicePlatform
    devicePnSecret
    id
    createdAt
    user {
      name
      email
      nativeLanguage
      languageLearn
      level
      thumbnailId
      aiTutorId
      customer
      isAdmin
      deactivate
      speed
      repair
      autoPlay
      id
      createdAt
      lastUsedDate
      thumbnail {
        type
        name
        url
        fileSize
        duration
        thumbnailResourceId
        id
        createdAt
      }
    }
  }
`
export const CategoryFFragmentDoc = gql`
  fragment CategoryF on Category {
    id
    tagName
    createdAt
    topicInCategory {
      id
      createdAt
      topicId
      categoryId
    }
  }
`
export const MessageFFragmentDoc = gql`
  fragment MessageF on Message {
    content
    userId
    topicInUserId
    sentByUser
    id
    createdAt
    topicInUser {
      topicId
      userLearnId
      id
      createdAt
    }
  }
`
export const PriceFFragmentDoc = gql`
  fragment PriceF on Price {
    planId
    price
    object
    active
    billingScheme
    currency
    customUnitAmount
    metadata
    product
    recurring
    taxBehavior
    type
    unitAmount
    unitAmountDecimal
    id
    createdAt
    plan {
      idProduct
      name
      description
      images
      marketingFeatures
      active
      type
      defaultPrice
      metadata
      gifId
      titleGif
      titlePlansGif
      subTitleGif
      id
      createdAt
    }
  }
`
export const ProductFFragmentDoc = gql`
  fragment ProductF on Product {
    idProduct
    priceId
    idPrice
    name
    discountPercent
    description
    images
    marketingFeatures
    active
    type
    defaultPrice
    metadata
    gifId
    titleGif
    titlePlansGif
    subTitleGif
    id
    createdAt
    gif {
      type
      name
      url
      fileSize
      originalResourceId
      originalResourceState
      duration
      thumbnailResourceId
      createdById
      id
      createdAt
    }
    price {
      planId
      price
      object
      active
      billingScheme
      currency
      customUnitAmount
      metadata
      product
      recurring
      taxBehavior
      type
      unitAmount
      unitAmountDecimal
      id
      createdAt
    }
  }
`
export const QuestionFFragmentDoc = gql`
  fragment QuestionF on Question {
    topicId
    type
    text
    index
    wordId
    mediaId
    thumbnailId
    id
    createdAt
    topic {
      userId
      name
      description
      level
      mediaId
      media {
        id
        url
      }
      languageTopic
      status
      id
      createdAt
    }
    word {
      userId
      topicId
      vocabAndPhrase
      mediaId
      description
      isVocabulary
      id
      createdAt
      media {
        id
        url
      }
    }
    media {
      type
      name
      url
      fileSize
      duration
      thumbnailResourceId
      createdById
      id
      createdAt
    }
    thumbnail {
      type
      name
      url
      fileSize
      duration
      thumbnailResourceId
      createdById
      id
      createdAt
    }
    translate {
      wQAId
      text
      id
      createdAt
      suportLanguage
    }
  }
`
export const ResourceInWordFFragmentDoc = gql`
  fragment ResourceInWordF on ResourceInWord {
    wordId
    type
    mediaId
    id
    createdAt
    word {
      userId
      topicId
      vocabAndPhrase
      mediaId
      description
      isVocabulary
      isThumbnail
      isTranslate
      id
      createdAt
    }
    media {
      type
      name
      url
      fileSize
      duration
      thumbnailResourceId
      createdById
      id
      createdAt
    }
  }
`
export const SplashFFragmentDoc = gql`
  fragment SplashF on Splash {
    thumbnailId
    title
    subTitle
    id
    createdAt
    thumbnail {
      type
      name
      url
      fileSize
      originalResourceId
      originalResourceState
      duration
      thumbnailResourceId
      createdById
      id
      createdAt
    }
  }
`
export const StreakFFragmentDoc = gql`
  fragment StreakF on Streak {
    mediaId
    thumbnailId
    numberStreak
    infor
    id
    createdAt
    media {
      type
      name
      url
      fileSize
    }
    thumbnail {
      type
      name
      url
      fileSize
    }
  }
`
export const StreakDailyFFragmentDoc = gql`
  fragment StreakDailyF on StreakDaily {
    name
    id
    createdAt
  }
`
export const StreakInUserFFragmentDoc = gql`
  fragment StreakInUserF on StreakInUser {
    userId
    streakId
    countStreak
    highestStreak
    dateStreak
    lastDate
    id
    createdAt
    user {
      thumbnailId
      aiTutorId
      name
      email
      nativeLanguage
      customer
      languageLearn
      level
      isAdmin
      deactivate
      speed
      repair
      autoPlay
      lastUsedDate
      id
      createdAt
    }
    streak {
      mediaId
      thumbnailId
      numberStreak
      infor
      id
      createdAt
    }
  }
`
export const TopicFFragmentDoc = gql`
  fragment TopicF on Topic {
    userId
    mediaId
    characterId
    name
    description
    level
    languageTopic
    status
    ordinalNumber
    scenario
    userRole
    aiTutorRole
    task
    id
    createdAt
    user {
      thumbnailId
      aiTutorId
      name
      email
      nativeLanguage
      customer
      languageLearn
      level
      isAdmin
      deactivate
      speed
      repair
      autoPlay
      lastUsedDate
      id
      createdAt
    }
    media {
      type
      name
      url
      fileSize
      duration
      thumbnailResourceId
      id
    }
    character {
      type
      name
      url
      fileSize
      duration
      thumbnailResourceId
      id
    }
    wordInUser {
      wordId
      userId
      topicId
      times
      used
      percentListen
      lastDay
      nextDay
      milestone
      isCompleted
      id
      createdAt
    }
    topicInCategory {
      topicId
      categoryId
      id
      createdAt
    }
    userAnswer {
      questionId
      answerId
      topicId
      userId
      wordInUserId
      times
      wrongTimes
      id
      createdAt
    }
    topicInUser {
      topicId
      userLearnId
      unlockTopic
      percent
      id
      createdAt
    }
  }
`
export const TopicInCategoryFFragmentDoc = gql`
  fragment TopicInCategoryF on TopicInCategory {
    topicId
    id
    createdAt
    categoryId
    topic {
      userId
      name
      description
      level
      mediaId
      languageTopic
      status
      id
      createdAt
      media {
        type
        name
        url
        fileSize
        duration
        thumbnailResourceId
        createdById
        id
        createdAt
      }
    }
    category {
      id
      tagName
      createdAt
    }
  }
`
export const TopicInUserFFragmentDoc = gql`
  fragment TopicInUserF on TopicInUser {
    topicId
    userLearnId
    unlockTopic
    percent
    task
    id
    createdAt
    userLearn {
      thumbnailId
      aiTutorId
      name
      email
      nativeLanguage
      customer
      languageLearn
      level
      thumbnailId
      isAdmin
      deactivate
      speed
      repair
      autoPlay
      lastUsedDate
      id
      createdAt
    }
    topic {
      userId
      mediaId
      characterId
      name
      description
      level
      languageTopic
      status
      ordinalNumber
      scenario
      userRole
      aiTutorRole
      task
      id
      createdAt
    }
  }
`
export const TranslateFFragmentDoc = gql`
  fragment TranslateF on Translate {
    wQAId
    text
    id
    createdAt
    Word {
      userId
      topicId
      thumbnailId
      mediaId
      vocabAndPhrase
      description
      isVocabulary
      id
      createdAt
    }
  }
`
export const UserFFragmentDoc = gql`
  fragment UserF on User {
    thumbnailId
    aiTutorId
    name
    email
    nativeLanguage
    customer
    languageLearn
    level
    isAdmin
    deactivate
    speed
    repair
    autoPlay
    id
    createdAt
    lastUsedDate
    thumbnail {
      type
      name
      url
      fileSize
      originalResourceId
      originalResourceState
      duration
      ffprobe
      thumbnailResourceId
      createdById
      id
      createdAt
    }
  }
`
export const WordFFragmentDoc = gql`
  fragment WordF on Word {
    userId
    topicId
    vocabAndPhrase
    mediaId
    description
    isVocabulary
    isThumbnail
    isTranslate
    id
    createdAt
    topic {
      userId
      name
      description
      level
      mediaId
      languageTopic
      status
      id
      createdAt
    }
    media {
      type
      name
      url
      fileSize
      duration
      thumbnailResourceId
      createdById
      id
      createdAt
    }
    translate {
      wQAId
      text
      id
      createdAt
      suportLanguage
    }
    wordInUser {
      wordId
      userId
      topicId
      times
      used
      percentListen
      id
      createdAt
    }
  }
`
export const WordInUserFFragmentDoc = gql`
  fragment WordInUserF on WordInUser {
    wordId
    userId
    topicId
    times
    used
    percentListen
    lastDay
    nextDay
    milestone
    isCompleted
    id
    createdAt
    word {
      userId
      topicId
      thumbnailId
      mediaId
      vocabAndPhrase
      description
      isVocabulary
      isThumbnail
      isTranslate
      translate {
        text
        wQAId
        suportLanguage
        id
        createdAt
      }
      id
      createdAt
    }
    user {
      thumbnailId
      aiTutorId
      name
      email
      nativeLanguage
      customer
      languageLearn
      level
      isAdmin
      deactivate
      speed
      repair
      autoPlay
      lastUsedDate
      id
    }
    topic {
      userId
      mediaId
      characterId
      name
      description
      level
      languageTopic
      status
      ordinalNumber
      scenario
      userRole
      aiTutorRole
      task
      id
      createdAt
    }
    userAnswer {
      questionId
      answerId
      topicId
      userId
      wordInUserId
      times
      wrongTimes
      id
      createdAt
    }
  }
`
export const WordbankFFragmentDoc = gql`
  fragment WordbankF on Wordbank {
    userId
    topicId
    word
    translatedWords
    isPhrase
    id
    createdAt
    user {
      thumbnailId
      aiTutorId
      name
      email
      nativeLanguage
      customer
      languageLearn
      level
      isAdmin
      deactivate
      speed
      repair
      autoPlay
      lastUsedDate
      id
      createdAt
    }
  }
`
export const SearchAiTutorDocument = gql`
  query searchAiTutor(
    $filter: AiTutorFilter
    $order: [AiTutorOrderBy!]
    $page: Pagination
  ) {
    searchAiTutor(filter: $filter, order: $order, page: $page) {
      ...AiTutorF
    }
  }
  ${AiTutorFFragmentDoc}
`
export const useSearchAiTutor = (
  options?: Omit<Urql.UseQueryArgs<SearchAiTutorQueryVariables>, 'query'>,
) =>
  Urql.useQuery<SearchAiTutorQuery, SearchAiTutorQueryVariables | void>({
    query: SearchAiTutorDocument,
    ...options,
  })
export interface Operations {
  searchAiTutor: (
    variables?: SearchAiTutorQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<SearchAiTutorQuery, SearchAiTutorQueryVariables>
  >
}
interface OperationsFactory {
  searchAiTutor: (
    client: Urql.Client,
  ) => (
    variables?: SearchAiTutorQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<SearchAiTutorQuery, SearchAiTutorQueryVariables>
  >
}
operationsFactory.searchAiTutor = client => (variables, context) =>
  client.query(SearchAiTutorDocument, variables, context).toPromise() as any
export const CountAiTutorDocument = gql`
  query countAiTutor($filter: AiTutorFilter) {
    countAiTutor(filter: $filter)
  }
`
export const useCountAiTutor = (
  options?: Omit<Urql.UseQueryArgs<CountAiTutorQueryVariables>, 'query'>,
) =>
  Urql.useQuery<CountAiTutorQuery, CountAiTutorQueryVariables | void>({
    query: CountAiTutorDocument,
    ...options,
  })
export interface Operations {
  countAiTutor: (
    variables?: CountAiTutorQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<CountAiTutorQuery, CountAiTutorQueryVariables>
  >
}
interface OperationsFactory {
  countAiTutor: (
    client: Urql.Client,
  ) => (
    variables?: CountAiTutorQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<CountAiTutorQuery, CountAiTutorQueryVariables>
  >
}
operationsFactory.countAiTutor = client => (variables, context) =>
  client.query(CountAiTutorDocument, variables, context).toPromise() as any
export const CreateAiTutorDocument = gql`
  mutation createAiTutor($data: CreateAiTutor!) {
    createAiTutor(data: $data) {
      ...AiTutorF
    }
  }
  ${AiTutorFFragmentDoc}
`
export const useCreateAiTutor = () =>
  Urql.useMutation<CreateAiTutorMutation, CreateAiTutorMutationVariables>(
    CreateAiTutorDocument,
  )
export interface Operations {
  createAiTutor: (
    variables: CreateAiTutorMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<CreateAiTutorMutation, CreateAiTutorMutationVariables>
  >
}
interface OperationsFactory {
  createAiTutor: (
    client: Urql.Client,
  ) => (
    variables: CreateAiTutorMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<CreateAiTutorMutation, CreateAiTutorMutationVariables>
  >
}
operationsFactory.createAiTutor = client => (variables, context) =>
  client.mutation(CreateAiTutorDocument, variables, context).toPromise() as any
export const UpdateAiTutorDocument = gql`
  mutation updateAiTutor($id: ID!, $data: UpdateAiTutor!) {
    updateAiTutor(id: $id, data: $data) {
      ...AiTutorF
    }
  }
  ${AiTutorFFragmentDoc}
`
export const useUpdateAiTutor = () =>
  Urql.useMutation<UpdateAiTutorMutation, UpdateAiTutorMutationVariables>(
    UpdateAiTutorDocument,
  )
export interface Operations {
  updateAiTutor: (
    variables: UpdateAiTutorMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<UpdateAiTutorMutation, UpdateAiTutorMutationVariables>
  >
}
interface OperationsFactory {
  updateAiTutor: (
    client: Urql.Client,
  ) => (
    variables: UpdateAiTutorMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<UpdateAiTutorMutation, UpdateAiTutorMutationVariables>
  >
}
operationsFactory.updateAiTutor = client => (variables, context) =>
  client.mutation(UpdateAiTutorDocument, variables, context).toPromise() as any
export const DeleteAiTutorDocument = gql`
  mutation deleteAiTutor($id: ID!) {
    deleteAiTutor(id: $id)
  }
`
export const useDeleteAiTutor = () =>
  Urql.useMutation<DeleteAiTutorMutation, DeleteAiTutorMutationVariables>(
    DeleteAiTutorDocument,
  )
export interface Operations {
  deleteAiTutor: (
    variables: DeleteAiTutorMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<DeleteAiTutorMutation, DeleteAiTutorMutationVariables>
  >
}
interface OperationsFactory {
  deleteAiTutor: (
    client: Urql.Client,
  ) => (
    variables: DeleteAiTutorMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<DeleteAiTutorMutation, DeleteAiTutorMutationVariables>
  >
}
operationsFactory.deleteAiTutor = client => (variables, context) =>
  client.mutation(DeleteAiTutorDocument, variables, context).toPromise() as any
export const SearchAnswerDocument = gql`
  query searchAnswer(
    $filter: AnswerFilter
    $order: [AnswerOrderBy!]
    $page: Pagination
  ) {
    searchAnswer(filter: $filter, order: $order, page: $page) {
      ...AnswerF
    }
  }
  ${AnswerFFragmentDoc}
`
export const useSearchAnswer = (
  options?: Omit<Urql.UseQueryArgs<SearchAnswerQueryVariables>, 'query'>,
) =>
  Urql.useQuery<SearchAnswerQuery, SearchAnswerQueryVariables | void>({
    query: SearchAnswerDocument,
    ...options,
  })
export interface Operations {
  searchAnswer: (
    variables?: SearchAnswerQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<SearchAnswerQuery, SearchAnswerQueryVariables>
  >
}
interface OperationsFactory {
  searchAnswer: (
    client: Urql.Client,
  ) => (
    variables?: SearchAnswerQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<SearchAnswerQuery, SearchAnswerQueryVariables>
  >
}
operationsFactory.searchAnswer = client => (variables, context) =>
  client.query(SearchAnswerDocument, variables, context).toPromise() as any
export const CountAnswerDocument = gql`
  query countAnswer($filter: AnswerFilter) {
    countAnswer(filter: $filter)
  }
`
export const useCountAnswer = (
  options?: Omit<Urql.UseQueryArgs<CountAnswerQueryVariables>, 'query'>,
) =>
  Urql.useQuery<CountAnswerQuery, CountAnswerQueryVariables | void>({
    query: CountAnswerDocument,
    ...options,
  })
export interface Operations {
  countAnswer: (
    variables?: CountAnswerQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<CountAnswerQuery, CountAnswerQueryVariables>
  >
}
interface OperationsFactory {
  countAnswer: (
    client: Urql.Client,
  ) => (
    variables?: CountAnswerQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<CountAnswerQuery, CountAnswerQueryVariables>
  >
}
operationsFactory.countAnswer = client => (variables, context) =>
  client.query(CountAnswerDocument, variables, context).toPromise() as any
export const CreateAnswerDocument = gql`
  mutation createAnswer($data: CreateAnswer!) {
    createAnswer(data: $data) {
      ...AnswerF
    }
  }
  ${AnswerFFragmentDoc}
`
export const useCreateAnswer = () =>
  Urql.useMutation<CreateAnswerMutation, CreateAnswerMutationVariables>(
    CreateAnswerDocument,
  )
export interface Operations {
  createAnswer: (
    variables: CreateAnswerMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<CreateAnswerMutation, CreateAnswerMutationVariables>
  >
}
interface OperationsFactory {
  createAnswer: (
    client: Urql.Client,
  ) => (
    variables: CreateAnswerMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<CreateAnswerMutation, CreateAnswerMutationVariables>
  >
}
operationsFactory.createAnswer = client => (variables, context) =>
  client.mutation(CreateAnswerDocument, variables, context).toPromise() as any
export const UpdateAnswerDocument = gql`
  mutation updateAnswer($id: ID!, $data: UpdateAnswer!) {
    updateAnswer(id: $id, data: $data) {
      ...AnswerF
    }
  }
  ${AnswerFFragmentDoc}
`
export const useUpdateAnswer = () =>
  Urql.useMutation<UpdateAnswerMutation, UpdateAnswerMutationVariables>(
    UpdateAnswerDocument,
  )
export interface Operations {
  updateAnswer: (
    variables: UpdateAnswerMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<UpdateAnswerMutation, UpdateAnswerMutationVariables>
  >
}
interface OperationsFactory {
  updateAnswer: (
    client: Urql.Client,
  ) => (
    variables: UpdateAnswerMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<UpdateAnswerMutation, UpdateAnswerMutationVariables>
  >
}
operationsFactory.updateAnswer = client => (variables, context) =>
  client.mutation(UpdateAnswerDocument, variables, context).toPromise() as any
export const DeleteAnswerDocument = gql`
  mutation deleteAnswer($id: ID!) {
    deleteAnswer(id: $id)
  }
`
export const useDeleteAnswer = () =>
  Urql.useMutation<DeleteAnswerMutation, DeleteAnswerMutationVariables>(
    DeleteAnswerDocument,
  )
export interface Operations {
  deleteAnswer: (
    variables: DeleteAnswerMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<DeleteAnswerMutation, DeleteAnswerMutationVariables>
  >
}
interface OperationsFactory {
  deleteAnswer: (
    client: Urql.Client,
  ) => (
    variables: DeleteAnswerMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<DeleteAnswerMutation, DeleteAnswerMutationVariables>
  >
}
operationsFactory.deleteAnswer = client => (variables, context) =>
  client.mutation(DeleteAnswerDocument, variables, context).toPromise() as any
export const SearchAnswerInQuestionDocument = gql`
  query searchAnswerInQuestion(
    $filter: AnswerInQuestionFilter
    $order: [AnswerInQuestionOrderBy!]
    $page: Pagination
  ) {
    searchAnswerInQuestion(filter: $filter, order: $order, page: $page) {
      ...AnswerInQuestionF
    }
  }
  ${AnswerInQuestionFFragmentDoc}
`
export const useSearchAnswerInQuestion = (
  options?: Omit<
    Urql.UseQueryArgs<SearchAnswerInQuestionQueryVariables>,
    'query'
  >,
) =>
  Urql.useQuery<
    SearchAnswerInQuestionQuery,
    SearchAnswerInQuestionQueryVariables | void
  >({ query: SearchAnswerInQuestionDocument, ...options })
export interface Operations {
  searchAnswerInQuestion: (
    variables?: SearchAnswerInQuestionQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      SearchAnswerInQuestionQuery,
      SearchAnswerInQuestionQueryVariables
    >
  >
}
interface OperationsFactory {
  searchAnswerInQuestion: (
    client: Urql.Client,
  ) => (
    variables?: SearchAnswerInQuestionQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      SearchAnswerInQuestionQuery,
      SearchAnswerInQuestionQueryVariables
    >
  >
}
operationsFactory.searchAnswerInQuestion = client => (variables, context) =>
  client
    .query(SearchAnswerInQuestionDocument, variables, context)
    .toPromise() as any
export const CountAnswerInQuestionDocument = gql`
  query countAnswerInQuestion($filter: AnswerInQuestionFilter) {
    countAnswerInQuestion(filter: $filter)
  }
`
export const useCountAnswerInQuestion = (
  options?: Omit<
    Urql.UseQueryArgs<CountAnswerInQuestionQueryVariables>,
    'query'
  >,
) =>
  Urql.useQuery<
    CountAnswerInQuestionQuery,
    CountAnswerInQuestionQueryVariables | void
  >({ query: CountAnswerInQuestionDocument, ...options })
export interface Operations {
  countAnswerInQuestion: (
    variables?: CountAnswerInQuestionQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      CountAnswerInQuestionQuery,
      CountAnswerInQuestionQueryVariables
    >
  >
}
interface OperationsFactory {
  countAnswerInQuestion: (
    client: Urql.Client,
  ) => (
    variables?: CountAnswerInQuestionQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      CountAnswerInQuestionQuery,
      CountAnswerInQuestionQueryVariables
    >
  >
}
operationsFactory.countAnswerInQuestion = client => (variables, context) =>
  client
    .query(CountAnswerInQuestionDocument, variables, context)
    .toPromise() as any
export const CreateAnswerInQuestionDocument = gql`
  mutation createAnswerInQuestion($data: CreateAnswerInQuestion!) {
    createAnswerInQuestion(data: $data) {
      ...AnswerInQuestionF
    }
  }
  ${AnswerInQuestionFFragmentDoc}
`
export const useCreateAnswerInQuestion = () =>
  Urql.useMutation<
    CreateAnswerInQuestionMutation,
    CreateAnswerInQuestionMutationVariables
  >(CreateAnswerInQuestionDocument)
export interface Operations {
  createAnswerInQuestion: (
    variables: CreateAnswerInQuestionMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      CreateAnswerInQuestionMutation,
      CreateAnswerInQuestionMutationVariables
    >
  >
}
interface OperationsFactory {
  createAnswerInQuestion: (
    client: Urql.Client,
  ) => (
    variables: CreateAnswerInQuestionMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      CreateAnswerInQuestionMutation,
      CreateAnswerInQuestionMutationVariables
    >
  >
}
operationsFactory.createAnswerInQuestion = client => (variables, context) =>
  client
    .mutation(CreateAnswerInQuestionDocument, variables, context)
    .toPromise() as any
export const UpdateAnswerInQuestionDocument = gql`
  mutation updateAnswerInQuestion($id: ID!, $data: UpdateAnswerInQuestion!) {
    updateAnswerInQuestion(id: $id, data: $data) {
      ...AnswerInQuestionF
    }
  }
  ${AnswerInQuestionFFragmentDoc}
`
export const useUpdateAnswerInQuestion = () =>
  Urql.useMutation<
    UpdateAnswerInQuestionMutation,
    UpdateAnswerInQuestionMutationVariables
  >(UpdateAnswerInQuestionDocument)
export interface Operations {
  updateAnswerInQuestion: (
    variables: UpdateAnswerInQuestionMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      UpdateAnswerInQuestionMutation,
      UpdateAnswerInQuestionMutationVariables
    >
  >
}
interface OperationsFactory {
  updateAnswerInQuestion: (
    client: Urql.Client,
  ) => (
    variables: UpdateAnswerInQuestionMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      UpdateAnswerInQuestionMutation,
      UpdateAnswerInQuestionMutationVariables
    >
  >
}
operationsFactory.updateAnswerInQuestion = client => (variables, context) =>
  client
    .mutation(UpdateAnswerInQuestionDocument, variables, context)
    .toPromise() as any
export const DeleteAnswerInQuestionDocument = gql`
  mutation deleteAnswerInQuestion($id: ID!) {
    deleteAnswerInQuestion(id: $id)
  }
`
export const useDeleteAnswerInQuestion = () =>
  Urql.useMutation<
    DeleteAnswerInQuestionMutation,
    DeleteAnswerInQuestionMutationVariables
  >(DeleteAnswerInQuestionDocument)
export interface Operations {
  deleteAnswerInQuestion: (
    variables: DeleteAnswerInQuestionMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      DeleteAnswerInQuestionMutation,
      DeleteAnswerInQuestionMutationVariables
    >
  >
}
interface OperationsFactory {
  deleteAnswerInQuestion: (
    client: Urql.Client,
  ) => (
    variables: DeleteAnswerInQuestionMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      DeleteAnswerInQuestionMutation,
      DeleteAnswerInQuestionMutationVariables
    >
  >
}
operationsFactory.deleteAnswerInQuestion = client => (variables, context) =>
  client
    .mutation(DeleteAnswerInQuestionDocument, variables, context)
    .toPromise() as any
export const LoginDocument = gql`
  mutation login($email: Email!, $password: NeString!) {
    login(email: $email, password: $password) {
      ...AuthSessionF
    }
  }
  ${AuthSessionFFragmentDoc}
`
export const useLogin = () =>
  Urql.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument)
export interface Operations {
  login: (
    variables: LoginMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<Urql.OperationResult<LoginMutation, LoginMutationVariables>>
}
interface OperationsFactory {
  login: (
    client: Urql.Client,
  ) => (
    variables: LoginMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<Urql.OperationResult<LoginMutation, LoginMutationVariables>>
}
operationsFactory.login = client => (variables, context) =>
  client.mutation(LoginDocument, variables, context).toPromise() as any
export const LogoutDocument = gql`
  mutation logout {
    logout
  }
`
export const useLogout = () =>
  Urql.useMutation<LogoutMutation, LogoutMutationVariables | void>(
    LogoutDocument,
  )
export interface Operations {
  logout: (
    variables?: LogoutMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<Urql.OperationResult<LogoutMutation, LogoutMutationVariables>>
}
interface OperationsFactory {
  logout: (
    client: Urql.Client,
  ) => (
    variables?: LogoutMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<Urql.OperationResult<LogoutMutation, LogoutMutationVariables>>
}
operationsFactory.logout = client => (variables, context) =>
  client.mutation(LogoutDocument, variables, context).toPromise() as any
export const RegisterDocument = gql`
  mutation register($data: Register!) {
    register(data: $data)
  }
`
export const useRegister = () =>
  Urql.useMutation<RegisterMutation, RegisterMutationVariables>(
    RegisterDocument,
  )
export interface Operations {
  register: (
    variables: RegisterMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<RegisterMutation, RegisterMutationVariables>
  >
}
interface OperationsFactory {
  register: (
    client: Urql.Client,
  ) => (
    variables: RegisterMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<RegisterMutation, RegisterMutationVariables>
  >
}
operationsFactory.register = client => (variables, context) =>
  client.mutation(RegisterDocument, variables, context).toPromise() as any
export const ResolveRegisterDocument = gql`
  mutation resolveRegister($data: CheckTicket!) {
    resolveRegister(data: $data) {
      ...AuthSessionF
    }
  }
  ${AuthSessionFFragmentDoc}
`
export const useResolveRegister = () =>
  Urql.useMutation<ResolveRegisterMutation, ResolveRegisterMutationVariables>(
    ResolveRegisterDocument,
  )
export interface Operations {
  resolveRegister: (
    variables: ResolveRegisterMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      ResolveRegisterMutation,
      ResolveRegisterMutationVariables
    >
  >
}
interface OperationsFactory {
  resolveRegister: (
    client: Urql.Client,
  ) => (
    variables: ResolveRegisterMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      ResolveRegisterMutation,
      ResolveRegisterMutationVariables
    >
  >
}
operationsFactory.resolveRegister = client => (variables, context) =>
  client
    .mutation(ResolveRegisterDocument, variables, context)
    .toPromise() as any
export const ResetPasswordDocument = gql`
  mutation resetPassword($id: ID!) {
    resetPassword(id: $id)
  }
`
export const useResetPassword = () =>
  Urql.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(
    ResetPasswordDocument,
  )
export interface Operations {
  resetPassword: (
    variables: ResetPasswordMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<ResetPasswordMutation, ResetPasswordMutationVariables>
  >
}
interface OperationsFactory {
  resetPassword: (
    client: Urql.Client,
  ) => (
    variables: ResetPasswordMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<ResetPasswordMutation, ResetPasswordMutationVariables>
  >
}
operationsFactory.resetPassword = client => (variables, context) =>
  client.mutation(ResetPasswordDocument, variables, context).toPromise() as any
export const ForgotPasswordDocument = gql`
  mutation forgotPassword($email: Email!) {
    forgotPassword(email: $email)
  }
`
export const useForgotPassword = () =>
  Urql.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(
    ForgotPasswordDocument,
  )
export interface Operations {
  forgotPassword: (
    variables: ForgotPasswordMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      ForgotPasswordMutation,
      ForgotPasswordMutationVariables
    >
  >
}
interface OperationsFactory {
  forgotPassword: (
    client: Urql.Client,
  ) => (
    variables: ForgotPasswordMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      ForgotPasswordMutation,
      ForgotPasswordMutationVariables
    >
  >
}
operationsFactory.forgotPassword = client => (variables, context) =>
  client.mutation(ForgotPasswordDocument, variables, context).toPromise() as any
export const ConfirmOtpDocument = gql`
  mutation confirmOTP($data: Confirm!) {
    confirmOTP(data: $data)
  }
`
export const useConfirmOtp = () =>
  Urql.useMutation<ConfirmOtpMutation, ConfirmOtpMutationVariables>(
    ConfirmOtpDocument,
  )
export interface Operations {
  confirmOtp: (
    variables: ConfirmOtpMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<ConfirmOtpMutation, ConfirmOtpMutationVariables>
  >
}
interface OperationsFactory {
  confirmOtp: (
    client: Urql.Client,
  ) => (
    variables: ConfirmOtpMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<ConfirmOtpMutation, ConfirmOtpMutationVariables>
  >
}
operationsFactory.confirmOtp = client => (variables, context) =>
  client.mutation(ConfirmOtpDocument, variables, context).toPromise() as any
export const ResolveForgotPasswordDocument = gql`
  mutation resolveForgotPassword(
    $data: resolveCheckTicket!
    $newPassword: Password!
  ) {
    resolveForgotPassword(data: $data, newPassword: $newPassword) {
      ...AuthSessionF
    }
  }
  ${AuthSessionFFragmentDoc}
`
export const useResolveForgotPassword = () =>
  Urql.useMutation<
    ResolveForgotPasswordMutation,
    ResolveForgotPasswordMutationVariables
  >(ResolveForgotPasswordDocument)
export interface Operations {
  resolveForgotPassword: (
    variables: ResolveForgotPasswordMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      ResolveForgotPasswordMutation,
      ResolveForgotPasswordMutationVariables
    >
  >
}
interface OperationsFactory {
  resolveForgotPassword: (
    client: Urql.Client,
  ) => (
    variables: ResolveForgotPasswordMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      ResolveForgotPasswordMutation,
      ResolveForgotPasswordMutationVariables
    >
  >
}
operationsFactory.resolveForgotPassword = client => (variables, context) =>
  client
    .mutation(ResolveForgotPasswordDocument, variables, context)
    .toPromise() as any
export const ResendTicketDocument = gql`
  mutation resendTicket($id: ID!) {
    resendTicket(id: $id)
  }
`
export const useResendTicket = () =>
  Urql.useMutation<ResendTicketMutation, ResendTicketMutationVariables>(
    ResendTicketDocument,
  )
export interface Operations {
  resendTicket: (
    variables: ResendTicketMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<ResendTicketMutation, ResendTicketMutationVariables>
  >
}
interface OperationsFactory {
  resendTicket: (
    client: Urql.Client,
  ) => (
    variables: ResendTicketMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<ResendTicketMutation, ResendTicketMutationVariables>
  >
}
operationsFactory.resendTicket = client => (variables, context) =>
  client.mutation(ResendTicketDocument, variables, context).toPromise() as any
export const CurrentAuthSessionDocument = gql`
  query currentAuthSession {
    currentAuthSession {
      ...AuthSessionF
    }
  }
  ${AuthSessionFFragmentDoc}
`
export const useCurrentAuthSession = (
  options?: Omit<Urql.UseQueryArgs<CurrentAuthSessionQueryVariables>, 'query'>,
) =>
  Urql.useQuery<
    CurrentAuthSessionQuery,
    CurrentAuthSessionQueryVariables | void
  >({ query: CurrentAuthSessionDocument, ...options })
export interface Operations {
  currentAuthSession: (
    variables?: CurrentAuthSessionQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      CurrentAuthSessionQuery,
      CurrentAuthSessionQueryVariables
    >
  >
}
interface OperationsFactory {
  currentAuthSession: (
    client: Urql.Client,
  ) => (
    variables?: CurrentAuthSessionQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      CurrentAuthSessionQuery,
      CurrentAuthSessionQueryVariables
    >
  >
}
operationsFactory.currentAuthSession = client => (variables, context) =>
  client
    .query(CurrentAuthSessionDocument, variables, context)
    .toPromise() as any
export const CheckEmailDocument = gql`
  query checkEmail($email: Email!) {
    checkEmail(email: $email)
  }
`
export const useCheckEmail = (
  options: Omit<Urql.UseQueryArgs<CheckEmailQueryVariables>, 'query'>,
) =>
  Urql.useQuery<CheckEmailQuery, CheckEmailQueryVariables>({
    query: CheckEmailDocument,
    ...options,
  })
export interface Operations {
  checkEmail: (
    variables: CheckEmailQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<Urql.OperationResult<CheckEmailQuery, CheckEmailQueryVariables>>
}
interface OperationsFactory {
  checkEmail: (
    client: Urql.Client,
  ) => (
    variables: CheckEmailQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<Urql.OperationResult<CheckEmailQuery, CheckEmailQueryVariables>>
}
operationsFactory.checkEmail = client => (variables, context) =>
  client.query(CheckEmailDocument, variables, context).toPromise() as any
export const LoginGoogleDocument = gql`
  mutation loginGoogle($data: InputGoogle!) {
    loginGoogle(data: $data) {
      ...AuthSessionF
    }
  }
  ${AuthSessionFFragmentDoc}
`
export const useLoginGoogle = () =>
  Urql.useMutation<LoginGoogleMutation, LoginGoogleMutationVariables>(
    LoginGoogleDocument,
  )
export interface Operations {
  loginGoogle: (
    variables: LoginGoogleMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<LoginGoogleMutation, LoginGoogleMutationVariables>
  >
}
interface OperationsFactory {
  loginGoogle: (
    client: Urql.Client,
  ) => (
    variables: LoginGoogleMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<LoginGoogleMutation, LoginGoogleMutationVariables>
  >
}
operationsFactory.loginGoogle = client => (variables, context) =>
  client.mutation(LoginGoogleDocument, variables, context).toPromise() as any
export const LoginFacebookDocument = gql`
  mutation loginFacebook($data: InputFacebook!) {
    loginFacebook(data: $data) {
      ...AuthSessionF
    }
  }
  ${AuthSessionFFragmentDoc}
`
export const useLoginFacebook = () =>
  Urql.useMutation<LoginFacebookMutation, LoginFacebookMutationVariables>(
    LoginFacebookDocument,
  )
export interface Operations {
  loginFacebook: (
    variables: LoginFacebookMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<LoginFacebookMutation, LoginFacebookMutationVariables>
  >
}
interface OperationsFactory {
  loginFacebook: (
    client: Urql.Client,
  ) => (
    variables: LoginFacebookMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<LoginFacebookMutation, LoginFacebookMutationVariables>
  >
}
operationsFactory.loginFacebook = client => (variables, context) =>
  client.mutation(LoginFacebookDocument, variables, context).toPromise() as any
export const SearchCategoryDocument = gql`
  query searchCategory(
    $filter: CategoryFilter
    $order: [CategoryOrderBy!]
    $page: Pagination
  ) {
    searchCategory(filter: $filter, order: $order, page: $page) {
      ...CategoryF
    }
  }
  ${CategoryFFragmentDoc}
`
export const useSearchCategory = (
  options?: Omit<Urql.UseQueryArgs<SearchCategoryQueryVariables>, 'query'>,
) =>
  Urql.useQuery<SearchCategoryQuery, SearchCategoryQueryVariables | void>({
    query: SearchCategoryDocument,
    ...options,
  })
export interface Operations {
  searchCategory: (
    variables?: SearchCategoryQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<SearchCategoryQuery, SearchCategoryQueryVariables>
  >
}
interface OperationsFactory {
  searchCategory: (
    client: Urql.Client,
  ) => (
    variables?: SearchCategoryQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<SearchCategoryQuery, SearchCategoryQueryVariables>
  >
}
operationsFactory.searchCategory = client => (variables, context) =>
  client.query(SearchCategoryDocument, variables, context).toPromise() as any
export const CountCategoryDocument = gql`
  query countCategory($filter: CategoryFilter) {
    countCategory(filter: $filter)
  }
`
export const useCountCategory = (
  options?: Omit<Urql.UseQueryArgs<CountCategoryQueryVariables>, 'query'>,
) =>
  Urql.useQuery<CountCategoryQuery, CountCategoryQueryVariables | void>({
    query: CountCategoryDocument,
    ...options,
  })
export interface Operations {
  countCategory: (
    variables?: CountCategoryQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<CountCategoryQuery, CountCategoryQueryVariables>
  >
}
interface OperationsFactory {
  countCategory: (
    client: Urql.Client,
  ) => (
    variables?: CountCategoryQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<CountCategoryQuery, CountCategoryQueryVariables>
  >
}
operationsFactory.countCategory = client => (variables, context) =>
  client.query(CountCategoryDocument, variables, context).toPromise() as any
export const CreateCategoryDocument = gql`
  mutation createCategory($data: CreateCategory!) {
    createCategory(data: $data) {
      ...CategoryF
    }
  }
  ${CategoryFFragmentDoc}
`
export const useCreateCategory = () =>
  Urql.useMutation<CreateCategoryMutation, CreateCategoryMutationVariables>(
    CreateCategoryDocument,
  )
export interface Operations {
  createCategory: (
    variables: CreateCategoryMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      CreateCategoryMutation,
      CreateCategoryMutationVariables
    >
  >
}
interface OperationsFactory {
  createCategory: (
    client: Urql.Client,
  ) => (
    variables: CreateCategoryMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      CreateCategoryMutation,
      CreateCategoryMutationVariables
    >
  >
}
operationsFactory.createCategory = client => (variables, context) =>
  client.mutation(CreateCategoryDocument, variables, context).toPromise() as any
export const UpdateCategoryDocument = gql`
  mutation updateCategory($id: ID!, $data: UpdateCategory!) {
    updateCategory(id: $id, data: $data) {
      ...CategoryF
    }
  }
  ${CategoryFFragmentDoc}
`
export const useUpdateCategory = () =>
  Urql.useMutation<UpdateCategoryMutation, UpdateCategoryMutationVariables>(
    UpdateCategoryDocument,
  )
export interface Operations {
  updateCategory: (
    variables: UpdateCategoryMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      UpdateCategoryMutation,
      UpdateCategoryMutationVariables
    >
  >
}
interface OperationsFactory {
  updateCategory: (
    client: Urql.Client,
  ) => (
    variables: UpdateCategoryMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      UpdateCategoryMutation,
      UpdateCategoryMutationVariables
    >
  >
}
operationsFactory.updateCategory = client => (variables, context) =>
  client.mutation(UpdateCategoryDocument, variables, context).toPromise() as any
export const DeleteCategoryDocument = gql`
  mutation deleteCategory($id: ID!) {
    deleteCategory(id: $id)
  }
`
export const useDeleteCategory = () =>
  Urql.useMutation<DeleteCategoryMutation, DeleteCategoryMutationVariables>(
    DeleteCategoryDocument,
  )
export interface Operations {
  deleteCategory: (
    variables: DeleteCategoryMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      DeleteCategoryMutation,
      DeleteCategoryMutationVariables
    >
  >
}
interface OperationsFactory {
  deleteCategory: (
    client: Urql.Client,
  ) => (
    variables: DeleteCategoryMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      DeleteCategoryMutation,
      DeleteCategoryMutationVariables
    >
  >
}
operationsFactory.deleteCategory = client => (variables, context) =>
  client.mutation(DeleteCategoryDocument, variables, context).toPromise() as any
export const SearchMessageDocument = gql`
  query searchMessage(
    $filter: MessageFilter
    $order: [MessageOrderBy!]
    $page: Pagination
  ) {
    searchMessage(filter: $filter, order: $order, page: $page) {
      ...MessageF
    }
  }
  ${MessageFFragmentDoc}
`
export const useSearchMessage = (
  options?: Omit<Urql.UseQueryArgs<SearchMessageQueryVariables>, 'query'>,
) =>
  Urql.useQuery<SearchMessageQuery, SearchMessageQueryVariables | void>({
    query: SearchMessageDocument,
    ...options,
  })
export interface Operations {
  searchMessage: (
    variables?: SearchMessageQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<SearchMessageQuery, SearchMessageQueryVariables>
  >
}
interface OperationsFactory {
  searchMessage: (
    client: Urql.Client,
  ) => (
    variables?: SearchMessageQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<SearchMessageQuery, SearchMessageQueryVariables>
  >
}
operationsFactory.searchMessage = client => (variables, context) =>
  client.query(SearchMessageDocument, variables, context).toPromise() as any
export const CountMessageDocument = gql`
  query countMessage($filter: MessageFilter) {
    countMessage(filter: $filter)
  }
`
export const useCountMessage = (
  options?: Omit<Urql.UseQueryArgs<CountMessageQueryVariables>, 'query'>,
) =>
  Urql.useQuery<CountMessageQuery, CountMessageQueryVariables | void>({
    query: CountMessageDocument,
    ...options,
  })
export interface Operations {
  countMessage: (
    variables?: CountMessageQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<CountMessageQuery, CountMessageQueryVariables>
  >
}
interface OperationsFactory {
  countMessage: (
    client: Urql.Client,
  ) => (
    variables?: CountMessageQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<CountMessageQuery, CountMessageQueryVariables>
  >
}
operationsFactory.countMessage = client => (variables, context) =>
  client.query(CountMessageDocument, variables, context).toPromise() as any
export const SearchMessageListDocument = gql`
  query searchMessageList(
    $filter: MessageFilter
    $order: [MessageOrderBy!]
    $page: Pagination
  ) {
    messages: searchMessage(filter: $filter, order: $order, page: $page) {
      ...MessageF
    }
    total: countMessage(filter: $filter)
  }
  ${MessageFFragmentDoc}
`
export const useSearchMessageList = (
  options?: Omit<Urql.UseQueryArgs<SearchMessageListQueryVariables>, 'query'>,
) =>
  Urql.useQuery<SearchMessageListQuery, SearchMessageListQueryVariables | void>(
    { query: SearchMessageListDocument, ...options },
  )
export interface Operations {
  searchMessageList: (
    variables?: SearchMessageListQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      SearchMessageListQuery,
      SearchMessageListQueryVariables
    >
  >
}
interface OperationsFactory {
  searchMessageList: (
    client: Urql.Client,
  ) => (
    variables?: SearchMessageListQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      SearchMessageListQuery,
      SearchMessageListQueryVariables
    >
  >
}
operationsFactory.searchMessageList = client => (variables, context) =>
  client.query(SearchMessageListDocument, variables, context).toPromise() as any
export const CreateMessageDocument = gql`
  mutation createMessage($data: CreateMessage!) {
    createMessage(data: $data) {
      ...MessageF
    }
  }
  ${MessageFFragmentDoc}
`
export const useCreateMessage = () =>
  Urql.useMutation<CreateMessageMutation, CreateMessageMutationVariables>(
    CreateMessageDocument,
  )
export interface Operations {
  createMessage: (
    variables: CreateMessageMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<CreateMessageMutation, CreateMessageMutationVariables>
  >
}
interface OperationsFactory {
  createMessage: (
    client: Urql.Client,
  ) => (
    variables: CreateMessageMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<CreateMessageMutation, CreateMessageMutationVariables>
  >
}
operationsFactory.createMessage = client => (variables, context) =>
  client.mutation(CreateMessageDocument, variables, context).toPromise() as any
export const SuggestMessageDocument = gql`
  query suggestMessage($topicInUserId: ID) {
    suggestMessage(topicInUserId: $topicInUserId)
  }
`
export const useSuggestMessage = (
  options?: Omit<Urql.UseQueryArgs<SuggestMessageQueryVariables>, 'query'>,
) =>
  Urql.useQuery<SuggestMessageQuery, SuggestMessageQueryVariables | void>({
    query: SuggestMessageDocument,
    ...options,
  })
export interface Operations {
  suggestMessage: (
    variables?: SuggestMessageQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<SuggestMessageQuery, SuggestMessageQueryVariables>
  >
}
interface OperationsFactory {
  suggestMessage: (
    client: Urql.Client,
  ) => (
    variables?: SuggestMessageQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<SuggestMessageQuery, SuggestMessageQueryVariables>
  >
}
operationsFactory.suggestMessage = client => (variables, context) =>
  client.query(SuggestMessageDocument, variables, context).toPromise() as any
export const TranslateMessageDocument = gql`
  query translateMessage($content: String) {
    translateMessage(content: $content)
  }
`
export const useTranslateMessage = (
  options?: Omit<Urql.UseQueryArgs<TranslateMessageQueryVariables>, 'query'>,
) =>
  Urql.useQuery<TranslateMessageQuery, TranslateMessageQueryVariables | void>({
    query: TranslateMessageDocument,
    ...options,
  })
export interface Operations {
  translateMessage: (
    variables?: TranslateMessageQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<TranslateMessageQuery, TranslateMessageQueryVariables>
  >
}
interface OperationsFactory {
  translateMessage: (
    client: Urql.Client,
  ) => (
    variables?: TranslateMessageQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<TranslateMessageQuery, TranslateMessageQueryVariables>
  >
}
operationsFactory.translateMessage = client => (variables, context) =>
  client.query(TranslateMessageDocument, variables, context).toPromise() as any
export const CheckTaskDocument = gql`
  query checkTask(
    $content: String
    $task: [TaskItemInput]
    $level: Level
    $topicInUserId: ID
  ) {
    checkTask(
      content: $content
      task: $task
      level: $level
      topicInUserId: $topicInUserId
    )
  }
`
export const useCheckTask = (
  options?: Omit<Urql.UseQueryArgs<CheckTaskQueryVariables>, 'query'>,
) =>
  Urql.useQuery<CheckTaskQuery, CheckTaskQueryVariables | void>({
    query: CheckTaskDocument,
    ...options,
  })
export interface Operations {
  checkTask: (
    variables?: CheckTaskQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<Urql.OperationResult<CheckTaskQuery, CheckTaskQueryVariables>>
}
interface OperationsFactory {
  checkTask: (
    client: Urql.Client,
  ) => (
    variables?: CheckTaskQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<Urql.OperationResult<CheckTaskQuery, CheckTaskQueryVariables>>
}
operationsFactory.checkTask = client => (variables, context) =>
  client.query(CheckTaskDocument, variables, context).toPromise() as any
export const SpeechToTextDocument = gql`
  query speechToText($audioBuffer: Buffer, $devices: Devices) {
    speechToText(audioBuffer: $audioBuffer, devices: $devices)
  }
`
export const useSpeechToText = (
  options?: Omit<Urql.UseQueryArgs<SpeechToTextQueryVariables>, 'query'>,
) =>
  Urql.useQuery<SpeechToTextQuery, SpeechToTextQueryVariables | void>({
    query: SpeechToTextDocument,
    ...options,
  })
export interface Operations {
  speechToText: (
    variables?: SpeechToTextQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<SpeechToTextQuery, SpeechToTextQueryVariables>
  >
}
interface OperationsFactory {
  speechToText: (
    client: Urql.Client,
  ) => (
    variables?: SpeechToTextQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<SpeechToTextQuery, SpeechToTextQueryVariables>
  >
}
operationsFactory.speechToText = client => (variables, context) =>
  client.query(SpeechToTextDocument, variables, context).toPromise() as any
export const TextToSpeechDocument = gql`
  query textToSpeech($text: String!) {
    textToSpeech(text: $text)
  }
`
export const useTextToSpeech = (
  options: Omit<Urql.UseQueryArgs<TextToSpeechQueryVariables>, 'query'>,
) =>
  Urql.useQuery<TextToSpeechQuery, TextToSpeechQueryVariables>({
    query: TextToSpeechDocument,
    ...options,
  })
export interface Operations {
  textToSpeech: (
    variables: TextToSpeechQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<TextToSpeechQuery, TextToSpeechQueryVariables>
  >
}
interface OperationsFactory {
  textToSpeech: (
    client: Urql.Client,
  ) => (
    variables: TextToSpeechQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<TextToSpeechQuery, TextToSpeechQueryVariables>
  >
}
operationsFactory.textToSpeech = client => (variables, context) =>
  client.query(TextToSpeechDocument, variables, context).toPromise() as any
export const DeleteMessageDocument = gql`
  mutation deleteMessage {
    deleteMessage
  }
`
export const useDeleteMessage = () =>
  Urql.useMutation<
    DeleteMessageMutation,
    DeleteMessageMutationVariables | void
  >(DeleteMessageDocument)
export interface Operations {
  deleteMessage: (
    variables?: DeleteMessageMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<DeleteMessageMutation, DeleteMessageMutationVariables>
  >
}
interface OperationsFactory {
  deleteMessage: (
    client: Urql.Client,
  ) => (
    variables?: DeleteMessageMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<DeleteMessageMutation, DeleteMessageMutationVariables>
  >
}
operationsFactory.deleteMessage = client => (variables, context) =>
  client.mutation(DeleteMessageDocument, variables, context).toPromise() as any
export const AutoCorrectMessageDocument = gql`
  query autoCorrectMessage($content: String) {
    autoCorrectMessage(content: $content)
  }
`
export const useAutoCorrectMessage = (
  options?: Omit<Urql.UseQueryArgs<AutoCorrectMessageQueryVariables>, 'query'>,
) =>
  Urql.useQuery<
    AutoCorrectMessageQuery,
    AutoCorrectMessageQueryVariables | void
  >({ query: AutoCorrectMessageDocument, ...options })
export interface Operations {
  autoCorrectMessage: (
    variables?: AutoCorrectMessageQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      AutoCorrectMessageQuery,
      AutoCorrectMessageQueryVariables
    >
  >
}
interface OperationsFactory {
  autoCorrectMessage: (
    client: Urql.Client,
  ) => (
    variables?: AutoCorrectMessageQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      AutoCorrectMessageQuery,
      AutoCorrectMessageQueryVariables
    >
  >
}
operationsFactory.autoCorrectMessage = client => (variables, context) =>
  client
    .query(AutoCorrectMessageDocument, variables, context)
    .toPromise() as any
export const TestAutoCorrectMessageDocument = gql`
  query testAutoCorrectMessage($content: String) {
    testAutoCorrectMessage(content: $content)
  }
`
export const useTestAutoCorrectMessage = (
  options?: Omit<
    Urql.UseQueryArgs<TestAutoCorrectMessageQueryVariables>,
    'query'
  >,
) =>
  Urql.useQuery<
    TestAutoCorrectMessageQuery,
    TestAutoCorrectMessageQueryVariables | void
  >({ query: TestAutoCorrectMessageDocument, ...options })
export interface Operations {
  testAutoCorrectMessage: (
    variables?: TestAutoCorrectMessageQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      TestAutoCorrectMessageQuery,
      TestAutoCorrectMessageQueryVariables
    >
  >
}
interface OperationsFactory {
  testAutoCorrectMessage: (
    client: Urql.Client,
  ) => (
    variables?: TestAutoCorrectMessageQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      TestAutoCorrectMessageQuery,
      TestAutoCorrectMessageQueryVariables
    >
  >
}
operationsFactory.testAutoCorrectMessage = client => (variables, context) =>
  client
    .query(TestAutoCorrectMessageDocument, variables, context)
    .toPromise() as any
export const DeleteMessageTopicInUserDocument = gql`
  mutation deleteMessageTopicInUser($topicInUserId: ID!) {
    deleteMessageTopicInUser(topicInUserId: $topicInUserId)
  }
`
export const useDeleteMessageTopicInUser = () =>
  Urql.useMutation<
    DeleteMessageTopicInUserMutation,
    DeleteMessageTopicInUserMutationVariables
  >(DeleteMessageTopicInUserDocument)
export interface Operations {
  deleteMessageTopicInUser: (
    variables: DeleteMessageTopicInUserMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      DeleteMessageTopicInUserMutation,
      DeleteMessageTopicInUserMutationVariables
    >
  >
}
interface OperationsFactory {
  deleteMessageTopicInUser: (
    client: Urql.Client,
  ) => (
    variables: DeleteMessageTopicInUserMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      DeleteMessageTopicInUserMutation,
      DeleteMessageTopicInUserMutationVariables
    >
  >
}
operationsFactory.deleteMessageTopicInUser = client => (variables, context) =>
  client
    .mutation(DeleteMessageTopicInUserDocument, variables, context)
    .toPromise() as any
export const GenerateCompleteTheDialogueDocument = gql`
  query generateCompleteTheDialogue($content: String, $language: Language) {
    generateCompleteTheDialogue(content: $content, language: $language)
  }
`
export const useGenerateCompleteTheDialogue = (
  options?: Omit<
    Urql.UseQueryArgs<GenerateCompleteTheDialogueQueryVariables>,
    'query'
  >,
) =>
  Urql.useQuery<
    GenerateCompleteTheDialogueQuery,
    GenerateCompleteTheDialogueQueryVariables | void
  >({ query: GenerateCompleteTheDialogueDocument, ...options })
export interface Operations {
  generateCompleteTheDialogue: (
    variables?: GenerateCompleteTheDialogueQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      GenerateCompleteTheDialogueQuery,
      GenerateCompleteTheDialogueQueryVariables
    >
  >
}
interface OperationsFactory {
  generateCompleteTheDialogue: (
    client: Urql.Client,
  ) => (
    variables?: GenerateCompleteTheDialogueQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      GenerateCompleteTheDialogueQuery,
      GenerateCompleteTheDialogueQueryVariables
    >
  >
}
operationsFactory.generateCompleteTheDialogue =
  client => (variables, context) =>
    client
      .query(GenerateCompleteTheDialogueDocument, variables, context)
      .toPromise() as any
export const GenerateFillTheBlankDocument = gql`
  query generateFillTheBlank(
    $content: String
    $isPhrase: Boolean
    $language: Language
  ) {
    generateFillTheBlank(
      content: $content
      isPhrase: $isPhrase
      language: $language
    )
  }
`
export const useGenerateFillTheBlank = (
  options?: Omit<
    Urql.UseQueryArgs<GenerateFillTheBlankQueryVariables>,
    'query'
  >,
) =>
  Urql.useQuery<
    GenerateFillTheBlankQuery,
    GenerateFillTheBlankQueryVariables | void
  >({ query: GenerateFillTheBlankDocument, ...options })
export interface Operations {
  generateFillTheBlank: (
    variables?: GenerateFillTheBlankQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      GenerateFillTheBlankQuery,
      GenerateFillTheBlankQueryVariables
    >
  >
}
interface OperationsFactory {
  generateFillTheBlank: (
    client: Urql.Client,
  ) => (
    variables?: GenerateFillTheBlankQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      GenerateFillTheBlankQuery,
      GenerateFillTheBlankQueryVariables
    >
  >
}
operationsFactory.generateFillTheBlank = client => (variables, context) =>
  client
    .query(GenerateFillTheBlankDocument, variables, context)
    .toPromise() as any
export const GenerateGameDocument = gql`
  query generateGame(
    $filterWordInUser: WordInUserFilter
    $orderWordInUser: [WordInUserOrderBy!]
    $filterWord: WordFilter
    $filterResourceInWord: ResourceInWordFilter
    $filterTranslate: TranslateFilter
  ) {
    wordInUser: searchWordInUser(
      filter: $filterWordInUser
      order: $orderWordInUser
    ) {
      ...WordInUserF
    }
    word: searchWord(filter: $filterWord) {
      ...WordF
    }
    resourceInWord: searchResourceInWord(filter: $filterResourceInWord) {
      ...ResourceInWordF
    }
    translate: searchTranslate(filter: $filterTranslate) {
      ...TranslateF
    }
  }
  ${WordInUserFFragmentDoc}
  ${WordFFragmentDoc}
  ${ResourceInWordFFragmentDoc}
  ${TranslateFFragmentDoc}
`
export const useGenerateGame = (
  options?: Omit<Urql.UseQueryArgs<GenerateGameQueryVariables>, 'query'>,
) =>
  Urql.useQuery<GenerateGameQuery, GenerateGameQueryVariables | void>({
    query: GenerateGameDocument,
    ...options,
  })
export interface Operations {
  generateGame: (
    variables?: GenerateGameQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<GenerateGameQuery, GenerateGameQueryVariables>
  >
}
interface OperationsFactory {
  generateGame: (
    client: Urql.Client,
  ) => (
    variables?: GenerateGameQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<GenerateGameQuery, GenerateGameQueryVariables>
  >
}
operationsFactory.generateGame = client => (variables, context) =>
  client.query(GenerateGameDocument, variables, context).toPromise() as any
export const GenerateHardGameDocument = gql`
  query generateHardGame($data: [InputWord!]!) {
    generateHardGame(data: $data) {
      type
      text
      index
      id
      answers {
        isCorrect
        text
      }
      question
    }
  }
`
export const useGenerateHardGame = (
  options: Omit<Urql.UseQueryArgs<GenerateHardGameQueryVariables>, 'query'>,
) =>
  Urql.useQuery<GenerateHardGameQuery, GenerateHardGameQueryVariables>({
    query: GenerateHardGameDocument,
    ...options,
  })
export interface Operations {
  generateHardGame: (
    variables: GenerateHardGameQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<GenerateHardGameQuery, GenerateHardGameQueryVariables>
  >
}
interface OperationsFactory {
  generateHardGame: (
    client: Urql.Client,
  ) => (
    variables: GenerateHardGameQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<GenerateHardGameQuery, GenerateHardGameQueryVariables>
  >
}
operationsFactory.generateHardGame = client => (variables, context) =>
  client.query(GenerateHardGameDocument, variables, context).toPromise() as any
export const GenerateReviewDocument = gql`
  query generateReview(
    $filterTopic: TopicFilter
    $filterWord: WordFilter
    $filterResourceInWord: ResourceInWordFilter
    $filterTranslate: TranslateFilter
    $dataHardGame: [InputWord!]!
  ) {
    topic: searchTopic(filter: $filterTopic) {
      ...TopicF
    }
    word: searchWord(filter: $filterWord) {
      ...WordF
    }
    resourceInWord: searchResourceInWord(filter: $filterResourceInWord) {
      ...ResourceInWordF
    }
    translate: searchTranslate(filter: $filterTranslate) {
      ...TranslateF
    }
    hardGame: generateHardGame(data: $dataHardGame) {
      type
      text
      id
      index
      answers {
        isCorrect
        text
      }
      question
    }
  }
  ${TopicFFragmentDoc}
  ${WordFFragmentDoc}
  ${ResourceInWordFFragmentDoc}
  ${TranslateFFragmentDoc}
`
export const useGenerateReview = (
  options: Omit<Urql.UseQueryArgs<GenerateReviewQueryVariables>, 'query'>,
) =>
  Urql.useQuery<GenerateReviewQuery, GenerateReviewQueryVariables>({
    query: GenerateReviewDocument,
    ...options,
  })
export interface Operations {
  generateReview: (
    variables: GenerateReviewQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<GenerateReviewQuery, GenerateReviewQueryVariables>
  >
}
interface OperationsFactory {
  generateReview: (
    client: Urql.Client,
  ) => (
    variables: GenerateReviewQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<GenerateReviewQuery, GenerateReviewQueryVariables>
  >
}
operationsFactory.generateReview = client => (variables, context) =>
  client.query(GenerateReviewDocument, variables, context).toPromise() as any
export const SearchTestTotalDocument = gql`
  query searchTestTotal(
    $filterQ: QuestionFilter
    $filterW: WordFilter
    $filterP: WordFilter
  ) {
    listen: countQuestion(filter: $filterQ)
    word: countWord(filter: $filterW)
    phrase: countWord(filter: $filterP)
  }
`
export const useSearchTestTotal = (
  options?: Omit<Urql.UseQueryArgs<SearchTestTotalQueryVariables>, 'query'>,
) =>
  Urql.useQuery<SearchTestTotalQuery, SearchTestTotalQueryVariables | void>({
    query: SearchTestTotalDocument,
    ...options,
  })
export interface Operations {
  searchTestTotal: (
    variables?: SearchTestTotalQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<SearchTestTotalQuery, SearchTestTotalQueryVariables>
  >
}
interface OperationsFactory {
  searchTestTotal: (
    client: Urql.Client,
  ) => (
    variables?: SearchTestTotalQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<SearchTestTotalQuery, SearchTestTotalQueryVariables>
  >
}
operationsFactory.searchTestTotal = client => (variables, context) =>
  client.query(SearchTestTotalDocument, variables, context).toPromise() as any
export const CreatePaymentIntentDocument = gql`
  mutation createPaymentIntent($data: InputCreatePaymentIntent) {
    createPaymentIntent(data: $data) {
      clientSecret
      customer
      paymentIntent
    }
  }
`
export const useCreatePaymentIntent = () =>
  Urql.useMutation<
    CreatePaymentIntentMutation,
    CreatePaymentIntentMutationVariables | void
  >(CreatePaymentIntentDocument)
export interface Operations {
  createPaymentIntent: (
    variables?: CreatePaymentIntentMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      CreatePaymentIntentMutation,
      CreatePaymentIntentMutationVariables
    >
  >
}
interface OperationsFactory {
  createPaymentIntent: (
    client: Urql.Client,
  ) => (
    variables?: CreatePaymentIntentMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      CreatePaymentIntentMutation,
      CreatePaymentIntentMutationVariables
    >
  >
}
operationsFactory.createPaymentIntent = client => (variables, context) =>
  client
    .mutation(CreatePaymentIntentDocument, variables, context)
    .toPromise() as any
export const ConfirmPaymentIntentDocument = gql`
  query confirmPaymentIntent($data: InputConfirmPaymentIntent) {
    confirmPaymentIntent(data: $data)
  }
`
export const useConfirmPaymentIntent = (
  options?: Omit<
    Urql.UseQueryArgs<ConfirmPaymentIntentQueryVariables>,
    'query'
  >,
) =>
  Urql.useQuery<
    ConfirmPaymentIntentQuery,
    ConfirmPaymentIntentQueryVariables | void
  >({ query: ConfirmPaymentIntentDocument, ...options })
export interface Operations {
  confirmPaymentIntent: (
    variables?: ConfirmPaymentIntentQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      ConfirmPaymentIntentQuery,
      ConfirmPaymentIntentQueryVariables
    >
  >
}
interface OperationsFactory {
  confirmPaymentIntent: (
    client: Urql.Client,
  ) => (
    variables?: ConfirmPaymentIntentQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      ConfirmPaymentIntentQuery,
      ConfirmPaymentIntentQueryVariables
    >
  >
}
operationsFactory.confirmPaymentIntent = client => (variables, context) =>
  client
    .query(ConfirmPaymentIntentDocument, variables, context)
    .toPromise() as any
export const ListCustomerPaymentMethodDocument = gql`
  query listCustomerPaymentMethod {
    listCustomerPaymentMethod
  }
`
export const useListCustomerPaymentMethod = (
  options?: Omit<
    Urql.UseQueryArgs<ListCustomerPaymentMethodQueryVariables>,
    'query'
  >,
) =>
  Urql.useQuery<
    ListCustomerPaymentMethodQuery,
    ListCustomerPaymentMethodQueryVariables | void
  >({ query: ListCustomerPaymentMethodDocument, ...options })
export interface Operations {
  listCustomerPaymentMethod: (
    variables?: ListCustomerPaymentMethodQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      ListCustomerPaymentMethodQuery,
      ListCustomerPaymentMethodQueryVariables
    >
  >
}
interface OperationsFactory {
  listCustomerPaymentMethod: (
    client: Urql.Client,
  ) => (
    variables?: ListCustomerPaymentMethodQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      ListCustomerPaymentMethodQuery,
      ListCustomerPaymentMethodQueryVariables
    >
  >
}
operationsFactory.listCustomerPaymentMethod = client => (variables, context) =>
  client
    .query(ListCustomerPaymentMethodDocument, variables, context)
    .toPromise() as any
export const CheckUserSubscribedDocument = gql`
  query checkUserSubscribed {
    checkUserSubscribed
  }
`
export const useCheckUserSubscribed = (
  options?: Omit<Urql.UseQueryArgs<CheckUserSubscribedQueryVariables>, 'query'>,
) =>
  Urql.useQuery<
    CheckUserSubscribedQuery,
    CheckUserSubscribedQueryVariables | void
  >({ query: CheckUserSubscribedDocument, ...options })
export interface Operations {
  checkUserSubscribed: (
    variables?: CheckUserSubscribedQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      CheckUserSubscribedQuery,
      CheckUserSubscribedQueryVariables
    >
  >
}
interface OperationsFactory {
  checkUserSubscribed: (
    client: Urql.Client,
  ) => (
    variables?: CheckUserSubscribedQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      CheckUserSubscribedQuery,
      CheckUserSubscribedQueryVariables
    >
  >
}
operationsFactory.checkUserSubscribed = client => (variables, context) =>
  client
    .query(CheckUserSubscribedDocument, variables, context)
    .toPromise() as any
export const MovePositionDocument = gql`
  mutation movePosition($ids: [ID!]!) {
    movePosition(ids: $ids)
  }
`
export const useMovePosition = () =>
  Urql.useMutation<MovePositionMutation, MovePositionMutationVariables>(
    MovePositionDocument,
  )
export interface Operations {
  movePosition: (
    variables: MovePositionMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<MovePositionMutation, MovePositionMutationVariables>
  >
}
interface OperationsFactory {
  movePosition: (
    client: Urql.Client,
  ) => (
    variables: MovePositionMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<MovePositionMutation, MovePositionMutationVariables>
  >
}
operationsFactory.movePosition = client => (variables, context) =>
  client.mutation(MovePositionDocument, variables, context).toPromise() as any
export const CreatePriceDocument = gql`
  mutation createPrice($data: InputCreatePrice!) {
    createPrice(data: $data) {
      ...PriceF
    }
  }
  ${PriceFFragmentDoc}
`
export const useCreatePrice = () =>
  Urql.useMutation<CreatePriceMutation, CreatePriceMutationVariables>(
    CreatePriceDocument,
  )
export interface Operations {
  createPrice: (
    variables: CreatePriceMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<CreatePriceMutation, CreatePriceMutationVariables>
  >
}
interface OperationsFactory {
  createPrice: (
    client: Urql.Client,
  ) => (
    variables: CreatePriceMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<CreatePriceMutation, CreatePriceMutationVariables>
  >
}
operationsFactory.createPrice = client => (variables, context) =>
  client.mutation(CreatePriceDocument, variables, context).toPromise() as any
export const UpdatePriceDocument = gql`
  mutation updatePrice($id: ID!, $data: UpdatePrice!) {
    updatePrice(id: $id, data: $data) {
      ...PriceF
    }
  }
  ${PriceFFragmentDoc}
`
export const useUpdatePrice = () =>
  Urql.useMutation<UpdatePriceMutation, UpdatePriceMutationVariables>(
    UpdatePriceDocument,
  )
export interface Operations {
  updatePrice: (
    variables: UpdatePriceMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<UpdatePriceMutation, UpdatePriceMutationVariables>
  >
}
interface OperationsFactory {
  updatePrice: (
    client: Urql.Client,
  ) => (
    variables: UpdatePriceMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<UpdatePriceMutation, UpdatePriceMutationVariables>
  >
}
operationsFactory.updatePrice = client => (variables, context) =>
  client.mutation(UpdatePriceDocument, variables, context).toPromise() as any
export const SearchPriceDocument = gql`
  query searchPrice(
    $filter: PriceFilter
    $order: [PriceOrderBy!]
    $page: Pagination
  ) {
    searchPrice: searchPrice(filter: $filter, order: $order, page: $page) {
      ...PriceF
    }
  }
  ${PriceFFragmentDoc}
`
export const useSearchPrice = (
  options?: Omit<Urql.UseQueryArgs<SearchPriceQueryVariables>, 'query'>,
) =>
  Urql.useQuery<SearchPriceQuery, SearchPriceQueryVariables | void>({
    query: SearchPriceDocument,
    ...options,
  })
export interface Operations {
  searchPrice: (
    variables?: SearchPriceQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<SearchPriceQuery, SearchPriceQueryVariables>
  >
}
interface OperationsFactory {
  searchPrice: (
    client: Urql.Client,
  ) => (
    variables?: SearchPriceQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<SearchPriceQuery, SearchPriceQueryVariables>
  >
}
operationsFactory.searchPrice = client => (variables, context) =>
  client.query(SearchPriceDocument, variables, context).toPromise() as any
export const CreateProductDocument = gql`
  mutation createProduct($data: InputCreateProduct) {
    createProduct(data: $data) {
      ...ProductF
    }
  }
  ${ProductFFragmentDoc}
`
export const useCreateProduct = () =>
  Urql.useMutation<
    CreateProductMutation,
    CreateProductMutationVariables | void
  >(CreateProductDocument)
export interface Operations {
  createProduct: (
    variables?: CreateProductMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<CreateProductMutation, CreateProductMutationVariables>
  >
}
interface OperationsFactory {
  createProduct: (
    client: Urql.Client,
  ) => (
    variables?: CreateProductMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<CreateProductMutation, CreateProductMutationVariables>
  >
}
operationsFactory.createProduct = client => (variables, context) =>
  client.mutation(CreateProductDocument, variables, context).toPromise() as any
export const UpdateProductDocument = gql`
  mutation updateProduct($id: ID!, $data: UpdateProduct!) {
    updateProduct(id: $id, data: $data) {
      ...ProductF
    }
  }
  ${ProductFFragmentDoc}
`
export const useUpdateProduct = () =>
  Urql.useMutation<UpdateProductMutation, UpdateProductMutationVariables>(
    UpdateProductDocument,
  )
export interface Operations {
  updateProduct: (
    variables: UpdateProductMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<UpdateProductMutation, UpdateProductMutationVariables>
  >
}
interface OperationsFactory {
  updateProduct: (
    client: Urql.Client,
  ) => (
    variables: UpdateProductMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<UpdateProductMutation, UpdateProductMutationVariables>
  >
}
operationsFactory.updateProduct = client => (variables, context) =>
  client.mutation(UpdateProductDocument, variables, context).toPromise() as any
export const DeleteProductDocument = gql`
  mutation deleteProduct($id: ID!) {
    deleteProduct(id: $id)
  }
`
export const useDeleteProduct = () =>
  Urql.useMutation<DeleteProductMutation, DeleteProductMutationVariables>(
    DeleteProductDocument,
  )
export interface Operations {
  deleteProduct: (
    variables: DeleteProductMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<DeleteProductMutation, DeleteProductMutationVariables>
  >
}
interface OperationsFactory {
  deleteProduct: (
    client: Urql.Client,
  ) => (
    variables: DeleteProductMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<DeleteProductMutation, DeleteProductMutationVariables>
  >
}
operationsFactory.deleteProduct = client => (variables, context) =>
  client.mutation(DeleteProductDocument, variables, context).toPromise() as any
export const SearchProductDocument = gql`
  query searchProduct(
    $filter: ProductFilter
    $order: [ProductOrderBy!]
    $page: Pagination
  ) {
    searchProduct: searchProduct(filter: $filter, order: $order, page: $page) {
      ...ProductF
    }
    count: countProduct(filter: $filter)
  }
  ${ProductFFragmentDoc}
`
export const useSearchProduct = (
  options?: Omit<Urql.UseQueryArgs<SearchProductQueryVariables>, 'query'>,
) =>
  Urql.useQuery<SearchProductQuery, SearchProductQueryVariables | void>({
    query: SearchProductDocument,
    ...options,
  })
export interface Operations {
  searchProduct: (
    variables?: SearchProductQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<SearchProductQuery, SearchProductQueryVariables>
  >
}
interface OperationsFactory {
  searchProduct: (
    client: Urql.Client,
  ) => (
    variables?: SearchProductQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<SearchProductQuery, SearchProductQueryVariables>
  >
}
operationsFactory.searchProduct = client => (variables, context) =>
  client.query(SearchProductDocument, variables, context).toPromise() as any
export const SearchQuestionDocument = gql`
  query searchQuestion(
    $filter: QuestionFilter
    $order: [QuestionOrderBy!]
    $page: Pagination
  ) {
    searchQuestion(filter: $filter, order: $order, page: $page) {
      ...QuestionF
    }
  }
  ${QuestionFFragmentDoc}
`
export const useSearchQuestion = (
  options?: Omit<Urql.UseQueryArgs<SearchQuestionQueryVariables>, 'query'>,
) =>
  Urql.useQuery<SearchQuestionQuery, SearchQuestionQueryVariables | void>({
    query: SearchQuestionDocument,
    ...options,
  })
export interface Operations {
  searchQuestion: (
    variables?: SearchQuestionQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<SearchQuestionQuery, SearchQuestionQueryVariables>
  >
}
interface OperationsFactory {
  searchQuestion: (
    client: Urql.Client,
  ) => (
    variables?: SearchQuestionQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<SearchQuestionQuery, SearchQuestionQueryVariables>
  >
}
operationsFactory.searchQuestion = client => (variables, context) =>
  client.query(SearchQuestionDocument, variables, context).toPromise() as any
export const CountQuestionDocument = gql`
  query countQuestion($filter: QuestionFilter) {
    countQuestion(filter: $filter)
  }
`
export const useCountQuestion = (
  options?: Omit<Urql.UseQueryArgs<CountQuestionQueryVariables>, 'query'>,
) =>
  Urql.useQuery<CountQuestionQuery, CountQuestionQueryVariables | void>({
    query: CountQuestionDocument,
    ...options,
  })
export interface Operations {
  countQuestion: (
    variables?: CountQuestionQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<CountQuestionQuery, CountQuestionQueryVariables>
  >
}
interface OperationsFactory {
  countQuestion: (
    client: Urql.Client,
  ) => (
    variables?: CountQuestionQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<CountQuestionQuery, CountQuestionQueryVariables>
  >
}
operationsFactory.countQuestion = client => (variables, context) =>
  client.query(CountQuestionDocument, variables, context).toPromise() as any
export const CreateQuestionDocument = gql`
  mutation createQuestion($data: CreateQuestion!) {
    createQuestion(data: $data) {
      ...QuestionF
    }
  }
  ${QuestionFFragmentDoc}
`
export const useCreateQuestion = () =>
  Urql.useMutation<CreateQuestionMutation, CreateQuestionMutationVariables>(
    CreateQuestionDocument,
  )
export interface Operations {
  createQuestion: (
    variables: CreateQuestionMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      CreateQuestionMutation,
      CreateQuestionMutationVariables
    >
  >
}
interface OperationsFactory {
  createQuestion: (
    client: Urql.Client,
  ) => (
    variables: CreateQuestionMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      CreateQuestionMutation,
      CreateQuestionMutationVariables
    >
  >
}
operationsFactory.createQuestion = client => (variables, context) =>
  client.mutation(CreateQuestionDocument, variables, context).toPromise() as any
export const UpdateQuestionDocument = gql`
  mutation updateQuestion($id: ID!, $data: UpdateQuestion!) {
    updateQuestion(id: $id, data: $data) {
      ...QuestionF
    }
  }
  ${QuestionFFragmentDoc}
`
export const useUpdateQuestion = () =>
  Urql.useMutation<UpdateQuestionMutation, UpdateQuestionMutationVariables>(
    UpdateQuestionDocument,
  )
export interface Operations {
  updateQuestion: (
    variables: UpdateQuestionMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      UpdateQuestionMutation,
      UpdateQuestionMutationVariables
    >
  >
}
interface OperationsFactory {
  updateQuestion: (
    client: Urql.Client,
  ) => (
    variables: UpdateQuestionMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      UpdateQuestionMutation,
      UpdateQuestionMutationVariables
    >
  >
}
operationsFactory.updateQuestion = client => (variables, context) =>
  client.mutation(UpdateQuestionDocument, variables, context).toPromise() as any
export const DeleteQuestionDocument = gql`
  mutation deleteQuestion($id: ID!) {
    deleteQuestion(id: $id)
  }
`
export const useDeleteQuestion = () =>
  Urql.useMutation<DeleteQuestionMutation, DeleteQuestionMutationVariables>(
    DeleteQuestionDocument,
  )
export interface Operations {
  deleteQuestion: (
    variables: DeleteQuestionMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      DeleteQuestionMutation,
      DeleteQuestionMutationVariables
    >
  >
}
interface OperationsFactory {
  deleteQuestion: (
    client: Urql.Client,
  ) => (
    variables: DeleteQuestionMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      DeleteQuestionMutation,
      DeleteQuestionMutationVariables
    >
  >
}
operationsFactory.deleteQuestion = client => (variables, context) =>
  client.mutation(DeleteQuestionDocument, variables, context).toPromise() as any
export const SearchResourceInWordDocument = gql`
  query searchResourceInWord(
    $filter: ResourceInWordFilter
    $order: [ResourceInWordOrderBy!]
    $page: Pagination
  ) {
    searchResourceInWord(filter: $filter, order: $order, page: $page) {
      ...ResourceInWordF
    }
  }
  ${ResourceInWordFFragmentDoc}
`
export const useSearchResourceInWord = (
  options?: Omit<
    Urql.UseQueryArgs<SearchResourceInWordQueryVariables>,
    'query'
  >,
) =>
  Urql.useQuery<
    SearchResourceInWordQuery,
    SearchResourceInWordQueryVariables | void
  >({ query: SearchResourceInWordDocument, ...options })
export interface Operations {
  searchResourceInWord: (
    variables?: SearchResourceInWordQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      SearchResourceInWordQuery,
      SearchResourceInWordQueryVariables
    >
  >
}
interface OperationsFactory {
  searchResourceInWord: (
    client: Urql.Client,
  ) => (
    variables?: SearchResourceInWordQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      SearchResourceInWordQuery,
      SearchResourceInWordQueryVariables
    >
  >
}
operationsFactory.searchResourceInWord = client => (variables, context) =>
  client
    .query(SearchResourceInWordDocument, variables, context)
    .toPromise() as any
export const CountResourceInWordDocument = gql`
  query countResourceInWord($filter: ResourceInWordFilter) {
    countResourceInWord(filter: $filter)
  }
`
export const useCountResourceInWord = (
  options?: Omit<Urql.UseQueryArgs<CountResourceInWordQueryVariables>, 'query'>,
) =>
  Urql.useQuery<
    CountResourceInWordQuery,
    CountResourceInWordQueryVariables | void
  >({ query: CountResourceInWordDocument, ...options })
export interface Operations {
  countResourceInWord: (
    variables?: CountResourceInWordQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      CountResourceInWordQuery,
      CountResourceInWordQueryVariables
    >
  >
}
interface OperationsFactory {
  countResourceInWord: (
    client: Urql.Client,
  ) => (
    variables?: CountResourceInWordQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      CountResourceInWordQuery,
      CountResourceInWordQueryVariables
    >
  >
}
operationsFactory.countResourceInWord = client => (variables, context) =>
  client
    .query(CountResourceInWordDocument, variables, context)
    .toPromise() as any
export const CreateResourceInWordDocument = gql`
  mutation createResourceInWord($data: CreateResourceInWord!) {
    createResourceInWord(data: $data) {
      ...ResourceInWordF
    }
  }
  ${ResourceInWordFFragmentDoc}
`
export const useCreateResourceInWord = () =>
  Urql.useMutation<
    CreateResourceInWordMutation,
    CreateResourceInWordMutationVariables
  >(CreateResourceInWordDocument)
export interface Operations {
  createResourceInWord: (
    variables: CreateResourceInWordMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      CreateResourceInWordMutation,
      CreateResourceInWordMutationVariables
    >
  >
}
interface OperationsFactory {
  createResourceInWord: (
    client: Urql.Client,
  ) => (
    variables: CreateResourceInWordMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      CreateResourceInWordMutation,
      CreateResourceInWordMutationVariables
    >
  >
}
operationsFactory.createResourceInWord = client => (variables, context) =>
  client
    .mutation(CreateResourceInWordDocument, variables, context)
    .toPromise() as any
export const DeleteResourceInWordDocument = gql`
  mutation deleteResourceInWord($id: ID!) {
    deleteResourceInWord(id: $id)
  }
`
export const useDeleteResourceInWord = () =>
  Urql.useMutation<
    DeleteResourceInWordMutation,
    DeleteResourceInWordMutationVariables
  >(DeleteResourceInWordDocument)
export interface Operations {
  deleteResourceInWord: (
    variables: DeleteResourceInWordMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      DeleteResourceInWordMutation,
      DeleteResourceInWordMutationVariables
    >
  >
}
interface OperationsFactory {
  deleteResourceInWord: (
    client: Urql.Client,
  ) => (
    variables: DeleteResourceInWordMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      DeleteResourceInWordMutation,
      DeleteResourceInWordMutationVariables
    >
  >
}
operationsFactory.deleteResourceInWord = client => (variables, context) =>
  client
    .mutation(DeleteResourceInWordDocument, variables, context)
    .toPromise() as any
export const ServerTimeDocument = gql`
  query serverTime {
    serverTime
  }
`
export const useServerTime = (
  options?: Omit<Urql.UseQueryArgs<ServerTimeQueryVariables>, 'query'>,
) =>
  Urql.useQuery<ServerTimeQuery, ServerTimeQueryVariables | void>({
    query: ServerTimeDocument,
    ...options,
  })
export interface Operations {
  serverTime: (
    variables?: ServerTimeQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<Urql.OperationResult<ServerTimeQuery, ServerTimeQueryVariables>>
}
interface OperationsFactory {
  serverTime: (
    client: Urql.Client,
  ) => (
    variables?: ServerTimeQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<Urql.OperationResult<ServerTimeQuery, ServerTimeQueryVariables>>
}
operationsFactory.serverTime = client => (variables, context) =>
  client.query(ServerTimeDocument, variables, context).toPromise() as any
export const SearchSplashDocument = gql`
  query searchSplash(
    $filter: SplashFilter
    $order: [SplashOrderBy!]
    $page: Pagination
  ) {
    searchSplash(filter: $filter, order: $order, page: $page) {
      ...SplashF
    }
  }
  ${SplashFFragmentDoc}
`
export const useSearchSplash = (
  options?: Omit<Urql.UseQueryArgs<SearchSplashQueryVariables>, 'query'>,
) =>
  Urql.useQuery<SearchSplashQuery, SearchSplashQueryVariables | void>({
    query: SearchSplashDocument,
    ...options,
  })
export interface Operations {
  searchSplash: (
    variables?: SearchSplashQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<SearchSplashQuery, SearchSplashQueryVariables>
  >
}
interface OperationsFactory {
  searchSplash: (
    client: Urql.Client,
  ) => (
    variables?: SearchSplashQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<SearchSplashQuery, SearchSplashQueryVariables>
  >
}
operationsFactory.searchSplash = client => (variables, context) =>
  client.query(SearchSplashDocument, variables, context).toPromise() as any
export const CountSplashDocument = gql`
  query countSplash($filter: SplashFilter) {
    countSplash(filter: $filter)
  }
`
export const useCountSplash = (
  options?: Omit<Urql.UseQueryArgs<CountSplashQueryVariables>, 'query'>,
) =>
  Urql.useQuery<CountSplashQuery, CountSplashQueryVariables | void>({
    query: CountSplashDocument,
    ...options,
  })
export interface Operations {
  countSplash: (
    variables?: CountSplashQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<CountSplashQuery, CountSplashQueryVariables>
  >
}
interface OperationsFactory {
  countSplash: (
    client: Urql.Client,
  ) => (
    variables?: CountSplashQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<CountSplashQuery, CountSplashQueryVariables>
  >
}
operationsFactory.countSplash = client => (variables, context) =>
  client.query(CountSplashDocument, variables, context).toPromise() as any
export const CreateSplashDocument = gql`
  mutation createSplash($data: CreateSplash!) {
    createSplash(data: $data) {
      ...SplashF
    }
  }
  ${SplashFFragmentDoc}
`
export const useCreateSplash = () =>
  Urql.useMutation<CreateSplashMutation, CreateSplashMutationVariables>(
    CreateSplashDocument,
  )
export interface Operations {
  createSplash: (
    variables: CreateSplashMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<CreateSplashMutation, CreateSplashMutationVariables>
  >
}
interface OperationsFactory {
  createSplash: (
    client: Urql.Client,
  ) => (
    variables: CreateSplashMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<CreateSplashMutation, CreateSplashMutationVariables>
  >
}
operationsFactory.createSplash = client => (variables, context) =>
  client.mutation(CreateSplashDocument, variables, context).toPromise() as any
export const UpdateSplashDocument = gql`
  mutation updateSplash($id: ID!, $data: UpdateSplash!) {
    updateSplash(id: $id, data: $data) {
      ...SplashF
    }
  }
  ${SplashFFragmentDoc}
`
export const useUpdateSplash = () =>
  Urql.useMutation<UpdateSplashMutation, UpdateSplashMutationVariables>(
    UpdateSplashDocument,
  )
export interface Operations {
  updateSplash: (
    variables: UpdateSplashMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<UpdateSplashMutation, UpdateSplashMutationVariables>
  >
}
interface OperationsFactory {
  updateSplash: (
    client: Urql.Client,
  ) => (
    variables: UpdateSplashMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<UpdateSplashMutation, UpdateSplashMutationVariables>
  >
}
operationsFactory.updateSplash = client => (variables, context) =>
  client.mutation(UpdateSplashDocument, variables, context).toPromise() as any
export const DeleteSplashDocument = gql`
  mutation deleteSplash($id: ID!) {
    deleteSplash(id: $id)
  }
`
export const useDeleteSplash = () =>
  Urql.useMutation<DeleteSplashMutation, DeleteSplashMutationVariables>(
    DeleteSplashDocument,
  )
export interface Operations {
  deleteSplash: (
    variables: DeleteSplashMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<DeleteSplashMutation, DeleteSplashMutationVariables>
  >
}
interface OperationsFactory {
  deleteSplash: (
    client: Urql.Client,
  ) => (
    variables: DeleteSplashMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<DeleteSplashMutation, DeleteSplashMutationVariables>
  >
}
operationsFactory.deleteSplash = client => (variables, context) =>
  client.mutation(DeleteSplashDocument, variables, context).toPromise() as any
export const SearchStreakDocument = gql`
  query searchStreak(
    $filter: StreakFilter
    $order: [StreakOrderBy!]
    $page: Pagination
  ) {
    searchStreak(filter: $filter, order: $order, page: $page) {
      ...StreakF
    }
  }
  ${StreakFFragmentDoc}
`
export const useSearchStreak = (
  options?: Omit<Urql.UseQueryArgs<SearchStreakQueryVariables>, 'query'>,
) =>
  Urql.useQuery<SearchStreakQuery, SearchStreakQueryVariables | void>({
    query: SearchStreakDocument,
    ...options,
  })
export interface Operations {
  searchStreak: (
    variables?: SearchStreakQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<SearchStreakQuery, SearchStreakQueryVariables>
  >
}
interface OperationsFactory {
  searchStreak: (
    client: Urql.Client,
  ) => (
    variables?: SearchStreakQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<SearchStreakQuery, SearchStreakQueryVariables>
  >
}
operationsFactory.searchStreak = client => (variables, context) =>
  client.query(SearchStreakDocument, variables, context).toPromise() as any
export const CountStreakDocument = gql`
  query countStreak($filter: StreakFilter) {
    countStreak(filter: $filter)
  }
`
export const useCountStreak = (
  options?: Omit<Urql.UseQueryArgs<CountStreakQueryVariables>, 'query'>,
) =>
  Urql.useQuery<CountStreakQuery, CountStreakQueryVariables | void>({
    query: CountStreakDocument,
    ...options,
  })
export interface Operations {
  countStreak: (
    variables?: CountStreakQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<CountStreakQuery, CountStreakQueryVariables>
  >
}
interface OperationsFactory {
  countStreak: (
    client: Urql.Client,
  ) => (
    variables?: CountStreakQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<CountStreakQuery, CountStreakQueryVariables>
  >
}
operationsFactory.countStreak = client => (variables, context) =>
  client.query(CountStreakDocument, variables, context).toPromise() as any
export const SearchStreaksDocument = gql`
  query searchStreaks(
    $filter: StreakFilter
    $order: [StreakOrderBy!]
    $page: Pagination
  ) {
    searchStreaks: searchStreak(filter: $filter, order: $order, page: $page) {
      ...StreakF
    }
    count: countStreak(filter: $filter)
  }
  ${StreakFFragmentDoc}
`
export const useSearchStreaks = (
  options?: Omit<Urql.UseQueryArgs<SearchStreaksQueryVariables>, 'query'>,
) =>
  Urql.useQuery<SearchStreaksQuery, SearchStreaksQueryVariables | void>({
    query: SearchStreaksDocument,
    ...options,
  })
export interface Operations {
  searchStreaks: (
    variables?: SearchStreaksQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<SearchStreaksQuery, SearchStreaksQueryVariables>
  >
}
interface OperationsFactory {
  searchStreaks: (
    client: Urql.Client,
  ) => (
    variables?: SearchStreaksQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<SearchStreaksQuery, SearchStreaksQueryVariables>
  >
}
operationsFactory.searchStreaks = client => (variables, context) =>
  client.query(SearchStreaksDocument, variables, context).toPromise() as any
export const CreateStreakDocument = gql`
  mutation createStreak($data: CreateStreak!) {
    createStreak(data: $data) {
      ...StreakF
    }
  }
  ${StreakFFragmentDoc}
`
export const useCreateStreak = () =>
  Urql.useMutation<CreateStreakMutation, CreateStreakMutationVariables>(
    CreateStreakDocument,
  )
export interface Operations {
  createStreak: (
    variables: CreateStreakMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<CreateStreakMutation, CreateStreakMutationVariables>
  >
}
interface OperationsFactory {
  createStreak: (
    client: Urql.Client,
  ) => (
    variables: CreateStreakMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<CreateStreakMutation, CreateStreakMutationVariables>
  >
}
operationsFactory.createStreak = client => (variables, context) =>
  client.mutation(CreateStreakDocument, variables, context).toPromise() as any
export const UpdateStreakDocument = gql`
  mutation updateStreak($id: ID!, $data: UpdateStreak!) {
    updateStreak(id: $id, data: $data) {
      ...StreakF
    }
  }
  ${StreakFFragmentDoc}
`
export const useUpdateStreak = () =>
  Urql.useMutation<UpdateStreakMutation, UpdateStreakMutationVariables>(
    UpdateStreakDocument,
  )
export interface Operations {
  updateStreak: (
    variables: UpdateStreakMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<UpdateStreakMutation, UpdateStreakMutationVariables>
  >
}
interface OperationsFactory {
  updateStreak: (
    client: Urql.Client,
  ) => (
    variables: UpdateStreakMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<UpdateStreakMutation, UpdateStreakMutationVariables>
  >
}
operationsFactory.updateStreak = client => (variables, context) =>
  client.mutation(UpdateStreakDocument, variables, context).toPromise() as any
export const DeleteStreakDocument = gql`
  mutation deleteStreak($id: ID!) {
    deleteStreak(id: $id)
  }
`
export const useDeleteStreak = () =>
  Urql.useMutation<DeleteStreakMutation, DeleteStreakMutationVariables>(
    DeleteStreakDocument,
  )
export interface Operations {
  deleteStreak: (
    variables: DeleteStreakMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<DeleteStreakMutation, DeleteStreakMutationVariables>
  >
}
interface OperationsFactory {
  deleteStreak: (
    client: Urql.Client,
  ) => (
    variables: DeleteStreakMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<DeleteStreakMutation, DeleteStreakMutationVariables>
  >
}
operationsFactory.deleteStreak = client => (variables, context) =>
  client.mutation(DeleteStreakDocument, variables, context).toPromise() as any
export const SearchStreakDailyDocument = gql`
  query searchStreakDaily(
    $filter: StreakDailyFilter
    $order: [StreakDailyOrderBy!]
    $page: Pagination
  ) {
    searchStreakDaily(filter: $filter, order: $order, page: $page) {
      ...StreakDailyF
    }
  }
  ${StreakDailyFFragmentDoc}
`
export const useSearchStreakDaily = (
  options?: Omit<Urql.UseQueryArgs<SearchStreakDailyQueryVariables>, 'query'>,
) =>
  Urql.useQuery<SearchStreakDailyQuery, SearchStreakDailyQueryVariables | void>(
    { query: SearchStreakDailyDocument, ...options },
  )
export interface Operations {
  searchStreakDaily: (
    variables?: SearchStreakDailyQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      SearchStreakDailyQuery,
      SearchStreakDailyQueryVariables
    >
  >
}
interface OperationsFactory {
  searchStreakDaily: (
    client: Urql.Client,
  ) => (
    variables?: SearchStreakDailyQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      SearchStreakDailyQuery,
      SearchStreakDailyQueryVariables
    >
  >
}
operationsFactory.searchStreakDaily = client => (variables, context) =>
  client.query(SearchStreakDailyDocument, variables, context).toPromise() as any
export const CountStreakDailyDocument = gql`
  query countStreakDaily($filter: StreakDailyFilter) {
    countStreakDaily(filter: $filter)
  }
`
export const useCountStreakDaily = (
  options?: Omit<Urql.UseQueryArgs<CountStreakDailyQueryVariables>, 'query'>,
) =>
  Urql.useQuery<CountStreakDailyQuery, CountStreakDailyQueryVariables | void>({
    query: CountStreakDailyDocument,
    ...options,
  })
export interface Operations {
  countStreakDaily: (
    variables?: CountStreakDailyQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<CountStreakDailyQuery, CountStreakDailyQueryVariables>
  >
}
interface OperationsFactory {
  countStreakDaily: (
    client: Urql.Client,
  ) => (
    variables?: CountStreakDailyQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<CountStreakDailyQuery, CountStreakDailyQueryVariables>
  >
}
operationsFactory.countStreakDaily = client => (variables, context) =>
  client.query(CountStreakDailyDocument, variables, context).toPromise() as any
export const SearchStreakDailysDocument = gql`
  query searchStreakDailys(
    $filter: StreakDailyFilter
    $order: [StreakDailyOrderBy!]
    $page: Pagination
  ) {
    searchStreakDailys: searchStreakDaily(
      filter: $filter
      order: $order
      page: $page
    ) {
      ...StreakDailyF
    }
    count: countStreakDaily(filter: $filter)
  }
  ${StreakDailyFFragmentDoc}
`
export const useSearchStreakDailys = (
  options?: Omit<Urql.UseQueryArgs<SearchStreakDailysQueryVariables>, 'query'>,
) =>
  Urql.useQuery<
    SearchStreakDailysQuery,
    SearchStreakDailysQueryVariables | void
  >({ query: SearchStreakDailysDocument, ...options })
export interface Operations {
  searchStreakDailys: (
    variables?: SearchStreakDailysQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      SearchStreakDailysQuery,
      SearchStreakDailysQueryVariables
    >
  >
}
interface OperationsFactory {
  searchStreakDailys: (
    client: Urql.Client,
  ) => (
    variables?: SearchStreakDailysQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      SearchStreakDailysQuery,
      SearchStreakDailysQueryVariables
    >
  >
}
operationsFactory.searchStreakDailys = client => (variables, context) =>
  client
    .query(SearchStreakDailysDocument, variables, context)
    .toPromise() as any
export const CreateStreakDailyDocument = gql`
  mutation createStreakDaily($data: CreateStreakDaily!) {
    createStreakDaily(data: $data) {
      ...StreakDailyF
    }
  }
  ${StreakDailyFFragmentDoc}
`
export const useCreateStreakDaily = () =>
  Urql.useMutation<
    CreateStreakDailyMutation,
    CreateStreakDailyMutationVariables
  >(CreateStreakDailyDocument)
export interface Operations {
  createStreakDaily: (
    variables: CreateStreakDailyMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      CreateStreakDailyMutation,
      CreateStreakDailyMutationVariables
    >
  >
}
interface OperationsFactory {
  createStreakDaily: (
    client: Urql.Client,
  ) => (
    variables: CreateStreakDailyMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      CreateStreakDailyMutation,
      CreateStreakDailyMutationVariables
    >
  >
}
operationsFactory.createStreakDaily = client => (variables, context) =>
  client
    .mutation(CreateStreakDailyDocument, variables, context)
    .toPromise() as any
export const UpdateStreakDailyDocument = gql`
  mutation updateStreakDaily($id: ID!, $data: UpdateStreakDaily!) {
    updateStreakDaily(id: $id, data: $data) {
      ...StreakDailyF
    }
  }
  ${StreakDailyFFragmentDoc}
`
export const useUpdateStreakDaily = () =>
  Urql.useMutation<
    UpdateStreakDailyMutation,
    UpdateStreakDailyMutationVariables
  >(UpdateStreakDailyDocument)
export interface Operations {
  updateStreakDaily: (
    variables: UpdateStreakDailyMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      UpdateStreakDailyMutation,
      UpdateStreakDailyMutationVariables
    >
  >
}
interface OperationsFactory {
  updateStreakDaily: (
    client: Urql.Client,
  ) => (
    variables: UpdateStreakDailyMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      UpdateStreakDailyMutation,
      UpdateStreakDailyMutationVariables
    >
  >
}
operationsFactory.updateStreakDaily = client => (variables, context) =>
  client
    .mutation(UpdateStreakDailyDocument, variables, context)
    .toPromise() as any
export const DeleteStreakDailyDocument = gql`
  mutation deleteStreakDaily($id: ID!) {
    deleteStreakDaily(id: $id)
  }
`
export const useDeleteStreakDaily = () =>
  Urql.useMutation<
    DeleteStreakDailyMutation,
    DeleteStreakDailyMutationVariables
  >(DeleteStreakDailyDocument)
export interface Operations {
  deleteStreakDaily: (
    variables: DeleteStreakDailyMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      DeleteStreakDailyMutation,
      DeleteStreakDailyMutationVariables
    >
  >
}
interface OperationsFactory {
  deleteStreakDaily: (
    client: Urql.Client,
  ) => (
    variables: DeleteStreakDailyMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      DeleteStreakDailyMutation,
      DeleteStreakDailyMutationVariables
    >
  >
}
operationsFactory.deleteStreakDaily = client => (variables, context) =>
  client
    .mutation(DeleteStreakDailyDocument, variables, context)
    .toPromise() as any
export const SearchStreakInUserDocument = gql`
  query searchStreakInUser(
    $filter: StreakInUserFilter
    $order: [StreakInUserOrderBy!]
    $page: Pagination
  ) {
    searchStreakInUser(filter: $filter, order: $order, page: $page) {
      ...StreakInUserF
    }
  }
  ${StreakInUserFFragmentDoc}
`
export const useSearchStreakInUser = (
  options?: Omit<Urql.UseQueryArgs<SearchStreakInUserQueryVariables>, 'query'>,
) =>
  Urql.useQuery<
    SearchStreakInUserQuery,
    SearchStreakInUserQueryVariables | void
  >({ query: SearchStreakInUserDocument, ...options })
export interface Operations {
  searchStreakInUser: (
    variables?: SearchStreakInUserQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      SearchStreakInUserQuery,
      SearchStreakInUserQueryVariables
    >
  >
}
interface OperationsFactory {
  searchStreakInUser: (
    client: Urql.Client,
  ) => (
    variables?: SearchStreakInUserQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      SearchStreakInUserQuery,
      SearchStreakInUserQueryVariables
    >
  >
}
operationsFactory.searchStreakInUser = client => (variables, context) =>
  client
    .query(SearchStreakInUserDocument, variables, context)
    .toPromise() as any
export const CountStreakInUserDocument = gql`
  query countStreakInUser($filter: StreakInUserFilter) {
    countStreakInUser(filter: $filter)
  }
`
export const useCountStreakInUser = (
  options?: Omit<Urql.UseQueryArgs<CountStreakInUserQueryVariables>, 'query'>,
) =>
  Urql.useQuery<CountStreakInUserQuery, CountStreakInUserQueryVariables | void>(
    { query: CountStreakInUserDocument, ...options },
  )
export interface Operations {
  countStreakInUser: (
    variables?: CountStreakInUserQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      CountStreakInUserQuery,
      CountStreakInUserQueryVariables
    >
  >
}
interface OperationsFactory {
  countStreakInUser: (
    client: Urql.Client,
  ) => (
    variables?: CountStreakInUserQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      CountStreakInUserQuery,
      CountStreakInUserQueryVariables
    >
  >
}
operationsFactory.countStreakInUser = client => (variables, context) =>
  client.query(CountStreakInUserDocument, variables, context).toPromise() as any
export const SearchStreakInUsersDocument = gql`
  query searchStreakInUsers(
    $filter: StreakInUserFilter
    $order: [StreakInUserOrderBy!]
    $page: Pagination
  ) {
    searchStreakInUsers: searchStreakInUser(
      filter: $filter
      order: $order
      page: $page
    ) {
      ...StreakInUserF
    }
    count: countStreakInUser(filter: $filter)
  }
  ${StreakInUserFFragmentDoc}
`
export const useSearchStreakInUsers = (
  options?: Omit<Urql.UseQueryArgs<SearchStreakInUsersQueryVariables>, 'query'>,
) =>
  Urql.useQuery<
    SearchStreakInUsersQuery,
    SearchStreakInUsersQueryVariables | void
  >({ query: SearchStreakInUsersDocument, ...options })
export interface Operations {
  searchStreakInUsers: (
    variables?: SearchStreakInUsersQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      SearchStreakInUsersQuery,
      SearchStreakInUsersQueryVariables
    >
  >
}
interface OperationsFactory {
  searchStreakInUsers: (
    client: Urql.Client,
  ) => (
    variables?: SearchStreakInUsersQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      SearchStreakInUsersQuery,
      SearchStreakInUsersQueryVariables
    >
  >
}
operationsFactory.searchStreakInUsers = client => (variables, context) =>
  client
    .query(SearchStreakInUsersDocument, variables, context)
    .toPromise() as any
export const CreateStreakInUserDocument = gql`
  mutation createStreakInUser($data: CreateStreakInUser!) {
    createStreakInUser(data: $data) {
      ...StreakInUserF
    }
  }
  ${StreakInUserFFragmentDoc}
`
export const useCreateStreakInUser = () =>
  Urql.useMutation<
    CreateStreakInUserMutation,
    CreateStreakInUserMutationVariables
  >(CreateStreakInUserDocument)
export interface Operations {
  createStreakInUser: (
    variables: CreateStreakInUserMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      CreateStreakInUserMutation,
      CreateStreakInUserMutationVariables
    >
  >
}
interface OperationsFactory {
  createStreakInUser: (
    client: Urql.Client,
  ) => (
    variables: CreateStreakInUserMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      CreateStreakInUserMutation,
      CreateStreakInUserMutationVariables
    >
  >
}
operationsFactory.createStreakInUser = client => (variables, context) =>
  client
    .mutation(CreateStreakInUserDocument, variables, context)
    .toPromise() as any
export const UpdateStreakInUserDocument = gql`
  mutation updateStreakInUser($id: ID!, $data: UpdateStreakInUser!) {
    updateStreakInUser(id: $id, data: $data) {
      ...StreakInUserF
    }
  }
  ${StreakInUserFFragmentDoc}
`
export const useUpdateStreakInUser = () =>
  Urql.useMutation<
    UpdateStreakInUserMutation,
    UpdateStreakInUserMutationVariables
  >(UpdateStreakInUserDocument)
export interface Operations {
  updateStreakInUser: (
    variables: UpdateStreakInUserMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      UpdateStreakInUserMutation,
      UpdateStreakInUserMutationVariables
    >
  >
}
interface OperationsFactory {
  updateStreakInUser: (
    client: Urql.Client,
  ) => (
    variables: UpdateStreakInUserMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      UpdateStreakInUserMutation,
      UpdateStreakInUserMutationVariables
    >
  >
}
operationsFactory.updateStreakInUser = client => (variables, context) =>
  client
    .mutation(UpdateStreakInUserDocument, variables, context)
    .toPromise() as any
export const DeleteStreakInUserDocument = gql`
  mutation deleteStreakInUser($id: ID!) {
    deleteStreakInUser(id: $id)
  }
`
export const useDeleteStreakInUser = () =>
  Urql.useMutation<
    DeleteStreakInUserMutation,
    DeleteStreakInUserMutationVariables
  >(DeleteStreakInUserDocument)
export interface Operations {
  deleteStreakInUser: (
    variables: DeleteStreakInUserMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      DeleteStreakInUserMutation,
      DeleteStreakInUserMutationVariables
    >
  >
}
interface OperationsFactory {
  deleteStreakInUser: (
    client: Urql.Client,
  ) => (
    variables: DeleteStreakInUserMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      DeleteStreakInUserMutation,
      DeleteStreakInUserMutationVariables
    >
  >
}
operationsFactory.deleteStreakInUser = client => (variables, context) =>
  client
    .mutation(DeleteStreakInUserDocument, variables, context)
    .toPromise() as any
export const SearchTopicDocument = gql`
  query searchTopic(
    $filter: TopicFilter
    $order: [TopicOrderBy!]
    $page: Pagination
  ) {
    searchTopic(filter: $filter, order: $order, page: $page) {
      ...TopicF
    }
  }
  ${TopicFFragmentDoc}
`
export const useSearchTopic = (
  options?: Omit<Urql.UseQueryArgs<SearchTopicQueryVariables>, 'query'>,
) =>
  Urql.useQuery<SearchTopicQuery, SearchTopicQueryVariables | void>({
    query: SearchTopicDocument,
    ...options,
  })
export interface Operations {
  searchTopic: (
    variables?: SearchTopicQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<SearchTopicQuery, SearchTopicQueryVariables>
  >
}
interface OperationsFactory {
  searchTopic: (
    client: Urql.Client,
  ) => (
    variables?: SearchTopicQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<SearchTopicQuery, SearchTopicQueryVariables>
  >
}
operationsFactory.searchTopic = client => (variables, context) =>
  client.query(SearchTopicDocument, variables, context).toPromise() as any
export const CountTopicDocument = gql`
  query countTopic($filter: TopicFilter) {
    countTopic(filter: $filter)
  }
`
export const useCountTopic = (
  options?: Omit<Urql.UseQueryArgs<CountTopicQueryVariables>, 'query'>,
) =>
  Urql.useQuery<CountTopicQuery, CountTopicQueryVariables | void>({
    query: CountTopicDocument,
    ...options,
  })
export interface Operations {
  countTopic: (
    variables?: CountTopicQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<Urql.OperationResult<CountTopicQuery, CountTopicQueryVariables>>
}
interface OperationsFactory {
  countTopic: (
    client: Urql.Client,
  ) => (
    variables?: CountTopicQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<Urql.OperationResult<CountTopicQuery, CountTopicQueryVariables>>
}
operationsFactory.countTopic = client => (variables, context) =>
  client.query(CountTopicDocument, variables, context).toPromise() as any
export const SearchTopicsDocument = gql`
  query searchTopics(
    $filter: TopicFilter
    $order: [TopicOrderBy!]
    $page: Pagination
  ) {
    searchTopics: searchTopic(filter: $filter, order: $order, page: $page) {
      ...TopicF
    }
    count: countTopic(filter: $filter)
  }
  ${TopicFFragmentDoc}
`
export const useSearchTopics = (
  options?: Omit<Urql.UseQueryArgs<SearchTopicsQueryVariables>, 'query'>,
) =>
  Urql.useQuery<SearchTopicsQuery, SearchTopicsQueryVariables | void>({
    query: SearchTopicsDocument,
    ...options,
  })
export interface Operations {
  searchTopics: (
    variables?: SearchTopicsQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<SearchTopicsQuery, SearchTopicsQueryVariables>
  >
}
interface OperationsFactory {
  searchTopics: (
    client: Urql.Client,
  ) => (
    variables?: SearchTopicsQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<SearchTopicsQuery, SearchTopicsQueryVariables>
  >
}
operationsFactory.searchTopics = client => (variables, context) =>
  client.query(SearchTopicsDocument, variables, context).toPromise() as any
export const DetailTopicDocument = gql`
  query detailTopic($id: NeString!) {
    detailTopic(id: $id) {
      ...TopicF
    }
  }
  ${TopicFFragmentDoc}
`
export const useDetailTopic = (
  options: Omit<Urql.UseQueryArgs<DetailTopicQueryVariables>, 'query'>,
) =>
  Urql.useQuery<DetailTopicQuery, DetailTopicQueryVariables>({
    query: DetailTopicDocument,
    ...options,
  })
export interface Operations {
  detailTopic: (
    variables: DetailTopicQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<DetailTopicQuery, DetailTopicQueryVariables>
  >
}
interface OperationsFactory {
  detailTopic: (
    client: Urql.Client,
  ) => (
    variables: DetailTopicQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<DetailTopicQuery, DetailTopicQueryVariables>
  >
}
operationsFactory.detailTopic = client => (variables, context) =>
  client.query(DetailTopicDocument, variables, context).toPromise() as any
export const CreateTopicDocument = gql`
  mutation createTopic($data: CreateTopic!) {
    createTopic(data: $data) {
      ...TopicF
    }
  }
  ${TopicFFragmentDoc}
`
export const useCreateTopic = () =>
  Urql.useMutation<CreateTopicMutation, CreateTopicMutationVariables>(
    CreateTopicDocument,
  )
export interface Operations {
  createTopic: (
    variables: CreateTopicMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<CreateTopicMutation, CreateTopicMutationVariables>
  >
}
interface OperationsFactory {
  createTopic: (
    client: Urql.Client,
  ) => (
    variables: CreateTopicMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<CreateTopicMutation, CreateTopicMutationVariables>
  >
}
operationsFactory.createTopic = client => (variables, context) =>
  client.mutation(CreateTopicDocument, variables, context).toPromise() as any
export const UpdateTopicDocument = gql`
  mutation updateTopic($id: ID!, $data: UpdateTopic!) {
    updateTopic(id: $id, data: $data) {
      ...TopicF
    }
  }
  ${TopicFFragmentDoc}
`
export const useUpdateTopic = () =>
  Urql.useMutation<UpdateTopicMutation, UpdateTopicMutationVariables>(
    UpdateTopicDocument,
  )
export interface Operations {
  updateTopic: (
    variables: UpdateTopicMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<UpdateTopicMutation, UpdateTopicMutationVariables>
  >
}
interface OperationsFactory {
  updateTopic: (
    client: Urql.Client,
  ) => (
    variables: UpdateTopicMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<UpdateTopicMutation, UpdateTopicMutationVariables>
  >
}
operationsFactory.updateTopic = client => (variables, context) =>
  client.mutation(UpdateTopicDocument, variables, context).toPromise() as any
export const DeleteTopicDocument = gql`
  mutation deleteTopic($ids: [ID!]!) {
    deleteTopic(ids: $ids)
  }
`
export const useDeleteTopic = () =>
  Urql.useMutation<DeleteTopicMutation, DeleteTopicMutationVariables>(
    DeleteTopicDocument,
  )
export interface Operations {
  deleteTopic: (
    variables: DeleteTopicMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<DeleteTopicMutation, DeleteTopicMutationVariables>
  >
}
interface OperationsFactory {
  deleteTopic: (
    client: Urql.Client,
  ) => (
    variables: DeleteTopicMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<DeleteTopicMutation, DeleteTopicMutationVariables>
  >
}
operationsFactory.deleteTopic = client => (variables, context) =>
  client.mutation(DeleteTopicDocument, variables, context).toPromise() as any
export const SearchTopicInCategoryDocument = gql`
  query searchTopicInCategory(
    $filter: TopicInCategoryFilter
    $order: [TopicInCategoryOrderBy!]
    $page: Pagination
  ) {
    searchTopicInCategory(filter: $filter, order: $order, page: $page) {
      ...TopicInCategoryF
    }
  }
  ${TopicInCategoryFFragmentDoc}
`
export const useSearchTopicInCategory = (
  options?: Omit<
    Urql.UseQueryArgs<SearchTopicInCategoryQueryVariables>,
    'query'
  >,
) =>
  Urql.useQuery<
    SearchTopicInCategoryQuery,
    SearchTopicInCategoryQueryVariables | void
  >({ query: SearchTopicInCategoryDocument, ...options })
export interface Operations {
  searchTopicInCategory: (
    variables?: SearchTopicInCategoryQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      SearchTopicInCategoryQuery,
      SearchTopicInCategoryQueryVariables
    >
  >
}
interface OperationsFactory {
  searchTopicInCategory: (
    client: Urql.Client,
  ) => (
    variables?: SearchTopicInCategoryQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      SearchTopicInCategoryQuery,
      SearchTopicInCategoryQueryVariables
    >
  >
}
operationsFactory.searchTopicInCategory = client => (variables, context) =>
  client
    .query(SearchTopicInCategoryDocument, variables, context)
    .toPromise() as any
export const CountTopicInCategoryDocument = gql`
  query countTopicInCategory($filter: TopicInCategoryFilter) {
    countTopicInCategory(filter: $filter)
  }
`
export const useCountTopicInCategory = (
  options?: Omit<
    Urql.UseQueryArgs<CountTopicInCategoryQueryVariables>,
    'query'
  >,
) =>
  Urql.useQuery<
    CountTopicInCategoryQuery,
    CountTopicInCategoryQueryVariables | void
  >({ query: CountTopicInCategoryDocument, ...options })
export interface Operations {
  countTopicInCategory: (
    variables?: CountTopicInCategoryQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      CountTopicInCategoryQuery,
      CountTopicInCategoryQueryVariables
    >
  >
}
interface OperationsFactory {
  countTopicInCategory: (
    client: Urql.Client,
  ) => (
    variables?: CountTopicInCategoryQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      CountTopicInCategoryQuery,
      CountTopicInCategoryQueryVariables
    >
  >
}
operationsFactory.countTopicInCategory = client => (variables, context) =>
  client
    .query(CountTopicInCategoryDocument, variables, context)
    .toPromise() as any
export const CreateTopicInCategoryDocument = gql`
  mutation createTopicInCategory($data: CreateTopicInCategory!) {
    createTopicInCategory(data: $data) {
      ...TopicInCategoryF
    }
  }
  ${TopicInCategoryFFragmentDoc}
`
export const useCreateTopicInCategory = () =>
  Urql.useMutation<
    CreateTopicInCategoryMutation,
    CreateTopicInCategoryMutationVariables
  >(CreateTopicInCategoryDocument)
export interface Operations {
  createTopicInCategory: (
    variables: CreateTopicInCategoryMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      CreateTopicInCategoryMutation,
      CreateTopicInCategoryMutationVariables
    >
  >
}
interface OperationsFactory {
  createTopicInCategory: (
    client: Urql.Client,
  ) => (
    variables: CreateTopicInCategoryMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      CreateTopicInCategoryMutation,
      CreateTopicInCategoryMutationVariables
    >
  >
}
operationsFactory.createTopicInCategory = client => (variables, context) =>
  client
    .mutation(CreateTopicInCategoryDocument, variables, context)
    .toPromise() as any
export const UpdateTopicInCategoryDocument = gql`
  mutation updateTopicInCategory($id: ID!, $data: UpdateTopicInCategory!) {
    updateTopicInCategory(id: $id, data: $data) {
      ...TopicInCategoryF
    }
  }
  ${TopicInCategoryFFragmentDoc}
`
export const useUpdateTopicInCategory = () =>
  Urql.useMutation<
    UpdateTopicInCategoryMutation,
    UpdateTopicInCategoryMutationVariables
  >(UpdateTopicInCategoryDocument)
export interface Operations {
  updateTopicInCategory: (
    variables: UpdateTopicInCategoryMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      UpdateTopicInCategoryMutation,
      UpdateTopicInCategoryMutationVariables
    >
  >
}
interface OperationsFactory {
  updateTopicInCategory: (
    client: Urql.Client,
  ) => (
    variables: UpdateTopicInCategoryMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      UpdateTopicInCategoryMutation,
      UpdateTopicInCategoryMutationVariables
    >
  >
}
operationsFactory.updateTopicInCategory = client => (variables, context) =>
  client
    .mutation(UpdateTopicInCategoryDocument, variables, context)
    .toPromise() as any
export const DeleteTopicInCategoryDocument = gql`
  mutation deleteTopicInCategory($id: ID!) {
    deleteTopicInCategory(id: $id)
  }
`
export const useDeleteTopicInCategory = () =>
  Urql.useMutation<
    DeleteTopicInCategoryMutation,
    DeleteTopicInCategoryMutationVariables
  >(DeleteTopicInCategoryDocument)
export interface Operations {
  deleteTopicInCategory: (
    variables: DeleteTopicInCategoryMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      DeleteTopicInCategoryMutation,
      DeleteTopicInCategoryMutationVariables
    >
  >
}
interface OperationsFactory {
  deleteTopicInCategory: (
    client: Urql.Client,
  ) => (
    variables: DeleteTopicInCategoryMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      DeleteTopicInCategoryMutation,
      DeleteTopicInCategoryMutationVariables
    >
  >
}
operationsFactory.deleteTopicInCategory = client => (variables, context) =>
  client
    .mutation(DeleteTopicInCategoryDocument, variables, context)
    .toPromise() as any
export const SearchTopicInUserDocument = gql`
  query searchTopicInUser(
    $filter: TopicInUserFilter
    $order: [TopicInUserOrderBy!]
    $page: Pagination
  ) {
    searchTopicInUser(filter: $filter, order: $order, page: $page) {
      ...TopicInUserF
    }
  }
  ${TopicInUserFFragmentDoc}
`
export const useSearchTopicInUser = (
  options?: Omit<Urql.UseQueryArgs<SearchTopicInUserQueryVariables>, 'query'>,
) =>
  Urql.useQuery<SearchTopicInUserQuery, SearchTopicInUserQueryVariables | void>(
    { query: SearchTopicInUserDocument, ...options },
  )
export interface Operations {
  searchTopicInUser: (
    variables?: SearchTopicInUserQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      SearchTopicInUserQuery,
      SearchTopicInUserQueryVariables
    >
  >
}
interface OperationsFactory {
  searchTopicInUser: (
    client: Urql.Client,
  ) => (
    variables?: SearchTopicInUserQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      SearchTopicInUserQuery,
      SearchTopicInUserQueryVariables
    >
  >
}
operationsFactory.searchTopicInUser = client => (variables, context) =>
  client.query(SearchTopicInUserDocument, variables, context).toPromise() as any
export const CreateTopicInUserDocument = gql`
  mutation createTopicInUser($data: CreateTopicInUser!) {
    createTopicInUser(data: $data) {
      ...TopicInUserF
    }
  }
  ${TopicInUserFFragmentDoc}
`
export const useCreateTopicInUser = () =>
  Urql.useMutation<
    CreateTopicInUserMutation,
    CreateTopicInUserMutationVariables
  >(CreateTopicInUserDocument)
export interface Operations {
  createTopicInUser: (
    variables: CreateTopicInUserMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      CreateTopicInUserMutation,
      CreateTopicInUserMutationVariables
    >
  >
}
interface OperationsFactory {
  createTopicInUser: (
    client: Urql.Client,
  ) => (
    variables: CreateTopicInUserMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      CreateTopicInUserMutation,
      CreateTopicInUserMutationVariables
    >
  >
}
operationsFactory.createTopicInUser = client => (variables, context) =>
  client
    .mutation(CreateTopicInUserDocument, variables, context)
    .toPromise() as any
export const UpdateTopicInUserDocument = gql`
  mutation updateTopicInUser($id: ID!, $data: InputUpdateTopicInUser) {
    updateTopicInUser(id: $id, data: $data) {
      ...TopicInUserF
    }
  }
  ${TopicInUserFFragmentDoc}
`
export const useUpdateTopicInUser = () =>
  Urql.useMutation<
    UpdateTopicInUserMutation,
    UpdateTopicInUserMutationVariables
  >(UpdateTopicInUserDocument)
export interface Operations {
  updateTopicInUser: (
    variables: UpdateTopicInUserMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      UpdateTopicInUserMutation,
      UpdateTopicInUserMutationVariables
    >
  >
}
interface OperationsFactory {
  updateTopicInUser: (
    client: Urql.Client,
  ) => (
    variables: UpdateTopicInUserMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      UpdateTopicInUserMutation,
      UpdateTopicInUserMutationVariables
    >
  >
}
operationsFactory.updateTopicInUser = client => (variables, context) =>
  client
    .mutation(UpdateTopicInUserDocument, variables, context)
    .toPromise() as any
export const DeleteTopicInUserDocument = gql`
  mutation deleteTopicInUser($id: ID!) {
    deleteTopicInUser(ids: $id)
  }
`
export const useDeleteTopicInUser = () =>
  Urql.useMutation<
    DeleteTopicInUserMutation,
    DeleteTopicInUserMutationVariables
  >(DeleteTopicInUserDocument)
export interface Operations {
  deleteTopicInUser: (
    variables: DeleteTopicInUserMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      DeleteTopicInUserMutation,
      DeleteTopicInUserMutationVariables
    >
  >
}
interface OperationsFactory {
  deleteTopicInUser: (
    client: Urql.Client,
  ) => (
    variables: DeleteTopicInUserMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      DeleteTopicInUserMutation,
      DeleteTopicInUserMutationVariables
    >
  >
}
operationsFactory.deleteTopicInUser = client => (variables, context) =>
  client
    .mutation(DeleteTopicInUserDocument, variables, context)
    .toPromise() as any
export const CreateTranslateDocument = gql`
  mutation createTranslate($data: InputTranslate!) {
    createTranslate(data: $data) {
      ...TranslateF
    }
  }
  ${TranslateFFragmentDoc}
`
export const useCreateTranslate = () =>
  Urql.useMutation<CreateTranslateMutation, CreateTranslateMutationVariables>(
    CreateTranslateDocument,
  )
export interface Operations {
  createTranslate: (
    variables: CreateTranslateMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      CreateTranslateMutation,
      CreateTranslateMutationVariables
    >
  >
}
interface OperationsFactory {
  createTranslate: (
    client: Urql.Client,
  ) => (
    variables: CreateTranslateMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      CreateTranslateMutation,
      CreateTranslateMutationVariables
    >
  >
}
operationsFactory.createTranslate = client => (variables, context) =>
  client
    .mutation(CreateTranslateDocument, variables, context)
    .toPromise() as any
export const UpdateTranslateDocument = gql`
  mutation updateTranslate($id: ID!, $data: InputTranslate!, $update: Boolean) {
    updateTranslate(id: $id, data: $data, update: $update) {
      ...TranslateF
    }
  }
  ${TranslateFFragmentDoc}
`
export const useUpdateTranslate = () =>
  Urql.useMutation<UpdateTranslateMutation, UpdateTranslateMutationVariables>(
    UpdateTranslateDocument,
  )
export interface Operations {
  updateTranslate: (
    variables: UpdateTranslateMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      UpdateTranslateMutation,
      UpdateTranslateMutationVariables
    >
  >
}
interface OperationsFactory {
  updateTranslate: (
    client: Urql.Client,
  ) => (
    variables: UpdateTranslateMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      UpdateTranslateMutation,
      UpdateTranslateMutationVariables
    >
  >
}
operationsFactory.updateTranslate = client => (variables, context) =>
  client
    .mutation(UpdateTranslateDocument, variables, context)
    .toPromise() as any
export const DeleteTranslateDocument = gql`
  mutation deleteTranslate($id: ID!) {
    deleteTranslate(id: $id)
  }
`
export const useDeleteTranslate = () =>
  Urql.useMutation<DeleteTranslateMutation, DeleteTranslateMutationVariables>(
    DeleteTranslateDocument,
  )
export interface Operations {
  deleteTranslate: (
    variables: DeleteTranslateMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      DeleteTranslateMutation,
      DeleteTranslateMutationVariables
    >
  >
}
interface OperationsFactory {
  deleteTranslate: (
    client: Urql.Client,
  ) => (
    variables: DeleteTranslateMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      DeleteTranslateMutation,
      DeleteTranslateMutationVariables
    >
  >
}
operationsFactory.deleteTranslate = client => (variables, context) =>
  client
    .mutation(DeleteTranslateDocument, variables, context)
    .toPromise() as any
export const SearchTranslateDocument = gql`
  query searchTranslate(
    $filter: TranslateFilter
    $order: [TranslateOrderBy!]
    $page: Pagination
  ) {
    searchTranslate(filter: $filter, order: $order, page: $page) {
      ...TranslateF
    }
  }
  ${TranslateFFragmentDoc}
`
export const useSearchTranslate = (
  options?: Omit<Urql.UseQueryArgs<SearchTranslateQueryVariables>, 'query'>,
) =>
  Urql.useQuery<SearchTranslateQuery, SearchTranslateQueryVariables | void>({
    query: SearchTranslateDocument,
    ...options,
  })
export interface Operations {
  searchTranslate: (
    variables?: SearchTranslateQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<SearchTranslateQuery, SearchTranslateQueryVariables>
  >
}
interface OperationsFactory {
  searchTranslate: (
    client: Urql.Client,
  ) => (
    variables?: SearchTranslateQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<SearchTranslateQuery, SearchTranslateQueryVariables>
  >
}
operationsFactory.searchTranslate = client => (variables, context) =>
  client.query(SearchTranslateDocument, variables, context).toPromise() as any
export const SuggestedTranslationDocument = gql`
  query suggestedTranslation($text: String, $language: Languages) {
    suggestedTranslation(text: $text, language: $language)
  }
`
export const useSuggestedTranslation = (
  options?: Omit<
    Urql.UseQueryArgs<SuggestedTranslationQueryVariables>,
    'query'
  >,
) =>
  Urql.useQuery<
    SuggestedTranslationQuery,
    SuggestedTranslationQueryVariables | void
  >({ query: SuggestedTranslationDocument, ...options })
export interface Operations {
  suggestedTranslation: (
    variables?: SuggestedTranslationQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      SuggestedTranslationQuery,
      SuggestedTranslationQueryVariables
    >
  >
}
interface OperationsFactory {
  suggestedTranslation: (
    client: Urql.Client,
  ) => (
    variables?: SuggestedTranslationQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      SuggestedTranslationQuery,
      SuggestedTranslationQueryVariables
    >
  >
}
operationsFactory.suggestedTranslation = client => (variables, context) =>
  client
    .query(SuggestedTranslationDocument, variables, context)
    .toPromise() as any
export const UpdateUserDocument = gql`
  mutation updateUser($data: UpdateMyProfile) {
    updateUser(data: $data) {
      ...UserF
    }
  }
  ${UserFFragmentDoc}
`
export const useUpdateUser = () =>
  Urql.useMutation<UpdateUserMutation, UpdateUserMutationVariables | void>(
    UpdateUserDocument,
  )
export interface Operations {
  updateUser: (
    variables?: UpdateUserMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<UpdateUserMutation, UpdateUserMutationVariables>
  >
}
interface OperationsFactory {
  updateUser: (
    client: Urql.Client,
  ) => (
    variables?: UpdateUserMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<UpdateUserMutation, UpdateUserMutationVariables>
  >
}
operationsFactory.updateUser = client => (variables, context) =>
  client.mutation(UpdateUserDocument, variables, context).toPromise() as any
export const SearchUserDocument = gql`
  query searchUser(
    $filter: UserFilter
    $order: [UserOrderBy!]
    $page: Pagination
  ) {
    searchUser(filter: $filter, order: $order, page: $page) {
      ...UserF
    }
  }
  ${UserFFragmentDoc}
`
export const useSearchUser = (
  options?: Omit<Urql.UseQueryArgs<SearchUserQueryVariables>, 'query'>,
) =>
  Urql.useQuery<SearchUserQuery, SearchUserQueryVariables | void>({
    query: SearchUserDocument,
    ...options,
  })
export interface Operations {
  searchUser: (
    variables?: SearchUserQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<Urql.OperationResult<SearchUserQuery, SearchUserQueryVariables>>
}
interface OperationsFactory {
  searchUser: (
    client: Urql.Client,
  ) => (
    variables?: SearchUserQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<Urql.OperationResult<SearchUserQuery, SearchUserQueryVariables>>
}
operationsFactory.searchUser = client => (variables, context) =>
  client.query(SearchUserDocument, variables, context).toPromise() as any
export const CountUserDocument = gql`
  query countUser($filter: UserFilter) {
    countUser(filter: $filter)
  }
`
export const useCountUser = (
  options?: Omit<Urql.UseQueryArgs<CountUserQueryVariables>, 'query'>,
) =>
  Urql.useQuery<CountUserQuery, CountUserQueryVariables | void>({
    query: CountUserDocument,
    ...options,
  })
export interface Operations {
  countUser: (
    variables?: CountUserQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<Urql.OperationResult<CountUserQuery, CountUserQueryVariables>>
}
interface OperationsFactory {
  countUser: (
    client: Urql.Client,
  ) => (
    variables?: CountUserQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<Urql.OperationResult<CountUserQuery, CountUserQueryVariables>>
}
operationsFactory.countUser = client => (variables, context) =>
  client.query(CountUserDocument, variables, context).toPromise() as any
export const SetAdminDocument = gql`
  mutation setAdmin($isAdmin: Boolean!, $id: ID!) {
    setAdmin(isAdmin: $isAdmin, id: $id) {
      ...UserF
    }
  }
  ${UserFFragmentDoc}
`
export const useSetAdmin = () =>
  Urql.useMutation<SetAdminMutation, SetAdminMutationVariables>(
    SetAdminDocument,
  )
export interface Operations {
  setAdmin: (
    variables: SetAdminMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<SetAdminMutation, SetAdminMutationVariables>
  >
}
interface OperationsFactory {
  setAdmin: (
    client: Urql.Client,
  ) => (
    variables: SetAdminMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<SetAdminMutation, SetAdminMutationVariables>
  >
}
operationsFactory.setAdmin = client => (variables, context) =>
  client.mutation(SetAdminDocument, variables, context).toPromise() as any
export const DeleteUserDocument = gql`
  mutation deleteUser($email: String, $id: String) {
    deleteUser(email: $email, id: $id)
  }
`
export const useDeleteUser = () =>
  Urql.useMutation<DeleteUserMutation, DeleteUserMutationVariables | void>(
    DeleteUserDocument,
  )
export interface Operations {
  deleteUser: (
    variables?: DeleteUserMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<DeleteUserMutation, DeleteUserMutationVariables>
  >
}
interface OperationsFactory {
  deleteUser: (
    client: Urql.Client,
  ) => (
    variables?: DeleteUserMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<DeleteUserMutation, DeleteUserMutationVariables>
  >
}
operationsFactory.deleteUser = client => (variables, context) =>
  client.mutation(DeleteUserDocument, variables, context).toPromise() as any
export const UpdateDeactivateUserDocument = gql`
  mutation updateDeactivateUser($id: ID!, $data: UpdateDeactivateUser!) {
    updateDeactivateUser(id: $id, data: $data) {
      ...UserF
    }
  }
  ${UserFFragmentDoc}
`
export const useUpdateDeactivateUser = () =>
  Urql.useMutation<
    UpdateDeactivateUserMutation,
    UpdateDeactivateUserMutationVariables
  >(UpdateDeactivateUserDocument)
export interface Operations {
  updateDeactivateUser: (
    variables: UpdateDeactivateUserMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      UpdateDeactivateUserMutation,
      UpdateDeactivateUserMutationVariables
    >
  >
}
interface OperationsFactory {
  updateDeactivateUser: (
    client: Urql.Client,
  ) => (
    variables: UpdateDeactivateUserMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      UpdateDeactivateUserMutation,
      UpdateDeactivateUserMutationVariables
    >
  >
}
operationsFactory.updateDeactivateUser = client => (variables, context) =>
  client
    .mutation(UpdateDeactivateUserDocument, variables, context)
    .toPromise() as any
export const ChangePasswordDocument = gql`
  mutation changePassword($oldPassword: String, $password: Password!) {
    changePassword(oldPassword: $oldPassword, password: $password)
  }
`
export const useChangePassword = () =>
  Urql.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(
    ChangePasswordDocument,
  )
export interface Operations {
  changePassword: (
    variables: ChangePasswordMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      ChangePasswordMutation,
      ChangePasswordMutationVariables
    >
  >
}
interface OperationsFactory {
  changePassword: (
    client: Urql.Client,
  ) => (
    variables: ChangePasswordMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      ChangePasswordMutation,
      ChangePasswordMutationVariables
    >
  >
}
operationsFactory.changePassword = client => (variables, context) =>
  client.mutation(ChangePasswordDocument, variables, context).toPromise() as any
export const DeleteUserByIdDocument = gql`
  mutation deleteUserById($id: String!) {
    deleteUserById(id: $id)
  }
`
export const useDeleteUserById = () =>
  Urql.useMutation<DeleteUserByIdMutation, DeleteUserByIdMutationVariables>(
    DeleteUserByIdDocument,
  )
export interface Operations {
  deleteUserById: (
    variables: DeleteUserByIdMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      DeleteUserByIdMutation,
      DeleteUserByIdMutationVariables
    >
  >
}
interface OperationsFactory {
  deleteUserById: (
    client: Urql.Client,
  ) => (
    variables: DeleteUserByIdMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      DeleteUserByIdMutation,
      DeleteUserByIdMutationVariables
    >
  >
}
operationsFactory.deleteUserById = client => (variables, context) =>
  client.mutation(DeleteUserByIdDocument, variables, context).toPromise() as any
export const DeleteUserByEmailDocument = gql`
  mutation deleteUserByEmail($email: String!) {
    deleteUserByEmail(email: $email)
  }
`
export const useDeleteUserByEmail = () =>
  Urql.useMutation<
    DeleteUserByEmailMutation,
    DeleteUserByEmailMutationVariables
  >(DeleteUserByEmailDocument)
export interface Operations {
  deleteUserByEmail: (
    variables: DeleteUserByEmailMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      DeleteUserByEmailMutation,
      DeleteUserByEmailMutationVariables
    >
  >
}
interface OperationsFactory {
  deleteUserByEmail: (
    client: Urql.Client,
  ) => (
    variables: DeleteUserByEmailMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      DeleteUserByEmailMutation,
      DeleteUserByEmailMutationVariables
    >
  >
}
operationsFactory.deleteUserByEmail = client => (variables, context) =>
  client
    .mutation(DeleteUserByEmailDocument, variables, context)
    .toPromise() as any
export const SearchWordDocument = gql`
  query searchWord(
    $filter: WordFilter
    $order: [WordOrderBy!]
    $page: Pagination
  ) {
    searchWord(filter: $filter, order: $order, page: $page) {
      ...WordF
    }
  }
  ${WordFFragmentDoc}
`
export const useSearchWord = (
  options?: Omit<Urql.UseQueryArgs<SearchWordQueryVariables>, 'query'>,
) =>
  Urql.useQuery<SearchWordQuery, SearchWordQueryVariables | void>({
    query: SearchWordDocument,
    ...options,
  })
export interface Operations {
  searchWord: (
    variables?: SearchWordQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<Urql.OperationResult<SearchWordQuery, SearchWordQueryVariables>>
}
interface OperationsFactory {
  searchWord: (
    client: Urql.Client,
  ) => (
    variables?: SearchWordQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<Urql.OperationResult<SearchWordQuery, SearchWordQueryVariables>>
}
operationsFactory.searchWord = client => (variables, context) =>
  client.query(SearchWordDocument, variables, context).toPromise() as any
export const SearchMultipleWordDocument = gql`
  query searchMultipleWord(
    $filter: WordFilter
    $order: [WordOrderBy!]
    $page1: Pagination
    $page2: Pagination
    $page3: Pagination
  ) {
    word1: searchWord(filter: $filter, order: $order, page: $page1) {
      ...WordF
    }
    word2: searchWord(filter: $filter, order: $order, page: $page2) {
      ...WordF
    }
    word3: searchWord(filter: $filter, order: $order, page: $page3) {
      ...WordF
    }
  }
  ${WordFFragmentDoc}
`
export const useSearchMultipleWord = (
  options?: Omit<Urql.UseQueryArgs<SearchMultipleWordQueryVariables>, 'query'>,
) =>
  Urql.useQuery<
    SearchMultipleWordQuery,
    SearchMultipleWordQueryVariables | void
  >({ query: SearchMultipleWordDocument, ...options })
export interface Operations {
  searchMultipleWord: (
    variables?: SearchMultipleWordQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      SearchMultipleWordQuery,
      SearchMultipleWordQueryVariables
    >
  >
}
interface OperationsFactory {
  searchMultipleWord: (
    client: Urql.Client,
  ) => (
    variables?: SearchMultipleWordQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      SearchMultipleWordQuery,
      SearchMultipleWordQueryVariables
    >
  >
}
operationsFactory.searchMultipleWord = client => (variables, context) =>
  client
    .query(SearchMultipleWordDocument, variables, context)
    .toPromise() as any
export const CountWordDocument = gql`
  query countWord($filter: WordFilter) {
    countWord(filter: $filter)
  }
`
export const useCountWord = (
  options?: Omit<Urql.UseQueryArgs<CountWordQueryVariables>, 'query'>,
) =>
  Urql.useQuery<CountWordQuery, CountWordQueryVariables | void>({
    query: CountWordDocument,
    ...options,
  })
export interface Operations {
  countWord: (
    variables?: CountWordQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<Urql.OperationResult<CountWordQuery, CountWordQueryVariables>>
}
interface OperationsFactory {
  countWord: (
    client: Urql.Client,
  ) => (
    variables?: CountWordQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<Urql.OperationResult<CountWordQuery, CountWordQueryVariables>>
}
operationsFactory.countWord = client => (variables, context) =>
  client.query(CountWordDocument, variables, context).toPromise() as any
export const CreateWordDocument = gql`
  mutation createWord($data: CreateWord!) {
    createWord(data: $data) {
      ...WordF
    }
  }
  ${WordFFragmentDoc}
`
export const useCreateWord = () =>
  Urql.useMutation<CreateWordMutation, CreateWordMutationVariables>(
    CreateWordDocument,
  )
export interface Operations {
  createWord: (
    variables: CreateWordMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<CreateWordMutation, CreateWordMutationVariables>
  >
}
interface OperationsFactory {
  createWord: (
    client: Urql.Client,
  ) => (
    variables: CreateWordMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<CreateWordMutation, CreateWordMutationVariables>
  >
}
operationsFactory.createWord = client => (variables, context) =>
  client.mutation(CreateWordDocument, variables, context).toPromise() as any
export const UpdateWordDocument = gql`
  mutation updateWord($id: ID!, $data: UpdateWord!) {
    updateWord(id: $id, data: $data) {
      ...WordF
    }
  }
  ${WordFFragmentDoc}
`
export const useUpdateWord = () =>
  Urql.useMutation<UpdateWordMutation, UpdateWordMutationVariables>(
    UpdateWordDocument,
  )
export interface Operations {
  updateWord: (
    variables: UpdateWordMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<UpdateWordMutation, UpdateWordMutationVariables>
  >
}
interface OperationsFactory {
  updateWord: (
    client: Urql.Client,
  ) => (
    variables: UpdateWordMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<UpdateWordMutation, UpdateWordMutationVariables>
  >
}
operationsFactory.updateWord = client => (variables, context) =>
  client.mutation(UpdateWordDocument, variables, context).toPromise() as any
export const DeleteWordDocument = gql`
  mutation deleteWord($ids: [ID!]!) {
    deleteWord(ids: $ids)
  }
`
export const useDeleteWord = () =>
  Urql.useMutation<DeleteWordMutation, DeleteWordMutationVariables>(
    DeleteWordDocument,
  )
export interface Operations {
  deleteWord: (
    variables: DeleteWordMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<DeleteWordMutation, DeleteWordMutationVariables>
  >
}
interface OperationsFactory {
  deleteWord: (
    client: Urql.Client,
  ) => (
    variables: DeleteWordMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<DeleteWordMutation, DeleteWordMutationVariables>
  >
}
operationsFactory.deleteWord = client => (variables, context) =>
  client.mutation(DeleteWordDocument, variables, context).toPromise() as any
export const DetailWordDocument = gql`
  query detailWord($id: ID!) {
    detailWord(id: $id) {
      ...WordF
    }
  }
  ${WordFFragmentDoc}
`
export const useDetailWord = (
  options: Omit<Urql.UseQueryArgs<DetailWordQueryVariables>, 'query'>,
) =>
  Urql.useQuery<DetailWordQuery, DetailWordQueryVariables>({
    query: DetailWordDocument,
    ...options,
  })
export interface Operations {
  detailWord: (
    variables: DetailWordQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<Urql.OperationResult<DetailWordQuery, DetailWordQueryVariables>>
}
interface OperationsFactory {
  detailWord: (
    client: Urql.Client,
  ) => (
    variables: DetailWordQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<Urql.OperationResult<DetailWordQuery, DetailWordQueryVariables>>
}
operationsFactory.detailWord = client => (variables, context) =>
  client.query(DetailWordDocument, variables, context).toPromise() as any
export const SearchResourceWordDocument = gql`
  query searchResourceWord(
    $filterTranslate: TranslateFilter
    $filterResource: ResourceInWordFilter
  ) {
    translate: searchTranslate(filter: $filterTranslate) {
      ...TranslateF
    }
    resource: searchResourceInWord(filter: $filterResource) {
      ...ResourceInWordF
    }
  }
  ${TranslateFFragmentDoc}
  ${ResourceInWordFFragmentDoc}
`
export const useSearchResourceWord = (
  options?: Omit<Urql.UseQueryArgs<SearchResourceWordQueryVariables>, 'query'>,
) =>
  Urql.useQuery<
    SearchResourceWordQuery,
    SearchResourceWordQueryVariables | void
  >({ query: SearchResourceWordDocument, ...options })
export interface Operations {
  searchResourceWord: (
    variables?: SearchResourceWordQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      SearchResourceWordQuery,
      SearchResourceWordQueryVariables
    >
  >
}
interface OperationsFactory {
  searchResourceWord: (
    client: Urql.Client,
  ) => (
    variables?: SearchResourceWordQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      SearchResourceWordQuery,
      SearchResourceWordQueryVariables
    >
  >
}
operationsFactory.searchResourceWord = client => (variables, context) =>
  client
    .query(SearchResourceWordDocument, variables, context)
    .toPromise() as any
export const SearchWordInUserDocument = gql`
  query searchWordInUser(
    $filter: WordInUserFilter
    $order: [WordInUserOrderBy!]
    $page: Pagination
  ) {
    searchWordInUser(filter: $filter, order: $order, page: $page) {
      ...WordInUserF
    }
  }
  ${WordInUserFFragmentDoc}
`
export const useSearchWordInUser = (
  options?: Omit<Urql.UseQueryArgs<SearchWordInUserQueryVariables>, 'query'>,
) =>
  Urql.useQuery<SearchWordInUserQuery, SearchWordInUserQueryVariables | void>({
    query: SearchWordInUserDocument,
    ...options,
  })
export interface Operations {
  searchWordInUser: (
    variables?: SearchWordInUserQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<SearchWordInUserQuery, SearchWordInUserQueryVariables>
  >
}
interface OperationsFactory {
  searchWordInUser: (
    client: Urql.Client,
  ) => (
    variables?: SearchWordInUserQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<SearchWordInUserQuery, SearchWordInUserQueryVariables>
  >
}
operationsFactory.searchWordInUser = client => (variables, context) =>
  client.query(SearchWordInUserDocument, variables, context).toPromise() as any
export const CountWordInUserDocument = gql`
  query countWordInUser($filter: WordInUserFilter) {
    countWordInUser(filter: $filter)
  }
`
export const useCountWordInUser = (
  options?: Omit<Urql.UseQueryArgs<CountWordInUserQueryVariables>, 'query'>,
) =>
  Urql.useQuery<CountWordInUserQuery, CountWordInUserQueryVariables | void>({
    query: CountWordInUserDocument,
    ...options,
  })
export interface Operations {
  countWordInUser: (
    variables?: CountWordInUserQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<CountWordInUserQuery, CountWordInUserQueryVariables>
  >
}
interface OperationsFactory {
  countWordInUser: (
    client: Urql.Client,
  ) => (
    variables?: CountWordInUserQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<CountWordInUserQuery, CountWordInUserQueryVariables>
  >
}
operationsFactory.countWordInUser = client => (variables, context) =>
  client.query(CountWordInUserDocument, variables, context).toPromise() as any
export const SearchReviewWordInUserDocument = gql`
  query searchReviewWordInUser($data: QueryReviewWordInUser) {
    searchReviewWordInUser(data: $data)
  }
`
export const useSearchReviewWordInUser = (
  options?: Omit<
    Urql.UseQueryArgs<SearchReviewWordInUserQueryVariables>,
    'query'
  >,
) =>
  Urql.useQuery<
    SearchReviewWordInUserQuery,
    SearchReviewWordInUserQueryVariables | void
  >({ query: SearchReviewWordInUserDocument, ...options })
export interface Operations {
  searchReviewWordInUser: (
    variables?: SearchReviewWordInUserQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      SearchReviewWordInUserQuery,
      SearchReviewWordInUserQueryVariables
    >
  >
}
interface OperationsFactory {
  searchReviewWordInUser: (
    client: Urql.Client,
  ) => (
    variables?: SearchReviewWordInUserQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      SearchReviewWordInUserQuery,
      SearchReviewWordInUserQueryVariables
    >
  >
}
operationsFactory.searchReviewWordInUser = client => (variables, context) =>
  client
    .query(SearchReviewWordInUserDocument, variables, context)
    .toPromise() as any
export const SearchVocabPhraseReviewDocument = gql`
  query searchVocabPhraseReview(
    $filter: WordInUserFilter
    $order: [WordInUserOrderBy!]
    $page: Pagination
  ) {
    searchVocabPhraseReview(filter: $filter, order: $order, page: $page) {
      ...WordInUserF
    }
  }
  ${WordInUserFFragmentDoc}
`
export const useSearchVocabPhraseReview = (
  options?: Omit<
    Urql.UseQueryArgs<SearchVocabPhraseReviewQueryVariables>,
    'query'
  >,
) =>
  Urql.useQuery<
    SearchVocabPhraseReviewQuery,
    SearchVocabPhraseReviewQueryVariables | void
  >({ query: SearchVocabPhraseReviewDocument, ...options })
export interface Operations {
  searchVocabPhraseReview: (
    variables?: SearchVocabPhraseReviewQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      SearchVocabPhraseReviewQuery,
      SearchVocabPhraseReviewQueryVariables
    >
  >
}
interface OperationsFactory {
  searchVocabPhraseReview: (
    client: Urql.Client,
  ) => (
    variables?: SearchVocabPhraseReviewQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      SearchVocabPhraseReviewQuery,
      SearchVocabPhraseReviewQueryVariables
    >
  >
}
operationsFactory.searchVocabPhraseReview = client => (variables, context) =>
  client
    .query(SearchVocabPhraseReviewDocument, variables, context)
    .toPromise() as any
export const CountVocabPhraseReviewDocument = gql`
  query countVocabPhraseReview($filter: WordInUserFilter) {
    countVocabPhraseReview(filter: $filter)
  }
`
export const useCountVocabPhraseReview = (
  options?: Omit<
    Urql.UseQueryArgs<CountVocabPhraseReviewQueryVariables>,
    'query'
  >,
) =>
  Urql.useQuery<
    CountVocabPhraseReviewQuery,
    CountVocabPhraseReviewQueryVariables | void
  >({ query: CountVocabPhraseReviewDocument, ...options })
export interface Operations {
  countVocabPhraseReview: (
    variables?: CountVocabPhraseReviewQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      CountVocabPhraseReviewQuery,
      CountVocabPhraseReviewQueryVariables
    >
  >
}
interface OperationsFactory {
  countVocabPhraseReview: (
    client: Urql.Client,
  ) => (
    variables?: CountVocabPhraseReviewQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      CountVocabPhraseReviewQuery,
      CountVocabPhraseReviewQueryVariables
    >
  >
}
operationsFactory.countVocabPhraseReview = client => (variables, context) =>
  client
    .query(CountVocabPhraseReviewDocument, variables, context)
    .toPromise() as any
export const CreateWordInUserDocument = gql`
  mutation createWordInUser($data: CreateWordInUser!) {
    createWordInUser(data: $data) {
      ...WordInUserF
    }
  }
  ${WordInUserFFragmentDoc}
`
export const useCreateWordInUser = () =>
  Urql.useMutation<CreateWordInUserMutation, CreateWordInUserMutationVariables>(
    CreateWordInUserDocument,
  )
export interface Operations {
  createWordInUser: (
    variables: CreateWordInUserMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      CreateWordInUserMutation,
      CreateWordInUserMutationVariables
    >
  >
}
interface OperationsFactory {
  createWordInUser: (
    client: Urql.Client,
  ) => (
    variables: CreateWordInUserMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      CreateWordInUserMutation,
      CreateWordInUserMutationVariables
    >
  >
}
operationsFactory.createWordInUser = client => (variables, context) =>
  client
    .mutation(CreateWordInUserDocument, variables, context)
    .toPromise() as any
export const UpdateWordInUserDocument = gql`
  mutation updateWordInUser($id: ID!, $data: UpdateWordInUser!) {
    updateWordInUser(id: $id, data: $data) {
      ...WordInUserF
    }
  }
  ${WordInUserFFragmentDoc}
`
export const useUpdateWordInUser = () =>
  Urql.useMutation<UpdateWordInUserMutation, UpdateWordInUserMutationVariables>(
    UpdateWordInUserDocument,
  )
export interface Operations {
  updateWordInUser: (
    variables: UpdateWordInUserMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      UpdateWordInUserMutation,
      UpdateWordInUserMutationVariables
    >
  >
}
interface OperationsFactory {
  updateWordInUser: (
    client: Urql.Client,
  ) => (
    variables: UpdateWordInUserMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      UpdateWordInUserMutation,
      UpdateWordInUserMutationVariables
    >
  >
}
operationsFactory.updateWordInUser = client => (variables, context) =>
  client
    .mutation(UpdateWordInUserDocument, variables, context)
    .toPromise() as any
export const DeleteWordInUserDocument = gql`
  mutation deleteWordInUser($id: ID!) {
    deleteWordInUser(id: $id)
  }
`
export const useDeleteWordInUser = () =>
  Urql.useMutation<DeleteWordInUserMutation, DeleteWordInUserMutationVariables>(
    DeleteWordInUserDocument,
  )
export interface Operations {
  deleteWordInUser: (
    variables: DeleteWordInUserMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      DeleteWordInUserMutation,
      DeleteWordInUserMutationVariables
    >
  >
}
interface OperationsFactory {
  deleteWordInUser: (
    client: Urql.Client,
  ) => (
    variables: DeleteWordInUserMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      DeleteWordInUserMutation,
      DeleteWordInUserMutationVariables
    >
  >
}
operationsFactory.deleteWordInUser = client => (variables, context) =>
  client
    .mutation(DeleteWordInUserDocument, variables, context)
    .toPromise() as any
export const CheckWordInUserDocument = gql`
  query checkWordInUser(
    $filter: WordInUserFilter
    $filterKnown: WordInUserFilter
    $filterUnKnown: WordInUserFilter
    $filterVocabularyUnknown: WordInUserFilter
    $filterVocabulary: WordInUserFilter
    $filterPhrase: WordInUserFilter
    $filterPhraseUnKnown: WordInUserFilter
    $filterTopicInUser: TopicInUserFilter
    $filterQuestion: QuestionFilter
    $filterWordInUser: WordInUserFilter
    $filterVocabPhraseReview: WordInUserFilter
    $filterWord: WordFilter
  ) {
    count: countWordInUser(filter: $filter)
    countKnown: countWordInUser(filter: $filterKnown)
    countUnKnown: countWordInUser(filter: $filterUnKnown)
    countVocabularyUnKnown: countWordInUser(filter: $filterVocabularyUnknown)
    countVocabulary: countWordInUser(filter: $filterVocabulary)
    countPhrase: countWordInUser(filter: $filterPhrase)
    countPhraseUnknown: countWordInUser(filter: $filterPhraseUnKnown)
    topicInUser: searchTopicInUser(filter: $filterTopicInUser) {
      id
      percent
      task
      unlockTopic
      learning
    }
    countQuestion: countQuestion(filter: $filterQuestion)
    wordInUser: searchWordInUser(filter: $filterWordInUser) {
      percentListen
    }
    countVocabPhraseReview: countVocabPhraseReview(
      filter: $filterVocabPhraseReview
    )
    countWord: countWord(filter: $filterWord)
  }
`
export const useCheckWordInUser = (
  options?: Omit<Urql.UseQueryArgs<CheckWordInUserQueryVariables>, 'query'>,
) =>
  Urql.useQuery<CheckWordInUserQuery, CheckWordInUserQueryVariables | void>({
    query: CheckWordInUserDocument,
    ...options,
  })
export interface Operations {
  checkWordInUser: (
    variables?: CheckWordInUserQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<CheckWordInUserQuery, CheckWordInUserQueryVariables>
  >
}
interface OperationsFactory {
  checkWordInUser: (
    client: Urql.Client,
  ) => (
    variables?: CheckWordInUserQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<CheckWordInUserQuery, CheckWordInUserQueryVariables>
  >
}
operationsFactory.checkWordInUser = client => (variables, context) =>
  client.query(CheckWordInUserDocument, variables, context).toPromise() as any
export const SearchWordbankDocument = gql`
  query searchWordbank(
    $filter: WordbankFilter
    $order: [WordbankOrderBy!]
    $page: Pagination
  ) {
    searchWordbank(filter: $filter, order: $order, page: $page) {
      ...WordbankF
    }
  }
  ${WordbankFFragmentDoc}
`
export const useSearchWordbank = (
  options?: Omit<Urql.UseQueryArgs<SearchWordbankQueryVariables>, 'query'>,
) =>
  Urql.useQuery<SearchWordbankQuery, SearchWordbankQueryVariables | void>({
    query: SearchWordbankDocument,
    ...options,
  })
export interface Operations {
  searchWordbank: (
    variables?: SearchWordbankQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<SearchWordbankQuery, SearchWordbankQueryVariables>
  >
}
interface OperationsFactory {
  searchWordbank: (
    client: Urql.Client,
  ) => (
    variables?: SearchWordbankQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<SearchWordbankQuery, SearchWordbankQueryVariables>
  >
}
operationsFactory.searchWordbank = client => (variables, context) =>
  client.query(SearchWordbankDocument, variables, context).toPromise() as any
export const CountWordbankDocument = gql`
  query countWordbank($filter: WordbankFilter) {
    countWordbank(filter: $filter)
  }
`
export const useCountWordbank = (
  options?: Omit<Urql.UseQueryArgs<CountWordbankQueryVariables>, 'query'>,
) =>
  Urql.useQuery<CountWordbankQuery, CountWordbankQueryVariables | void>({
    query: CountWordbankDocument,
    ...options,
  })
export interface Operations {
  countWordbank: (
    variables?: CountWordbankQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<CountWordbankQuery, CountWordbankQueryVariables>
  >
}
interface OperationsFactory {
  countWordbank: (
    client: Urql.Client,
  ) => (
    variables?: CountWordbankQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<CountWordbankQuery, CountWordbankQueryVariables>
  >
}
operationsFactory.countWordbank = client => (variables, context) =>
  client.query(CountWordbankDocument, variables, context).toPromise() as any
export const SearchWordbanksDocument = gql`
  query searchWordbanks(
    $filter: WordbankFilter
    $order: [WordbankOrderBy!]
    $page: Pagination
  ) {
    searchWordbanks: searchWordbank(
      filter: $filter
      order: $order
      page: $page
    ) {
      ...WordbankF
    }
    count: countWordbank(filter: $filter)
  }
  ${WordbankFFragmentDoc}
`
export const useSearchWordbanks = (
  options?: Omit<Urql.UseQueryArgs<SearchWordbanksQueryVariables>, 'query'>,
) =>
  Urql.useQuery<SearchWordbanksQuery, SearchWordbanksQueryVariables | void>({
    query: SearchWordbanksDocument,
    ...options,
  })
export interface Operations {
  searchWordbanks: (
    variables?: SearchWordbanksQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<SearchWordbanksQuery, SearchWordbanksQueryVariables>
  >
}
interface OperationsFactory {
  searchWordbanks: (
    client: Urql.Client,
  ) => (
    variables?: SearchWordbanksQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<SearchWordbanksQuery, SearchWordbanksQueryVariables>
  >
}
operationsFactory.searchWordbanks = client => (variables, context) =>
  client.query(SearchWordbanksDocument, variables, context).toPromise() as any
export const CreateWordbankDocument = gql`
  mutation createWordbank($data: CreateWordbank!) {
    createWordbank(data: $data) {
      ...WordbankF
    }
  }
  ${WordbankFFragmentDoc}
`
export const useCreateWordbank = () =>
  Urql.useMutation<CreateWordbankMutation, CreateWordbankMutationVariables>(
    CreateWordbankDocument,
  )
export interface Operations {
  createWordbank: (
    variables: CreateWordbankMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      CreateWordbankMutation,
      CreateWordbankMutationVariables
    >
  >
}
interface OperationsFactory {
  createWordbank: (
    client: Urql.Client,
  ) => (
    variables: CreateWordbankMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      CreateWordbankMutation,
      CreateWordbankMutationVariables
    >
  >
}
operationsFactory.createWordbank = client => (variables, context) =>
  client.mutation(CreateWordbankDocument, variables, context).toPromise() as any
export const UpdateWordbankDocument = gql`
  mutation updateWordbank($id: ID!, $data: UpdateWordbank!) {
    updateWordbank(id: $id, data: $data) {
      ...WordbankF
    }
  }
  ${WordbankFFragmentDoc}
`
export const useUpdateWordbank = () =>
  Urql.useMutation<UpdateWordbankMutation, UpdateWordbankMutationVariables>(
    UpdateWordbankDocument,
  )
export interface Operations {
  updateWordbank: (
    variables: UpdateWordbankMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      UpdateWordbankMutation,
      UpdateWordbankMutationVariables
    >
  >
}
interface OperationsFactory {
  updateWordbank: (
    client: Urql.Client,
  ) => (
    variables: UpdateWordbankMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      UpdateWordbankMutation,
      UpdateWordbankMutationVariables
    >
  >
}
operationsFactory.updateWordbank = client => (variables, context) =>
  client.mutation(UpdateWordbankDocument, variables, context).toPromise() as any
export const DeleteWordbankDocument = gql`
  mutation deleteWordbank($id: ID!) {
    deleteWordbank(id: $id)
  }
`
export const useDeleteWordbank = () =>
  Urql.useMutation<DeleteWordbankMutation, DeleteWordbankMutationVariables>(
    DeleteWordbankDocument,
  )
export interface Operations {
  deleteWordbank: (
    variables: DeleteWordbankMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      DeleteWordbankMutation,
      DeleteWordbankMutationVariables
    >
  >
}
interface OperationsFactory {
  deleteWordbank: (
    client: Urql.Client,
  ) => (
    variables: DeleteWordbankMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      DeleteWordbankMutation,
      DeleteWordbankMutationVariables
    >
  >
}
operationsFactory.deleteWordbank = client => (variables, context) =>
  client.mutation(DeleteWordbankDocument, variables, context).toPromise() as any
