import type { FC } from 'react'
import { Platform, TouchableOpacity, View } from 'react-native'

import { SystemIcon } from '#components/base/SystemIcon'
import { Text } from '#components/base/Text'
import { tw } from '#components/utils/tw'
import { ActionSheet } from '#components/widgets/ActionSheet'
import { navigate } from '#navigator/helpers'

type Pr = {
  closeActionsheet: () => void
  topicId: string
}

export const PlayMiniGame: FC<Pr> = ({ closeActionsheet, topicId }) => {
  const handleGame = () => {
    closeActionsheet()
    navigate('App', {
      screen: 'Games',
      params: {
        topicId,
        isPhrase: false,
        isCompleted: true,
        isPlayAgain: true,
      },
    })
  }

  const handleListenGame = () => {
    closeActionsheet()
    navigate('App', {
      screen: 'ListenGame',
      params: { topicId, isCompleted: true },
    })
  }

  return (
    <ActionSheet
      header='What do you want to play?'
      textAlign='left'
      headerStyle={tw`pt-4 px-2`}
      containerStyle={tw.style(Platform.OS !== 'web' ? 'h-50' : '')}
    >
      <View style={tw`flex-1 flex-row items-center gap-3 p-2`}>
        <TouchableOpacity
          style={tw`flex-1 flex-col items-center justify-center rounded-2xl border-2 border-secondary1-500 p-5.5 gap-3 h-26`}
          onPress={handleGame}
        >
          <SystemIcon
            type='SAX'
            name='Game'
            size={32}
            color={tw.color('secondary1-500')}
          />
          <Text specialType='paragraph1' textAlign='center'>
            Vocab & Phrasess
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={tw`flex-1 flex-col items-center justify-center rounded-2xl border-2 border-secondary2-500 p-5.5 gap-3 h-26`}
          onPress={handleListenGame}
        >
          <SystemIcon
            type='SAX'
            name='Headphone'
            size={32}
            color={tw.color('secondary2-500')}
          />
          <Text specialType='paragraph1' textAlign='center'>
            Listening
          </Text>
        </TouchableOpacity>
      </View>
    </ActionSheet>
  )
}
