import { gql } from '#graphql/urql'

export const onForgotPassword = async (email: string) => {
  try {
    const res = await gql.forgotPassword({ email })
    return res.data?.forgotPassword
  } catch (e) {
    return null
  }
}
