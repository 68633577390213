import { Text, View } from 'react-native'

import { LinearGradientColor } from '#components/base/LinearGradientColor/LinearGradientColor'
import { tw } from '#components/utils/tw'

export const LaunchScreen = () => (
  <LinearGradientColor>
    <View style={tw`flex-1 justify-center items-center`}>
      <Text
        style={[
          tw`text-[64px]`,
          { lineHeight: 64, fontWeight: '700', color: tw.color('text-4') },
        ]}
      >
        CODY
      </Text>
    </View>
  </LinearGradientColor>
)
