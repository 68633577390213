import { toastSuccess } from '#components/utils/Toast'
import { gql } from '#graphql/urql'
import { onAppInit } from '#screens/App/onAppInit'

export const handleVerify = async (
  id: string,
  code?: string,
  secret?: string,
) => {
  const r = await gql.resolveRegister({
    data: {
      code,
      id,
      secret,
    },
  })
  if (r.error || !r.data) {
    return null
  }
  await onAppInit({
    currentAuthSession: r.data.resolveRegister,
  })
  toastSuccess({ message: 'Register success' })

  return true
}
