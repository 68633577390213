import type { MaterialTopTabBarProps } from '@react-navigation/material-top-tabs'
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'
import { MotiView } from 'moti'
import { useEffect } from 'react'
import { Platform, Pressable, useWindowDimensions, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

import { SystemIcon } from '#components/base/SystemIcon'
import { Text } from '#components/base/Text'
import { useDarkBg, useDarkColor } from '#components/dark/hooks'
import { audioManager } from '#components/utils/audio/audioManager'
import { tw } from '#components/utils/tw'
import { Profile } from '#screens/Profile'
import { Topics } from '#screens/Topics'
import { WordBank } from '#screens/WordBank/WordBank'

import type { HomeBottomTabParamList } from './types'

const tabs = [
  {
    name: 'Topics',
    component: Topics,
    iconName: 'Home2',
  },
  {
    name: 'WordBank',
    component: WordBank,
    iconName: 'ArchiveTick',
  },
  {
    name: 'Profile',
    component: Profile,
    iconName: 'Profile',
  },
] as const

const top = 8
const left = 16
const tabBarHeight = 66
const tabHeight = tabBarHeight

const BottomTabBar = ({ state, navigation }: MaterialTopTabBarProps) => {
  const bg = useDarkBg()
  const dark = useDarkColor()
  const { width } = useWindowDimensions()
  const tabBarWidth = width
  const tabWidth = tabBarWidth / tabs.length
  const insets = useSafeAreaInsets()

  useEffect(() => {
    audioManager.stop()
  }, [state?.index])

  return (
    <View
      style={tw.style(
        'relative w-full flex-row shadow-md border-t-[1px]',
        bg,
        `border-[${dark('gray-800', 'gray-100')}]`,
        { width: tabBarWidth },
        Platform.OS === 'ios'
          ? {
              paddingBottom: insets.bottom,
              height: tabBarHeight + insets.bottom,
            }
          : { height: tabBarHeight },
      )}
    >
      <MotiView
        animate={{ left: state.index * tabWidth + left }}
        transition={{ duration: 150, type: 'timing' }}
        style={tw.style('absolute rounded-3xl', {
          width: tabWidth - left * 2,
          height: tabHeight - top * 2,
          top,
        })}
      />
      {state.routes.map((r, i) => {
        const isFocused = state.index === i
        const tabInfo = tabs.find(t => t.name === r.name)!

        const handlePress = () => {
          const e = navigation.emit({
            type: 'tabPress',
            target: r.key,
            canPreventDefault: true,
          })
          if (!isFocused && !e.defaultPrevented) {
            navigation.navigate(r.name, r.params)
          }
        }

        return (
          <Pressable
            key={r.key}
            style={tw.style('items-center justify-center gap-1', {
              width: tabWidth,
              height: tabHeight,
            })}
            onPress={handlePress}
          >
            <SystemIcon
              type='SAX'
              name={tabInfo.iconName}
              variant={isFocused ? 'Bold' : 'Linear'}
              color={tw.color(isFocused ? 'primary-400' : 'icon')}
            />
            <Text
              specialType='smalltext'
              color={tw.color(isFocused ? 'primary-400' : 'text-1')}
            >
              {tabInfo.name}
            </Text>
          </Pressable>
        )
      })}
    </View>
  )
}

const BottomTab = createMaterialTopTabNavigator<HomeBottomTabParamList>()
export const HomeBottomTab = () => (
  <BottomTab.Navigator
    tabBarPosition='bottom'
    tabBar={props => <BottomTabBar {...props} />}
  >
    {tabs.map(route => (
      <BottomTab.Screen
        key={route.name}
        name={route.name}
        component={route.component}
        options={{ lazy: true }}
      />
    ))}
  </BottomTab.Navigator>
)
