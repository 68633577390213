export interface QuesPhraseType {
  quesPhrase: string
  word1: number
  word2: number
}

export const getQuesUiFromAns = (ques: string, ans: string, word: string) => {
  let temp: string = ''
  if (ques.includes('...')) {
    if (ans.includes('...')) {
      const answer = ans.split('...') ?? ['']
      temp =
        ques.split('...')[0] +
        answer[0].trim() +
        ques.split('...')[1] +
        answer[1].trim() +
        ques.split('...')[2]
    } else {
      temp = ques.replace('...', ans.trim() ?? '')
    }
  } else {
    temp = ques !== '' ? ques : word ?? ''
  }
  return temp
}

export const getQuesPhraseFromQuesUi = (
  quesUi: string,
  word: string,
  ques: string,
) => {
  let word1 = -1
  let word2 = -1
  const startIndex = quesUi?.indexOf(word) ?? 0
  let temp = ''
  word
    .split(' ')
    .map((_, index) => (temp += ques.slice(startIndex).split(' ')[index] + ' '))
  temp = temp.trim()
  temp?.split(' ').map((e, index) => {
    if (e === '...') {
      if (word1 === -1) {
        word1 = index
      } else {
        word2 = index
      }
    }
  })

  return {
    quesPhrase: temp,
    word1,
    word2,
  }
}

export const getAnsFromQuesPhrase = (
  word: string,
  quesPhrase: QuesPhraseType,
) => {
  let ansText: string = ''
  if (quesPhrase.word2 !== -1) {
    ansText =
      word.split(' ')[quesPhrase.word1] +
      ' ... ' +
      word.split(' ')[quesPhrase.word2]
  } else if (quesPhrase.word1 !== -1) {
    ansText = word.split(' ')[quesPhrase.word1]
  } else {
    ansText = word
  }

  return ansText
}
