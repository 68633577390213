import type { LanguageType, Level } from '#types/language'

import type { AnswerGame, QuestionGame } from './games'
import { answersGame } from './games'
import { answersListen } from './listenGame'
import type { SearchTopicItem } from './topic'
import type { SearchTranslateItem } from './translate'

export type LevelTestType = 'word' | 'phrase' | 'listen'

export type TotalsType = {
  totalWords: number
  totalPhrases: number
  totalListen: number
}

export type RandomLevelTest = TotalsType & {
  type: LevelTestType
}

export type LevelTestItem = {
  type: LevelTestType
  position: number
  language: LanguageType
  level: Level
  topic?: SearchTopicItem
  translation: SearchTranslateItem[]
}

export const levelTestCount: { [key in Level]: number } = {
  A1: 4,
  A2: 2,
  B1: 2,
  B2: 2,
}

export type UpdateLevel = { newLevel: Level; updateLevel: Level }

export const levelUp: { [key in Level]: UpdateLevel } = {
  A1: { newLevel: 'B1', updateLevel: 'A2' },
  A2: { newLevel: 'A2', updateLevel: 'A2' },
  B1: { newLevel: 'B2', updateLevel: 'B1' },
  B2: { newLevel: 'B2', updateLevel: 'B2' },
}

export const fallback: { [key in Level]: UpdateLevel } = {
  A1: { newLevel: 'A1', updateLevel: 'A1' },
  A2: { newLevel: 'A2', updateLevel: 'A2' },
  B1: { newLevel: 'A2', updateLevel: 'A2' },
  B2: { newLevel: 'B1', updateLevel: 'B1' },
}

export type ItemLevel = {
  value: Level
  label: string
}

export const data: ItemLevel[] = [
  {
    value: 'A2',
    label: 'I know a little English',
  },
  {
    value: 'B1',
    label: 'I know a some English',
  },
  {
    value: 'B2',
    label: 'I know a lot of English',
  },
]

export type ItemProp = {
  item: ItemLevel
  onPress: (level: Level) => void
  isSelected: boolean
}

export type ReviewType = 'Pass' | 'Fail'

export type LevelTestContainer = LevelTestItem & {
  question: QuestionGame
  answers: AnswerGame[]
}

export const answersLevelTest: string[] = [...answersGame, ...answersListen]

export const getLevelDescription = (level: string) => {
  switch (level) {
    case 'A1':
      return {
        label: 'A1 - Beginner',
        color: 'secondary1-400',
        bg: 'secondary1-50',
      }
    case 'A2':
      return {
        label: 'A2 - Elementary',
        color: 'secondary1-400',
        bg: 'secondary1-50',
      }
    case 'B1':
      return {
        label: 'B1 - Intermediate',
        color: 'secondary2-400',
        bg: 'secondary2-50',
      }
    case 'B2':
      return {
        label: 'B2 - UpperIntermediate',
        color: 'secondary2-400',
        bg: 'secondary2-50',
      }
    default:
      return { label: level, color: 'gray', bg: 'background-light-2' }
  }
}
