import { observer } from 'mobx-react-lite'
import { Fragment } from 'react'
import { TouchableOpacity, View } from 'react-native'

import { images } from '#assets'
import type { IconSvgComponentProps } from '#components/base/IconSvg'
import { Image } from '#components/base/Image'
import { ScrollView } from '#components/base/ScrollView'
import { SystemIcon } from '#components/base/SystemIcon'
import { Text } from '#components/base/Text'
import { getUserName } from '#components/utils/getUserName'
import { tw } from '#components/utils/tw'
import { Divider } from '#components/widgets/Divider'
import { Header } from '#components/widgets/Header'
import {
  useSearchStreakInUser,
  useSearchTopicInUser,
  useSearchUser,
  useSearchWordInUser,
} from '#graphql/codegen'
import { navigate } from '#navigator/helpers'
import { S } from '#store'
import { ChooseLanguage } from '#types/language'
import { getLevelDescription } from '#types/levelTest'

import type { Level } from '../../../../server/schema/types/types'
import { AvatarProfile } from './AvatarProfile'
import type { ItemProps } from './Item'
import { Item } from './Item'

type IdType =
  | 'subscription'
  | 'learn-language'
  | 'native-language'
  | 'english-level'

type ItemD = ItemProps & {
  id: IdType
  isDivider?: boolean
}

export const Profile = observer(() => {
  const [d] = useSearchUser({
    variables: {
      filter: {
        OR: [
          { email_iLike: `%${S.shared.currentUser?.email}%` },
          { name_iLike: `%${S.shared.currentUser?.name}%` },
        ],
      },
      order: ['name_asc'],
    },
  })

  const [streakData] = useSearchStreakInUser({
    variables: { filter: { userId: S.shared.currentUser?.id } },
  })

  const [wiu] = useSearchWordInUser({
    variables: { filter: { times: 7, userId: S.shared.currentUser?.id } },
  })

  const [tiu] = useSearchTopicInUser({
    variables: {
      filter: { percent: 100, userLearnId: S.shared.currentUser?.id },
    },
  })
  const wordInUser = wiu.data?.searchWordInUser || []
  const phraseCount = wordInUser.filter(item => !item.word?.isVocabulary).length
  const wordCount = wordInUser.filter(item => item.word?.isVocabulary).length
  const topicInUser = tiu.data?.searchTopicInUser || []
  const completedTopicCount = topicInUser.length
  const thumbnailUser = d?.data?.searchUser[0]?.thumbnail?.url
  const nameUser = d?.data?.searchUser[0]?.name
  const level = (d?.data?.searchUser[0]?.level.current || 'A1') as Level

  const data: ItemD[] = [
    {
      id: 'subscription',
      title: 'Subscription',
      iconLeft: 'Star',
      subTitle: 'Basic plan',
      type: 'icon',
      isDivider: true,
    },
    {
      id: 'learn-language',
      title: 'I want to learn',
      iconLeft: 'Book1',
      type: 'icon',
      isDivider: true,
    },
    {
      id: 'native-language',
      title: 'I speak',
      iconLeft: 'Global',
      type: 'icon',
      isDivider: true,
    },
    {
      id: 'english-level',
      title: 'Level',
      iconLeft: 'Medal',
      subTitle: 'Level ' + level,
      subTilteType: 'type2',
      type: 'icon',
      isDivider: true,
    },
  ]

  const screenRedirect = (id: string) => {
    switch (id) {
      case 'learn-language': {
        navigate('App', {
          screen: 'ChooseLanguage',
          params: { type: ChooseLanguage.learn },
        })
        break
      }
      case 'native-language': {
        navigate('App', {
          screen: 'ChooseLanguage',
          params: { type: ChooseLanguage.native },
        })
        break
      }
      case 'english-level': {
        navigate('App', { screen: 'ChooseLevel', params: { isShow: true } })
        break
      }
      case 'subscription':
        navigate('App', { screen: 'Payment' })
        break
    }
  }

  const onPressSetting = () => {
    navigate('App', {
      screen: 'Setting',
    })
  }

  const items: {
    id: number
    count: number
    label: string
    icon: IconSvgComponentProps
    color: string
  }[] = [
    {
      id: 1,
      count:
        (streakData.data?.searchStreakInUser || []).length > 0
          ? streakData.data?.searchStreakInUser[0].countStreak || 0
          : 0,
      label: 'Days Streak',
      icon: 'streak',
      color: 'primary-300',
    },
    {
      id: 2,
      count: phraseCount || 0,
      label: 'Learned phrases',
      icon: 'phrase',
      color: 'warning-500',
    },
    {
      id: 3,
      count: wordCount || 0,
      label: 'Learned words',
      icon: 'word',
      color: 'secondary2-500',
    },
    {
      id: 4,
      count: completedTopicCount || 0,
      label: 'Completed lessons',
      icon: 'lesson',
      color: 'primary-400',
    },
  ]

  const onMasteredPress = () => {
    navigate('App', {
      screen: 'SuggestedSpeak',
      params: { topicId: '' },
    })
  }

  const onStreakRecordPress = () => {
    navigate('App', {
      screen: 'StreakRecord',
    })
  }

  return (
    <View style={tw`flex-1 flex-col`}>
      <Header
        isBack={false}
        title='Profile'
        isShowSetting={true}
        onPressSetting={onPressSetting}
      />
      <ScrollView contentContainerStyle={tw`pt-4`}>
        <View style={tw.style('flex-col mx-4 py-4 rounded-3xl')}>
          <View style={tw.style('flex-row items-center px-4 mb-6')}>
            <AvatarProfile
              key={thumbnailUser}
              size='large'
              name={nameUser}
              style={tw`w-20 h-20 rounded-full`}
              source={{
                uri: thumbnailUser,
              }}
              isImage={!thumbnailUser}
            />
            <View style={tw.style('flex-col justify-center mx-7 gap-2')}>
              <Text specialType='Headline4'>
                {getUserName({
                  email: S.shared.currentUser?.email || '',
                  name: S.shared.currentUser?.name || '',
                })}
              </Text>
              <View
                style={tw.style('rounded-full border px-2 py-1', {
                  borderColor: tw.color(getLevelDescription(level).color),
                  backgroundColor: tw.color(getLevelDescription(level).bg),
                })}
              >
                <Text
                  specialType='smalltext'
                  color={tw.color(getLevelDescription(level).color)}
                >
                  {getLevelDescription(level).label}
                </Text>
              </View>
            </View>
          </View>
          <Text specialType='Headline4'>Overview</Text>
          <View style={tw.style('flex-row flex-wrap gap-3 pb-6 pt-4')}>
            {items.map(item => (
              <View
                key={item.id}
                style={tw.style(
                  'flex-1 bg-background-light-white rounded-2xl px-4 py-8 gap-2',
                  { minWidth: '48%' },
                )}
              >
                <View style={tw.style('flex-row gap-2 items-center')}>
                  <SystemIcon
                    name={item.icon}
                    type='SVG'
                    color={tw.color(item.color)}
                    size={24}
                  />
                  <Text specialType='Headline3' color={tw.color(item.color)}>
                    {item.count}
                  </Text>
                </View>

                <Text specialType='paragraph2' color={tw.color('text-2')}>
                  {item.label}
                </Text>
              </View>
            ))}
          </View>

          <TouchableOpacity
            style={tw.style(
              'flex-row px-4 py-3 bg-background-light-white rounded-2xl items-center gap-2 mb-6',
            )}
            onPress={onStreakRecordPress}
          >
            <View style={tw.style('rounded-full bg-background-light-2 p-3')}>
              <Image
                source={images.image_streak}
                style={tw`w-8 h-8`}
                resizeMode='contain'
              />
            </View>

            <Text specialType='Title' style={tw.style('flex-1')}>
              Streak record
            </Text>

            <SystemIcon name='ArrowRight2' type='SAX' size={32} />
          </TouchableOpacity>

          <TouchableOpacity
            style={tw.style(
              'flex-row px-4 py-3 bg-background-light-white rounded-2xl items-center gap-2 mb-6',
            )}
            onPress={onMasteredPress}
          >
            <View style={tw.style('rounded-full bg-background-light-2 p-3')}>
              <SystemIcon
                name='BrifecaseTick'
                type='SAX'
                size={32}
                color={tw.color('secondary2-400')}
              />
            </View>

            <Text specialType='Title' style={tw.style('flex-1')}>
              Masterd words/phrases
            </Text>

            <SystemIcon name='ArrowRight2' type='SAX' size={32} />
          </TouchableOpacity>

          <Text specialType='Headline4'>Learning setting</Text>

          <View
            style={tw.style(
              'rounded-2xl bg-background-light-white mt-4 pt-2 gap-2',
            )}
          >
            {data.map(({ id, isDivider, ...itemProps }) => (
              <Fragment key={id}>
                <Item {...itemProps} onPress={() => screenRedirect(id)} />
                {isDivider && (
                  <Divider style={tw.style('border-neutral-200')} />
                )}
              </Fragment>
            ))}
          </View>
        </View>
      </ScrollView>
    </View>
  )
})
