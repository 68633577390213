import { z } from 'zod'

import { toastError } from '#components/utils/Toast'
import { OTPDialog } from '#components/widgets/ModalOTP'
import { gql } from '#graphql/urql'

import { handleVerify } from './onConfirmOTP'

const FormSchema = z.object({
  email: z
    .string({ required_error: 'Email is required' })
    .email('Invalid email address'),
  password: z
    .string({ required_error: 'Password is required' })
    .min(6, { message: 'Password must be at least 6 characters' }),
})

export type RegisterFormValues = z.infer<typeof FormSchema>

export const onRegisterSubmit = async (
  values: RegisterFormValues,
  openModal: (component: React.ComponentType<any>, props?: object) => void,
) => {
  const r = await gql.register({ data: values })

  if (r.error || !r.data) {
    toastError({ message: r.error?.message })
    return
  }

  openModal(OTPDialog, {
    handleConfirm: (value: string) =>
      handleVerify(r.data?.register ?? '', value),
    idForgotPassword: r.data?.register,
    containerProps: {
      closeOnOverlayClick: false,
    },
  })
}
