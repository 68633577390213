import { UploadOutlined } from '@ant-design/icons'
import { Button, Flex, Select, Upload as UploadMultiple } from 'antd'
import type { UploadChangeParam } from 'antd/es/upload'
import { useMemo, useState } from 'react'

import { handleFileType } from '#admin/components/utils/checkFileType'
import { getUrlToFile } from '#admin/components/utils/getUrlToFile'
import { Upload } from '#admin/components/widgets/Upload'
import { uploadFile } from '#apis/files'
import { toastError, toastSuccess } from '#components/utils/Toast'
import { tw } from '#components/utils/tw'
import type { ResourceInWord } from '#graphql/codegen'
import type { InputQuestionGame } from '#types/games'
import { mediaGameT, resourceGameType } from '#types/games'
import type { ResponseFile, UploadFileProps } from '#types/media'

type Props = {
  question: InputQuestionGame
  handleSelectChange: (
    type: 'audio' | 'video' | 'image',
  ) => (data: SelectItem & { key: string }) => void
  handleMediaQuestionChange: (
    type: 'audio' | 'video' | 'image',
  ) => (data: ResponseFile) => void
  resourceInWord: ResourceInWord[]
  mediaData: (SelectItem & { key: string })[]
  setMediaData: (value: (SelectItem & { key: string })[]) => void
  isUpload: boolean
  setQuestion: (question: Partial<InputQuestionGame>) => void
  setIsUpload: (value: boolean) => void
}

type SelectItem = { label: string; value: string }

export const HandleQuesUploadAudio = ({
  question,
  handleSelectChange,
  handleMediaQuestionChange,
  resourceInWord,
  mediaData,
  isUpload,
  setMediaData,
  setQuestion,
  setIsUpload,
}: Props) => {
  const [loading, setLoading] = useState<boolean>(false)
  const getOptions = () => {
    if (question.typeGame === 'selectImage') {
      const newData = resourceInWord
        ?.filter(e => e?.type?.split('_')[0] === 'audio' && e.media)
        .map(e => ({
          label: e.media?.name || '',
          value: e.media?.url || '',
          key: e?.mediaId ?? '',
        }))
      return [...newData, ...mediaData]
    }
    if (question.typeGame === 'correctTranslation') {
      const newData = resourceInWord
        ?.filter(
          e =>
            (e?.type?.split('_')[0] === 'video' ||
              e?.type?.split('_')[0] === 'audio') &&
            e.media,
        )
        .map(e => ({
          label: e.media?.name || '',
          value: e.media?.url || '',
          key: e?.mediaId ?? '',
        }))
      return [...newData, ...mediaData]
    }
    const newData = resourceInWord
      ?.filter(
        e =>
          e?.type?.split('_')[0] === mediaGameT[question.typeGame] && e.media,
      )
      .map(e => ({
        label: e.media?.name || '',
        value: e.media?.url || '',
        key: e?.mediaId ?? '',
      }))
    return [...newData, ...mediaData]
  }

  const file = useMemo(
    () =>
      getUrlToFile({
        url: question?.media?.url ?? '',
        name: question?.media?.name ?? '',
      }),
    [question.typeGame],
  )

  const handleUploadChange = async (fileUpload: UploadFileProps) => {
    setLoading(true)
    const checkFileType =
      fileUpload.type?.split('/')[0] === 'audio' ? 'audio' : 'video'

    if (!checkFileType) {
      toastError({ message: `${fileUpload?.name} is wrong file type!` })
      setLoading(false)
      return
    }
    try {
      setLoading(true)
      if (checkFileType) {
        const fileUploadDone: any = await uploadFile(fileUpload, checkFileType)
        const uploadedFile: UploadFileProps = {
          ...fileUploadDone,
          status: 'done',
          uid: fileUploadDone.id,
          type: handleFileType(fileUploadDone?.url),
        }
        setQuestion({
          media: {
            url: uploadedFile?.url ?? '',
            mediaId: uploadedFile?.uid ?? '',
            name: uploadedFile?.name ?? '',
          },
        })
        setMediaData([
          ...mediaData,
          {
            label: uploadedFile?.name,
            value: uploadedFile?.url ?? '',
            key: uploadedFile?.uid ?? '',
          },
        ])

        toastSuccess({ message: 'Uploaded file successfully.' })
      }
    } catch (error) {
      toastError({ message: 'Failed to upload file' })
    } finally {
      setLoading(false)
    }
    setLoading(false)
  }
  return (
    <Flex gap={8} style={tw`flex items-center`}>
      <Select
        showSearch
        placeholder='Search to Select'
        optionFilterProp='label'
        options={getOptions()}
        value={{
          label: question?.media?.name || '',
          value: question?.media?.url || '',
          key: question?.media?.mediaId || '',
        }}
        labelInValue
        onChange={handleSelectChange(mediaGameT[question.typeGame])}
        disabled={isUpload}
      />

      {question.typeGame === 'correctTranslation' ? (
        <UploadMultiple
          beforeUpload={() => false}
          fileList={file ? [file] : []}
          onChange={({
            file: newFileList,
          }: UploadChangeParam<UploadFileProps>) => {
            handleUploadChange(newFileList)
          }}
          type='select'
          showUploadList={false}
          disabled={loading}
        >
          <Button
            loading={loading}
            icon={<UploadOutlined />}
            type='dashed'
            style={tw`w-36`}
          >
            Upload file
          </Button>
        </UploadMultiple>
      ) : (
        <Upload
          resourceType={
            question.typeGame !== 'sameOrDifferent'
              ? resourceGameType[question.typeGame]
              : 'audio'
          }
          url={question?.media?.url || ''}
          type={mediaGameT[question.typeGame]}
          display='audio'
          required
          onChange={handleMediaQuestionChange(mediaGameT[question.typeGame])}
          isHiddenLabel
          onFileUploading={setIsUpload}
        />
      )}
    </Flex>
  )
}
