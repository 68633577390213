import type { TasksItem } from '#graphql/codegen'
import type { UserTasks } from '#types/chat'

export const convertToUserTasks = (tasks: TasksItem[]): UserTasks => ({
  data: tasks.map(task => ({
    id: task.id || '',
    name: task.name || '',
    description: task.description || '',
    isPassed: false,
    subTask: (task.subTask || []).map(subTask => ({
      id: subTask?.id || '',
      name: subTask?.name || '',
      description: subTask?.description || '',
      isPassed: false,
    })),
  })),
})

export const compareJSON = (obj1: any, obj2: any): boolean => {
  const isObject = (obj: any) => obj !== null && typeof obj === 'object'

  if (!isObject(obj1) || !isObject(obj2)) {
    return obj1 === obj2
  }

  const keys1 = Object.keys(obj1)
  const keys2 = Object.keys(obj2)

  if (keys1.length !== keys2.length) {
    return false
  }

  for (const key of keys1) {
    if (!keys2.includes(key) || !compareJSON(obj1[key], obj2[key])) {
      return false
    }
  }

  return true
}
