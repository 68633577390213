import { Flex, Form, Select, Typography } from 'antd'
import { useState } from 'react'

import { tw } from '#components/utils/tw'
import type { ResourceInWord } from '#graphql/codegen'
import type { Answer, GameType, InputQuestionGame } from '#types/games'
import {
  disableGameT,
  phraseGameLabelQuestion,
  wordGameLabel,
} from '#types/games'
import type { ResponseFile } from '#types/media'
import type { SearchWordItem } from '#types/word'

import { HandleQuesInput } from './HandleQuesInput'
import { HandleQuesUploadAudio } from './HandleQuesUploadAudio'
import { HandleQuesUploadImage } from './HandleQuesUploadImage'

type Props = {
  question: InputQuestionGame
  setQuestion: (question: Partial<InputQuestionGame>) => void
  isEditing: boolean
  word: SearchWordItem | null
  resourceInWord: ResourceInWord[]
  answers: Answer[]
  questionPhrase: string
  setQuestionPhrase: (value: string) => void
}

type SelectItem = { label: string; value: string }

export const Question = ({
  setQuestion,
  question,
  isEditing,
  word,
  resourceInWord,
  answers,
  questionPhrase,
  setQuestionPhrase,
}: Props) => {
  const [isUpload, setIsUpload] = useState<boolean>(false)
  const [isUploadImage, setIsUploadImage] = useState<boolean>(false)
  const [mediaData, setMediaData] = useState<(SelectItem & { key: string })[]>(
    [],
  )
  const [imageData, setImageData] = useState<(SelectItem & { key: string })[]>(
    [],
  )

  const handleMediaQuestionChange =
    (type: 'audio' | 'video' | 'image') => (data: ResponseFile) => {
      if (type === 'image') {
        setIsUploadImage(true)
        setQuestion({
          thumbnail: { url: data?.url, mediaId: data.id, name: data?.name },
        })
        setImageData(prev => [
          ...prev,
          { label: data?.name, value: data?.url, key: data.id },
        ])
      } else {
        setIsUpload(true)
        setQuestion({
          media: { url: data?.url, mediaId: data.id, name: data?.name },
        })
        setMediaData(prev => [
          ...prev,
          { label: data?.name, value: data?.url, key: data.id },
        ])
      }
      setIsUploadImage(false)
      setIsUpload(false)
    }

  const handleSelectChange =
    (type: 'audio' | 'video' | 'image') =>
    (data: SelectItem & { key: string }) => {
      setIsUpload(false)
      if (type === 'image') {
        setQuestion({
          thumbnail: { url: data.value, mediaId: data.key, name: data.label },
        })
      } else {
        setQuestion({
          media: { url: data.value, mediaId: data.key, name: data.label },
        })
      }
    }

  const handleChange = (value: GameType) => {
    setQuestion({
      ...question,
      typeGame: value,
      text: disableGameT[value] ? word?.vocabAndPhrase ?? '' : '',
    })
  }

  return (
    <Flex vertical style={tw`bg-gray-50 p-4 border-2 rounded-xl`}>
      <Form.Item
        label={
          <Typography.Text style={tw`text-6 px-2`}>Game Type</Typography.Text>
        }
        layout='vertical'
      >
        <Select
          value={question.typeGame}
          style={tw`w-full`}
          onChange={handleChange}
          options={word?.isVocabulary ? wordGameLabel : phraseGameLabelQuestion}
          disabled={isEditing}
        />
      </Form.Item>
      <Form.Item
        layout='vertical'
        label={
          <Typography.Text style={tw`text-5 px-2`}>Question</Typography.Text>
        }
        validateTrigger={['onChange', 'onBlur']}
        rules={[
          {
            required: true,
            message: 'Please input a question for the audio.',
          },
        ]}
        style={tw`mb-2 flex-1`}
      >
        {[
          'completeTheDialogue',
          'fillTheBlank',
          'selectImage',
          'rearrangement',
        ].includes(question.typeGame) && (
          <HandleQuesInput
            answers={answers}
            question={question}
            questionPhrase={questionPhrase}
            setQuestion={setQuestion}
            setQuestionPhrase={setQuestionPhrase}
            word={word}
          />
        )}
        {['sameOrDifferent', 'correctPhrase'].includes(question.typeGame) && (
          <HandleQuesUploadImage
            imageData={imageData}
            handleMediaQuestionChange={handleMediaQuestionChange}
            handleSelectChange={handleSelectChange}
            isUploadImage={isUploadImage}
            question={question}
            setIsUploadImage={setIsUploadImage}
            word={word}
          />
        )}
        {[
          'hearAudio',
          'hearVideo',
          'correctTranslation',
          'completeTheDialogue',
          'sameOrDifferent',
        ].includes(question.typeGame) && (
          <HandleQuesUploadAudio
            handleMediaQuestionChange={handleMediaQuestionChange}
            handleSelectChange={handleSelectChange}
            isUpload={isUpload}
            mediaData={mediaData}
            question={question}
            resourceInWord={resourceInWord}
            setIsUpload={setIsUpload}
            setMediaData={setMediaData}
            setQuestion={setQuestion}
          />
        )}
      </Form.Item>
    </Flex>
  )
}
