import { observer } from 'mobx-react-lite'
import React from 'react'
import { Platform, View } from 'react-native'

import { tw } from '#components/utils/tw'
import { ActionSheet } from '#components/widgets/ActionSheet'
import { Divider } from '#components/widgets/Divider'
import { gql } from '#graphql/urql'
import { navigate } from '#navigator/helpers'
import type { ItemProps } from '#screens/Profile/Item'
import { Item } from '#screens/Profile/Item'
import { S } from '#store'

type SettingProps = {
  closeActionsheet: () => void
}

type ItemData = ItemProps & {
  id: string
  isDivider?: boolean
}

export const ChatSetting: React.FC<SettingProps> = observer(
  ({ closeActionsheet }) => {
    const settingsData: ItemData[] = [
      {
        id: 'auto-play',
        title: 'Auto-play voice',
        type: 'switch',
        isChecked: S.shared.currentUser?.autoPlay,
        isDivider: true,
      },
      {
        id: 'choose-teacher',
        title: 'AI Talk Tutor',
        type: 'icon',
        isDivider: true,
      },
      {
        id: 'speaking-speed',
        title: 'Voice Speed',
        type: 'list',
        options: [
          { label: 'Slow', value: 0.5 },
          { label: 'Normal', value: 1 },
          { label: 'Fast', value: 1.5 },
        ],
        selectedOption: S.shared.currentUser?.speed,
      },
      {
        id: 'error-correction-level',
        title: 'Auto-correct mistakes',
        type: 'list',
        options: [
          { label: 'Never', value: 0 },
          { label: 'Sometimes', value: 1 },
          { label: 'Always', value: 2 },
        ],
        selectedOption: S.shared.currentUser?.repair,
        isDivider: false,
      },
    ]
    const onItemPress = (itemId: string) => {
      switch (itemId) {
        case 'choose-teacher':
          navigate('App', { screen: 'ChooseTeacher' })
          closeActionsheet()
          break
        default:
          break
      }
    }

    const onOptionSelect = async (itemId: string, value: number) => {
      if (itemId === 'speaking-speed') {
        const newUser = S.shared.currentUser
          ? {
              ...S.shared.currentUser,
              speed: value,
            }
          : null
        S.shared.currentUser = newUser
        await gql.updateUser({
          data: {
            speed: value,
          },
        })
      } else if (itemId === 'error-correction-level') {
        const newUser = S.shared.currentUser
          ? {
              ...S.shared.currentUser,
              repair: value,
            }
          : null
        S.shared.currentUser = newUser
        await gql.updateUser({
          data: {
            repair: value,
          },
        })
      }
    }

    const onChangeSwitch = async (itemId: string, value: boolean) => {
      const newUser = S.shared.currentUser
        ? {
            ...S.shared.currentUser,
            autoPlay: value,
          }
        : null
      S.shared.currentUser = newUser
      await gql.updateUser({
        data: {
          autoPlay: value,
        },
      })
    }

    return (
      <ActionSheet
        containerStyle={tw.style(
          '',
          Platform.OS === 'web' ? 'flex-1' : 'h-150',
        )}
        isLoading={false}
        header='Voice settings'
      >
        <View
          style={tw.style(
            'flex-1 flex-col p-4 m-2 rounded-3xl bg-neutral-75 shadow-lg gap-4 ',
            { shadowColor: tw.color('primary-300') },
          )}
        >
          {settingsData.map(({ id, isDivider, ...itemProps }) => (
            <React.Fragment key={id}>
              <Item
                {...itemProps}
                onPress={() => onItemPress(id)}
                onOptionPress={value => onOptionSelect(id, value)}
                onChangeSwitch={value => onChangeSwitch(id, value)}
              />
              {isDivider && <Divider style={tw.style('border-neutral-200')} />}
            </React.Fragment>
          ))}
        </View>
      </ActionSheet>
    )
  },
)
