import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { ScrollView, View } from 'react-native'

import { images } from '#assets'
import { Button } from '#components/base/Button/Button'
import { FlatList } from '#components/base/FlatList'
import { Gif } from '#components/base/Gif/Gif'
import { Image } from '#components/base/Image'
import { SystemIcon } from '#components/base/SystemIcon'
import { Text } from '#components/base/Text'
import { useOverlay } from '#components/overlay/hooks'
import { tw } from '#components/utils/tw'
import { Divider } from '#components/widgets/Divider'
import { Header } from '#components/widgets/Header'
import { useSearchProduct } from '#graphql/codegen'
import { gql } from '#graphql/urql'
import { S } from '#store'
import type { ProductItem } from '#types/product'

import { PaymentRadioButton } from '../PaymentRadioButton'
import { ActionSheetCheckpayment } from './ActionSheetCheckPayment'

export const PayementPackage = observer(
  ({ onPress }: { onPress: (selectedPlan: ProductItem | null) => void }) => {
    const [data] = useSearchProduct({
      variables: { filter: { active: true } },
    })
    const products = data?.data?.searchProduct || []
    const [selectedPlanId, setSelectedPlanId] = useState<string>('')
    const [selectedPlan, setSelectedPlan] = useState<ProductItem | null>(null)
    const { openActionsheet } = useOverlay()

    useEffect(() => {
      if (products.length > 0) {
        const firstProduct = products[0]
        setSelectedPlanId(firstProduct.id)
        setSelectedPlan(firstProduct)
      }
    }, [products])

    useEffect(() => {
      const checkSubscribed = async () => {
        const r = await gql.checkUserSubscribed(undefined, {
          requestPolicy: 'network-only',
        })
        const checkPaymentUser = r.data?.checkUserSubscribed
        if (checkPaymentUser?.length > 0) {
          openActionsheet(ActionSheetCheckpayment)
        }
        S.shared.setCheckUserSubscribed(checkPaymentUser?.length > 0)
      }

      checkSubscribed()
    }, [])

    const handleCheckUserPayment = () => {
      openActionsheet(ActionSheetCheckpayment)
    }

    const BenefitItem = ({
      features,
    }: {
      features?: { image?: string; title?: string; description?: string }[]
    }) => (
      <View style={tw.style('flex-col gap-2')}>
        {features?.map((feature, index) => (
          <View key={index} style={tw.style('flex-col gap-2')}>
            <View style={tw.style('flex-row gap-2')}>
              <View style={tw.style('w-11 h-11')}>
                <Image
                  source={{ uri: feature.image }}
                  width={44}
                  height={44}
                  style={tw.style({ aspectRatio: 1 / 1 })}
                />
              </View>
              <View style={tw.style('flex-1')}>
                <Text specialType='Title'>{feature.title}</Text>
                <Text specialType='Note'>{feature.description}</Text>
              </View>
            </View>
            {index !== features.length - 1 ? (
              <Divider style={tw.style('border-neutral-200')} />
            ) : null}
          </View>
        ))}
      </View>
    )

    const renderBenefitItem = () => {
      const features = selectedPlan?.marketingFeatures?.features
      if (!features) {
        return <View></View>
      }
      return (
        <View
          style={tw.style('bg-background-light-white rounded-2xl mb-4 p-4')}
        >
          <BenefitItem features={features} />
        </View>
      )
    }

    const renderPlanItem = ({ item }: { item: ProductItem }) => (
      <PaymentRadioButton
        key={item.id}
        title={item.name}
        description={item.description}
        price={item.price?.unitAmount || 0}
        selected={selectedPlanId === item.id}
        currency={item.price?.currency || 'usd'}
        onSelect={() => {
          setSelectedPlanId(item.id)
          setSelectedPlan(item)
        }}
        discount={item.discountPercent}
      />
    )

    const renderItemSeparator = () => <View style={tw.style('h-3 w-full')} />
    return (
      <View style={tw.style('flex-1 bg-background-light-2')}>
        <Header isBack iconType='arrow' />
        <ScrollView
          contentContainerStyle={tw`p-4 flex-grow`}
          showsVerticalScrollIndicator={false}
        >
          <View style={tw.style('items-center pb-6')}>
            <SystemIcon type='SVG' name='paymentGiftHeader' size={160} />
            <View style={tw.style('flex-row justify-center')}>
              {selectedPlan ? (
                <>
                  <Text specialType='Headline2'>
                    {selectedPlan.titleGif || ''}{' '}
                  </Text>
                  <Text specialType='Headline2' color={tw.color('primary-400')}>
                    {selectedPlan.titlePlansGif || ''}
                  </Text>
                </>
              ) : null}
            </View>
            <Text specialType='paragraph2' textAlign='center'>
              {selectedPlan?.subTitleGif || ''}
            </Text>
          </View>
          <View style={tw.style('flex-row items-center pb-4')}>
            <Divider style={tw.style('border-neutral-200')} />
            <View style={tw.style('px-1')}>
              <Text specialType='Headline4' color={tw.color('primary-400')}>
                Key benefits
              </Text>
            </View>
            <Divider style={tw.style('border-neutral-200')} />
          </View>
          {renderBenefitItem()}
          {data.fetching ? (
            <View style={tw`flex-1 items-center justify-center h-full`}>
              <Gif source={images.loading} width={50} height={50} />
            </View>
          ) : (
            <FlatList
              data={products}
              renderItem={renderPlanItem}
              ItemSeparatorComponent={renderItemSeparator}
            />
          )}
        </ScrollView>
        <View style={tw.style('p-4 bg-background-light-2')}>
          <Button
            disabled={!selectedPlanId || S.shared.checkUserSubscribed}
            onPress={() => {
              if (S.shared.checkUserSubscribed) {
                handleCheckUserPayment()
              } else {
                onPress(selectedPlan)
              }
            }}
            style={tw.style('mb-3')}
          >
            Subscribe now
          </Button>

          <View style={tw.style('flex-row self-center')}>
            <Text specialType='Title' color={tw.color('text-2')}>
              Cancel anytime. Term Privacy
            </Text>
          </View>
        </View>
      </View>
    )
  },
)
