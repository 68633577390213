import { gql } from '#graphql/urql'

export const confirmOTP = async (code: string, id: string) => {
  try {
    const response = await gql.confirmOtp({
      data: {
        code,
        id,
      },
    })
    return response
  } catch (e) {
    return null
  }
}
