import { Form, Input } from 'antd'
import type React from 'react'
import { useState } from 'react'

import { toastError, toastSuccess } from '#admin/components/utils/Toast'
import { ModalLayout } from '#admin/components/widgets/ModalLayout'
import { Upload } from '#admin/components/widgets/Upload'
import { tw } from '#components/utils/tw'
import { gql } from '#graphql/urql'

type Features = {
  title: string
  description: string
  image: string
  idImage: string
}

type Prop = {
  selectedFeature: Features[]
  indexFeature: number
  productId: string
  closeModal: () => void
  onOk: () => void
}

export const EditFeatureModal = ({
  selectedFeature,
  indexFeature,
  productId,
  closeModal,
  onOk,
}: Prop) => {
  const { TextArea } = Input

  const [loading, setLoading] = useState<boolean>(false)
  const [title, setTitle] = useState<string>(
    selectedFeature[indexFeature]?.title ?? '',
  )
  const [subtitle, setSubtitle] = useState<string>(
    selectedFeature[indexFeature]?.description ?? '',
  )
  const [image, setImage] = useState<{ id: string; url: string }>({
    id: selectedFeature[indexFeature]?.idImage ?? '',
    url: selectedFeature[indexFeature]?.image ?? '',
  })

  const handleModalOk = async () => {
    setLoading(true)
    let res
    if (indexFeature >= 0) {
      const temp = selectedFeature?.map((e, index) => {
        if (index !== indexFeature) {
          return e
        } else {
          return {
            idImage: image.id,
            image: image.url,
            title,
            description: subtitle,
          }
        }
      })
      res = await gql.updateProduct({
        id: productId,
        data: {
          marketingFeatures: {
            features: temp,
          },
        },
      })
    } else {
      const temp = [
        ...selectedFeature,
        {
          idImage: image.id,
          image: image.url,
          title,
          description: subtitle,
        },
      ]
      res = await gql.updateProduct({
        id: productId,
        data: {
          marketingFeatures: {
            features: temp,
          },
        },
      })
    }

    if (res?.error || !res?.data) {
      toastError({ title: 'Error', message: res?.error?.message })
      setLoading(false)
      return
    }
    toastSuccess({
      message: 'Update feature successfully',
    })
    onOk()
    closeModal()
    setLoading(false)
  }

  const handleModalCancel = () => {
    closeModal()
  }

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && title && subtitle && image.url && !loading) {
      handleModalOk()
    }
  }

  return (
    <ModalLayout
      title={selectedFeature ? 'Edit feature' : 'Add new feature'}
      closeModal={handleModalCancel}
      onConfirm={handleModalOk}
      disableOk={!title || !subtitle || !image.url || loading}
      autoClose={false}
      isLoading={loading}
    >
      <Form layout='vertical' onKeyUp={handleKeyPress} style={tw`w- pt-2`}>
        <Form.Item label='Image'>
          <Upload
            display='pictureCard'
            resourceType='image_feature'
            type='image'
            url={image.url}
            onChange={value => {
              setImage({
                id: value.id,
                url: value.url,
              })
            }}
          />
        </Form.Item>
        <Form.Item label='Title'>
          <Input
            value={title}
            onChange={e => {
              setTitle(e.target.value)
            }}
          />
        </Form.Item>
        <Form.Item label='Subtitle'>
          <TextArea
            value={subtitle}
            onChange={e => {
              setSubtitle(e.target.value)
            }}
          />
        </Form.Item>
      </Form>
    </ModalLayout>
  )
}
