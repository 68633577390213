import { useState } from 'react'

import { AnswersList } from '#admin/components/widgets/Answer/AnswersList'
import { useOverlay } from '#components/overlay/hooks'
import { toastError } from '#components/utils/Toast'
import type { ResourceInWord } from '#graphql/codegen'
import { gql } from '#graphql/urql'
import type { Answer, InputQuestionGame } from '#types/games'

import { AnswerItem } from './AnswerItem'
import { AnswerModal } from './AnswerModal'

type Props = {
  question: InputQuestionGame
  answers: Answer[]
  setAnswer: (data: {
    text: Answer
    index: number
    type: 'Add' | 'Remove' | 'Update' | 'UpdateCorrect'
  }) => void
  resourceInWord: ResourceInWord[]
  disable?: boolean
}

export const Answers = ({
  question,
  answers,
  setAnswer,
  disable,
  resourceInWord,
}: Props) => {
  const { openModal } = useOverlay()
  const [loading, setLoading] = useState<boolean>(false)

  const handleAddAnswer = () => {
    openModal(AnswerModal, {
      questionType: question.typeGame,
      onChangeSuccess: async i => {
        setAnswer({ text: i, index: answers.length, type: 'Add' })
      },
      questionId: question.id ?? '',
      isEdit: !!question.id,
      containerProps: {
        closeOnOverlayClick: false,
      },
    })
  }

  const handleEditAnswer = (answer: Answer, index: number) => {
    setAnswer({
      text: answer,
      index,
      type: 'Update',
    })
  }

  const handleDelete = (answer: Answer, index: number) => {
    setAnswer({
      text: answer,
      index,
      type: 'Remove',
    })
  }

  const onChangeRadio = async (item: Answer, index: number) => {
    try {
      if (item.id) {
        setLoading(true)
        const resp = await gql.updateAnswerInQuestion({
          id: item.id,
          data: { isTrue: true, questionId: question.id },
        })
        if (resp.error) {
          toastError({ message: 'Change answer correct failed' })
          return
        }
        setAnswer({
          text: { ...item, isTrue: true },
          index,
          type: 'UpdateCorrect',
        })
      } else {
        setAnswer({
          text: { ...item, isTrue: true },
          index,
          type: 'UpdateCorrect',
        })
      }
    } catch (error) {
      toastError({ message: 'Change answer correct failed' })
    } finally {
      setLoading(false)
    }
  }

  return (
    <AnswersList
      disabled={disable}
      handleAdd={handleAddAnswer}
      hideButton={['sameOrDifferent', 'rearrangement'].includes(
        question.typeGame,
      )}
      isHiddenHeader={['sameOrDifferent'].includes(question.typeGame)}
      loading={loading}
    >
      {answers.map((answer, index) => (
        <AnswerItem
          key={index}
          index={index}
          answer={answer}
          gameType={question.typeGame}
          disable={disable}
          handleDelete={handleDelete}
          handleUpdate={handleEditAnswer}
          isHiddenActionButton={answers.length <= 2 && !!answer.id}
          questionId={question.id}
          onChangeRadio={onChangeRadio}
          resourceInWord={resourceInWord}
        />
      ))}
    </AnswersList>
  )
}
