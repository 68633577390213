import { WarningFilled } from '@ant-design/icons'
import { Flex, Input, Radio, Typography } from 'antd'
import { useState } from 'react'

import { FillTheBlank } from '#admin/components/question/fillTheBlank'
import { tw } from '#components/utils/tw'
import type { Answer, InputQuestionGame } from '#types/games'
import { disableGameT } from '#types/games'
import type { SearchWordItem } from '#types/word'

type Props = {
  question: InputQuestionGame
  word: SearchWordItem | null
  setQuestion: (question: Partial<InputQuestionGame>) => void
  questionPhrase: string
  setQuestionPhrase: (value: string) => void
  answers: Answer[]
}
export const HandleQuesInput = ({
  question,
  word,
  setQuestion,
  questionPhrase,
  setQuestionPhrase,
  answers,
}: Props) => {
  const [isChange, setIsChange] = useState(false)
  const [questionUI, setQuestionUI] = useState(word?.vocabAndPhrase)
  const [wordChoose, setWordChoose] = useState<{
    word1: number
    word2: number
  }>({
    word1: -1,
    word2: -1,
  })
  return (
    <div>
      {question.typeGame === 'fillTheBlank' && (
        <div style={tw`mb-4`}>
          {word?.isVocabulary ? (
            <div>
              <div>
                You need to fill in the question box with a sentence containing
                the keyword!
              </div>
              <div>
                Ex: I took a <span style={tw`bg-yellow-200`}>plane</span> from
                America to Vietnam {'(Key word: plane)'}
              </div>
            </div>
          ) : (
            <div>
              <div>You can choose up to 2 words to create a question!</div>
              <div>
                Ex: I took a plane <span style={tw`bg-yellow-200`}>from</span>{' '}
                America <span style={tw`bg-yellow-200`}>to</span> Vietnam{' '}
                {'(Key word: from...to)'}
                <br />
                {'=>'} I took a plane <span style={tw`bg-yellow-200`}>...</span>{' '}
                America <span style={tw`bg-yellow-200`}>...</span> Vietnam{' '}
              </div>
            </div>
          )}
        </div>
      )}
      {question.typeGame !== 'completeTheDialogue' ? (
        <div>
          {question.typeGame === 'fillTheBlank' ? (
            <Input
              value={
                (!question.text && !word?.isVocabulary) ||
                disableGameT[question.typeGame]
                  ? word?.vocabAndPhrase
                  : word
                    ? questionUI
                    : question.text
              }
              onChange={e => {
                setQuestionUI(e.target.value)
              }}
              style={{ marginRight: '8px', marginBottom: '8px' }}
              disabled={disableGameT[question.typeGame]}
            />
          ) : (
            <Input
              value={
                disableGameT[question.typeGame]
                  ? word?.vocabAndPhrase ?? question.text
                  : question.text
              }
              onChange={e => {
                setQuestion({ text: e.target.value })
              }}
              style={{ marginRight: '8px', marginBottom: '8px' }}
              disabled={disableGameT[question.typeGame] && !!word}
            />
          )}
        </div>
      ) : (
        <Flex style={tw`flex flex-col`}>
          <Flex style={tw`flex items-center mb-2`}>
            <Typography.Text style={tw`text-4 px-2`}>
              Character A :
            </Typography.Text>
            <Input
              style={tw`flex-1`}
              value={question.index === 0 ? question.text : ''}
              disabled={question.index !== 0}
              onChange={e => setQuestion({ text: e.target.value })}
            />
            <Radio
              checked={question.index === 0}
              onChange={() => setQuestion({ index: 0 })}
              style={tw`ml-2`}
            />
          </Flex>

          <Flex style={tw`flex items-center mb-2`}>
            <Typography.Text style={tw`text-4 px-2`}>
              Character B :
            </Typography.Text>
            <Input
              style={tw`flex-1`}
              value={question.index === 1 ? question.text : ''}
              disabled={question.index !== 1}
              onChange={e => setQuestion({ text: e.target.value })}
            />
            <Radio
              checked={question.index === 1}
              onChange={() => setQuestion({ index: 1 })}
              style={tw`ml-2`}
            />
          </Flex>
        </Flex>
      )}

      {question.typeGame === 'fillTheBlank' && (
        <FillTheBlank
          answers={answers}
          isChange={isChange}
          question={question}
          questionPhrase={questionPhrase}
          setQuestionPhrase={setQuestionPhrase}
          questionUI={questionUI ?? ''}
          setQuestionUI={setQuestionUI}
          setIsChange={setIsChange}
          setQuestion={setQuestion}
          setWordChoose={setWordChoose}
          word={word}
          wordChoose={wordChoose}
        />
      )}
      {!questionUI
        ?.toLowerCase()
        ?.includes(word?.vocabAndPhrase.toLowerCase().trim() ?? '') &&
        !word?.isVocabulary &&
        question.typeGame === 'fillTheBlank' && (
          <Flex gap={4} style={tw`mt-1`}>
            <WarningFilled style={{ color: 'red' }} />
            <div style={tw`text-red-600`}>
              You can not input word in phrases!!!
            </div>
          </Flex>
        )}
    </div>
  )
}
