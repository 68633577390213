import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { Platform, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

import { Button } from '#components/base/Button/Button'
import { FlatList } from '#components/base/FlatList'
import { Text } from '#components/base/Text'
import { tw } from '#components/utils/tw'
import { Header } from '#components/widgets/Header'
import type { UpdateMyProfile } from '#graphql/codegen'
import { gql } from '#graphql/urql'
import { goBack, navigate } from '#navigator/helpers'
import type { AppStackScreenProps } from '#navigator/types'
import { S } from '#store'
import type { LanguageType } from '#types/language'
import { ChooseLanguage as TypeChooseLanguage } from '#types/language'
import type { CurrentUser } from '#types/user'

import type { ItemLanguageType } from './ItemLanguage'
import { ItemLanguage } from './ItemLanguage'

const dataLanguage: ItemLanguageType[] = [
  { id: '1', value: 'Spanish', label: 'Spanish', icon: 'Spain' },
  { id: '2', value: 'English', label: 'English', icon: 'English' },
]

export const ChooseLanguage = observer(
  ({ route }: AppStackScreenProps<'ChooseLanguage'>) => {
    const type = route?.params?.type ?? TypeChooseLanguage.both
    const [position, setPosition] = useState<boolean>(true)
    const [native, setNative] = useState<LanguageType>('Spanish')
    const [language, setLanguage] = useState<LanguageType>('English')
    const insets = useSafeAreaInsets()

    useEffect(() => {
      switch (type) {
        case TypeChooseLanguage.learn:
          setPosition(false)
          setLanguage(
            (S.shared.currentUser?.languageLearn as LanguageType) ?? 'English',
          )
          break
        case TypeChooseLanguage.native:
          setPosition(true)
          setNative(
            (S.shared.currentUser?.nativeLanguage as LanguageType) ?? 'English',
          )
          break
        default:
          setPosition(true)
          break
      }
    }, [type])

    useEffect(() => {
      if (type === TypeChooseLanguage.both) {
        if (native === 'English') {
          setLanguage('Spanish')
        } else {
          setLanguage('English')
        }
      }
    }, [native])

    const onPressBack = () => {
      if (type !== TypeChooseLanguage.both) {
        goBack()
      } else if (!position) {
        setPosition(true)
      }
    }

    const onPressItem = (item: ItemLanguageType) => {
      if (position) {
        setNative(item.value as LanguageType)
      } else {
        setLanguage(item.value as LanguageType)
      }
    }

    const updateUserInfo = async (data: UpdateMyProfile) => {
      const newUser = S.shared.currentUser
        ? {
            ...S.shared.currentUser,
            speed: 1,
            repair: false,
            autoPlay: false,
            ...data,
          }
        : null
      S.shared.currentUser = newUser as CurrentUser
      await gql.updateUser({
        data: {
          speed: 1,
          repair: 0,
          autoPlay: false,
          ...data,
        },
      })
    }

    const navigateScreen = () => {
      navigate('App', { screen: 'ChooseLevel' })
    }

    const onPressContinue = async () => {
      switch (type) {
        case TypeChooseLanguage.learn:
          let level = S.shared.currentUser?.level
          if (S.shared.currentUser?.languageLearn !== language) {
            level = { ...level, current: 'A1' }
          }
          updateUserInfo({
            languageLearn: language,
            level,
          })
          onPressBack()
          break
        case TypeChooseLanguage.native:
          updateUserInfo({ nativeLanguage: native })
          onPressBack()
          break
        default:
          if (position) {
            setPosition(false)
          } else {
            await updateUserInfo({
              languageLearn: language,
              nativeLanguage: native,
            })
            navigateScreen()
          }
          break
      }
    }

    const renderItem = ({
      item,
    }: {
      item: ItemLanguageType
      index: number
    }) => (
      <ItemLanguage
        item={item}
        isSelected={position ? item.value === native : item.value === language}
        onPress={onPressItem}
      />
    )

    const renderItemSeparator = () => <View style={tw`h-3 w-full`} />

    return (
      <View style={tw`flex-1 bg-background-light-1 dark:bg-background-dark-1`}>
        <Header
          typeSlider={type === TypeChooseLanguage.both ? 'slice' : 'basic'}
          countTotal={3}
          sliderValue={position ? 1 : 2}
          onPressBack={onPressBack}
          isBack={!position || type !== TypeChooseLanguage.both}
          iconType='arrow'
        />
        <View
          style={tw.style(
            'flex-1 flex-col p-4',
            Platform.OS === 'ios' && {
              paddingBottom: Math.max(insets.bottom, 16),
            },
          )}
        >
          <View style={tw`mb-6 mt-4`}>
            <Text specialType='Headline3' textAlign='center'>
              {position ? 'I speak ...' : 'I want to learn...'}
            </Text>
          </View>
          <View style={tw`flex-1 flex-col`}>
            <FlatList
              data={dataLanguage}
              renderItem={renderItem}
              ItemSeparatorComponent={renderItemSeparator}
            />
          </View>
          <View style={tw`mb-8`}>
            <Text
              specialType='paragraph2'
              textAlign='center'
              color={tw.color('text-2')}
            >
              More languages will be added soon...
            </Text>
          </View>
          <Button size='large' onPress={onPressContinue}>
            Continue
          </Button>
        </View>
      </View>
    )
  },
)
