import { debounce } from 'lodash'
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { TouchableOpacity, useWindowDimensions, View } from 'react-native'
import TrackPlayer, { Event, State } from 'react-native-track-player'

import { SystemIcon } from '#components/base/SystemIcon'
import { Text } from '#components/base/Text'
import type { AudioPropsRef } from '#components/utils/audio/type'
import { useAudioManager } from '#components/utils/audio/useAudioManager'
import { tw } from '#components/utils/tw'
import type { GameQuestionProps } from '#types/games'

export const AudioQuestion = forwardRef<AudioPropsRef, GameQuestionProps>(
  ({ question }, ref) => {
    const { height } = useWindowDimensions()
    const { play, stop, isPlay } = useAudioManager({
      url: question.media?.url || '',
    })

    const [isPlaying, setIsPlaying] = useState<'normal' | 'slowly' | ''>('')

    useImperativeHandle(
      ref,
      () => ({ stop: stopAudio, play: playAudio, isPlay }),
      [isPlay, question.media],
    )

    const playAudio = () => {
      setIsPlaying('normal')
      play(1)
    }

    const playAudioWithRate = debounce((r: number) => {
      setIsPlaying(r === 1 ? 'normal' : 'slowly')
      play(r)
    }, 200)

    const stopAudio = () => {
      stop()
    }

    useEffect(() => {
      const onPlaybackState = TrackPlayer.addEventListener(
        Event.PlaybackState,
        data => {
          if (data.state === State.Stopped || data.state === State.Paused) {
            setIsPlaying('')
          }
        },
      )

      return () => {
        onPlaybackState.remove()
      }
    }, [])

    return (
      <View style={tw.style('flex-col p-4 mb-4')}>
        <View style={tw`flex-1 flex-row items-center justify-center gap-4`}>
          <TouchableOpacity
            disabled={isPlaying === 'normal'}
            onPress={() => playAudioWithRate(1)}
            style={tw.style(
              'rounded-full border-4 border-line-2 p-6 items-center justify-center',
              { backgroundColor: isPlaying === 'normal' ? '#AAB8C9' : '' },
            )}
          >
            <SystemIcon
              type='SAX'
              name='VolumeHigh'
              size={height * 0.1}
              color={
                isPlaying === 'normal' ? '#D9E2EC' : tw.color('primary-400')
              }
              variant='Bold'
            />
          </TouchableOpacity>
          {question.type !== 'selectImage' && (
            <TouchableOpacity
              disabled={isPlaying === 'slowly'}
              onPress={() => playAudioWithRate(0.5)}
              style={tw.style(
                'rounded-full border-[3px] border-line-2 p-2 items-center justify-center',
                { backgroundColor: isPlaying === 'slowly' ? '#AAB8C9' : '' },
              )}
            >
              <SystemIcon
                type='SVG'
                name='snail'
                size={height * 0.05}
                color={isPlaying === 'slowly' ? '#D9E2EC' : undefined}
              />
            </TouchableOpacity>
          )}
          {question.type === 'selectImage' && (
            <Text
              numberOfLines={4}
              style={tw`flex-shrink`}
              specialType='Headline2'
            >
              {question?.text}
            </Text>
          )}
        </View>
      </View>
    )
  },
)
