import {
  CloseOutlined,
  EditOutlined,
  PlayCircleOutlined,
} from '@ant-design/icons'
import { Button, Flex, Image, Radio, Typography } from 'antd'

import { toastError, toastSuccess } from '#admin/components/utils/Toast'
import { ModalLayout } from '#admin/components/widgets/ModalLayout'
import { useOverlay } from '#components/overlay/hooks'
import { tw } from '#components/utils/tw'
import type { ResourceInWord } from '#graphql/codegen'
import { gql } from '#graphql/urql'
import type { Answer, GameType } from '#types/games'

import { AnswerModal } from './AnswerModal'

type Props = {
  index: number
  answer: Answer
  gameType: GameType
  disable?: boolean
  handleUpdate: (answer: Answer, index: number) => void
  handleDelete: (answer: Answer, index: number) => void
  onChangeRadio?: (answer: Answer, index: number) => void
  isHiddenActionButton?: boolean
  resourceInWord?: ResourceInWord[]
  questionId?: string
}

export const AnswerItem = ({
  index,
  answer,
  gameType,
  handleUpdate,
  handleDelete,
  questionId,
  isHiddenActionButton,
  onChangeRadio,
  resourceInWord,
}: Props) => {
  const { openModal } = useOverlay()

  const options = resourceInWord
    ?.filter(e => e.mediaId && e.type === 'audio_game')
    .map(e => ({
      label: e.media?.name ?? '',
      value: e.media?.url ?? '',
      key: e.mediaId,
    }))

  const handleRemove = () => {
    if (answer.id) {
      openModal(ModalLayout, {
        onConfirm: async () => {
          const res = await gql.deleteAnswer({ id: answer.answerId ?? '' })
          if (res.data) {
            toastSuccess({
              message: 'Answer deleted successfully',
            })
            handleDelete(answer, index)
          } else {
            toastError({
              message: 'Delete answer failed',
            })
          }
        },
        title: 'Confirm Delete',
        content: 'Are you sure you want to delete this answer?',
        successMessage: 'Answer deleted successfully',
        errorMessage: 'Failed to delete answer',
        containerProps: {
          closeOnOverlayClick: false,
        },
      })
    } else {
      handleDelete(answer, index)
    }
  }

  const handleEdit = () => {
    openModal(AnswerModal, {
      questionType: gameType,
      existingAnswer: answer,
      onChangeSuccess: i => {
        handleUpdate({ ...answer, ...i }, index)
      },
      isEdit: !!answer.id,
      questionId: questionId ?? '',
      options,
    })
  }

  const handleAudioClick = () => {
    window.open(answer.audio?.url, '_blank')
  }

  const handleRadio = () => {
    if (!answer.isTrue) {
      onChangeRadio?.(answer, index)
    }
  }

  return (
    <Flex
      gap={8}
      style={tw.style(
        'flex-1 items-center bg-white p-3 rounded-xl',
        answer.isTrue && 'bg-[#CCECFA]',
      )}
    >
      <Radio
        checked={answer.isTrue}
        disabled={gameType !== 'sameOrDifferent'}
        onChange={handleRadio}
      />
      <Flex style={tw`flex-1 items-center`}>
        {gameType === 'selectImage' ? (
          <Image
            width={60}
            height={60}
            style={tw`border-2 border-gray-400 rounded-xl`}
            src={answer.image?.url}
          />
        ) : (
          <Typography>{answer.text || `Answer ${index + 1}`}</Typography>
        )}
      </Flex>
      {gameType !== 'sameOrDifferent' && (
        <Flex style={tw`flex-1 items-center`}>
          <div style={tw`w-100 justify-start`}>
            {answer.audio && (
              <Button
                onClick={handleAudioClick}
                icon={<PlayCircleOutlined size={24} />}
                type='link'
                style={tw`p-0 h-11`}
                disabled={!answer.isTrue}
              >
                {answer.audio.name}
              </Button>
            )}
          </div>
          <Flex style={tw`items-center w-20`} gap={8}>
            <Button
              onClick={handleEdit}
              icon={<EditOutlined />}
              type='default'
            />
            {!isHiddenActionButton && !answer.isTrue && (
              <Button
                onClick={handleRemove}
                icon={<CloseOutlined />}
                type='default'
              />
            )}
          </Flex>
        </Flex>
      )}
    </Flex>
  )
}
