import { Flex, Form, Input, Select } from 'antd'
import { useEffect, useState } from 'react'

import { toastError, toastSuccess } from '#admin/components/utils/Toast'
import { ModalLayout } from '#admin/components/widgets/ModalLayout'
import { Upload } from '#admin/components/widgets/Upload'
import { tw } from '#components/utils/tw'
import { gql } from '#graphql/urql'
import type { Answer, GameMedia, GameType } from '#types/games'
import type { ResponseFile } from '#types/media'

type SelectProp = {
  label: string
  value: string
  key: string
}

type Prop = {
  closeModal: () => void
  questionType: GameType
  existingAnswer?: Answer
  isEdit?: boolean
  onChangeSuccess: (answer: Answer) => void
  questionId: string
  options?: SelectProp[]
}

export const AnswerModal = ({
  closeModal,
  questionType,
  existingAnswer,
  isEdit,
  onChangeSuccess,
  questionId,
  options,
}: Prop) => {
  const [answerText, setAnswerText] = useState(existingAnswer?.text || '')
  const [image, setImage] = useState<GameMedia>({
    url: existingAnswer?.image?.url || '',
    mediaId: existingAnswer?.thumbnailId || '',
    name: existingAnswer?.image?.name || '',
  })
  const [audio, setAudio] = useState<GameMedia>({
    url: existingAnswer?.audio?.url || '',
    mediaId: existingAnswer?.mediaId || '',
    name: existingAnswer?.audio?.name || '',
  })
  const [audioSelected, setAudioSelected] = useState<SelectProp>()

  const [uploading, setUploading] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [dataAudioSelected, setDataAudioSelected] = useState<SelectProp[]>(
    options ?? [],
  )

  useEffect(() => {
    setAudioSelected({
      label: existingAnswer?.audio?.name || '',
      value: existingAnswer?.audio?.url || '',
      key: existingAnswer?.mediaId || '',
    })
  }, [existingAnswer?.audio])

  useEffect(() => {
    setDataAudioSelected(options ?? [])
  }, [JSON.stringify(options)])

  const handleOk = async () => {
    try {
      if (isEdit) {
        if (existingAnswer?.id) {
          callAPIUpdate()
        } else {
          callAPIAdd()
        }
      } else {
        onChangeSuccess({
          isTrue: existingAnswer?.isTrue ?? false,
          text: answerText,
          image: {
            url: image.url,
            name: image.name,
          },
          audio: {
            url: audio.url,
            name: audio.name,
          },
          mediaId: audio?.mediaId || '',
          thumbnailId: image?.mediaId || '',
        })
        closeModal()
      }
    } catch (error) {
      toastError({ message: 'Error updating answer' })
    }
  }

  const callAPIUpdate = async () => {
    if (existingAnswer?.id) {
      try {
        setLoading(true)
        const resp = await gql.updateAnswer({
          data: { text: answerText },
          id: existingAnswer?.answerId ?? '',
        })
        if (resp.error) {
          toastError({ message: 'Update answer failed' })
          return
        }
        const res = await gql.updateAnswerInQuestion({
          id: existingAnswer.id ?? '',
          data: {
            mediaId: audio.mediaId,
            isTrue: existingAnswer.isTrue,
            thumbnailId: image.mediaId,
            questionId: questionId ?? '',
          },
        })
        if (res.data?.updateAnswerInQuestion) {
          onChangeSuccess({
            ...existingAnswer,
            image: {
              url: image.url,
              name: image.name,
            },
            audio: {
              url: audio.url,
              name: audio.name,
            },
            text: answerText,
            mediaId: audio.mediaId,
            thumbnailId: image.mediaId,
          })
          toastSuccess({ message: 'Answer updated successfully' })
          closeModal()
        } else {
          toastError({ message: 'Update answer failed' })
        }
      } catch (error) {
        toastError({ message: 'Update answer failed' })
      } finally {
        setLoading(false)
      }
    }
  }

  const callAPIAdd = async () => {
    try {
      setLoading(true)
      const createAnswerData = await gql.createAnswer({
        data: {
          text: answerText,
        },
      })
      const res = await gql.createAnswerInQuestion({
        data: {
          questionId,
          isTrue: false,
          mediaId: audio.mediaId,
          thumbnailId: image.mediaId,
          answerId: createAnswerData.data?.createAnswer.id,
        },
      })
      if (res.data) {
        onChangeSuccess({
          ...existingAnswer,
          id: res.data?.createAnswerInQuestion?.id ?? '',
          text: answerText,
          image: {
            url: image.url,
            name: image.name,
          },
          audio: {
            url: audio.url,
            name: audio.name,
          },
          mediaId: audio.mediaId,
          thumbnailId: image.mediaId,
          isTrue: false,
        })
        toastSuccess({ message: 'Create answer successfully' })
        closeModal()
      } else {
        toastError({ message: 'Create answer failed' })
      }
    } catch (error) {
      toastError({ message: 'Create answer failed' })
    } finally {
      setLoading(false)
    }
  }

  return (
    <ModalLayout
      title={existingAnswer ? 'Edit answer' : 'Add new answer'}
      closeModal={closeModal}
      onConfirm={handleOk}
      disableOk={
        uploading ||
        loading ||
        (!answerText && questionType !== 'selectImage') ||
        (questionType === 'selectImage' && !image.mediaId) ||
        (existingAnswer?.isTrue && !audio.mediaId)
      }
      disableCancel={loading || uploading}
      autoClose={false}
      isLoading={loading}
    >
      <Form layout='vertical' style={tw`w-120 pt-2`}>
        {questionType !== 'selectImage' ? (
          <Form.Item label='Answer'>
            <Input
              value={answerText}
              onChange={e => setAnswerText(e.target.value)}
              className='w-full'
              disabled={
                (existingAnswer?.isTrue || loading) &&
                !!existingAnswer?.text &&
                questionType !== 'correctPhrase'
              }
            />
          </Form.Item>
        ) : (
          <Form.Item label='Image'>
            <Upload
              resourceType='image_game'
              type='image'
              url={image.url}
              onChange={(data: ResponseFile) =>
                setImage(s => ({ ...s, url: data.url, mediaId: data.id }))
              }
              display='image'
              disabled={loading}
              onFileUploading={e => setUploading(e || false)}
            />
          </Form.Item>
        )}
        {existingAnswer?.isTrue && (
          <Form.Item label='Audio'>
            <Flex gap={8} style={tw`w-full items-center justify-between`}>
              <Select
                showSearch
                placeholder='Search to Select'
                options={dataAudioSelected}
                value={audioSelected}
                labelInValue
                disabled={loading}
                style={{ width: '68%' }}
                onChange={value => {
                  setAudioSelected(value)
                  setAudio(s => ({
                    ...s,
                    url: value.value,
                    mediaId: value.key,
                  }))
                }}
              />
              <Upload
                resourceType='audio'
                type='audio'
                url={audio.url}
                onChange={(data: ResponseFile) => {
                  setAudio(s => ({ ...s, url: data.url, mediaId: data.id }))
                  const newAudio = {
                    label: data?.name || '',
                    value: data?.url || '',
                    key: data.id,
                  }
                  if (existingAnswer?.isTrue) {
                    setAudioSelected(newAudio)
                    setDataAudioSelected(s => [...s, newAudio])
                  }
                }}
                display='audio'
                disabled={loading}
                onFileUploading={e => setUploading(e || false)}
                isHiddenLabel={existingAnswer?.isTrue}
                onDeleted={() =>
                  setAudio(s => ({ mediaId: '', url: '', name: '' }))
                }
              />
            </Flex>
          </Form.Item>
        )}
      </Form>
    </ModalLayout>
  )
}
