import { DeleteOutlined } from '@ant-design/icons'
import { useIsFocused } from '@react-navigation/native'
import { Button, Flex, Typography } from 'antd'
import { useEffect, useState } from 'react'

import { NavigateBackButton } from '#admin/components/utils/NavigateBackButton'
import { ModalLayout } from '#admin/components/widgets/ModalLayout'
import { PaginatedTable } from '#admin/components/widgets/PaginatedTable'
import { useOverlay } from '#components/overlay/hooks'
import { tw } from '#components/utils/tw'
import {
  useCountQuestion,
  useDetailWord,
  useSearchQuestion,
} from '#graphql/codegen'
import { gql } from '#graphql/urql'
import { navigate } from '#navigator/helpers'
import type { AdminScreenProps } from '#navigator/types'
import { phraseGameLabelQuestion, wordGameLabel } from '#types/games'
import type { SearchQuestionItem } from '#types/question'

export const getGameTypeLabel = (value: string, word: boolean) => {
  if (word) {
    return wordGameLabel.find(o => o.value === value)?.label
  } else {
    return phraseGameLabelQuestion.find(o => o.value === value)?.label
  }
}

export const Ques = ({
  navigation,
  route,
}: AdminScreenProps<'AdminTopicQues'>) => {
  const { wordId, topicId } = route.params
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const isFocused = useIsFocused()
  const { openModal } = useOverlay()

  const [word] = useDetailWord({
    variables: { id: wordId },
  })

  const [question, refetchQuestion] = useSearchQuestion({
    variables: {
      filter: {
        wordId,
      },
      // order: ['word_createdAt_asc'],
      page: { limit: pageSize, offset: (currentPage - 1) * pageSize },
    },
  })

  const [count, refetchCount] = useCountQuestion({
    variables: {
      filter: {
        wordId,
      },
    },
  })

  useEffect(() => {
    if (isFocused) {
      refetchCount({ requestPolicy: 'network-only' })
      refetchQuestion({ requestPolicy: 'network-only' })
    }
  }, [isFocused])

  const handleNavigateBack = () => {
    if (navigation.canGoBack()) {
      navigation.goBack()
    } else {
      navigation.navigate('AdminTopicDetail', {
        id: topicId,
        tab: 'word_and_phrases',
      })
    }
  }
  const reloadDelete = () => {
    refetchQuestion({ requestPolicy: 'network-only' })
    refetchCount({ requestPolicy: 'network-only' })

    if (count.data?.countQuestion !== undefined) {
      const remainingItemsCount = count.data.countQuestion - 1
      let newPageCount = Math.ceil(remainingItemsCount / pageSize)
      if (newPageCount < 1) {
        newPageCount = 1
      }
      if (currentPage > newPageCount) {
        setCurrentPage(newPageCount)
      }
    }
  }

  const actionsColumn = {
    title: 'Actions',
    key: 'actions',
    width: 100,
    render: (_, record) => (
      <div style={tw`flex items-center justify-center`}>
        <Button
          icon={<DeleteOutlined />}
          onClick={async e => {
            e.stopPropagation()
            openModal(ModalLayout, {
              onConfirm: async () => {
                await gql.deleteQuestion({ id: record.id })
                reloadDelete()
              },
              title: 'Confirm delete',
              content: 'Are you sure you want to delete this question?',
              successMessage: 'Question deleted successfully',
              errorMessage: 'Delete question failed',
              containerProps: {
                closeOnOverlayClick: false,
              },
            })
          }}
          className='p-0 bg-transparent self-center'
          danger={true}
        />
      </div>
    ),
  }

  const columns = [
    {
      title: 'Game Type',
      dataIndex: 'type',
      key: 'type',
      render: text => (
        <div style={{ cursor: 'pointer' }}>
          {getGameTypeLabel(text, word.data?.detailWord.isVocabulary || false)}
        </div>
      ),
    },
    {
      title: 'Question',
      dataIndex: 'text',
      key: 'text',
      render: (_, record) => (
        <div style={{ cursor: 'pointer' }}>{record.text}</div>
      ),
    },

    actionsColumn,
  ]

  const onNavigateUpdate = (record: SearchQuestionItem) => {
    navigate('Admin', {
      screen: 'AdminTopicQuesDetail',
      params: {
        quesId: record.id,
        topicId,
        wordId,
        languageTopic: record?.topic?.languageTopic ?? '',
      },
    })
  }

  const onNavigateAdd = () => {
    navigate('Admin', {
      screen: 'AdminTopicQuesDetail',
      params: {
        topicId,
        wordId,
        quesId: '',
        languageTopic: word.data?.detailWord.topic?.languageTopic ?? '',
      },
    })
  }

  return (
    <Flex style={tw`flex-1 flex-col p-4 h-full overflow-y-hidden`}>
      <Flex style={tw`flex pt-5 items-center`}>
        <NavigateBackButton direction={handleNavigateBack} />
        <Typography.Text style={tw`text-6 px-2`}>Question</Typography.Text>
      </Flex>
      <Flex>
        <Typography.Text style={tw`py-4`} type='secondary'>
          {`Word: ${word.data?.detailWord.vocabAndPhrase || ''}`}
        </Typography.Text>
      </Flex>
      <Flex style={tw`self-end p-4`}>
        <Button disabled={word.fetching} type='primary' onClick={onNavigateAdd}>
          Add
        </Button>
      </Flex>
      <PaginatedTable<SearchQuestionItem>
        dataSource={question.data?.searchQuestion ?? []}
        columns={columns}
        loading={question.fetching}
        total={count.data?.countQuestion ?? 0}
        currentPage={currentPage}
        pageSize={pageSize}
        setCurrentPage={setCurrentPage}
        setPageSize={setPageSize}
        // expandable={expandable}
        onRowClick={onNavigateUpdate}
        scrollHeight={'50vh'}
      />
    </Flex>
  )
}
