import { zodResolver } from '@hookform/resolvers/zod'
import type { UploadFile } from 'antd'
import type { RcFile } from 'antd/es/upload'
import { observer } from 'mobx-react-lite'
import { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Platform, TouchableOpacity, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { z } from 'zod'

import { CropImage } from '#admin/components/widgets/CropImage'
import { Button } from '#components/base/Button/Button'
import { ButtonIcon } from '#components/base/ButtonIcon/ButtonIcon'
import { SystemIcon } from '#components/base/SystemIcon'
import { Text } from '#components/base/Text'
import { FormField } from '#components/form/FormField'
import { Input } from '#components/form/Input'
import { useOverlay } from '#components/overlay/hooks'
import { getUserName } from '#components/utils/getUserName'
import { toastError, toastSuccess } from '#components/utils/Toast'
import { tw } from '#components/utils/tw'
import { Header } from '#components/widgets/Header'
import { gql } from '#graphql/urql'
import { useCurrentUser } from '#graphql/utils/useCurrentUser'
import { replace } from '#navigator/helpers'
import { AvatarProfile } from '#screens/Profile/AvatarProfile'
import { S } from '#store'
import type { ResponseFile } from '#types/media'

import { ActionSheetImage } from './ActionSheetImage'
import {
  checkCameraPermission,
  checkPhotoLibraryPermission,
} from './onCheckPermission'
import { onChooseAvatar, onTakePicture } from './onTakePicture'

const FormSchema = z.object({
  name: z.string({ required_error: 'Name is required' }),
  email: z.string({ required_error: 'Email is required' }),
})

type EditProFileValue = z.infer<typeof FormSchema>

export const EditProfile = observer(() => {
  const [u] = useCurrentUser()

  const { openActionsheet } = useOverlay()
  const [showEditIcon, setShowEditIcon] = useState(false)
  const { control, watch, setValue } = useForm<EditProFileValue>({
    resolver: zodResolver(FormSchema),
    shouldFocusError: true,
  })

  const [avatarUrl, setAvatarUrl] = useState<string | undefined>(
    S.shared?.currentUser?.thumbnail?.url,
  )
  const imageInputRef = useRef<HTMLInputElement | null>(null)
  const [thumbnailId, setThumbnailId] = useState<string>('')
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [fileUpload, setFileUpload] = useState<UploadFile | null>(null)

  const nameValue = watch('name')
  const insets = useSafeAreaInsets()
  useEffect(() => {
    setShowEditIcon(nameValue !== '')
  }, [nameValue])

  useEffect(() => {
    const name = getUserName({
      email: S.shared.currentUser?.email || '',
      name: S.shared.currentUser?.name || '',
    })
    setValue('name', name)
    setValue('email', S.shared.currentUser?.email ?? '')
    setThumbnailId(S.shared.currentUser?.thumbnailId ?? '')
  }, [])

  const bottomSheet = async () => {
    openActionsheet(ActionSheetImage, {
      onDeleteAvatar: handleDeleteAvatar,
      onUploadAvatar: handleUploadAvatar,
      ontakePicture: handleTakePicture,
    })
  }

  const handleTakePicture = async () => {
    try {
      const hasPermission = await checkCameraPermission()

      if (!hasPermission) {
        toastError({
          message: 'Camera permission is required to take a picture!',
        })
        return Promise.resolve(true)
      }
      const res = await onTakePicture()
      if (res) {
        setThumbnailId(res.id)
        setAvatarUrl(res.url)
        toastSuccess({ message: 'Image change successful' })
        return true
      } else {
        toastError({ message: 'Upload image fail!' })
        return false
      }
    } catch (error) {
      toastError({ message: 'Upload image fail!' })
      return false
    }
  }

  const handleButtonPress = async () => {
    const res = await gql.updateUser({
      data: { name: nameValue, thumbnailId },
    })
    if (res.data?.updateUser) {
      S.shared.currentUser = res.data?.updateUser
      S.shared.setAvatar(res.data.updateUser.thumbnail?.url ?? '')
      toastSuccess({ message: 'Profile updated successfully' })
    } else {
      toastError({ message: 'Profile update failed. Please try again' })
    }
  }

  const handleDeleteAvatar = () => {
    setAvatarUrl(undefined)
    S.shared.setAvatar('')
    setThumbnailId('')
    setFileUpload(null)
    setIsOpen(false)
    if (imageInputRef.current) {
      imageInputRef.current.value = ''
    }
  }

  const handleUploadAvatar = async () => {
    try {
      if (Platform.OS === 'web') {
        imageInputRef.current?.click()
        return Promise.resolve(true)
      } else {
        const hasPermission = await checkPhotoLibraryPermission()

        if (!hasPermission) {
          toastError({
            message: 'Photo library permission is required to take a picture!',
          })
          return Promise.resolve(true)
        }
        const res = await onChooseAvatar()
        if (res) {
          setThumbnailId(res.id)
          setAvatarUrl(res.url)
          toastSuccess({
            message: 'Image change successful',
          })
          return true
        } else {
          toastError({
            message: 'Upload image fail!',
          })
          return false
        }
      }
    } catch (error) {
      toastError({
        message: 'Upload image fail!',
      })
      return false
    }
  }

  if (!u) {
    return null
  }
  const handleImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0]
      const fileToUpLoad = convertFileToUploadFile(file)
      setFileUpload(fileToUpLoad)
      setIsOpen(true)
    }
  }
  const convertFileToUploadFile = (file: File): UploadFile => {
    const uploadFile: UploadFile = {
      uid: file.name,
      name: file.name,
      type: file.type,
      size: file.size,
      originFileObj: file as RcFile,
    }

    return uploadFile
  }
  const handleChangePassWord = () => {
    replace('App', { screen: 'ChangePassword' })
  }
  const handleEditPress = () => {
    setValue('name', '') // Clear the input field value
  }

  return (
    <View
      style={tw.style(
        'flex-1 flex-col',
        Platform.OS === 'ios' && {
          paddingBottom: Math.max(insets.bottom / 2),
        },
        // Platform.OS === 'ios' && { paddingTop: insets.top },
      )}
    >
      <Header isDark title='Edit profile' />
      <View style={tw`items-center px-4 flex-1 mt-2`}>
        {avatarUrl ? (
          <View
            style={tw.style(
              'w-20 h-20 rounded-full bg-neutral-200 items-center justify-center',
            )}
          >
            <AvatarProfile
              key={avatarUrl}
              size='large'
              name={S.shared.currentUser?.name}
              style={tw`w-20 h-20 rounded-full`}
              source={{
                uri: avatarUrl,
              }}
            />
          </View>
        ) : (
          <View
            style={tw.style(
              'w-20 h-20 rounded-full bg-neutral-200 items-center justify-center',
            )}
          >
            <SystemIcon type='SAX' name='Camera' size={32} />
          </View>
        )}

        <Button
          titleColor={tw.color('primary-400')}
          tone='plain'
          onPress={bottomSheet}
        >
          Set new photo
        </Button>
        {Platform.OS === 'web' && (
          <input
            type='file'
            accept='image/*'
            onChange={handleImageChange}
            style={{ display: 'none' }}
            id='imageUpload'
            ref={imageInputRef}
          />
        )}
        <View style={tw`w-full gap-y-6 mt-8`}>
          <FormField name='name' control={control}>
            <Input
              autoCapitalize='none'
              placeholder='Type your name here'
              keyboardType='email-address'
              suffix={
                showEditIcon && (
                  <ButtonIcon
                    icon={{
                      name: 'Edit2',
                      type: 'SAX',
                    }}
                    shadow={false}
                    style={tw`p-0 bg-transparent`}
                    tone='secondary'
                    size={24}
                    onPress={handleEditPress} // Add onPress handler
                  />
                )
              }
              prefix={
                <View style={tw.style('pr-2')}>
                  <SystemIcon
                    type='SAX'
                    name='ProfileCircle'
                    color={tw.color('icon')}
                  />
                </View>
              }
            />
          </FormField>
          <FormField name='email' control={control}>
            <Input
              editable={false}
              style={tw.style('text-text-2')}
              prefix={
                <View style={tw.style('pr-2')}>
                  <SystemIcon
                    type='SAX'
                    name='Sms'
                    color={tw.color('text-text-2')}
                  />
                </View>
              }
            />
          </FormField>
          <TouchableOpacity onPress={handleChangePassWord}>
            <Text specialType='paragraph1' color={tw.color('primary-400')}>
              Change password
            </Text>
          </TouchableOpacity>
        </View>
      </View>
      <View style={tw.style('pb-4 px-4')}>
        <Button
          disabled={
            (avatarUrl === S.shared.currentUser?.thumbnail?.url &&
              thumbnailId === S.shared.currentUser?.thumbnailId &&
              nameValue === S.shared.currentUser?.name) ||
            !nameValue
            // !avatarUrl
            // avatarUrl
          }
          onPress={handleButtonPress}
        >
          Save
        </Button>
      </View>
      {Platform.OS === 'web' && !!fileUpload && (
        <CropImage
          isOpen={isOpen}
          file={fileUpload}
          aspect={1 / 1}
          circularCrop
          circleSize={100}
          minHeight={100}
          onCrop={(value: ResponseFile) => {
            setThumbnailId(value.id)
            setAvatarUrl(value.url)
          }}
          onCancel={() => {
            setIsOpen(false)
            setFileUpload(null)
          }}
        />
      )}
    </View>
  )
})
