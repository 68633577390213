import type { AvatarProps } from 'antd'
import { Avatar, Flex } from 'antd'
import type React from 'react'

import { ModalLayout } from '#admin/components/widgets/ModalLayout'
import { tw } from '#components/utils/tw'

type Pr = {
  closeModal: () => void
  avatarUrl: string
  shape?: AvatarProps['shape']
  title?: string
}

export const ModalImage: React.FC<Pr> = ({
  closeModal,
  avatarUrl,
  shape = 'circle',
  title,
}) => {
  const handleModalCancel = () => {
    closeModal()
  }
  return (
    <ModalLayout
      title={title || ''}
      closeModal={handleModalCancel}
      onConfirm={handleModalCancel}
      showOnlyCloseButton
    >
      <Flex style={tw`flex-1 items-center justify-center w-auto`}>
        <Flex style={tw`flex-1 items-center justify-center`}>
          <Avatar
            shape={shape}
            src={avatarUrl}
            size={250}
            style={tw`flex-1/2 border-2 border-gray-300`}
          />
        </Flex>
      </Flex>
    </ModalLayout>
  )
}
