import { Button, Flex } from 'antd'
import { useEffect } from 'react'

import {
  getQuesPhraseFromQuesUi,
  getQuesUiFromAns,
} from '#admin/screens/QuesDetail/HandleQuesFillTheBlank'
import type { Answer, InputQuestionGame } from '#types/games'
import type { SearchWordItem } from '#types/word'

type Props = {
  wordChoose: {
    word1: number
    word2: number
  }
  setWordChoose: (wordChoose: { word1: number; word2: number }) => void
  word: SearchWordItem | null
  isChange: boolean
  setQuestionPhrase: (value: string) => void
  setIsChange: (value: boolean) => void
  questionPhrase: string
  setQuestion: (question: Partial<InputQuestionGame>) => void
  question: InputQuestionGame
  questionUI: string
  setQuestionUI: (value: string) => void
  answers: Answer[]
}

export const FillTheBlank = ({
  wordChoose,
  setWordChoose,
  word,
  isChange,
  setQuestionPhrase,
  setIsChange,
  questionPhrase,
  setQuestion,
  question,
  questionUI,
  setQuestionUI,
  answers,
}: Props) => {
  useEffect(() => {
    if (isChange && !word?.isVocabulary) {
      if (wordChoose.word1 === -1) {
        setQuestionPhrase(word?.vocabAndPhrase ?? '')
      } else {
        let temp = ''
        word?.vocabAndPhrase?.split(' ').map((e, index) => {
          if (index === wordChoose.word1 || index === wordChoose.word2) {
            temp += '... '
          } else {
            temp += e + ' '
          }
        })
        setQuestionPhrase(temp.trim())
      }
      setIsChange(false)
    }
  }, [wordChoose, isChange])
  useEffect(() => {
    if (question.typeGame === 'fillTheBlank' && word) {
      let temp: string = ''
      let count = 0
      questionUI?.split(' ').map(e => {
        if (e.toLowerCase() === word?.vocabAndPhrase.toLowerCase()) {
          if (count !== wordChoose.word1) {
            temp += e + ' '
          } else {
            temp += '... '
          }
          count++
        } else {
          temp += e + ' '
        }
      })
      const quesText = !word?.isVocabulary
        ? questionUI
            ?.toLowerCase()
            .replace(
              word?.vocabAndPhrase.toLowerCase() ?? '',
              questionPhrase ?? word?.vocabAndPhrase ?? '',
            )
        : temp.trim()
      setQuestion({
        text: quesText.replace(
          quesText[0],
          quesText[0]?.toUpperCase() ?? quesText[0],
        ),
      })
    }
  }, [word, questionUI, questionPhrase, wordChoose])

  useEffect(() => {
    if (question.typeGame === 'fillTheBlank' && question.id) {
      if (!word?.isVocabulary) {
        const quesUi = getQuesUiFromAns(
          question.text,
          answers.find(e => e.isTrue)?.text ?? '',
          word?.vocabAndPhrase ?? '',
        )
        setQuestionUI(quesUi)

        const quesPhrase = getQuesPhraseFromQuesUi(
          quesUi,
          word?.vocabAndPhrase ?? '',
          question.text,
        )
        setQuestionPhrase(quesPhrase.quesPhrase)
        setWordChoose({
          word1: quesPhrase.word1,
          word2: quesPhrase.word2,
        })
      } else {
        let count = 0
        setQuestionUI(
          question.text.replace('...', word.vocabAndPhrase.toLowerCase()),
        )
        question.text.split(' ').map(e => {
          if (e === '...') {
            setWordChoose({ word1: count, word2: -1 })
          }
          if (e.toLowerCase() === word.vocabAndPhrase.toLowerCase()) {
            count++
          }
        })
      }
    }
  }, [question.id])
  useEffect(() => {
    if (question.typeGame === 'fillTheBlank' && question.text === '') {
      setQuestion({ text: word?.vocabAndPhrase })
    }
  }, [question.typeGame])

  return (
    <div>
      {!word?.isVocabulary ? (
        <Flex gap={8}>
          {word?.vocabAndPhrase
            .trim()
            .split(' ')
            .map((e, index) => (
              <Button
                key={index}
                type={
                  wordChoose.word1 === index || wordChoose.word2 === index
                    ? 'primary'
                    : 'default'
                }
                onClick={() => {
                  if (wordChoose.word1 === index) {
                    if (wordChoose.word2 !== -1) {
                      setWordChoose({
                        ...wordChoose,
                        word1: wordChoose.word2,
                        word2: -1,
                      })
                    } else {
                      setWordChoose({ word1: -1, word2: -1 })
                    }
                  } else if (wordChoose.word2 === index) {
                    setWordChoose({ ...wordChoose, word2: -1 })
                  } else {
                    if (wordChoose.word1 === -1) {
                      setWordChoose({ word1: index, word2: -1 })
                    } else {
                      setWordChoose({ ...wordChoose, word2: index })
                    }
                  }
                  setIsChange(true)
                }}
              >
                {e}
              </Button>
            ))}
        </Flex>
      ) : (
        <Flex gap={8}>
          {questionUI
            ?.trim()
            .split(' ')
            .filter(e => e.toLowerCase() === word.vocabAndPhrase.toLowerCase())
            .map((e, index) => (
              <Button
                key={index}
                type={
                  wordChoose.word1 === index || wordChoose.word2 === index
                    ? 'primary'
                    : 'default'
                }
                onClick={() => {
                  if (wordChoose.word1 === index) {
                    setWordChoose({ word1: -1, word2: -1 })
                  } else {
                    setWordChoose({ word1: index, word2: -1 })
                  }
                  setIsChange(true)
                }}
              >
                {e}
              </Button>
            ))}
        </Flex>
      )}
    </div>
  )
}
