import { observer } from 'mobx-react-lite'
import { Fragment } from 'react'
import { View } from 'react-native'

import { ScrollView } from '#components/base/ScrollView'
import { tw } from '#components/utils/tw'
import { Divider } from '#components/widgets/Divider'
import { Header } from '#components/widgets/Header'
import { gql } from '#graphql/urql'
import { navigate } from '#navigator/helpers'
import type { ItemProps } from '#screens/Profile/Item'
import { Item } from '#screens/Profile/Item'
import { onLogoutPress } from '#screens/Profile/onLogoutPress'
import { S } from '#store'

type IdType =
  | 'edit-profile'
  | 'push-notification'
  | 'dark-mode'
  | 'private-policy'
  | 'help'
  | 'log-out'
  | 'delete-account'

type ItemD = ItemProps & {
  id: IdType
  isDivider?: boolean
}

export const Setting = observer(() => {
  const data: ItemD[] = [
    {
      id: 'edit-profile',
      title: 'Edit profile',
      iconLeft: 'Edit',
      type: 'icon',
      isDivider: true,
    },
    // {
    //   id: 'push-notification',
    //   title: 'Push notification',
    //   iconLeft: 'Notification',
    //   type: 'switch',
    //   isDivider: true,
    // },
    // {
    //   id: 'dark-mode',
    //   title: 'Dark mode',
    //   iconLeft: 'Moon',
    //   type: 'switch',
    //   isDivider: true,
    // },
    {
      id: 'private-policy',
      title: 'Private policy',
      iconLeft: 'ShieldTick',
      type: 'icon',
    },
    {
      id: 'help',
      title: 'Help',
      iconLeft: 'MessageQuestion',
      type: 'icon',
      isDivider: true,
    },
    {
      id: 'log-out',
      title: 'Log out',
      iconLeft: 'LoginCurve',
      type: 'icon',
      isDivider: true,
    },
  ]

  const screenRedirect = async (id: string) => {
    switch (id) {
      case 'edit-profile': {
        navigate('App', {
          screen: 'EditProfile',
        })
        break
      }
      case 'private-policy': {
        navigate('App', {
          screen: 'PrivacyPolicy',
        })
        break
      }
      case 'log-out': {
        onLogoutPress()
        break
      }
      case 'help': {
        // navigate('App', { screen: 'ChooseLevel', params: { isShow: true } })
        break
      }
      case 'log-out': {
        await onLogoutPress()
        break
      }
    }
  }

  const onPressClearGames = async () => {
    const respWordInUser = await gql.searchWordInUser(
      {
        filter: { userId: S.shared.currentUser?.id },
      },
      {
        requestPolicy: 'network-only',
      },
    )
    const wordInUser = respWordInUser.data?.searchWordInUser || []
    if (wordInUser.length > 0) {
      await Promise.all(
        wordInUser.map(async w => await gql.deleteWordInUser({ id: w.id })),
      )
    }
    const respTopicInUser = await gql.searchTopicInUser(
      {
        filter: { userLearnId: S.shared.currentUser?.id },
      },
      {
        requestPolicy: 'network-only',
      },
    )
    const topicInUser = respTopicInUser.data?.searchTopicInUser || []
    if (topicInUser.length > 0) {
      await Promise.all(
        topicInUser.map(async t => await gql.deleteTopicInUser({ id: t.id })),
      )
    }
  }

  const onPressClearTask = () => {
    gql.deleteMessage()
  }

  return (
    <View style={tw`flex-1 flex-col`}>
      <Header isBack={true} title='Setting' />
      <ScrollView>
        <View style={tw.style('flex-col mx-4 py-4 rounded-3xl')}>
          <View
            style={tw.style('rounded-2xl bg-background-light-white pt-2 gap-2')}
          >
            {data.map(({ id, isDivider, ...itemProps }) => (
              <Fragment key={id}>
                <Item {...itemProps} onPress={() => screenRedirect(id)} />
                {isDivider && (
                  <Divider style={tw.style('border-neutral-200')} />
                )}
              </Fragment>
            ))}
          </View>
        </View>
        <View style={tw.style('pt-2 px-2')}>
          <View
            style={tw.style('rounded-full bg-neutral-75 shadow-lg', {
              shadowColor: tw.color('primary-300'),
            })}
          >
            <Item
              iconLeft='Trash'
              onPress={onPressClearGames}
              title='Clear games'
              type='icon'
            />
          </View>
        </View>
        <View style={tw.style('pt-2 px-2')}>
          <View
            style={tw.style('rounded-full bg-neutral-75 shadow-lg', {
              shadowColor: tw.color('primary-300'),
            })}
          >
            <Item
              iconLeft='Trash'
              onPress={onPressClearTask}
              title='Clear tasks & messages'
              type='icon'
            />
          </View>
        </View>
      </ScrollView>
    </View>
  )
})
